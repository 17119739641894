import axios from "axios";
import { api } from "../../service";
import {
  SET_EXCHANGE_ACCOUNT_LIST,
  SET_PHONE_CODES,
  SET_ACCOUNT_STATUSES,
  SET_GENERAL_ASSETS,
  SET_BASKET_TRADING_MODELS,
  SET_RISK_MANAGEMENT_MODELS,
  SET_ASSETS_WITH_PRICE,
} from "./constant";

/**
 * Get exchange accounts list.
 *
 * @returns
 */
export const getExchangeAccounts = () => async (dispatch) => {
  const { data } = await api.get(`/reference/exchange-accounts`);

  if (data) dispatch({ type: SET_EXCHANGE_ACCOUNT_LIST, payload: data });

  return data;
};

/**
 * Get phone codes.
 *
 * @returns array
 */
export const getPhoneCodes = () => async (dispatch) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_KEY}/reference/countries`);

  if (data) dispatch({ type: SET_PHONE_CODES, payload: data });

  return data;
};

/**
 * Get account statuses.
 *
 * @returns array;
 */
export const getAccountStatuses = () => async (dispatch) => {
  const { data } = await api.get(`/reference/client-account-status`);

  if (data) dispatch({ type: SET_ACCOUNT_STATUSES, payload: data });

  return data;
};

/**
 * Get all of any recognised asset.
 *
 * @returns array;
 */
export const getAssets = () => async (dispatch) => {
  const { data } = await api.get(`/reference/assets`);

  if (data) dispatch({ type: SET_GENERAL_ASSETS, payload: data });

  return data;
};

/**
 * Get all of asset models by Basket trading.
 *
 * @returns array;
 */
export const getAssetModelsByBasketTrading = () => async (dispatch) => {
  const { data } = await api.get(`/reference/assets/basket-trading-models`);

  if (data) dispatch({ type: SET_BASKET_TRADING_MODELS, payload: data });

  return data;
};

/**
 * Get all of asset models by Risk Management.
 *
 * @returns array;
 */
 export const getAssetModelsByRiskManagement = () => async (dispatch) => {
  const { data } = await api.get(`/reference/assets/risk-management-models`);

  if (data) dispatch({ type: SET_RISK_MANAGEMENT_MODELS, payload: data });

  return data;
};

export const getAssetsPriceByExchangeAccountID = (pxExAccountID) => async (dispatch) => {
  const { data } = await api.get(`/reference/exchange-accounts/${pxExAccountID}/assets`);

  if (data) dispatch({ type: SET_ASSETS_WITH_PRICE, payload: data });

  return data;
};

export const getChannels = () => async (dispatch) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_KEY}/reference/social-networks`);

  return data;
}