import React from "react";

export default function MarkerTemplate(item, holdings) {
  var color = item.visible ? item.marker.fill : "#888";
  return (
    <React.Fragment>
      <svg width="60">
        <g>
          <rect
            x={15}
            y={0}
            width={30}
            height={20}
            dominantBaseline="middle"
            textAnchor="middle"
            fill={color}
            stroke="#000"
            strokeWidth="1"
          ></rect>
        </g>
        <text
          x="50%"
          y={37}
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={15}
          fill="#878787"
        >
          {item.text}
        </text>
        <text
          x="50%"
          y={59}
          dominantBaseline="middle"
          textAnchor="middle"
          fill={color}
        >
          {holdings[item.id] && `${Math.round(holdings[item.id].percent_holding * 100) / 100}%`}
        </text>
      </svg>
    </React.Fragment>
  );
}
