import { SET_REDIRECTED_FROM_DEMO, SET_STEP_INDEX, SET_TOUR_STEPS, START_TOUR, START_DEMO } from "./constant";

const DEFAULT_STATE = {
  run: false,
  steps: [],
  stepIndex: 0,
  redirectedDuringDemo: false,
  startDemo: false,
};

const tourReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case START_DEMO:
      return { ...state, startDemo: action.payload };
    case START_TOUR:
      return { ...state, run: action.payload };
    case SET_TOUR_STEPS:
      return { ...state, steps: action.payload };
    case SET_STEP_INDEX:
      return { ...state, stepIndex: action.payload };
    case SET_REDIRECTED_FROM_DEMO:
      return { ...state, redirectedDuringDemo: action.payload };
    default:
      return state;
  }
};

export default tourReducer;
