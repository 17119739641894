import React from "react";

const CustomTitle = ({ data }) => {
  return (
    <div className="px-accordion-title">
      <span>{data.name}</span>
      <span className={`dot ${data.status_class_name}`}></span>
    </div>
  );
};
export default CustomTitle;
