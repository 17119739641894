import React from "react";
import {
  Chart,
  Size,
  Series,
  ArgumentAxis,
  CommonSeriesSettings,
  Legend,
  Margin,
  Tooltip,
  Grid,
  Point,
} from "devextreme-react/chart";
import { useScreenSize } from "../../../utils/media-query.js";
import { COLOR_PALETTE } from "../../../constants/app.js";

const serieSources = [
  { value: "percent1", name: "Buy & Hold" },
  { value: "percent2", name: "Risk Managed" },
];

const HypotheticcalComparison = ({ risk_history }) => {
  const screenSize = useScreenSize();

  // Filter the data by the original one which comes from the server.
  const newRiskHistory =
    risk_history &&
    risk_history.map((item) => {
        // Assgin fields here.
      return {
        day: item[0],
        percent1: item[1],
        percent2: item[2],
      };
    });

  // Display date and account value in the tooltip.
  const customizeTooltip = (pointInfo) => {
    return { text: pointInfo.argument + "\n" + pointInfo.value };
  };

  return (
    <React.Fragment>
      <Chart palette="Violet" dataSource={newRiskHistory} sortingMethod={false}>
        {screenSize.isXSmall && <Size width={280} height={800} />}
        {serieSources.map(function (item, index) {
          return (
            <Series key={item.value} valueField={item.value} name={item.name} color={COLOR_PALETTE[index]} />
          );
        })}
        <CommonSeriesSettings argumentField="day" type={"line"}>
          <Point size={2} />
        </CommonSeriesSettings>
        <Margin bottom={20} />
        <ArgumentAxis
          valueMarginsEnabled={false}
          discreteAxisDivisionMode="crossLabels"
          inverted={true}
        >
          <Grid visible={true} />
        </ArgumentAxis>
        <Legend
          verticalAlignment="bottom"
          horizontalAlignment="center"
          itemTextPosition="bottom"
        />
        <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
      </Chart>
    </React.Fragment>
  );
};

export default HypotheticcalComparison;
