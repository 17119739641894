import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popup } from "devextreme-react/popup";
import CompetitionEndMessageIcon from "../assets/imgs/competition-cup-stars.png";
import { Button } from "devextreme-react";
import { setRankingsData } from "../redux/dashboard/action";

const CompetitionEndMessage = ({ rankingsData }) => {
  const dispatch = useDispatch();
  const rankOne = rankingsData?.[0];
  const rankingsDataExceptTopOne = rankingsData.filter((item) => item.rank !== rankOne.rank);

  const accounts = useSelector((state) => state.accounts.list);
  const user = useSelector((state) => state.auth.info);
  const myRank = rankingsData.find((item) => item.user_id === user.client_user_id)?.rank;

  let hashAccounts = {};
  accounts?.map((item) => {
    hashAccounts = { ...hashAccounts, [item.client_user_id]: item }
    return item;
  })

  const handleValuesWithSuffix = (value) => {
    let parsedValue = parseInt(value);
    let numberStr = '';

    if (parsedValue >= 10 && parsedValue < 20) {
      numberStr = parsedValue + "th";
    } else {
      const numberMod = parsedValue % 10;
      switch (numberMod) {
        case 1:
          numberStr = parsedValue + 'st';
          break;
        case 2:
          numberStr = parsedValue + 'nd';
          break;

        case 3:
          numberStr = parsedValue + 'rd';
          break;
        default:
          numberStr = parsedValue + 'th';
          break;
      }
    }

    return numberStr;
  }

  const renderContent = () => {
    return (
      <div>
        <div className="px-header">
          <div style={{
            backgroundImage: `url(${CompetitionEndMessageIcon})`,
            height: 250,
            backgroundSize: "contain",
            backgroundColor: "#143A65",
            position: "relative",
          }} >
            <p style={{ position: "absolute", bottom: 0, width: "100%", textAlign: "center", fontSize: 20, color: rankOne.user_id === user.client_user_id ? "green" : "yellow", marginBottom: 5 }}>1st Place - {rankOne?.game_name}</p>
          </div>
        </div>
        <div className="px-content">
          <ul style={{
            listStyle: "none",
            padding: 0
          }}>
            {rankingsDataExceptTopOne && rankingsDataExceptTopOne.length > 0 && rankingsDataExceptTopOne.map((item) => {
              // Since original data was spliced to pick up the first rank.
              // We need to plus 1 to filter correctly.
              if (item.rank === 2 || item.rank === 3) {
                return (
                  <li key={item.rank} style={{
                    backgroundColor: myRank === item.rank ? "green" : "#143A65",
                    padding: "5px 10px",
                    color: "white",
                    marginBottom: 5,
                    textAlign: "center",
                  }}>{handleValuesWithSuffix(item.rank)} - {item.game_name}</li>
                )
              }
              if (item.rank === 4 && myRank > 4) {
                return (
                  <li key={item.rank} style={{
                    backgroundColor: "#143A65",
                    padding: "5px 10px",
                    color: "white",
                    marginBottom: 5,
                    textAlign: "center",
                  }}>{"..."} </li>
                )
              }
              if (item.rank > 4 && myRank === item.rank) {
                return (
                  <li key={item.rank} style={{
                    backgroundColor: myRank === item.rank ? "green" : "#143A65",
                    padding: "5px 10px",
                    color: "white",
                    marginBottom: 5,
                    textAlign: "center",
                  }}>{handleValuesWithSuffix(item.rank)} - {item.game_name} </li>
                )
              }
              return <></>;
            })}
          </ul>
        </div>
        <Button onClick={() => dispatch(setRankingsData(null))} text="OK" type="primary" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} />
      </div>
    );
  };

  return (
    <Popup
      visible={true}
      animation={{
        show: {
          type: "pop",
          duration: 10,
          from: {
            scale: 0.55
          }
        }
      }}
      width={300}
      height={550}
      position={"center"}
      showCloseButton={false}
      closeOnOutsideClick={false}
      contentRender={renderContent}
      className={"px-competition-end-message-popup"}
      titleRender={(e) => <div style={{ width: "100%"}}><p style={{ textAlign: "center", fontWeight: "bold", fontSize: 26, margin: "15px 15px" }}>WINNERS</p></div>}
    />
  );
};

export default CompetitionEndMessage;
