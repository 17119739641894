import React from "react";
import { useSelector } from "react-redux";
import BasketTrading from "./BasketTrading";
import RiskManagement from "./RiskManagement";

const CompleteSetup = ({ previousStep, setLoading }) => {
  const assetsInfo = useSelector((state) => state.manageAsset.assetsInfo);

  const currentManagementTypeID = assetsInfo?.current_management_type_id;
  return (
    <>
      {currentManagementTypeID === 1 && (
        <BasketTrading
          setLoading={setLoading}
          previousStep={previousStep}
          isSetup={true}
        />
      )}
      {currentManagementTypeID === 2 && (
        <RiskManagement previousStep={previousStep} isSetup={true} />
      )}
    </>
  );
};
export default CompleteSetup;
