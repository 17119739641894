import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useDigitInput from "react-digit-input";
import { useToast } from "../../../contexts/toast";
import { Button } from "devextreme-react/button";
import "./VrDevicePairing.scss";
import { connectDeviceWithPairCode } from "../../../redux/auth/action";
import {
  DEVICE_REGISTERED_FAIL_MSG,
  DEVICE_REGISTERED_SUCCESS_MSG,
  DEVICE_REGISTERED_TIMED_OUT_MSG,
} from "../../../constants";

const VrDevicePairing = () => {
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const [value, onChange] = useState("");
  const [disableSubmit, setdisableSubmit] = useState(true);
  const [digitCode, setDigitCode] = useState(null);

  const digits = useDigitInput({
    acceptedCharacters: /^[a-zA-Z0-9]*$/,
    length: 6,
    value,
    onChange,
  });

  const getCodes = useCallback(async (res) => {
    if (!res) return;
    setdisableSubmit(false);
    setDigitCode(res);

    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
  }, []);

  const registerDevice = async () => {
    const data = await dispatch(
      connectDeviceWithPairCode({ pair_code: digitCode })
    );

    if (!data) return;
    if (!checkPairCodeStatus(data)) {
      //To set auto-focus after getting the error message.
      var firstDigitElement = document.getElementById("first-digit");
      firstDigitElement.focus();
      setdisableSubmit(true);
    }
  };

  const checkPairCodeStatus = (data) => {
    switch (data.pairing_status) {
      case "Invalid":
        showToast(DEVICE_REGISTERED_FAIL_MSG, "error", 5000);
        return false;
      case "Complete":
        showToast(DEVICE_REGISTERED_SUCCESS_MSG, "success", 5000);
        return true;
      case "Timed Out":
        showToast(DEVICE_REGISTERED_TIMED_OUT_MSG, "error", 5000);
        return false;
      default:
        break;
    }
  };

  const isArrayFull = (arr) => {
    if (arr.length === 0) return false;
    for (var i = 0, l = arr.length; i < l; i++) {
      if (" " === arr[i]) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (isArrayFull(value.split(""))) {
      setTimeout(() => {
        getCodes(value);
      }, 200);
    }
  }, [getCodes, value]);

  return (
    <React.Fragment>
      <div
        className={
          "content-block dx-card responsive-paddings px-device-pair-container"
        }
      >
        <h2 className={"content-block"}>VIRTUAL REALITY DEVICE PAIRING</h2>
        <div className="content dx-fieldset">
          <div className="dx-field">
            <div className={"dx-field-value width-100"}>
              <div className="input-group">
                <input
                  id="first-digit"
                  className="digit"
                  inputMode="decimal"
                  autofocus="true"
                  {...digits[0]}
                />
                <input
                  name="digit"
                  className="digit"
                  inputMode="decimal"
                  {...digits[1]}
                />
                <input
                  name="digit"
                  className="digit"
                  inputMode="decimal"
                  {...digits[2]}
                />
                <span className="hyphen" />
                <input
                  name="digit"
                  className="digit"
                  inputMode="decimal"
                  {...digits[3]}
                />
                <input
                  name="digit"
                  className="digit"
                  inputMode="decimal"
                  {...digits[4]}
                />
                <input
                  name="digit"
                  className="digit"
                  inputMode="decimal"
                  {...digits[5]}
                />
              </div>
              <span>
                Enter pairing code displayed in your virtual reality headset
              </span>
              <div className="px-button-section">
                <Button
                  text="SUBMIT"
                  width="150"
                  type="default"
                  disabled={disableSubmit}
                  onClick={() => registerDevice()}
                  style={{ marginRight: 10 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default VrDevicePairing;
