import React, { createContext, useCallback, useContext, useState } from "react";
import { Toast } from "devextreme-react/toast";

const ToastContext = createContext({});
const useToast = () => useContext(ToastContext);

const ToastProvider = ({ position, children }) => {
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "info",
    message: "",
    displayTime: 1000,
    position,
  });

  const onHiding = () => {
    setToastConfig({
      ...toastConfig,
      isVisible: false,
    });
  };

  const showToast = useCallback((message, type = "info", displayTime) => {
    setToastConfig((curConfig) => ({
      ...curConfig,
      isVisible: true,
      message,
      type,
      displayTime
    }));
  }, []);

  return (
    <>
      <ToastContext.Provider value={{ showToast }}>
        {children}
      </ToastContext.Provider>
      <Toast
        visible={toastConfig.isVisible}
        message={toastConfig.message}
        type={toastConfig.type}
        onHiding={onHiding}
        displayTime={toastConfig.displayTime}
        position={"bottom right"}
      ></Toast>
    </>
  );
};

export { ToastProvider, useToast };
