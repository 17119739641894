import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import "devextreme-react/text-area";
import { Button } from 'devextreme-react/button';
import Tabs from 'devextreme-react/tabs';
import { LoadPanel } from 'devextreme-react/load-panel';
import EditAccountInformation from "../../../components/account-setup/EditAccountInformation";
import AccountStatsAndHoldings from "../../../components/account-setup/AccountStatsAndHoldings";
import BasketTrading from "../../../components/account-setup/BasketTrading";
import RiskManagement from "../../../components/account-setup/RiskManagement";
import BasicRiskManagement from "../../../components/account-setup/BasicRiskManagement";
import BasicBasketTrading from "../../../components/account-setup/BasicBasketTrading";
import {
  getAccountInformationByID,
  getAccountHoldings,
  getAccountStats,
  getApiKeyAndSecret,
  updateAccountStatus,
  updateApiKeyAndSecret,
  setAccountInformation,
  setSelectedAccount,
  getBasketAssetsWithAssetsThatCouldBeBasket,
  getRiskManagedAssetsWithAssetsThatCouldBeRiskManaged,
  setManagementTypeID,
  setOriginalManagementTypeID,
} from "../../../redux/accounts/action";
import { getAssetAllocationByAccountID } from "../../../redux/manage-asset/action";
import {
  getAssetModelsByBasketTrading,
  getAssetsPriceByExchangeAccountID,
} from "../../../redux/references/action";
import { api } from "../../../service";
import { useToast } from "../../../contexts/toast";
import { useContentLoading } from "../../../contexts/contentLoading";
import {
  ACCOUNT_ACTIONS,
  ADVANCED_ACCOUNT_TABS,
  BASIC_ACCOUNT_TABS,
  DEFAULT_TOAST_TIME,
  UPDATE_ACCOUNT_INFORMATION,
} from "../../../constants/app";
import "./Accounts.scss";
import ViewOnlyManagement from "../../../components/account-setup/ViewOnlyManagement";
import { ProfileFormEditedBefore } from "../../../redux/auth/action";

const FilterArray = (arr, type) => {
  const data =
    arr &&
    arr.map((item, index) => {
      type === "holdings" ? (item.holding_id = index) : (item.stats_id = index);
      return item;
    });

  return data;
};

const EditAccount = () => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const mountedStatusRef = useRef(false);
  const intervalHandlerRef = useRef(undefined);
  const { showToast } = useToast();
  const { setLoadingProgress, setLoadingIndicator, setMessage } =
    useContentLoading();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [position, setPosition] = useState({ of: "#edit-client-user" });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [filteredHoldings, setFilteredHoldings] = useState([]);
  const [filteredStats, setFilteredStats] = useState([]);
  const [accountAction, setAccountAction] = useState([]);
  const [apiInfo, setApiInfo] = useState(null);
  const [tabData, setTabData] = useState(null);

  const accountID = match.params.accountID;

  const accounts = useSelector((state) => state.accounts.list);
  const account = useSelector((state) => state.accounts.info);

  const holdings = useSelector((state) => state.accounts.holdings);
  const stats = useSelector((state) => state.accounts.stats);
  const exchanges = useSelector((state) => state.reference.exchanges);

  // const defaultTarget = account ? account.management_type_id : 1;

  const managementTypeID = useSelector(
    (state) => state.accounts.curManagementTypeID
  );
  const exchangeAccountID = account ? account.exchange_account_id : null;
  const apiStatusID = account?.api_status_id || null;
  const accountReference = account?.account_reference || null;

  useEffect(() => {
    if (location && location.state) {
      setSelectedIndex(location.state.tabIndex);
    }
    // If the page is mounted.
    mountedStatusRef.current = true;

    // When the page is unmounted such like user leave the page.
    return () => {
      mountedStatusRef.current = false;
      dispatch(setAccountInformation(null));
      dispatch(setManagementTypeID(null));
      dispatch(setOriginalManagementTypeID(null));
      dispatch(ProfileFormEditedBefore(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   dispatch(setManagementTypeID(defaultTarget));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [defaultTarget]);

  const caseAPIPendingFunc = () => {
    // Pending
    showToast(UPDATE_ACCOUNT_INFORMATION, "success", 4000);
    setLoadingProgress(true, true);

    async function checkAPIStatus() {
      const { data: resData } = await api.get(
        `/accounts/${accountID}/api-status`
      );

      const data = resData ? { ...account, ...resData[0] } : null;
      // const data = resData ? resData[0] : null;

      if (!mountedStatusRef.current) return;

      if (!data || data.api_status_id === 1) {
        setLoadingProgress(true);
        return false;
      }

      // The odering function call is important here.
      setLoadingProgress(false);
      dispatch(setAccountInformation(data));

      if (intervalHandlerRef.current !== undefined)
        clearInterval(intervalHandlerRef.current);

      return true;
    }

    checkAPIStatus().then((result) => {
      if (!mountedStatusRef.current) return;
      if (result) return;

      // Call the api by every 2s.
      intervalHandlerRef.current = setInterval(
        checkAPIStatus,
        DEFAULT_TOAST_TIME
      );
    });
  };

  const caseAPIFailedFunc = () => {
    // Return to the previous step.
    dispatch(getApiKeyAndSecret(accountID)).then((res) => {
      setApiInfo(res);
      // Failed API Key
      setMessage(
        "The API key entered has failed setup. Please recheck the API key and update to try again",
        accountID
      );
    });
  };

  const caseAPISucceedFunc = () => {
    // Go to the Asset Allocation tab after the api key has been tested successfully.
    setSelectedIndex(1);

    showToast(
      `The API Key has been tested successfully. the ${accountReference} should be active now again.`,
      "success",
      4000
    );
  };

  useEffect(() => {
    switch (apiStatusID) {
      case 1:
        // Pending
        caseAPIPendingFunc();
        break;
      case 2 || 3:
        // Failed
        caseAPIFailedFunc();
        break;
      case 4:
        // Succeed
        if (intervalHandlerRef.current !== undefined) caseAPISucceedFunc();
        break;
      default:
        break;
    }

    return () => {
      if (intervalHandlerRef.current !== undefined)
        clearInterval(intervalHandlerRef.current);
      setLoadingIndicator(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    apiStatusID,
    dispatch,
    setLoadingIndicator,
    setLoadingProgress,
    setMessage,
  ]);

  useEffect(() => {
    setLoading(true);
    if (accountID) {
      Promise.all([
        dispatch(getAccountHoldings(accountID)),
        dispatch(getAccountStats(accountID)),
        dispatch(getApiKeyAndSecret(accountID)),
        dispatch(getAssetAllocationByAccountID(accountID)),
        dispatch(getBasketAssetsWithAssetsThatCouldBeBasket(accountID)),
        dispatch(
          getRiskManagedAssetsWithAssetsThatCouldBeRiskManaged(accountID)
        ),
        dispatch(getAssetModelsByBasketTrading()),
      ]).then(([, , , , , , , ,]) => {
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountID]);

  useEffect(() => {
    dispatch(setSelectedAccount(account));
  }, [account, dispatch]);

  useEffect(() => {
    if (exchangeAccountID)
      dispatch(getAssetsPriceByExchangeAccountID(exchangeAccountID));
  }, [dispatch, exchangeAccountID]);

  useEffect(() => {
    // Filter array.
    setFilteredHoldings(FilterArray(holdings, "holdings"));
    setFilteredStats(FilterArray(stats, "stats"));
  }, [holdings, stats]);

  useEffect(() => {
    const actions =
      account && account.account_status_id === 1
        ? ACCOUNT_ACTIONS[1]
        : ACCOUNT_ACTIONS[0];
    setAccountAction([actions]);
    filterTabSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    if (!account) return;

    const runMethod = async () => {
      await filterTabSource();

      const { expert_gui: expertGUI } = account;

      if (managementTypeID !== 2) {
        setSelectedIndex(1);
      } else {
        if (!expertGUI) setSelectedIndex(1);
        else setSelectedIndex(2);
      }
    };
    runMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managementTypeID]);

  const onTabsSelectionChanged = (args) => {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
    }
  };

  const handleClientStatus = async (value) => {
    const message = value === 1 ? "be activated" : "view only";
    setPosition({ of: "#edit-client-user" });
    setLoading(true);
    await dispatch(updateAccountStatus(accountID, value, accounts));

    // When user tries to suspend the account and go to the dashboard.
    // This account should not be selected because of suspended.
    if (value !== 1) {
      await dispatch(setSelectedAccount(null));
    }

    showToast(
      `Your account has now been changed to ${message}`,
      "success",
      DEFAULT_TOAST_TIME
    );
    await dispatch(getAccountInformationByID(accountID));

    setLoading(false);
  };

  const handleAction = (e) => {
    handleClientStatus(e.itemData.value);
  };

  const dispatchUpdateSelfServiceAccount = async (values) => {
    await dispatch(updateApiKeyAndSecret(values, accountID)).then((res) => {
      const update = {
        ...account,
        api_status_id: 1,
        api_status_name: "Pending",
      };

      dispatch(setAccountInformation(update));
    });
    await dispatch(getApiKeyAndSecret(accountID));
  };

  const goBack = () => {
    history.goBack();
  };

  const handleBasicModeTabContent = useCallback(() => {
    switch (selectedIndex) {
      case 0:
        return (
          <EditAccountInformation
            loading={loading}
            setLoading={setLoading}
            exchanges={exchanges}
            account={account}
            goBack={goBack}
            accountAction={accountAction}
            dispatchUpdateSelfServiceAccount={dispatchUpdateSelfServiceAccount}
            handleAction={handleAction}
          />
        );
      case 1:
        if (managementTypeID === 3) {
          return (
            <ViewOnlyManagement
              expertGUI={false}
              accountID={accountID}
              isSetup={false}
              managementTypeID={managementTypeID}
              setSelectedIndex={setSelectedIndex}
            />
          );
        } else if (managementTypeID === 1) {
          return (
            <BasicBasketTrading
              isSetup={false}
              accountID={accountID}
              managementTypeID={managementTypeID}
              setSelectedIndex={setSelectedIndex}
            />
          );
        } else {
          return (
            <BasicRiskManagement
              isSetup={false}
              accountID={accountID}
              managementTypeID={managementTypeID}
              setSelectedIndex={setSelectedIndex}
            />
          );
        }
      case 2:
        return (
          <AccountStatsAndHoldings
            holdings={filteredHoldings}
            stats={filteredStats}
          />
        );
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex, managementTypeID]);

  const handleAdvancedModeTabContent = useCallback(() => {
    switch (selectedIndex) {
      case 0:
        return (
          <EditAccountInformation
            setLoading={setLoading}
            exchanges={exchanges}
            account={account}
            apiInfo={apiInfo}
            goBack={goBack}
            accountAction={accountAction}
            dispatchUpdateSelfServiceAccount={dispatchUpdateSelfServiceAccount}
            handleAction={handleAction}
          />
        );
      case 1:
        if (managementTypeID === 3) {
          return (
            <ViewOnlyManagement
              isSetup={false}
              expertGUI={false}
              setSelectedIndex={setSelectedIndex}
            />
          );
        } else if (managementTypeID === 1) {
          return (
            <BasicBasketTrading
              isSetup={false}
              accountID={accountID}
              managementTypeID={managementTypeID}
              setSelectedIndex={setSelectedIndex}
            />
          );
        } else {
          return (
            <BasicRiskManagement
              isSetup={false}
              accountID={accountID}
              managementTypeID={managementTypeID}
              setSelectedIndex={setSelectedIndex}
            />
          );
        }
      case 2:
        if (managementTypeID === 1)
          return <BasketTrading setLoading={setLoading} />;
        else if (managementTypeID === 2) return <RiskManagement />;
        break;
      case 3:
        return (
          <AccountStatsAndHoldings
            holdings={filteredHoldings}
            stats={filteredStats}
          />
        );
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex, managementTypeID]);

  const filterTabSource = async () => {
    if (!account) return;

    const { expert_gui: expertGUI } = account;
    var accountData = null;
    if (!expertGUI) {
      accountData = BASIC_ACCOUNT_TABS;
    } else {
      accountData = ADVANCED_ACCOUNT_TABS;
      if (managementTypeID !== 2) {
        accountData = BASIC_ACCOUNT_TABS;
      }
    } 

    setTabData(accountData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <React.Fragment>
      <div className={"header"}>
        <h2 className={"content-block"}>
          <Button
            icon="arrowleft"
            className="margin-right-20"
            onClick={goBack}
            style={{ marginRight: 10 }}
          />
          ACCOUNT DETAILS
        </h2>
      </div>
      <div
        className={"content-block dx-card responsive-paddings"}
        id="edit-client-user"
      >
        <Tabs
          style={{ marginTop: 50 }}
          dataSource={tabData}
          scrollByContent={true}
          selectedIndex={selectedIndex}
          onOptionChanged={onTabsSelectionChanged}
        />
        <div className="content dx-fieldset">
          <div className="dx-field">
            <div className={"dx-field-value width-100"}>
              <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={position}
                visible={loading}
                container="#edit-client-user"
              />
              {account?.expert_gui && managementTypeID === 2
                ? handleAdvancedModeTabContent()
                : handleBasicModeTabContent()}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditAccount;
