import React, { useEffect, useRef, useCallback, useMemo } from "react";
import * as events from "devextreme/events";
import TreeView from "devextreme-react/tree-view";
import Joyride from "react-joyride";
import { useNavigation } from "../../contexts/navigation";
import { useScreenSize } from "../../utils/media-query";
import { imersNavigation } from "../../app-navigation";
import { cyberToolsNavigation } from "../../app-cybertools-navigation";
import "./SideNavigationMenu.scss";
import { useSelector } from "react-redux";
import { WEALTH_NAV_STEPS } from "../../constants";

function normalizePath(pxIsLarge, sideBarMode) {
  const navigation =
    !sideBarMode || sideBarMode === "imers"
      ? imersNavigation
      : cyberToolsNavigation;
  return navigation.map((item) => {
    if (item.path && !/^\//.test(item.path)) {
      item.path = `/${item.path}`;
    }
    return { ...item, expanded: pxIsLarge };
  });
}

const SideNavigationMenu = (props) => {
  const { children, selectedItemChanged, openMenu, compactMode, onMenuReady } =
    props;

  const sideBarMode = useSelector((state) => state.auth.mode);
  const {run} = useSelector((state) => state.tourState);
  const { isLarge } = useScreenSize();

  const items = useMemo(
    () => normalizePath(isLarge, sideBarMode),
    [isLarge, sideBarMode]
  );

  const {
    navigationData: { currentPath },
  } = useNavigation();

  const treeViewRef = useRef();
  const wrapperRef = useRef();
  const getWrapperRef = useCallback(
    (element) => {
      const prevElement = wrapperRef.current;
      if (prevElement) {
        events.off(prevElement, "dxclick");
      }

      wrapperRef.current = element;
      events.on(element, "dxclick", (e) => {
        openMenu(e);
      });
    },
    [openMenu]
  );

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) return;

    if (currentPath !== undefined) {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    }

    if (compactMode) treeView.collapseAll();
  }, [currentPath, compactMode]);

  return (
    <div
      className={"dx-swatch-additional side-navigation-menu"}
      ref={getWrapperRef}
    >
       <Joyride
        callback={() => {}}
        continuous
        run={run}
        scrollToFirstStep
        showProgress={false}
        showSkipButton={false}
        stepIndex={0}
        steps={WEALTH_NAV_STEPS}
        disableCloseOnEsc
      />
      {children}
      <div className={"menu-container"}>
        <TreeView
          ref={treeViewRef}
          items={items}
          keyExpr={"path"}
          selectionMode={"single"}
          focusStateEnabled={true}
          expandEvent={"click"}
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          width={"100%"}
          onItemRendered={(event) => {
            if(event.itemData?.text === "WEALTH") {
              event.itemElement?.setAttribute('id', "tour_sideMenuWealth")
            }
          }}
        />
      </div>
    </div>
  );
};

export default SideNavigationMenu;
