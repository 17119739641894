import React from "react";
import PieChart, { Legend, Series, Size } from "devextreme-react/pie-chart";
import { Aggregation, Label } from "devextreme-react/chart";
import MarkerTemplate from "./MakerTemplate";
import { COLOR_PALETTE } from "../../constants";

const AccountPosition = (props) => {
  console.log("holdings", props.holdings)
  return (
    <PieChart
      id="pie"
      type="doughnut"
      dataSource={props.holdings}
      palette={COLOR_PALETTE}
      innerRadius={0.85}
    >
      <Size width={"100%"} height={400} />
      <Series argumentField="asset" valueField="percent_holding">
        <Label visible={false} format="fixedPoint"></Label>
        <Aggregation enabled={true} />
      </Series>
      <Legend
        horizontalAlignment="center"
        verticalAlignment="bottom"
        customizeText={() => {}}
        markerRender={(item) => MarkerTemplate(item, props.holdings)}
      />
    </PieChart>
  );
};

export default AccountPosition;
