import React from "react";
import useWebSocket from "react-use-websocket";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import { loggedIn } from "../utils/storage";
import { get } from "../utils/storage";
import { MAIN_TOKEN_KEY } from "../constants";
import { useSocialContext } from "../contexts/socialDashboard";

const PrivateRoute = ({
  component: Component,
  componentProps,
  path,
  exact,
}) => {
  const { setSocialData, socialData, setCounts } = useSocialContext();
  const siteMode = useSelector((state) => state.auth.mode);
  const token = get(MAIN_TOKEN_KEY);

  let wsBaseUrl = "ss-wip.panxora.com";

  if (!window.location.host.includes("localhost"))
    wsBaseUrl = window.location.host;

  let wsClient;

  if (siteMode !== "cybertools") {
    wsClient = new useWebSocket(`wss://${wsBaseUrl}/socialws/`, {
      onOpen: () => {
        getRealTimeDataFromWebsocket();
        console.log("Social socket opened.");
        document.addEventListener("visibilitychange", func, true);
      },
      onClose: () => {
        window.removeEventListener("visibilitychange", func, false);
        console.log("Social socket closed.");
      },
      shouldReconnect: () => true,
      onMessage: (event) => {
        const friendsData = JSON.parse(event?.data);
        if (
          friendsData &&
          ["update", "login_successful"].includes(friendsData?.message_type)
        ) {
          setSocialData(friendsData?.detail);
        }
      },
    });
  }
  const func = () => {
    if (document.hidden) {
      wsClient.sendMessage("unsubscribe");
      console.log(
        "Browser tab is hidden and unsubscribe action should be here"
      );
    } else {
      getRealTimeDataFromWebsocket();
      console.log("Browser tab is visible");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const getRealTimeDataFromWebsocket = () => {
    const filteredToken = token.split('"')[1];
    const subscribeData = {
      message_type: "subscribe",
      detail: { token: `${filteredToken}` },
    };
    wsClient.sendMessage(JSON.stringify(subscribeData));
  };

  React.useEffect(() => {
    if (!socialData) return;

    const { friends, messages } = socialData;

    if (!friends) return;

    const friendsCount = friends?.filter(
      (item) => item.friend_status === "received friend request"
    );
    setCounts({
      friendsCount: friendsCount?.length ?? 0,
      messagesCount:
        messages?.filter(
          (msg) => msg.action === "received" && msg.status === "unread"
        )?.length ?? 0,
    });

    // eslint-disable-next-line
  }, [socialData]);

  return (
    <Route
      path={path}
      exact={exact}
      render={(props) =>
        loggedIn() ? (
          <Component {...props} {...componentProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location.pathname },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
