import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DataGrid, { Column, Editing } from "devextreme-react/data-grid";
import DisplayAccountInfo from "./DisplayAccountInfo";
import ReduceTargetAmountForm from "./ReduceTargetAmountForm";
import AllocateBalanceForm from "./AllocateBalanceForm";
import { useScreenSize } from "../../utils/media-query";
import { Button } from "devextreme-react/button";

const calcAccountBalance = (allocations, type) => {
  if (allocations) {
    var sum = 0;
    allocations.map((item) => {
      const { position, target_quantity, price } = item;
      const target = type === "allocated" ? target_quantity : position;
      sum += target * price;
      return item;
    });
    return sum;
  }
  return 0;
};

const RiskManagement = ({ previousStep = null, isSetup }) => {
  const targetAssetGridRef = useRef();
  const screenSize = useScreenSize();
  const history = useHistory();

  const account = useSelector((state) => state.accounts.info);
  const assetsInfo = useSelector((state) => state.manageAsset.assetsInfo);

  const { account_reference: accountRef } = account || null;
  const screenWidth = screenSize.isLarge || screenSize.isMedium ? 33 : 100;
  const displayMethod =
    screenSize.isLarge || screenSize.isMedium ? "flex" : "block";

  const [allocations, setAllocations] = useState();

  // Current Balance: (Position * Price) of risk-managed assets + position of base currency.
  // Allocated Balance: (Target Quantity * Price) of risk-managed assets.
  // Free Balance: Current Balance - Allocated Balance
  const [balanceInformation, setBalanceInformation] = useState({
    currentBalance: 0,
    freeBalance: 0,
    allocatedBalance: 0,
  });

  useEffect(() => {
    if (!assetsInfo) return;
    const data = JSON.parse(JSON.stringify(assetsInfo.allocations));
    const updated = data.filter((item) => item.current_model_id !== 1);
    calculateBalanceInformation(updated);
    setAllocations(updated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetsInfo]);

  const calculateBalanceInformation = (data) => {
    const currentBalance =
      Math.round(
        (calcAccountBalance(data, "current") + account.base_currency_position) *
          100
      ) / 100;

    const allocatedBalance = calcAccountBalance(data, "allocated");
    setBalanceInformation({
      currentBalance,
      allocatedBalance,
      freeBalance: Math.max(
        Math.round((currentBalance - allocatedBalance) * 100) / 100,
        0
      ),
    });
  };

  const renderReduceTargetForm = useCallback(() => {
    return <ReduceTargetAmountForm allocations={allocations} curManagementTypeID={assetsInfo?.current_management_type_id} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocations]);

  const renderAllocateBalanceForm = useCallback(() => {
    const { freeBalance } = balanceInformation;
    if (freeBalance > 0)
      return (
        <AllocateBalanceForm defaultUnique={balanceInformation.freeBalance} />
      );
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balanceInformation]);

  return (
    <React.Fragment>
      <div className="px-assets-header">
        <p className="px-asset-title">RISK MANAGEMENT</p>
        {isSetup && (
          <div className="px-btn-tools">
            <Button
              text="PREVIOUS STEP"
              onClick={previousStep}
              style={{ marginRight: 10 }}
            />
            <Button
              text="COMPLETE SETUP"
              onClick={() => {
                history.push("/account-dashboard");
              }}
              style={{ marginRight: 10 }}
            />
          </div>
        )}
      </div>
      {/* ACCOUNT BALANCE VIEW */}
      <DisplayAccountInfo
        accountRef={accountRef}
        accountBalance={balanceInformation.currentBalance}
      />
      <div style={{ display: displayMethod }}>
        <div
          style={{
            width: `${screenWidth}%`,
            padding: "20px 20px",
          }}
        >
          <div className="px-assets-header">
            <p className="px-asset-sub-title">TARGET ALLOCATION</p>
          </div>
          <DataGrid
            className={"dx-card wide-card"}
            dataSource={allocations}
            showBorders={true}
            keyExpr="instrument_id"
            ref={(ref) => (targetAssetGridRef.current = ref)}
            focusedRowEnabled={false}
            defaultFocusedRowIndex={false}
            columnAutoWidth={true}
            columnHidingEnabled={true}
          >
            <Editing mode="row" />
            <Column
              dataField={"exchange_symbol"}
              caption={"ASSET"}
              hidingPriority={6}
            />
            <Column
              dataField={"price"}
              caption={"CURRENT PRICE"}
              hidingPriority={6}
            />
            <Column
              dataField={"target_quantity"}
              caption={"TARGET QUANTITY"}
              hidingPriority={2}
            />
          </DataGrid>
        </div>
        <div
          style={{
            width: `${screenWidth}%`,
            padding: "20px 20px",
          }}
        >
          <div className="px-assets-header">
            <p className="px-asset-sub-title">REDUCE TARGET QUANTITY</p>
          </div>
          <div className="px-assets-controller" style={{ marginTop: 45 }}>
            {renderReduceTargetForm()}
          </div>
        </div>

        <div
          style={{
            width: `${screenWidth}%`,
            padding: "20px 20px",
          }}
        >
          <div className="px-assets-header">
            <p className="px-asset-sub-title" style={{ marginRight: 10 }}>
              ALLOCATE FREE BALANCE TO RISK MANAGED ASSETS
            </p>
          </div>
          <div className="px-assets-header">
            <p>
              Free Available Balance:
              <strong> {balanceInformation.freeBalance}</strong>
            </p>
          </div>
          <div className="px-assets-controller">
            {renderAllocateBalanceForm()}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default RiskManagement;
