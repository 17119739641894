import {
  SET_SELECTED_ACCOUNT,
  SET_ACCOUNT_INFORMATION,
  SET_SELF_SERVICE_ACCOUNTS,
  SET_ACCOUNT_HOLDINGS,
  SET_ACCOUNT_STATS,
  ADD_NEW_ACCOUNT_TO_LIST,
  SET_FULL_ASSET_LIST,
  REMOVE_USER_DATA,
  SET_MANAGEMENT_TYPE_ID,
  SET_ORIGINAL_MANAGEMENT_TYPE_ID,
  SET_BASKET_ASSET_LIST,
  SET_RISK_MANAGED_ASSET_LIST,
  SET_ACCOUNT_API_INFO,
  SET_RISK_REPORT_DATA,
  SET_RISK_RATING_INFO,
  SET_ACTIVE_COMPETITIONS,
} from "./constant";

const DEFAULT_STATE = {
  list: null,
  info: null,
  holdings: null,
  stats: null,
  account: null,
  editOne: null,
  fullAssets: null,
  basketAssets: null,
  riskManagedAssets: null,
  curManagementTypeID: null,
  originalManagementTypeID: null,
  apiInfo: null,
  riskReportInfo: null,
  riskRatingInfo: null,
  activeCompetitions: null,
};

const accountsReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_SELECTED_ACCOUNT:
      return { ...state, account: action.payload };
    case SET_ACCOUNT_INFORMATION:
      return { ...state, info: action.payload };
    case ADD_NEW_ACCOUNT_TO_LIST:
      return { ...state, list: [...state.list, action.payload] };
    case SET_SELF_SERVICE_ACCOUNTS:
      return { ...state, list: action.payload };
    case SET_ACCOUNT_HOLDINGS:
      return { ...state, holdings: action.payload };
    case SET_ACCOUNT_STATS:
      return { ...state, stats: action.payload };
    case SET_FULL_ASSET_LIST:
      return { ...state, fullAssets: action.payload };
    case SET_BASKET_ASSET_LIST:
      return { ...state, basketAssets: action.payload };
    case SET_RISK_MANAGED_ASSET_LIST:
      return { ...state, riskManagedAssets: action.payload };
    case SET_MANAGEMENT_TYPE_ID:
      return { ...state, curManagementTypeID: action.payload };
    case SET_ORIGINAL_MANAGEMENT_TYPE_ID:
      return { ...state, originalManagementTypeID: action.payload };
    case SET_ACCOUNT_API_INFO:
      return { ...state, apiInfo: action.payload };
    case SET_RISK_REPORT_DATA:
      return { ...state, riskReportInfo: action.payload };
    case SET_RISK_RATING_INFO:
      return { ...state, riskRatingInfo: action.payload };
    case SET_ACTIVE_COMPETITIONS:
      return { ...state, activeCompetitions: action.payload };
    // After call logout action.
    case REMOVE_USER_DATA:
      return DEFAULT_STATE;
    default:
      return state;
  }
};

export default accountsReducer;
