import React from "react";

export default function TooltipTemplate(pieChart) {
  return (
    <svg>
      <circle
        cx="25"
        cy="15"
        r={pieChart.getInnerRadius()}
        fill="white"
      ></circle>
      <image href={"panxora-favicon.png"} x="20" y="8" width="12" height="12" />
    </svg>
  );
}
