import { useState, useEffect, memo, useMemo, useRef } from "react";
import AnyChart from "anychart-react";
import useWebSocket from "react-use-websocket";
import anychart from "anychart";
import { Link } from "react-router-dom";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button,
  MenuList,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  AiOutlineDelete,
  AiOutlineSave,
  AiOutlineFullscreen,
  AiOutlineSliders,
  AiOutlineLineChart,
  AiOutlineClockCircle,
  AiOutlineSearch,
  AiOutlineBarChart,
  AiOutlineDotChart,
  AiOutlineAreaChart,
  AiOutlineFullscreenExit,
} from "react-icons/ai";
import { BiBarChart, BiSun, BiLinkExternal } from "react-icons/bi";
import { BsBarChartSteps, BsBezier } from "react-icons/bs";
import { RiBarChartHorizontalLine } from "react-icons/ri";

import {
  setChartState,
  setThemeState,
} from "../../../redux/tradingDashboard/action";
import {
  CHART_ANNOTATIONS,
  CHART_INDICATORS,
  CHART_TIMESCALES,
  CHART_TYPES,
  MAIN_TOKEN_KEY,
  NEW_PLOTS,
} from "../../../constants";
import { get } from "../../../utils/storage";
import CustomTooltip from "../../../components/CustomTooltip";

anychart.licenseKey("app.imers.land-931c8c63-cf3f1c73");

function mapChartData(data) {
  if (!data || !Array.isArray(data)) return [];

  return data.map((item) => {
    return [item.date, item.open, item.high, item.low, item.close, item.volume];
  });
}

const renderIcon = (name) => {
  switch (name) {
    case "area":
      return <AiOutlineAreaChart />;
    case "candlestick":
      return <AiOutlineSliders />;
    case "column":
      return <AiOutlineBarChart />;
    case "jumpLine":
      return <BsBarChartSteps />;
    case "line":
      return <AiOutlineLineChart />;
    case "marker":
      return <AiOutlineDotChart />;
    case "ohlc":
      return <BiBarChart />;
    case "rangeArea":
      return <AiOutlineAreaChart />;
    case "rangeColumn":
      return <BiBarChart />;
    case "rangeSplineArea":
      return <AiOutlineLineChart />;
    case "rangeStepArea":
      return <AiOutlineAreaChart />;
    case "spline":
      return <AiOutlineLineChart />;
    case "splineArea":
      return <AiOutlineAreaChart />;
    case "stepArea":
      return <AiOutlineAreaChart />;
    case "stepLine":
      return <BsBarChartSteps />;
    case "stick":
      return <RiBarChartHorizontalLine />;
    default:
      return <AiOutlineLineChart />;
  }
};

const ChartPanel = ({
  openSearchDialog,
  wsBaseUrl,
  setChartFullScreen,
  chartFullScreen,
  chartsData,
  setChartsData,
  selectedRange,
  setSelectedRange,
  isEditorMode,
  setIsEditorMode,
}) => {
  const textInputRef = useRef();
  const dispatch = useDispatch();
  const chartState = useSelector((state) => state.tradingDashboard.chartState);
  const currentTheme = useSelector(
    (state) => state.tradingDashboard.currentTheme
  );
  const [connectedChartWSS, setConnectedChartWSS] = useState(false);
  const [stocksData, setStocksData] = useState([]);
  const [newStocksData, setNewStocksData] = useState([]);
  const [selectedAnnotation, setSelectedAnnotation] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [chartInstance, setChartInstance] = useState(null);

  const token = get(MAIN_TOKEN_KEY);

  let wsChartsClient = new useWebSocket(`wss://${wsBaseUrl}/chartws/`, {
    onOpen: () => {
      setConnectedChartWSS(true);
    },
    onClose: () => {
      setConnectedChartWSS(false);
    },
    shouldReconnect: () => true,
    onMessage: (event) => {
      const parsedData = JSON.parse(event?.data);
      if (!parsedData) return;
      processChartData(parsedData);
    },
  });

  useEffect(() => {
    if (connectedChartWSS && chartState) {
      getRealTimeStocksData();
    }
    // eslint-disable-next-line
  }, [connectedChartWSS, chartState, chartFullScreen]);

  useEffect(() => {
    if (!chartsData || chartsData.length === 0) return;

    if (isEditorMode) {
      setNewStocksData(mapChartData(chartsData));
    } else {
      if (newStocksData.length > 0) {
        setStocksData(newStocksData);
        setNewStocksData([]);
      } else {
        setStocksData(mapChartData(chartsData));
      }
    }
    // eslint-disable-next-line
  }, [chartsData, isEditorMode]);

  const renderChart = useMemo(() => {
    const stage = anychart.graphics.create();

    const dataTable = anychart.data.table(0);
    dataTable.addData(stocksData);

    const mapping = dataTable.mapAs();
    mapping.addField("open", 1, "first");
    mapping.addField("high", 2, "max");
    mapping.addField("low", 3, "min");
    mapping.addField("close", 4, "last");
    mapping.addField("value", 4, "last");
    mapping.addField("volume", 5);

    const rat_mapping = dataTable.mapAs({ priceA: 1, priceB: 2 });

    const chart = anychart.stock();
    setChartInstance(chart);

    const plot1 = chart.plot(0);
    const series = plot1[chartState.type](mapping);
    series.name(`${chartState.exchange}: ${chartState.market}`);
    if (["ohlc", "candlestick"].includes(chartState.type)) {
      series.fallingFill("#F45B3D");
      series.fallingStroke("#F45B3D");
      series.risingFill("#2EB777");
      series.risingStroke("#2EB777");
    }

    plot1.annotations().enabled(true);

    if (chartState.indicators && chartState.indicators?.length > 0) {
      const indicator = chartState.indicators[0];

      if (!indicator) return;

      if (NEW_PLOTS.includes(indicator)) {
        if (indicator === "rat") {
          chart.plot(1)[indicator](rat_mapping);
        } else {
          chart.plot(1)[indicator](mapping);
        }
        chart.plot(1).yAxis().orientation("right");
      } else {
        plot1[indicator](mapping);
      }
    }

    chart.background(
      currentTheme === "Glass"
        ? "rgba(43,29,26,.2)"
        : currentTheme === "Light"
        ? "#ffffff"
        : "rgba(43,29,26,1)"
    );
    chart.padding(0, 60, 0, 0);
    chart.scroller(true);
    chart.scroller().candlestick(mapping);
    chart.interactivity().zoomOnMouseWheel(true);
    const credits = chart.credits();
    credits.enabled(false);

    const crosshair = chart.crosshair();
    crosshair.xStroke("#676069", 0.25, "5 2");
    crosshair.yStroke("#676069", 0.25, "5 2");
    crosshair.displayMode("float");
    chart.tooltip(true);
    plot1.yAxis().orientation("right");

    const indicator = plot1.priceIndicator();
    indicator.value("last-visible");
    indicator.stroke("orange", 2, "2 2");
    indicator.label().background().fill("orange");
    indicator.label().fontColor("white");
    // let rangePicker = anychart.ui.rangePicker();
    // let rangeSelector = anychart.ui.rangeSelector();
    // rangeSelector.render(chart);
    // rangePicker.render(chart);

    chart.listen("selectedrangechangefinish", (e) => {
      setSelectedRange({
        firstSelected: new Date(e.firstSelected),
        lastSelected: new Date(e.lastSelected),
      });
    });

    if (chartState?.annotations) {
      plot1.annotations().fromJson(chartState.annotations);
    }

    chart.listen("annotationDrawingFinish", (e) => {
      const annt = e.annotation;
      dispatch(
        setChartState({
          ...chartState,
          annotations: plot1.annotations().toJson(true),
        })
      );

      if (annt.type === "label") {
        if (textInputRef.current?.value) {
          annt.text(textInputRef.current?.value);
        }
      }
    });

    chart.listen("annotationSelect", function (e) {
      if (e.annotation.type === "label") {
        textInputRef.current.focus();
        textInputRef.current.value = e.annotation.text();
        textInputRef.current.selectionEnd = e.annotation.text().length;
      }

      setSelectedAnnotation(e.annotation);
    });

    chart.listen("annotationUnselect", function (e) {
      if (e.annotation.type === "label") {
        textInputRef.current.value = "";
      }
      setSelectedAnnotation(null);
    });

    chart.listen("annotationChangeFinish", function () {
      setIsEditorMode(true);
    });

    if (selectedRange) {
      chart.selectRange(
        selectedRange?.firstSelected,
        selectedRange?.lastSelected
      );
    }

    return (
      <AnyChart
        instance={stage}
        width={"100%"}
        height={"100%"}
        charts={[chart]}
      />
    );
    // eslint-disable-next-line
  }, [chartState, stocksData, chartFullScreen, currentTheme]);

  /**
   * Process data for Charts
   */

  const processChartData = (parsedData) => {
    if (parsedData.hasOwnProperty("data")) {
      const historicalData = Object.keys(parsedData?.data).map((key) => {
        return {
          id: key,
          date: new Date(parsedData?.data[key]?.timestamp),
          close: parsedData?.data[key]?.last,
          high: parsedData?.data[key]?.high,
          low: parsedData?.data[key]?.low,
          open: parsedData?.data[key]?.open,
          volume: parsedData?.data[key]?.volume,
        };
      });

      setChartsData(historicalData);
    } else {
      const mainKey = Object.keys(parsedData);
      const secondaryKey = Object.keys(parsedData[mainKey]);
      let newOrUpdated = parsedData[mainKey][secondaryKey[0]];
      const currentStocksData = [...chartsData];

      const existingData = currentStocksData.find(
        (item) => item.id === secondaryKey[0]
      );
      const exisitingIndex = existingData
        ? currentStocksData.findIndex((item) => item.id === secondaryKey[0])
        : -1;

      newOrUpdated = {
        id: secondaryKey[0],
        date: new Date(newOrUpdated?.timestamp),
        close: newOrUpdated?.last,
        high: newOrUpdated?.high,
        low: newOrUpdated?.low,
        open: newOrUpdated?.open,
        volume: newOrUpdated?.volume,
      };

      if (exisitingIndex > -1) {
        currentStocksData[exisitingIndex] = newOrUpdated;
      } else {
        currentStocksData.push(newOrUpdated);
      }

      setChartsData(currentStocksData);
    }
  };

  const getRealTimeStocksData = () => {
    const exchange = chartState ? chartState?.exchange : "Imers";
    const market = chartState ? chartState?.market : "BTC/USD";
    const timescale = chartState ? chartState?.time : "hour";
    const filteredToken = token.split('"')[1];
    const subscribeData = {
      token: filteredToken,
      type: "request",
      exchange,
      market,
      bar: timescale,
    };

    wsChartsClient.sendMessage(JSON.stringify(subscribeData));
  };

  const handleOpenMenu = (event, anchorType) => {
    setSelectedMenu(anchorType);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);

    setTimeout(() => setSelectedMenu(null), 200);
  };

  const handleLabelChange = (e) => {
    if (chartInstance) {
      const at = chartInstance.plot(0).annotations().getSelectedAnnotation();
      if (at) {
        at.text(e.target.value);
      }
    }
  };

  return (
    <>
      <div className="custom-toolbar">
        <textarea
          id="annotation-label"
          ref={textInputRef}
          onChange={handleLabelChange}
        ></textarea>
        <div>
          <CustomTooltip title="Market symbol e.g. BTC">
            <IconButton size="small" onClick={openSearchDialog}>
              <AiOutlineSearch size={"18px"} />
            </IconButton>
          </CustomTooltip>

          <CustomTooltip title="Chart">
            <IconButton
              size="small"
              onClick={(e) => handleOpenMenu(e, "chart")}
            >
              <AiOutlineSliders size={"18px"} />
            </IconButton>
          </CustomTooltip>

          <CustomTooltip title="Indicator">
            <IconButton
              size="small"
              onClick={(e) => handleOpenMenu(e, "indicator")}
            >
              <AiOutlineLineChart size={"18px"} />
            </IconButton>
          </CustomTooltip>
          <CustomTooltip
            title={
              isEditorMode
                ? "Timescale not available during editor mode, click save changes"
                : "Timescale"
            }
          >
            <span style={{ cursor: isEditorMode ? "not-allowed" : "pointer" }}>
              <Button
                disabled={isEditorMode}
                size="small"
                style={{ fontSize: "13px", fontWeight: "bold" }}
                onClick={(e) => handleOpenMenu(e, "timescale")}
                color="inherit"
                startIcon={
                  <AiOutlineClockCircle
                    size={"18px"}
                    color={
                      currentTheme === "Glass" || currentTheme === "Dark"
                        ? "#f1f1f1"
                        : "#444"
                    }
                  />
                }
              >
                {
                  CHART_TIMESCALES.find(
                    (item) => item.name === chartState?.time
                  ).indicator
                }
              </Button>
            </span>
          </CustomTooltip>

          <CustomTooltip title="Add annotation">
            <IconButton
              size="small"
              onClick={(e) => handleOpenMenu(e, "annotation")}
            >
              <BsBezier size={"18px"} />
            </IconButton>
          </CustomTooltip>
        </div>

        <div className="config-buttons">
          {selectedAnnotation && (
            <CustomTooltip title="Remove annotation">
              <IconButton
                onClick={() => {
                  if (selectedAnnotation) {
                    chartInstance
                      .plot(0)
                      .annotations()
                      .removeAnnotation(selectedAnnotation);

                    dispatch(
                      setChartState({
                        ...chartState,
                        annotations: chartInstance
                          .plot(0)
                          .annotations()
                          .toJson(true),
                      })
                    );
                    setSelectedAnnotation(null);
                  }
                }}
                size="small"
              >
                <AiOutlineDelete />
              </IconButton>
            </CustomTooltip>
          )}
          {isEditorMode && (
            <CustomTooltip title="Save changes">
              <Button
                onClick={() => {
                  dispatch(
                    setChartState({
                      ...chartState,
                      annotations: chartInstance
                        .plot(0)
                        .annotations()
                        .toJson(true),
                    })
                  );
                  setIsEditorMode(false);
                  if (selectedAnnotation) setSelectedAnnotation(null);
                }}
                size="small"
                startIcon={<AiOutlineSave />}
              >
                {chartFullScreen && "Save Changes"}
              </Button>
            </CustomTooltip>
          )}
          {!chartFullScreen && (
            <CustomTooltip title={`Open chart in a new tab`}>
              <Link
                to={`/trading_chart?market=${chartState.market}&exchange=${
                  chartState.exchange
                }&period=${
                  CHART_TIMESCALES.find((time) => time.name === chartState.time)
                    .indicator
                }&type=${chartState.type}&token=${JSON.parse(token)}`}
                target="_blank"
              >
                <IconButton size="small">
                  <BiLinkExternal />
                </IconButton>
              </Link>
            </CustomTooltip>
          )}
          {chartFullScreen && (
            <CustomTooltip title={`Toggle theme`}>
              <IconButton
                size="small"
                style={{ marginLeft: 12, marginRight: 12 }}
                onClick={(e) => {
                  handleOpenMenu(e, "themes");
                }}
              >
                <BiSun />
              </IconButton>
            </CustomTooltip>
          )}
          <CustomTooltip title={chartFullScreen ? "Minimize" : "Maximize"}>
            <IconButton
              size="small"
              onClick={() => setChartFullScreen((ps) => !ps)}
            >
              {chartFullScreen ? (
                <AiOutlineFullscreenExit size={"18px"} />
              ) : (
                <AiOutlineFullscreen size={"18px"} />
              )}
            </IconButton>
          </CustomTooltip>
        </div>
      </div>
      {renderChart}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        PaperProps={{
          style: {
            maxHeight: "calc(100vh - 400px)",
            minHeight: "50px",
            minWidth: 80,
            backgroundColor:
              currentTheme === "Glass" || currentTheme === "Dark"
                ? "rgba(43, 29, 26, 0.9)"
                : "#ffffff",
            color:
              currentTheme === "Glass" || currentTheme === "Dark"
                ? "#ffffff"
                : "#222222",
          },
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuList disableListWrap disablePadding>
          {selectedMenu === "indicator" &&
            CHART_INDICATORS.map((item) => (
              <MenuItem
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingRight: 2,
                  paddingLeft: 4,
                }}
                title={item.text}
                button={false}
                selected={item.type === chartState.indicators[0]}
                key={item.indicator + "-" + item.id}
                onClick={() => {
                  const type = item.type;

                  dispatch(
                    setChartState({
                      ...chartState,
                      indicators: chartState.indicators.includes(type)
                        ? []
                        : [type],
                    })
                  );
                }}
              >
                <ListItemIcon
                  style={{
                    minWidth: 24,
                    color:
                      currentTheme === "Glass" || currentTheme === "Dark"
                        ? "#ffffff"
                        : "#222222",
                  }}
                >
                  <Checkbox
                    checked={chartState.indicators.includes(item.type)}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <p
                      style={{
                        lineHeight: 0,
                        fontSize: 11,
                        fontWeight: "bold",
                      }}
                    >
                      {item.text}
                    </p>
                  }
                />
              </MenuItem>
            ))}
          {selectedMenu === "chart" &&
            CHART_TYPES.map((item) => (
              <MenuItem
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingRight: 2,
                  paddingLeft: 4,
                }}
                selected={item.type === chartState.type}
                key={item.type + "-" + item.id}
                onClick={() => {
                  const type = item.type;
                  dispatch(
                    setChartState({
                      ...chartState,
                      type,
                    })
                  );
                  handleClose();
                }}
              >
                <ListItemIcon
                  style={{
                    minWidth: 24,
                    color:
                      currentTheme === "Glass" || currentTheme === "Dark"
                        ? "#ffffff"
                        : "#222222",
                  }}
                >
                  {renderIcon(item.type)}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <p
                      style={{
                        lineHeight: 0,
                        fontSize: 11,
                        fontWeight: "bold",
                      }}
                    >
                      {item.text}
                    </p>
                  }
                />
              </MenuItem>
            ))}
          {selectedMenu === "annotation" &&
            CHART_ANNOTATIONS.map((item) => (
              <MenuItem
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingRight: 2,
                  paddingLeft: 4,
                }}
                key={item.text + "-" + item.id}
                onClick={() => {
                  if (!chartInstance) return;
                  if (!isEditorMode) {
                    setIsEditorMode(true);
                  }

                  if (item.type === "label") {
                    chartInstance.annotations().startDrawing({
                      type: "label",
                      fontSize: "16px",
                      fontColor: "#010101",
                    });
                  } else {
                    chartInstance.annotations().startDrawing(item.type);
                  }
                  handleClose();
                }}
              >
                <ListItemText
                  primary={
                    <p
                      style={{
                        lineHeight: 0,
                        fontSize: 11,
                        fontWeight: "bold",
                      }}
                    >
                      {item.text}
                    </p>
                  }
                />
              </MenuItem>
            ))}
          {selectedMenu === "timescale" &&
            CHART_TIMESCALES.map((item) => (
              <MenuItem
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingRight: 2,
                  paddingLeft: 4,
                }}
                selected={item.name === chartState.time}
                key={item.indicator + "-" + item.id}
                onClick={() => {
                  const time = item.name;
                  if (chartState.time !== time) {
                    setSelectedRange(null);
                  }
                  dispatch(
                    setChartState({
                      ...chartState,
                      time,
                    })
                  );
                  handleClose();
                }}
              >
                <ListItemText
                  primary={
                    <p
                      style={{
                        lineHeight: 0,
                        fontSize: 11,
                        fontWeight: "bold",
                      }}
                    >
                      {item.text}
                    </p>
                  }
                />
              </MenuItem>
            ))}
          {selectedMenu === "themes" &&
            ["Light", "Dark", "Glass"].map((item) => (
              <MenuItem
                key={item}
                onClick={() => {
                  handleClose();
                  dispatch(setThemeState(item));
                }}
              >
                <ListItemText primary={item} />
              </MenuItem>
            ))}
        </MenuList>
      </Menu>
    </>
  );
};

export default memo(ChartPanel);
