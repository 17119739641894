import React from "react";

export default function CommonCell({
  item,
  noContent = null,
  type,
  removeCell,
  accMode,
  activeCompetition,
}) {
  return (
    <div
      className={`px-common-cell ${
        type === "exchange" &&
        item?.toLowerCase() === "imers" &&
        ((activeCompetition && accMode === "competition") ||
          accMode === "practice")
          ? "px-cell-bg-yellow"
          : ""
      }`}
      style={{
        border: `${noContent} ? "none !important"`,
        cursor: noContent ? "default" : "pointer",
      }}
    >
      {!noContent && (
        <>
          <span className="current-value">{item} </span>
          {removeCell && (
            <div
              className="remove-icon"
              onClick={() => {
                removeCell(item);
              }}
            >
              ✕
            </div>
          )}
        </>
      )}
    </div>
  );
}
