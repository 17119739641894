import React, { useState } from "react";
import RadioGroup from "devextreme-react/radio-group";
import { Popup } from "devextreme-react/popup";
import { Position, ToolbarItem } from "devextreme-react/autocomplete";

export const priorityEntities = [
  { id: 0, text: "Continue to advanced asset management screen" },
  { id: 1, text: "Stay on dashboard" },
];
const AccountModePopup = ({ setVisibleAccountModePp, handleAccountMode }) => {
  const [selectionPriority, setSelectionPriority] = useState(false);

  const changeSelectionPriority = (e) => {
    setSelectionPriority(e.value);
  };

  return (
    <div id="container">
      <h1>CHANGE TO ADVANCED MODE</h1>

      <Popup
        visible={true}
        onHiding={setVisibleAccountModePp}
        dragEnabled={true}
        closeOnOutsideClick={false}
        showCloseButton={false}
        showTitle={true}
        title="CHANGE TO ADVANCED MODE"
        container=".dx-viewport"
        width={400}
        height={200}
      >
        <Position at="center" my="center" />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={{
            text: "Okay",
            onClick: () => handleAccountMode(selectionPriority),
          }}
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={{
            text: "Cancel",
            onClick: () => setVisibleAccountModePp(false),
          }}
        />
        <div className="px-acc-mode-popup">
          <RadioGroup
            id="radio-group-with-selection"
            items={priorityEntities}
            value={selectionPriority}
            valueExpr="id"
            displayExpr="text"
            onValueChanged={changeSelectionPriority}
          />
        </div>
      </Popup>
    </div>
  );
};

export default AccountModePopup;
