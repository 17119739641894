import React, { useRef, useState } from "react";
import QRCode from "qrcode.react";
import { Button } from "devextreme-react/button";
import { Popup } from "devextreme-react/popup";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  NumericRule,
  GroupItem,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import { codeEditorOptions } from "../../constants/app";
import Error from "../Error";

const Setup2FAForm = (props) => {
  const { twoFa, handleLogOut, dispatchSetup2FA } = props;
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const formData = useRef({});

  const onSubmit = async (e) => {
    e.preventDefault();
    const { code } = formData.current;
    setLoading(true);

    try {
      await dispatchSetup2FA(code);
    } catch (error) {
      setLoading(false);
      setErrorMsg(error.message);
    }
  };

  return (
    <Popup
      visible={true}
      closeOnOutsideClick={false}
      showCloseButton={false}
      showTitle={true}
      titleRender={() => {
        return (
          <React.Fragment>
            <h4>2FA SETUP</h4>
            <Button
              className="logout-btn"
              icon="repeat"
              onClick={handleLogOut}
            ></Button>
          </React.Fragment>
        );
      }}
      className="security"
      container=".dx-viewport"
      width={600}
      height={800}
    >
      <span className="error">
        You must enable Google Authenticator two-factor authentication to use
        this service.
      </span>
      <div className={"content-2fa"}>
        <ol className="description-list">
          <li>Search for "Authenticator" in your app store.</li>
          <li>Open the app</li>
          <li>
            <p>
              Pair the Authentication app with your account by scanning the QR code
            </p>
            {twoFa && (
              <QRCode
                renderAs="svg"
                width="250"
                height="250"
                value={twoFa.otpauth_uri}
              />
            )}
            <p>Or enter this secret key into your Authenticator app:</p>
            <strong>{twoFa && twoFa.secret}</strong>
          </li>
          <li>
            Enter the 6-digit verification code generated by your Authenticator
            app.
          </li>
        </ol>
        <form className={"security-form"} onSubmit={onSubmit}>
          {!!errorMsg && <Error>{errorMsg}</Error>}
          <Form
            formData={formData.current}
            disabled={loading}
            readOnly={false}
            showColonAfterLabel={true}
            validationGroup="currentData"
            defaultFormData={formData.current}
          >
            <GroupItem cssClass="first-group">
              <Item
                dataField={"code"}
                editorType={"dxTextBox"}
                editorOptions={codeEditorOptions}
              >
                <RequiredRule message="The code is required" />
                <NumericRule message="The code should be number." />
                <Label visible={false} />
              </Item>
            </GroupItem>
            <GroupItem cssClass="first-group">
              <ButtonItem>
                <ButtonOptions
                  width={"100%"}
                  type={"default"}
                  useSubmitBehavior={true}
                >
                  <span className="dx-button-text">
                    {loading ? (
                      <LoadIndicator
                        width={"24px"}
                        height={"24px"}
                        visible={true}
                      />
                    ) : (
                      "CHECK CODE"
                    )}
                  </span>
                </ButtonOptions>
              </ButtonItem>
            </GroupItem>
          </Form>
        </form>
      </div>
    </Popup>
  );
};

export default Setup2FAForm;
