import {
  SET_REDIRECTED_FROM_DEMO,
  SET_STEP_INDEX,
  SET_TOUR_STEPS,
  START_DEMO,
  START_TOUR,
} from "./constant";

/**
 * Either starts or stops the tour
 * @param {boolean} payload
 *
 */

export const setStartDemo = (payload) => async (dispatch) => {
  dispatch({ type: START_DEMO, payload });
};

/**
 * Either starts or stops the tour
 * @param {boolean} payload
 *
 */

export const startStopTour = (payload) => async (dispatch) => {
  dispatch({ type: START_TOUR, payload });
};

/**
 * Sets the next index for on going tour
 * @param {number} payload
 *
 */

export const setStepIndex = (payload) => async (dispatch) => {
  dispatch({ type: SET_STEP_INDEX, payload });
};

/**
 * Set steps for the tour
 * @param {[]} payload
 *
 */

export const setTourSteps = (payload) => async (dispatch) => {
  dispatch({ type: SET_TOUR_STEPS, payload });
};

/**
 * Set steps for the tour
 * @param {boolean} payload
 *
 */

export const setRedirectedFromDemo = (payload) => async (dispatch) => {
  dispatch({ type: SET_REDIRECTED_FROM_DEMO, payload });
};
