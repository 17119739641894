import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import Form, {
  Item,
  GroupItem,
  ButtonItem,
  Label,
  ButtonOptions,
} from "devextreme-react/form";
import DropDownButton from "devextreme-react/drop-down-button";
import UpdateAPIKeyAndSecretPopup from "./UpdateAPIKeyAndSecretPopup";
import {
  accountDefaultOption,
  exchangeDefaultOption,
  apiKeyDefaultOption,
  apiSecretDefaultOption,
} from "../../constants/app";

const EditAccountInformation = ({
  exchanges,
  account,
  accountAction,
  dispatchUpdateSelfServiceAccount,
  handleAction,
  goBack,
}) => {
  const formData = useRef({});
  const [isPopup, setIsPopup] = useState(false);

  const apiInfo = useSelector((state) => state.accounts.apiInfo);

  const exchangeEditorOption = {
    ...exchangeDefaultOption,
    items: exchanges,
    value: account ? account.exchange_account_id : "",
  };

  const accountNameEditorOption = {
    ...accountDefaultOption,
    value: account ? account.account_reference : "",
  };

  const apiKeyEditorOption = {
    ...apiKeyDefaultOption,
    value: apiInfo ? apiInfo.api_key : "",
  };

  const apiSecretEditorOption = {
    ...apiSecretDefaultOption,
    value: apiInfo ? apiInfo.api_secret : "",
  };

  const handleUpdateAPIKeySecretPopup = () => {
    setIsPopup(!isPopup);
  };

  return (
    <div className="px-form-container">
      <form className="px-form">
        <Form formData={formData.current} showColonAfterLabel={false}>
          <Item
            dataField="account_reference"
            editorType={"dxTextBox"}
            isRequired={true}
            editorOptions={accountNameEditorOption}
            disabled={true}
          >
            <Label visible={true} text="ACCOUNT NAME" />
          </Item>
          <Item
            dataField="exchange_account_id"
            editorType={"dxSelectBox"}
            isRequired={true}
            editorOptions={exchangeEditorOption}
            disabled={true}
          >
            <Label visible={true} text="EXCHANGE" />
          </Item>
          <Item
            dataField="api_key"
            editorType={"dxTextBox"}
            isRequired={true}
            editorOptions={apiKeyEditorOption}
            disabled={true}
          >
            <Label visible={true} text="API KEY" />
          </Item>
          <Item
            dataField="api_secret"
            editorType={"dxTextBox"}
            isRequired={true}
            editorOptions={apiSecretEditorOption}
            disabled={true}
          >
            <Label visible={true} text="API SECRET" />
          </Item>
          <GroupItem colCount={2}>
            <ButtonItem horizontalAlignment="left">
              <ButtonOptions
                width="100%"
                text="Add"
                type="default"
                useSubmitBehavior={false}
                onClick={handleUpdateAPIKeySecretPopup}
              >
                <span className="dx-button-text">REPLACE API KEY & SECRET</span>
              </ButtonOptions>
            </ButtonItem>
            <ButtonItem horizontalAlignment="right">
              <ButtonOptions
                width="100%"
                type="normal"
                text="Cancel"
                useSubmitBehavior={false}
                onClick={() => goBack()}
              />
            </ButtonItem>
          </GroupItem>
        </Form>
      </form>
      <div className="px-actions-container">
        {accountAction && (
          <DropDownButton
            className="px-dropdown-button"
            width={250}
            splitButton={true}
            useSelectMode={false}
            text="ACTIONS"
            icon="bulletlist"
            items={accountAction}
            displayExpr="name"
            keyExpr="id"
            onItemClick={handleAction}
          />
        )}
      </div>
      {isPopup && (
        <UpdateAPIKeyAndSecretPopup
          handleUpdateAPIKeySecretPopup={handleUpdateAPIKeySecretPopup}
          dispatchUpdateSelfServiceAccount={dispatchUpdateSelfServiceAccount}
        />
      )}
    </div>
  );
};
export default EditAccountInformation;
