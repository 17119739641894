import { combineReducers } from "redux";
import accountsReducer from "./accounts/reducer";
import authReducer from "./auth/reducer";
import dashboardReducer from "./dashboard/reducer";
import manageAssetReducer from "./manage-asset/reducer";
import referenceReducer from "./references/reducer";
import selfServiceUserReducer from "./self-service-user/reducer";
import tourState from "./app-tour/reducer";
import tradingDashboard from "./tradingDashboard/reducer";

export default combineReducers({
  auth: authReducer,
  accounts: accountsReducer,
  dashboard: dashboardReducer,
  tradingDashboard,
  reference: referenceReducer,
  selfServiceUser: selfServiceUserReducer,
  manageAsset: manageAssetReducer,
  tourState,
});
