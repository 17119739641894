import { useState, useEffect } from "react";
import { ScrollView } from "devextreme-react";
import { BiTargetLock } from "react-icons/bi";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { joinCompetitionByID } from "../../redux/tradingDashboard/action";
import { handleAccountMode } from "../../redux/auth/action";
import { setSelectedAccount } from "../../redux/accounts/action";

const AvailableCompetitions = ({
  competitions,
  dispatch,
  setCompetitions,
  joinedCompetition,
}) => {
  const [availableCompetitions, setAvailableCompetitions] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setAvailableCompetitions(
      competitions.filter((item) => ["Open", "Pending"].includes(item.status))
    );
  }, [competitions]);

  const handleCompetition = async (compData) => {
    setCompetitions((state) =>
      state.filter((s) => s.competition_id !== compData.competition_id)
    );

    const data = await dispatch(joinCompetitionByID(compData.competition_id));
    if (data) {
      await dispatch(handleAccountMode("competition"));
      dispatch(
        setSelectedAccount({
          account_name: compData.description,
          client_account_id: parseInt(data.Message) ?? null,
          competition_id: compData.competition_id,
          competition_type_id: compData.competition_type_id,
          defaultAccount: false,
        })
      );
      history.push("/trading-dashboard");
    }
  };
  return (
    <>
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          height: "30px",
          width: "100%",
          marginBottom: 8,
        }}
      >
        <p
          className="margin-bottom-50"
          style={{
            fontWeight: "bold",
            fontSize: "13px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <BiTargetLock
            color="#8bc441"
            size={24}
            style={{ marginRight: "8px" }}
          />{" "}
          AVAILABLE COMPETITION
        </p>
      </div>
      <ScrollView direction="vertical">
        <div className="table-container">
          <table className="px-competition-table">
            <thead>
              <tr>
                <th>NAME</th>
                <th>START TIME</th>
                <th>ENTRANTS</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {availableCompetitions && availableCompetitions?.length > 0 ? (
                availableCompetitions?.map(
                  (row) =>
                    joinedCompetition &&
                    joinedCompetition.findIndex(
                      (j) => j.competition_id === row.competition_id
                    ) < 0 && (
                      <tr
                        key={row.competition_id}
                        style={{ borderBottom: "unset" }}
                      >
                        <td>{row?.description}</td>
                        <td style={{ width: "180px" }}>
                          {/* {row.status.toLowerCase() !== "pending" ?  */}
                          {new Date(row.start_time * 1000).toLocaleString()}
                          {/* : "-"} */}
                        </td>
                        <td style={{ width: "50px" }}>{row.entrants.length}</td>
                        <td style={{ width: "80px" }}>
                          {row?.canJoin ? (
                            <Button
                              size="small"
                              fullWidth
                              variant="text"
                              onClick={() => handleCompetition(row)}
                            >
                              JOIN
                            </Button>
                          ) : (
                            "PENDING"
                          )}
                        </td>
                      </tr>
                    )
                )
              ) : (
                <tr>
                  <td colSpan={10}>No data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </ScrollView>
    </>
  );
};

export default AvailableCompetitions;
