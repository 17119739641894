import { api } from "../../service";
import axios from "axios";
import { SET_CHART_STATE, TOGGLE_THEME } from "./constant";

export const createScreen = (payload) => async () => {
  try {
    await api.post(`/screens`, payload);
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : { message: error.message };
    throw new Error(message);
  }
};

export const getScreens = () => async () => {
  const { data } = await api.get(`/screens`);

  return data;
};

export const getScreenByID = (screenID) => async () => {
  const { data } = await api.get(`/screens/${screenID}`);

  return data;
};
/**
 * Get all exchange names.
 *
 * @returns {Array}
 */
export const getAllExchangeList = () => async () => {
  const { data } = await api.get(`/reference/pricing-exchanges`);

  return data;
};

/**
 * Get all exchange list by user id.
 *
 * @param {String} selfServiceUserID
 * @returns
 */
export const getAllExchangeListByUserID = (userScreenID) => async () => {
  const { data } = await api.get(`/screens/${userScreenID}/exchanges`);

  return data;
};

/**
 * Add exchange.
 *
 * @param {String} userScreenID
 * @param {Object} payload
 * @returns
 */
export const addNewExchange = (userScreenID, payload) => async () => {
  try {
    const { data } = await api.post(
      `/screens/${userScreenID}/exchanges`,
      payload
    );

    return data;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Delete exchange.
 *
 * @param {String} userScreenID
 * @param {Object} payload
 * @returns
 */
export const deleteExchange = (userScreenID, payload) => async () => {
  const { data } = await api.delete(`/screens/${userScreenID}/exchanges`, {
    data: payload,
  });

  return data;
};

/**
 * Get market list.
 *
 * @returns {Array}
 */
export const getMarketList = () => async () => {
  const { data } = await api.get("/reference/pricing-markets");

  return data;
};

/**
 * Get market list by user id.
 *
 * @param {String} userScreenID
 * @returns
 */
export const getMarketListByUserID = (userScreenID) => async () => {
  const { data } = await api.get(`/screens/${userScreenID}/markets`);

  return data;
};

/**
 * Add market.
 *
 * @param {String} userScreenID
 * @param {Object} payload
 * @returns
 */
export const addNewMarket = (userScreenID, payload) => async () => {
  try {
    const { data } = await api.post(
      `/screens/${userScreenID}/markets`,
      payload
    );
    return data;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Delete exchange.
 *
 * @param {String} userScreenID
 * @param {Object} payload
 * @returns
 */
export const deleteMarket = (userScreenID, payload) => async () => {
  const { data } = await api.delete(`/screens/${userScreenID}/markets`, {
    data: payload,
  });

  return data;
};

/**
 * Get price wall data.
 *
 * @param {String} userScreenID
 * @returns
 */
export const getPriceWallData = (userScreenID) => async () => {
  const { data } = await api.get(`/screens/${userScreenID}/prices`);

  return data;
};

export const placeOrderData = (payload) => async () => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_TRADE_API_KEY}/place_order`,
    null,
    {
      headers: payload,
    }
  );

  if (data) return data.message.order_id;
  return null;
};

export const cancelOrderData = (payload) => async () => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_TRADE_API_KEY}/cancel_order`,
    null,
    {
      headers: payload,
    }
  );

  if (data) return data;
  return null;
};

export const joinCompetitionByID = (pxCompetitionID) => async () => {
  const { data } = await api.get(
    `/private-competitions/${pxCompetitionID}/join`
  );

  return data;
};

export const setChartState = (data) => (dispatch) => {
  dispatch({ type: SET_CHART_STATE, payload: data });
};

export const setThemeState = (data) => (dispatch) => {
  dispatch({ type: TOGGLE_THEME, payload: data });
};
