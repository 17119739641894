import React from "react";
import { RadioGroup } from "devextreme-react";

import DialogPortal from "../DialogPortal";
import CloseIcon from "../../assets/imgs/icon-close-white.png";

const ReportDialog = ({ closeDialog, reportUser, data }) => {
  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState("");
  const [reportReason, setReportReason] = React.useState("");

  React.useEffect(() => {
    // For animating Modal
    const dialogContainer = document.querySelector(".report-dialog-content");

    const timeout = setTimeout(() => {
      dialogContainer.classList.add("animate-modal");
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  const handleClose = () => {
    const dialogContainer = document.querySelector(".report-dialog-content");
    dialogContainer.classList.remove("animate-modal");

    setTimeout(() => {
      closeDialog();
    }, 200);
  };

  const handleSubmit = () => {
    if (!value.trim()) {
      setError("Please write your report");
    }
    reportUser(data.user_id, `${reportReason}-${value}`);
    closeDialog();
  };

  return (
    <DialogPortal className="social-dashboard-dialog">
      <div className="report-dialog-content">
        <div className="social-dashboard-dialog-header">
          <div className="header-content">
            <div className="header-title">Report User</div>
            <div
              className="header-close-btn"
              onClick={handleClose}
              style={{ paddingTop: "14px", paddingBottom: "14px" }}
            >
              <img src={CloseIcon} alt="Close" />
            </div>
          </div>
        </div>
        <div className="social-dashboard-dialog-container">
          <div className="report-inner-content">
            <div className="report-inner-radio-options">
              <p>Why are you reporting them?</p>
              <RadioGroup
                value={reportReason}
                onValueChange={(value) => setReportReason(value)}
                items={[
                  "Aggressive",
                  "Child Endangerment",
                  "Discrimination",
                  "Disruptive",
                  "Privacy/Personal Information Violation",
                  "Self Hard",
                  "Sexual Harassment",
                  "Threats of Violence",
                ]}
              />
            </div>
          </div>
          <div className="report-dialog-form">
            <textarea
              rows={3}
              value={value}
              onChange={(e) => setValue(e.target.value.trimStart())}
              type="text"
              placeholder={`What did they do?${"\n"}Who was impacted?`}
            />
            {error && <span>{error}</span>}

            <div className="report-form-buttons">
              <button className="cancel-btn" onClick={closeDialog}>
                Cancel
              </button>
              <button
                disabled={!Boolean(value) || !Boolean(reportReason)}
                className="report-btn"
                onClick={handleSubmit}
              >
                Report
              </button>
            </div>
          </div>
        </div>
      </div>
    </DialogPortal>
  );
};

export default ReportDialog;
