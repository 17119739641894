import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  useEffect,
} from "react";
import { ProgressBar } from "devextreme-react/progress-bar";
import { LoadPanel } from "devextreme-react/load-panel";
import { Button } from "devextreme-react/button";

const ContentLoadingContext = createContext({});

function ContentLoadingProvider({ children }) {
  const [options, setOptions] = useState({
    type: "indicator",
    visible: true,
  });
  const [loading, setLoading] = useState(false);
  const [overlayStyles, setOverlayStyles] = useState({
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  });
  const [element, setElement] = useState(null);
  const [elementId, setElementId] = useState("scroll-content");

  useEffect(() => {
    const newElement = document.getElementById(elementId);
    if (!newElement) return;

    setElement(newElement);
    return;
  }, [loading, elementId]);

  useEffect(() => {
    if (!element) return;

    function updateSize() {
      const rect = element.getBoundingClientRect();
      setOverlayStyles({
        left: rect.left,
        top: rect.top,
        width: rect.width,
        height: rect.height,
      });
    }

    updateSize();
    const resizeObserver = new window.ResizeObserver(updateSize);
    resizeObserver.observe(element);
    return () => {
      resizeObserver.disconnect();
    };
  }, [loading, element]);

  /**
   * Loading indicator - set options and type.
   */
  const setLoadingIndicator = useCallback((isLoading) => {
    setOptions({ type: "indicator", visible: true });
    setLoading(isLoading);
  }, []);

  /**
   * Progress bar -  set options and type.
   */
  const setLoadingProgress = useCallback((isLoading, isReset) => {
    setOptions((prev) => {
      if (isLoading) {
        const step = Math.floor(Math.random() * 5) + 5;
        const prevProgress =
          typeof prev.current === "number" ? prev.current : 0;

        let current = prevProgress + step;
        if (isReset === true) {
          current = 0;
        } else if (current > 90) {
          current = prevProgress;
        }

        return { type: "progress", current, visible: true };
      } else {
        return { type: "progress", current: 100, visible: true };
      }
    });

    if (!isLoading) {
      setTimeout(() => {
        setLoading(false);
      }, 100);
    } else {
      setLoading(true);
    }
  }, []);

  const setMessage = useCallback((message = "", accountID = "") => {
    setOptions({ type: "message", message, accountID, visible: true });
  }, []);

  const statusFormat = (value) => {
    return "Testing API Key...";
  };

  const closeIndicator = () => {
    setOptions({ ...options, visible: false });
  };

  return (
    <ContentLoadingContext.Provider
      value={{
        setLoadingIndicator,
        setLoadingProgress,
        setMessage,
        setElementId,
        progress: options.current,
        loading,
      }}
    >
      {options.visible &&
        ((options.type === "progress" && loading) ||
          options.type === "message") && (
          <div
            className="dx-overlay-wrapper dx-loadpanel-wrapper dx-overlay-shader px-overlay-wrapper"
            style={{
              ...overlayStyles,
              backgroundColor:
                options.type === "message"
                  ? "rgba(40,0,0,.32)"
                  : "rgba(0,0,0,.32)",
            }}
          >
            <div className="px-loading-indicator">
              {options.type === "message" ? (
                <p className="px-message">
                  {options.message}{" "}
                  <Button icon="check" onClick={closeIndicator} />
                  {/* <span
                  onClick={() =>
                    history.push(`/accounts/edit/${options.accountID}`)
                  }
                >
                  account settings
                </span> */}
                </p>
              ) : (
                <ProgressBar
                  min={0}
                  max={100}
                  value={options.current}
                  statusFormat={statusFormat}
                />
              )}
            </div>
          </div>
        )}
      {options.visible && options.type === "indicator" && loading && (
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ of: "#scroll-content" }}
          showIndicator={true}
          shading={true}
          showPane={true}
          visible={true}
        />
      )}
      {children}
    </ContentLoadingContext.Provider>
  );
}

const useContentLoading = () => useContext(ContentLoadingContext);

export { ContentLoadingProvider, useContentLoading };
