import React from "react";
import PieChart, { Legend, Series, Size } from "devextreme-react/pie-chart";
import { Label } from "devextreme-react/chart";

const CurrentRiskRating = ({ risk_rating }) => {
  const sourceArray = [
    {
      id: -1,
      value: 100 - risk_rating,
    },
    {
      id: 2,
      value: risk_rating,
    },
  ];

  const customizePoint = (point) => {
    const { data } = point;

    if (data.id !== -1) {
      if (data.value > 0)
        return {
          color: "green",
        };
    }
    if (data.id === -1) {
      if (data.value > 0) 
        return {
          color: "red",
        };
    }
  };

  return (
    <div>
      <PieChart
        id="pie"
        key="id"
        dataSource={sourceArray}
        resolveLabelOverlapping="shift"
        sizeGroup="piesGroup"
        innerRadius={0.85}
        type="doughnut"
        centerRender={TooltipTemplate}
        customizePoint={customizePoint}
      >
        <Size width={"100%"} height={400} />
        <Series argumentField="id" valueField="value" hoverMode="none">
          <Label visible={false}></Label>
        </Series>
        <Legend visible={false} />
      </PieChart>
    </div>
  );
};

function TooltipTemplate(pieChart) {
  return (
    <svg>
      <circle
        cx="100"
        cy="80"
        r={pieChart.getInnerRadius()}
        fill="white"
      ></circle>
      <image
        href={"panxora-favicon.png"}
        x="20"
        y="8"
        width="150"
        height="150"
      />
    </svg>
  );
}
export default CurrentRiskRating;
