import React from "react";
import ReactDOM from "react-dom";

const DialogPortal = ({ children, className }) => {
  const [container] = React.useState(() => {
    const elem = document.createElement("div");
    elem.classList.add(className);

    return elem;
  });

  React.useEffect(() => {
    document.body.appendChild(container);
    

    return () => document.body.removeChild(container);
    // eslint-disable-next-line
  }, []);

  return ReactDOM.createPortal(children, container);
};

export default DialogPortal;
