import React, { createContext, useContext, useState } from "react";

const SocialDashboardContext = createContext({});

const SocialDashboardProvider = ({ children }) => {
  const [socialData, setSocialData] = useState(null);
  const [openSocialDialog, setOpenSocialDialog] = useState({
    open: false,
    tab: 0,
  });
  const [counts, setCounts] = useState({
    friendsCount: 0,
    messagesCount: 0,
  });

  return (
    <SocialDashboardContext.Provider
      value={{
        socialData,
        setSocialData,
        openSocialDialog,
        setOpenSocialDialog,
        counts,
        setCounts,
      }}
    >
      {children}
    </SocialDashboardContext.Provider>
  );
};

export default SocialDashboardProvider;

export const useSocialContext = () => useContext(SocialDashboardContext);
