import React, { useCallback, useRef, useState } from "react";
import LoadIndicator from "devextreme-react/load-indicator";
import Form, {
  ButtonItem,
  ButtonOptions,
  CompareRule,
  NumericRule,
  GroupItem,
  Item,
  Label,
  RequiredRule,
} from "devextreme-react/form";
import { useToast } from "../../contexts/toast";
import { DEFAULT_TOAST_TIME, UPDATE_PASSWORD_SUCCESS_MSG } from "../../constants";

const AccountPasswordForm = (props) => {
  const { dispatchChangePassword } = props;
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});

  const passwordComparison = () => {
    return formData.current.newPassword;
  };

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { oldPassword, newPassword, code } = formData.current;

      setLoading(true);
      try {
        await dispatchChangePassword(oldPassword, newPassword, code);
        setLoading(false);
        showToast(UPDATE_PASSWORD_SUCCESS_MSG, "success", DEFAULT_TOAST_TIME);
      } catch (error) {
        setLoading(false);
        showToast(error.message, "error", DEFAULT_TOAST_TIME);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatchChangePassword]
  );

  return (
    <form id={"change-password-form"} onSubmit={onSubmit}>
      <Form
        formData={formData.current}
        disabled={loading}
        readOnly={false}
        showColonAfterLabel={true}
        validationGroup="currentData"
      >
        <GroupItem colCount={2}>
          <GroupItem>
            <Item
              dataField={"oldPassword"}
              editorType={"dxTextBox"}
              editorOptions={oldPasswordEditorOptions}
            >
              <RequiredRule message="Password is required" />
              <Label visible={true} text="CURRENT PASSWORD" />
            </Item>
            <Item
              dataField={"newPassword"}
              editorType={"dxTextBox"}
              editorOptions={newPasswordEditorOptions}
            >
              <RequiredRule message="Password is required" />
              <Label visible={true} text="NEW PASSWORD" />
            </Item>
            <Item
              dataField={"confirmPassword"}
              editorType={"dxTextBox"}
              editorOptions={confirmPasswordEditorOptions}
            >
              <RequiredRule message="Confirm Password is required" />
              <CompareRule
                message="New Password and Confirm Password do not match"
                comparisonTarget={passwordComparison}
              />
              <Label visible={true} text="CONFIRM NEW PASSWORD" />
            </Item>
            <Item
              dataField={"code"}
              editorType={"dxTextBox"}
              editorOptions={codeEditorOptions}
            >
              <RequiredRule message="The code is required" />
              <NumericRule message="The code should be number." />
              <Label visible={true} text="2FA CODE" />
            </Item>
            <ButtonItem>
              <ButtonOptions
                width={"100%"}
                type={"default"}
                useSubmitBehavior={true}
              >
                <span className="dx-button-text">
                  {loading ? (
                    <LoadIndicator
                      width={"24px"}
                      height={"24px"}
                      visible={true}
                    />
                  ) : (
                    "SAVE"
                  )}
                </span>
              </ButtonOptions>
            </ButtonItem>
          </GroupItem>
        </GroupItem>
      </Form>
    </form>
  );
};

const oldPasswordEditorOptions = {
  stylingMode: "filled",
  placeholder: "Type your current password",
  mode: "password",
};
const newPasswordEditorOptions = {
  stylingMode: "filled",
  placeholder: "Type your new password",
  mode: "password",
};
const confirmPasswordEditorOptions = {
  stylingMode: "filled",
  placeholder: "Retype your new password",
  mode: "password",
};
const codeEditorOptions = {
  stylingMode: "filled",
  placeholder: "Type your authentication code",
  mode: "text",
};

export default AccountPasswordForm;
