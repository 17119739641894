import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form, {
  Item,
  ButtonItem,
  Label,
  ButtonOptions,
  RangeRule,
  PatternRule,
} from "devextreme-react/form";
import "devextreme-react/text-area";
import { LoadIndicator } from "devextreme-react/load-indicator";
import Error from "../Error";
import {
  getAssetAllocationByAccountID,
  updateAssetAllocationByAccountID,
} from "../../redux/manage-asset/action";
import { useToast } from "../../contexts/toast";
import {
  changeReqPayloadFormat,
  joinWithoutDupes,
} from "../../utils/custom-functions";
import { DEFAULT_TOAST_TIME } from "../../constants";

const AllocateBalanceForm = ({ defaultUnique }) => {
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const formData = useRef({});
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [uniqueAmount, setUniqueAmount] = useState();
  const [assetID, setCurrentAssetID] = useState();

  const assetsInfo = useSelector((state) => state.manageAsset.assetsInfo);
  const account = useSelector((state) => state.accounts.info);
  const fullAssets = useSelector((state) => state.accounts.riskManagedAssets);
  const priceAssets = useSelector((state) => state.reference.priceAssets);
  const accountID = account ? account.client_account_id : null;

  const assetFieldDefaultOptions = {
    items: fullAssets ? fullAssets : null,
    searchEnabled: true,
    searchExpr: ["exchange_symbol"],
    valueExpr: "instrument_id",
    displayExpr: "exchange_symbol",
    onValueChanged: (e) => {
      if (e.value) {
        setCurrentAssetID(e.value);

        // Auto focus the amount-input.
        const field = "amount";
        const editor = formRef.current.instance.getEditor(field);
        editor.focus();
      }
    },
  };

  const amountFieldDefaultOptions = {
    stylingMode: "filled",
    placeholder: "",
    mode: "text",
  };

  useEffect(() => {
    if (defaultUnique) setUniqueAmount(defaultUnique);
  }, [defaultUnique]);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      // Dispatch method.
      allocateFreeBalance(formData.current);
      setLoading(false);
      setCurrentAssetID(null);
      // Initialize the devextreme form.
      formRef.current.instance.resetValues();
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  const getPriceByAssetID = (id) => {
    if (priceAssets) {
      const data = priceAssets.filter((item) => item.instrument_id === id);
      return data[0].asset_price;
    }
    return 0;
  };

  const getExchangeSymbolByAssetID = (id) => {
    if (priceAssets) {
      const data = priceAssets.filter((item) => item.instrument_id === id);
      return data[0].asset_name;
    }
    return 0;
  };

  const allocateFreeBalance = async (values) => {
    /**
     * When the user adds quantity to an existing or new asset, they enter the quantity in the base currency.
     * The amount of the Target Allocation delta is calculated as Quantity / Price (for that asset).
     * If this is an existing asset in the Selected Assets table then the target allocation is adjusted.
     * If this is a new asset, then the asset is added with the specified price and the calculated Target Allocation delta and an Actual position of 0.
     */
    if (!assetsInfo) return;

    const { amount, instrument_id } = values;
    const data = assetsInfo.allocations.filter(
      (item) => item.instrument_id === instrument_id
    );
    
    let tempAllocs;

    var selectedAssetName = "";
    var isNewAsset = false;

    if (data && data.length !== 0) {
      // If the selected asset is the one that is in the current allocation.
      tempAllocs = assetsInfo.allocations.map((item) => {
        if (item.instrument_id === instrument_id) {
          selectedAssetName = item.exchange_symbol;
          return {
            ...item,
            current_model_id: 3,
            target_quantity: item.target_quantity + amount / item.price,
          };
        }
        return item;
      });
    } else {
      isNewAsset = true;
      // If Selected asset is a new one.
      selectedAssetName = getExchangeSymbolByAssetID(instrument_id);

      const item = {
        instrument_id: instrument_id,
        current_model_id: 3,
        target_percentage: 0,
        price: getPriceByAssetID(instrument_id),
        exchange_symbol: getExchangeSymbolByAssetID(instrument_id),
        target_quantity: amount / getPriceByAssetID(instrument_id),
      };

      tempAllocs = [...assetsInfo.allocations, item];
    }

    // Clone the original allocations.
    const originalAllocations = JSON.parse(
      JSON.stringify(assetsInfo.allocations)
    );
    // Merge two arrays
    const mixedAllocations = joinWithoutDupes(originalAllocations, tempAllocs);

    const reqPayload = {
      management_type_id: assetsInfo.current_management_type_id,
      allocations: changeReqPayloadFormat(mixedAllocations),
    };

    await dispatch(
      updateAssetAllocationByAccountID(accountID, reqPayload)
    ).then((res) => {
      if (res) {
        showToast(
          `${
            isNewAsset
              ? `The quantity of ${selectedAssetName} has been updated`
              : `The ${selectedAssetName} has been added`
          }`,
          "success",
          DEFAULT_TOAST_TIME
        );
      }
    });
    await dispatch(getAssetAllocationByAccountID(accountID));
  };

  return (
    <form onSubmit={handleSubmit}>
      {!!error && <Error>{error}</Error>}
      <Form
        ref={formRef}
        formData={formData.current}
        showColonAfterLabel={false}
        disabled={loading}
        width={"100%"}
      >
        <Item
          dataField="instrument_id"
          editorType={"dxSelectBox"}
          editorOptions={assetFieldDefaultOptions}
          isRequired={true}
        >
          <Label visible={true} text={"ASSET"} />
        </Item>
        {assetID && (
          <Item
            dataField="amount"
            editorTyp={"dxTextBox"}
            editorOptions={amountFieldDefaultOptions}
            isRequired={true}
          >
            <Label visible={true} text={"AMOUNT TO ALLOCATE"} />
            {/* <PatternRule pattern={/^[+-]?\d+(\.\d+)?$/} message={"This is not the number."} /> */}
            <PatternRule
              pattern={/^[+-]?((\.\d+)|(\d+(\.\d+)?))$/}
              message={"This is not the number."}
            />
            <RangeRule
              min={0}
              message={`The amount should be positive and less than the free available balance`}
              max={uniqueAmount}
            />
          </Item>
        )}
        <ButtonItem horizontalAlignment="left">
          <ButtonOptions
            width="100%"
            text="Add"
            type="default"
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {loading ? (
                <LoadIndicator width={"24px"} height={"24px"} visible={true} />
              ) : (
                "ALLOCATE BALANCE"
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
};

export default React.memo(AllocateBalanceForm);
