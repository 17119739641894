import { api } from "../../service";
import {
  SET_SELF_SERVICE_USER,
  SET_BILLING_RATES_INFO,
  SET_BILLING_ACCOUNTS_INFO,
} from "./constant";

/**
 * Get self service user by id.
 *
 * @returns
 */
export const getSelfServiceUserByID =
  (selfServiceUserID) => async (dispatch) => {
    const { data } = await api.get(`/users/${selfServiceUserID}`);

    if (data) dispatch({ type: SET_SELF_SERVICE_USER, payload: data });

    return data;
  };

/**
 * Update self service user by id.
 *
 * @param {string} selfSErviceUserID
 * @param {object} values
 * @returns
 */
export const updateSelfServiceUser =
  (selfSErviceUserID, values) => async () => {
    try {
      const { data } = await api.put(`/users/${selfSErviceUserID}`, values);

      return data;
    } catch (error) {
      const message = error.response
        ? error.response.data.message
        : { message: error.message };
      throw new Error(message);
    }
  };

/**
 * Add a new address.
 *
 * @param {string} selfServiceUserID
 * @param {object} values
 * @returns
 */
export const addAddress = (selfServiceUserID, values) => async () => {
  try {
    const { data } = await api.post(
      `/users/${selfServiceUserID}/addresses`,
      values
    );

    return data[0];
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Update the self service user's address.
 *
 * @param {string} selfServiceUserID
 * @param {string} addressID
 * @param {object} values
 * @returns
 */
export const updateAddress =
  (selfServiceUserID, addressID, values) => async () => {
    try {
      const { data } = await api.put(
        `/users/${selfServiceUserID}/addresses/${addressID}`,
        values
      );

      return data[0];
    } catch (error) {
      const message = error.response
        ? error.response.data.message
        : { message: error.message };
      throw new Error(message);
    }
  };

/**
 * Get the self service user's address.
 * @param {string} selfServiceUserID
 * @param {string} addressID
 * @returns
 */
export const getAddressByID = (selfServiceUserID, addressID) => async () => {
  try {
    const { data } = await api.get(
      `/users/${selfServiceUserID}/addresses/${addressID}`
    );

    return data[0];
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Get the Billing rates information.
 *
 * @param {string} pxAccountID
 * @returns
 */
export const getBillingRates = (pxClientUserID) => async (dispatch) => {
  try {
    const { data } = await api.get(
      `/client-users/${pxClientUserID}/billing-rates`
    );

    if (data) dispatch({ type: SET_BILLING_RATES_INFO, payload: data });

    return data[0];
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Get the Billing accounts information.
 *
 * @param {string} pxClientUserID
 * @returns
 */
export const getBillingAccounts = (pxClientUserID) => async (dispatch) => {
  try {
    const { data } = await api.get(
      `/client-users/${pxClientUserID}/billing-accounts`
    );

    if (data) dispatch({ type: SET_BILLING_ACCOUNTS_INFO, payload: data });

    return data[0];
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Get affiliate link.
 *
 * @param {string} clientUserID
 * @returns
 */
export const getAffiliateLink = (clientUserID) => async () => {
  const { data } = await api.get(`/users/${clientUserID}/affiliate-link`);

  return data;
};

/**
 * Get affiliate signups.
 *
 * @param {string} clientUserID
 * @returns
 */
export const getAffiliateSignUps = (clientUserID) => async () => {
  const { data } = await api.get(`/users/${clientUserID}/affiliate-signups`);

  return data;
};

/**
 * Get all twitter handlers.
 *
 * @param {string} clientUserID
 * @returns
 */
export const getTwitterHandles = (clientUserID) => async () => {
  const { data } = await api.get(`/users/${clientUserID}/twitter-handles`);

  return data;
};

/**
 * Add new twitter handler.
 *
 * @param {string} clientUserID
 * @param {string} handlerName
 * @returns
 */
export const addTwitterHandler = (clientUserID, handlerName) => async () => {
  try {
    await api.post(`/users/${clientUserID}/twitter-handles`, {
      twitter_handle: handlerName,
    });
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Remove specific twitter handler.
 *
 * @param {string} clientUserID
 * @param {string} handlerName
 * @returns
 */
export const removeTwitterHandler = (clientUserID, handlerName) => async () => {
  await api.delete(`/users/${clientUserID}/twitter-handles/${handlerName}`);
};

// Needs to be refactored.
export const getSocialNetworks = (clientUserID) => async (dispatch) => {
  const { data } = await api.get(`/users/${clientUserID}/social-networks`);

  return data;
};

export const addSocialNetworkToChannel =
  (clientUserID, payload) => async () => {
    try {
      const { data } = await api.post(
        `/users/${clientUserID}/social-networks`,
        payload
      );

      return data;
    } catch (error) {
      const message = error.response
        ? error.response.data.message
        : { message: error.message };
      throw new Error(message);
    }
  };

export const updateSocialNetworkToChannel =
  (clientUserID, socialNetworkID, payload) => async () => {
    try {
      await api.put(
        `/users/${clientUserID}/social-networks/${socialNetworkID}`,
        payload
      );
    } catch (error) {
      const message = error.response
        ? error.response.data.message
        : { message: error.message };
      throw new Error(message);
    }
  };

export const removeSocialNetwork =
  (clientUserID, socialNetworkID) => async () => {
    const { data } = await api.delete(
      `/users/${clientUserID}/social-networks/${socialNetworkID}`
    );

    return data;
  };

export const getImersProgress = (clientUserID) => async () => {
  const { data } = await api.get(`/users/${clientUserID}/imers/progress`);

  return data;
};
