import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Prompt } from "react-router";
import { useLocation, useHistory } from "react-router-dom";
import LoadPanel from "devextreme-react/load-panel";
import IdleTimer from "react-idle-timer";

import UnauthenticatedContent, {
  isPublicContent,
} from "./UnauthenticatedContent";
import Content from "./Content";
import { connect } from "./redux/auth/action";
import { logout } from "./redux/auth/action";
import { getCompanyLogo } from "./redux/dashboard/action";
import { useAppData } from "./contexts/app";
import { get } from "./utils/storage";
import { useScreenSizeClass } from "./utils/media-query";
import { MAIN_TOKEN_KEY, IDLE_USER_LIMIT } from "./constants/app";
import "intro.js/introjs.css";
import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
// import "./dx-styles.scss";

const App = () => {
  const idleTimerRef = useRef({});
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, setLoading } = useAppData();
  const screenSizeClass = useScreenSizeClass();

  const user = useSelector((state) => state.auth.info);
  const isEdited = useSelector((state) => state.auth.isEdited);

  // User Idle logout variables
  const [isTimedOut, setIsTimedout] = useState(false);
  const [timeout] = useState(IDLE_USER_LIMIT);

  useEffect(() => {
    setLoading(false);

    // if path is public url, don't check user
    if (isPublicContent(location.pathname)) {
      return;
    }

    const token = get(MAIN_TOKEN_KEY);
    setLoading(true);

    const promise = token
      ? dispatch(connect())
      : Promise.reject(new Error("Signed out"));

    promise
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        history.push("/login", { from: location.pathname });
        setLoading(false);
      });
    // only check one times when component is mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) dispatch(getCompanyLogo(user && user.legal_entity_id));
  }, [dispatch, user]);

  // If action detected, set timeout false
  const onAction = () => {
    setIsTimedout(false);
  };

  // If active tab detected, set timeout false
  const onActive = () => {
    setIsTimedout(false);
  };

  // Navigate to login page
  const handleLogout = () => {
    // Before logout, save last path to session storage so user back again after login
    const token = get(MAIN_TOKEN_KEY);
    if (token) dispatch(logout(history, location));
  };

  // If no action detected, and timed out, go to login
  const onIdle = () => {
    if (isTimedOut) {
      const path = window.location.pathname;
      if (path.includes("trading-dashboard")) {
        console.log("idle timer reset");
        idleTimerRef.current.reset();
        return;
      }

      handleLogout();
    } else {
      idleTimerRef.current.reset();
      setIsTimedout(true);
    }
  };

  const renderContent = () => {
    if (loading) return <LoadPanel visible={true} />;

    if (user) return <Content />;

    return <UnauthenticatedContent />;
  };

  return (
    <div
      className={`app ${
        user ? "dashboard" : "px-public-component"
      } ${screenSizeClass}`}
    >
      <IdleTimer
        ref={idleTimerRef}
        element={document}
        onActive={onActive}
        onIdle={onIdle}
        onAction={onAction}
        debounce={250}
        timeout={timeout}
      />
      <Prompt
        when={isEdited}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      {renderContent()}
    </div>
  );
};

export default App;
