export const cyberToolsNavigation = [
  {
    text: "TRADING DASHBOARD",
    icon: "money",
    path: "/trading-dashboard",
  },
  {
    text: "ACCOUNT DASHBOARD",
    icon: "chart",
    path: "/account-dashboard",
  },
  {
    text: "ACCOUNTS",
    icon: "columnfield",
    path: "/accounts",
  },
  {
    text: "SETTINGS",
    icon: "preferences",
    path: "/settings",
  },
  // {
  //   text: "INSTRUCTION VIDEO",
  //   icon: "video",
  //   path: "",
  //   items: [
  //     {
  //       id: 1,
  //       text: "PLACE TRADE",
  //       mode: "instruction_play",
  //     },
  //     {
  //       id: 2,
  //       text: "CANCEL TRADE",
  //       mode: "instruction_play",
  //     },
  //     {
  //       id: 3,
  //       text: "JOIN COMPETITION",
  //       mode: "instruction_play",
  //     },
  //   ],
  // },
  // {
  //   text: "SWITCH TO IMERS LAND",
  //   icon: "refresh",
  //   mode: "imers",
  // },
];
