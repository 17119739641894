import React, { useEffect, useState } from "react";
import { Button } from "devextreme-react/button";
import { useDispatch, useSelector } from "react-redux";
import DataGrid, { Column, Editing } from "devextreme-react/data-grid";
import { changeReqPayloadFormat } from "../../utils/custom-functions";
import { useContentLoading } from "../../contexts/contentLoading";
import {
  getAssetAllocationByAccountID,
  updateAssetAllocationByAccountID,
} from "../../redux/manage-asset/action";
import {
  addNewAccountToList,
  setAccountInformation,
  setSelectedAccount,
  setSelfServiceAccounts,
} from "../../redux/accounts/action";
import { useToast } from "../../contexts/toast";
import { SETUP_ACCOUNT_SUCCESS_MSG } from "../../constants";
import { useHistory } from "react-router-dom";
import Strategy from "./Strategy";
import { useScreenSize } from "../../utils/media-query";
import { ProfileFormEditedBefore } from "../../redux/auth/action";

const ViewOnlyManagement = ({
  isSetup,
  setSelectedIndex = null,
  expertGUI,
  previousStep = null,
  managementTypeID,
  accountID,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const { setLoadingIndicator } = useContentLoading();
  const screenSize = useScreenSize();

  const [opened, setOpened] = useState(false);
  const [allocations, setAllocations] = useState();

  const assetsInfo = useSelector((state) => state.manageAsset.assetsInfo);
  const accounts = useSelector((state) => state.accounts.list);

  const screenWidth =
    screenSize.isLarge || screenSize.isMedium ? "100%" : "100%";
  const displayMethod =
    screenSize.isLarge || screenSize.isMedium ? "flex" : "block";

  useEffect(() => {
    if (!assetsInfo) return;
    const data = JSON.parse(JSON.stringify(assetsInfo.allocations));
    const updated = data.map((item) => {
      var can_be_basket = false;
      var can_be_risk = false;
        if (
          item.available_models[0].management_type_id === 1 &&
          item.available_models[0].model &&
          item.available_models[0].model.length === 2
        ) {
          can_be_basket = true;
        } else can_be_basket = false;

        if (
          item.available_models[1].management_type_id === 2 &&
          item.available_models[1].model &&
          item.available_models[1].model.length === 2
        ) {
          can_be_risk = true;
        } else can_be_risk = false;

      item.can_be_basket = can_be_basket;
      item.can_be_risk = can_be_risk;
      return item;
    });

    setAllocations(updated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetsInfo]);
  const dispatchCommitAllocations = async () => {
    
    dispatch(ProfileFormEditedBefore(false));
    
    const data = {
      management_type_id: managementTypeID,
      allocations: changeReqPayloadFormat(assetsInfo.allocations),
    };

    setLoadingIndicator(true);
    await dispatch(updateAssetAllocationByAccountID(accountID, data)).then(
      async (res) => {
        if (isSetup) {
          dispatch(setSelectedAccount(res[0]));
          const data =
            accounts &&
            accounts.filter(
              (item) => item.client_account_id === res[0].client_account_id
            );

          if (data && data.length === 0) dispatch(addNewAccountToList(res[0]));
          else {
            const updates = accounts.map((item) => {
              if (item.client_account_id === res[0].client_account_id) {
                return {
                  ...item,
                  account_status_id: 4,
                  account_status: "Active",
                };
              } else return item;
            });
            dispatch(setSelfServiceAccounts(updates));
          }

          showToast(SETUP_ACCOUNT_SUCCESS_MSG, "success", 4000);
          await dispatch(getAssetAllocationByAccountID(accountID));
          setLoadingIndicator(false);
          history.push("/account-dashboard");
        } else {
          const { management_type_id, expert_gui } = res[0];
          res[0].management_type_id = management_type_id;
          dispatch(setAccountInformation(res[0]));
          dispatch(setSelectedAccount(res[0]));
          dispatch(getAssetAllocationByAccountID(accountID));
          setLoadingIndicator(false);
          // Redirect user to next tab or dashboard by the account mode.
          if (expert_gui) setSelectedIndex(2);
          else history.push("/account-dashboard");
        }
      }
    );
  };

  const handleManagementType = (pxValue) => {
    // Pending for setting management type.

    setOpened(false);
  };
  return (
    <div
      style={{ display: displayMethod }}
      className="px-asset-allocation-container"
    >
      {!isSetup && (
        <div
          id="mySidepanel"
          className={`${opened ? "sidepanel open" : "sidepanel"}`}
        >
          <Strategy
            isSetup={false}
            handleManagementType={handleManagementType}
          />
        </div>
      )}
      <div
        style={{
          width: screenWidth,
          padding: "20px 20px",
          height: 450,
        }}
      >
        <div className="px-assets-header">
          <p className="px-asset-title no-padding">VIEW ONLY SETUP</p>
          <div className="px-btn-tools">
            {!isSetup && (
              <>
                <Button
                  text="CHANGE MANAGEMENT TYPE"
                  onClick={() => setOpened(!opened)}
                  style={{ marginRight: 10 }}
                />
                <Button
                  text={`${isSetup ? "CONTINUE SETUP" : "COMMIT CHANGES"}`}
                  onClick={() => {
                    dispatchCommitAllocations();
                  }}
                />
              </>
            )}
            {isSetup && (
              <>
                <Button
                  text="PREVIOUS STEP"
                  onClick={previousStep}
                  style={{ marginRight: 10 }}
                />
                <Button
                  text={`${expertGUI ? "CONTINUE SETUP" : "COMPLETE SETUP"}`}
                  onClick={() => {
                    dispatchCommitAllocations();
                  }}
                  style={{ marginRight: 10 }}
                />
              </>
            )}
          </div>
        </div>
        <DataGrid
          className={"dx-card wide-card"}
          dataSource={allocations}
          showBorders={true}
          keyExpr="instrument_id"
          focusedRowEnabled={false}
          defaultFocusedRowIndex={false}
          columnAutoWidth={true}
          columnHidingEnabled={true}
        >
          <Editing mode="row" />
          <Column
            dataField={"exchange_symbol"}
            caption={"ASSET"}
            hidingPriority={6}
          />
          <Column
            dataField={"can_be_basket"}
            caption={"BASKET TRADING MANAGEABLE"}
            hidingPriority={6}
          />
          <Column
            dataField={"can_be_risk"}
            caption={"RISK MANAGEABLE"}
            hidingPriority={2}
          />
          <Column
            dataField={"position"}
            caption={"ACTUAL QUANTITY"}
            hidingPriority={2}
          />
        </DataGrid>
      </div>
    </div>
  );
};
export default ViewOnlyManagement;
