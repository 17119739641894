export default function HashToObject(arr) {
  let object = {};
  arr &&
    arr.map((item) => {
      object = { ...object, [item.id ? item.id : item.instrument_id]: item };
      return item;
    });
  return object;
};

// Change the array format.
export const changeReqPayloadFormat = (arr) => {
  const updated =
    arr &&
    arr.map((item) => {
      const {
        instrument_id,
        current_model_id,
        target_percentage,
        target_quantity,
      } = item;
      let modelID = current_model_id ? current_model_id : 1;
      const a = {
        instrument_id,
        current_model_id: modelID,
        target_percentage,
        target_quantity,
      };
      return a;
    });
  return updated;
};

export function joinWithoutDupes(A, B) {
  // const a = new Set(A.map(x => x.instrument_id))
  const b = new Set(B.map((x) => x.instrument_id));
  return [...A.filter((x) => !b.has(x.instrument_id)), ...B];
};