import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "devextreme-react/button";
import DataGrid, { Column, Editing, Lookup } from "devextreme-react/data-grid";
import DisplayAccountInfo from "./DisplayAccountInfo";
import IncrementDecrementButton from "./IncrementDecrementButton";
import {
  getAssetAllocationByAccountID,
  updateAssetAllocationByAccountID,
} from "../../redux/manage-asset/action";
import { getFullAssetsByAccountID } from "../../redux/accounts/action";
import { useScreenSize } from "../../utils/media-query";
import {
  changeReqPayloadFormat,
  joinWithoutDupes,
} from "../../utils/custom-functions";

function calcAccountBalance(allocations, type) {
  if (allocations) {
    var sum = 0;
    allocations.map((item) => {
      const { position, target_quantity, price } = item;
      const target = type === "allocated" ? target_quantity : position;
      sum += target * price;
      return item;
    });
    return sum;
  }
  return 0;
}

const BasketTrading = ({ setLoading, previousStep = null, isSetup }) => {
  const history = useHistory();
  const targetAssetGridRef = useRef();
  const screenSize = useScreenSize();
  const dispatch = useDispatch();
  const [disableCommit, setDisableCommit] = useState(true);
  const [sumOfPercentage, setSumOfPercentage] = useState(0);

  const account = useSelector((state) => state.accounts.info);
  const assetsInfo = useSelector((state) => state.manageAsset.assetsInfo);
  let fullAssets = useSelector((state) => state.accounts.basketAssets);
  const tempFullAssets = JSON.parse(JSON.stringify(fullAssets));

  let customizedFullAssets = JSON.parse(
    JSON.stringify(fullAssets ? fullAssets : [])
  );

  customizedFullAssets = customizedFullAssets.map((item) => {
    const token = {
      asset_id: item.instrument_id,
      client_account_id: item.client_account_id,
      description: item.description,
      exchange_symbol: item.exchange_symbol,
      symbol: item.symbol,
      is_new_asset: item.is_new_asset,
      disabled: item.is_new_asset ? false : true,
    };
    return token;
  });

  const [allocations, setAllocations] = useState();

  const { account_reference: accountRef } = account;
  const accountID = account ? account.client_account_id : null;
  const currentBalance =
    Math.round(calcAccountBalance(assetsInfo.allocations, "current") * 100) /
    100;

  const screenWidth =
    screenSize.isLarge || screenSize.isMedium ? "50%" : "100%";
  const displayMethod =
    screenSize.isLarge || screenSize.isMedium ? "block" : "block";

  useEffect(() => {
    if (!assetsInfo) return;
    const data = JSON.parse(JSON.stringify(assetsInfo.allocations));
    const updated = data.filter((item) => item.current_model_id !== 1);

    var sum = 0;
    updated.map((item) => {
      sum += item.target_percentage;
      return item;
    });

    sum = parseFloat(sum.toFixed(1));

    if (sum === 100) setDisableCommit(false);
    else setDisableCommit(true);

    setSumOfPercentage(sum);

    setAllocations(updated);
  }, [assetsInfo]);

  const handleChangeAsset = (id, key, value) => {
    setAllocations((cur) => {
      if (!cur) return [];
      var sum = 0;
      const updated = cur.map((item) => {
        if (item.instrument_id === id) {
          sum += value;
          if (!item.target_quantity)
            return { ...item, [key]: value, target_quantity: 0 };
          else return { ...item, [key]: value };
        }
        sum += item.target_percentage;
        return item;
      });

      sum = parseFloat(sum.toFixed(1));

      if (sum === 100) setDisableCommit(false);
      else setDisableCommit(true);
      setSumOfPercentage(sum);

      return updated;
    });
  };

  const handleCommitAllocations = async () => {
    // Clone the original allocations.
    const originalAllocations = JSON.parse(
      JSON.stringify(assetsInfo.allocations)
    );
    // the assets that are in allocations should be all Rebalance (Basket).
    const basketAssetsArray = allocations.map((item) => {
      item.current_model_id = 2;
      return item;
    });

    // Merge two arrays
    const mixedAllocations = joinWithoutDupes(
      originalAllocations,
      basketAssetsArray
    );

    const finalData = {
      management_type_id: assetsInfo.current_management_type_id,
      allocations: changeReqPayloadFormat(mixedAllocations),
    };

    fullAssets = tempFullAssets;

    setLoading(true);
    await dispatch(updateAssetAllocationByAccountID(accountID, finalData));
    await dispatch(getAssetAllocationByAccountID(accountID));
    // For now, call the asset-list endpoint again here. This code base should update in the future.
    await dispatch(getFullAssetsByAccountID(accountID));
    // It takes user to the dashboard with the specific account if it is Setup Wizard.
    if (isSetup) history.push("/account-dashboard");
    setLoading(false);
  };

  const handleEditorPreparing = (e) => {
    if (e.dataField === "target_percentage" && e.parentType === "dataRow") {
      e.editorOptions.value = Math.round((100 - sumOfPercentage) * 100) / 100;
      e.row.data.target_percentage =
        Math.round((100 - sumOfPercentage) * 100) / 100;
    }
  };

  return (
    <React.Fragment>
      <div className="px-assets-header">
        <p className="px-asset-title">BASKET TRADING</p>
      </div>
      <DisplayAccountInfo
        accountRef={accountRef}
        accountBalance={currentBalance}
      />
      <div style={{ display: displayMethod }}>
        <div
          style={{
            width: screenWidth,
            padding: "20px 20px",
          }}
        >
          <div className="px-assets-header">
            <p className="px-asset-sub-title" style={{ marginRight: 10 }}>
              TARGET ALLOCATION
            </p>
            <p
              className={
                sumOfPercentage > 100
                  ? "px-sum red"
                  : sumOfPercentage === 100
                  ? "px-sum green"
                  : "px-sum warn"
              }
            >
              TOTAL: {sumOfPercentage}%
            </p>
            <div className="px-btn-tools">
              {isSetup && (
                <Button
                  text="PREVIOUS STEP"
                  onClick={previousStep}
                  style={{ marginRight: 10 }}
                />
              )}
              <Button
                className="px-asset-btn"
                text={`${isSetup ? "COMPLETE SETUP" : "COMMIT CHANGES"}`}
                onClick={() => handleCommitAllocations()}
                disabled={disableCommit}
              />
            </div>
          </div>
          <DataGrid
            className={"dx-card wide-card"}
            dataSource={allocations}
            showBorders={true}
            keyExpr="instrument_id"
            ref={(ref) => (targetAssetGridRef.current = ref)}
            focusedRowEnabled={true}
            defaultFocusedRowIndex={false}
            columnAutoWidth={true}
            columnHidingEnabled={true}
            loadPanel={null}
            onEditorPreparing={handleEditorPreparing}
            onRowInserted={(e) => {
              const { instrument_id, target_percentage } = e.data;

              handleChangeAsset(
                instrument_id,
                "target_percentage",
                target_percentage
              );

              // Update the one of assets if it already has been added into Target Allocation table.
              tempFullAssets.map((item) => {
                if (item.instrument_id === instrument_id) {
                  item.is_new_asset = 0;
                }
                return item;
              });
            }}
          >
            <Editing
              mode="row"
              allowAdding={sumOfPercentage < 100 ? true : false}
            />
            <Column
              dataField={"instrument_id"}
              caption={"ASSET"}
              hidingPriority={6}
            >
              <Lookup
                dataSource={customizedFullAssets}
                displayExpr="exchange_symbol"
                valueExpr="asset_id"
              />
            </Column>
            <Column
              dataField={"target_percentage"}
              caption={"TARGET % BASKET VALUE"}
              hidingPriority={2}
              cellRender={(container, options) => {
                return (
                  <span
                    id={`percentage_${container.data.instrument_id}`}
                    style={{ display: "block", margin: "0px !important" }}
                  >
                    {Math.round(container.data.target_percentage * 10) / 10}
                  </span>
                );
              }}
            />
            <Column
              caption={"+/-"}
              cellRender={(container, options) => {
                return (
                  <>
                    {(container.data.instrument_id ||
                      container.data.target_percentage > 0) && (
                      <IncrementDecrementButton
                        icon="plus"
                        className="px-plus-btn"
                        value={container.data.target_percentage}
                        onChange={(value) => {
                          if (container.data.instrument_id)
                            handleChangeAsset(
                              container.data.instrument_id,
                              "target_percentage",
                              value
                            );
                          else {
                            setSumOfPercentage(100 - value);
                          }
                        }}
                        targetElementID={`percentage_${container.data.instrument_id}`}
                        min={0.1}
                        max={1}
                        buttonTitle={"+"}
                      />
                    )}
                    {container.data.target_percentage > 0 && (
                      <IncrementDecrementButton
                        icon="minus"
                        className="px-minus-btn"
                        value={container.data.target_percentage}
                        onChange={(value) => {
                          if (container.data.instrument_id)
                            handleChangeAsset(
                              container.data.instrument_id,
                              "target_percentage",
                              value
                            );
                          else {
                            setSumOfPercentage(100 - value);
                          }
                        }}
                        targetElementID={`percentage_${container.data.instrument_id}`}
                        min={-0.1}
                        max={-1}
                        buttonTitle={"-"}
                      />
                    )}
                  </>
                );
              }}
            />
            {/* <Column
              dataField={"current_model_id"}
              caption={"SOURCE"}
              hidingPriority={2}
            >
              <Lookup
                dataSource={availableModels}
                displayExpr="name"
                valueExpr="id"
              />
            </Column> */}
          </DataGrid>
        </div>
      </div>
    </React.Fragment>
  );
};
export default BasketTrading;
