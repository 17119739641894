import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from "devextreme-react/responsive-box";

import {
  getAccountValues,
  getHolidings,
  getOnboardingElementStatusByID,
  getPerformanceGraphDropDownContents,
  getRequestStatusOfAccountHistoryFile,
} from "../../../redux/dashboard/action";
import {
  getSelfServiceAccounts,
  getTradesByAccountID,
  setSelectedAccount,
} from "../../../redux/accounts/action";
import { setChartState } from "../../../redux/tradingDashboard/action";
import { getSelfServiceUserByID } from "../../../redux/self-service-user/action";
// import CompetitionPanel from "../../../components/dashboard/CompetitionPanel";
// import PositionPanel from "../../../components/dashboard/PositionPanel";
// import OrderPanel from "../../../components/dashboard/OrderPanel";
import CompetitionEndMessage from "../../../components/CompetitionEndMessage";
import ChartPanel from "./ChartPanel";
import SearchDialog from "./SearchDialog";

import "./TradingDashboard.scss";
import PricewallTable from "./PricewallTable";

function screen(width) {
  return width < 700 ? "sm" : "lg";
}
const TradingDashboard = () => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.accounts.account);
  const accounts = useSelector((state) => state.accounts.list);
  const accMode = useSelector((state) => state.auth.accMode);
  // const siteMode = useSelector((state) => state.auth.mode);
  const rankingsData = useSelector((state) => state.dashboard.rankingsData);
  const chartState = useSelector((state) => state.tradingDashboard.chartState);
  const currentTheme = useSelector(
    (state) => state.tradingDashboard.currentTheme
  );

  const { client_user_id } = useSelector((state) => state.auth.info);

  // Get the default account which account status is Active.
  const defaultAccount =
    accMode && accMode === "live"
      ? accounts?.filter(
          (item) =>
            item.default_account === true && item.account_status_id !== 0
        )?.[0]
      : accounts?.filter((item) => item.account_mode === accMode)?.[0];

  const [shouldBeRefreshed, setShouldBeRefreshed] = useState(false);
  const [reqStatusOfExportAccountHistory, setReqStatusOfExportAccountHistory] =
    useState(null);
  // const [activeOrderData, setActiveOrderData] = useState(null);
  // const [activeCompetition, setActiveCompoetition] = useState(false);
  // const [joinedCompetition, setJoinedCompetition] = useState(null);
  const [chartFullScreen, setChartFullScreen] = useState(false);
  const [openSearchDialog, setOpenSearchDialog] = useState(false);
  const [isEditorMode, setIsEditorMode] = useState(false);
  const [chartsData, setChartsData] = useState([]);
  const [selectedChartRange, setSelectedChartRange] = useState(null);

  const accountID = account?.client_account_id || null;

  let wsBaseUrl = "ss-wip.panxora.com";

  if (!window.location.host.includes("localhost")) {
    wsBaseUrl = window.location.host;
  }

  useEffect(() => {
    if (!account) {
      if (defaultAccount) dispatch(setSelectedAccount(defaultAccount));
      else {
        const updated =
          accounts &&
          // eslint-disable-next-line array-callback-return
          accounts.filter((item) => item.account_status_id !== 2);

        if (updated && updated.length > 0)
          dispatch(setSelectedAccount(updated[0]));
      }
    } else {
      if (defaultAccount) {
        if (account.client_account_id === defaultAccount.client_account_id)
          dispatch(
            setSelectedAccount({
              ...account,
              default_account: true,
            })
          );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);

  useEffect(() => {
    dispatch(getSelfServiceUserByID(client_user_id));
    dispatch(getSelfServiceAccounts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // The dasboard should be refreshed whatever user refresh or select another account.
    if (accountID || shouldBeRefreshed) {
      Promise.all([
        dispatch(getAccountValues(accountID)),
        dispatch(getHolidings(accountID)),
        dispatch(getTradesByAccountID(accountID)),
        dispatch(getRequestStatusOfAccountHistoryFile(accountID)),
        dispatch(getPerformanceGraphDropDownContents(accountID)),
        dispatch(getOnboardingElementStatusByID(client_user_id)),
      ]).then(([, holdings, trades, reqStatus, , ,]) => {
        // setHoldings(holdings);
        // setTrades(trades);
        setReqStatusOfExportAccountHistory(reqStatus);

        // After refreshing the dashboard, the flag should be false again for next time to refresh.
        setShouldBeRefreshed(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountID, shouldBeRefreshed]);

  useEffect(() => {
    if (!reqStatusOfExportAccountHistory) return;

    const { export_status_id: exportStatusID } =
      reqStatusOfExportAccountHistory;
    var interval = null;

    if (exportStatusID === 1) {
      interval = setInterval(() => {
        fetchRequestStatus();
      }, 2000);
    } else {
      clearInterval(interval);
    }

    const fetchRequestStatus = async () => {
      const data = await dispatch(
        getRequestStatusOfAccountHistoryFile(accountID)
      );
      setReqStatusOfExportAccountHistory(data);
    };

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqStatusOfExportAccountHistory]);

  // const hasActiveCompetition = (flag) => {
  //   setActiveCompoetition(flag);
  // };

  // const [countDown, setCountDown] = useState(0);
  // const [runTimer, setRunTimer] = useState(false);
  // const [firstTimeActive, setFirstTimeActive] = useState(false);

  // useEffect(() => {
  //   let timerId;

  //   if (runTimer) {
  //     timerId = setInterval(() => {
  //       setCountDown((countDown) => countDown - 1);
  //     }, 1000);
  //   } else {
  //     clearInterval(timerId);
  //   }

  //   return () => clearInterval(timerId);
  // }, [runTimer]);

  // useEffect(() => {
  //   if (countDown < 0 && runTimer) {
  //     setRunTimer(false);
  //     setCountDown(0);
  //   }
  // }, [countDown, runTimer]);

  // useEffect(() => {
  //   if (!joinedCompetition) return;

  //   if (
  //     joinedCompetition[0].status === "Active" &&
  //     runTimer === true &&
  //     firstTimeActive === false
  //   ) {
  //     setFirstTimeActive(true);
  //     setCountDown(0);
  //     setRunTimer(false);
  //   }

  //   if (joinedCompetition[0].status === "Active" && runTimer === false) {
  //     const endTimeStamp = joinedCompetition[0].end_time * 1000;
  //     const utcTimeStamp = Date.UTC(
  //       new Date().getUTCFullYear(),
  //       new Date().getUTCMonth(),
  //       new Date().getUTCDate(),
  //       new Date().getUTCHours(),
  //       new Date().getUTCMinutes(),
  //       new Date().getUTCSeconds()
  //     );

  //     const diffDate = new Date(Math.abs(endTimeStamp - utcTimeStamp));

  //     setCountDown(diffDate.getMinutes() * 60 + diffDate.getSeconds());
  //     setRunTimer(true);
  //   }
  //   if (joinedCompetition[0].status === "Locked" && runTimer === false) {
  //     setCountDown(60 * 1);
  //     setRunTimer(true);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [joinedCompetition]);

  // const seconds = String(countDown % 60).padStart(2, 0);
  // const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);
  return (
    <React.Fragment>
      <React.Fragment>
        {chartFullScreen ? (
          <div className="chart-fullscreen">
            <div
              className={`content-block ${
                currentTheme === "Dark"
                  ? "dark"
                  : currentTheme === "Glass"
                  ? "glass"
                  : "light"
              }-theme-bg dx-card px-item`}
            >
              <div className="chart-container">
                <div className="stock-chart">
                  <ChartPanel
                    wsBaseUrl={wsBaseUrl}
                    openSearchDialog={() => setOpenSearchDialog(true)}
                    setChartFullScreen={setChartFullScreen}
                    chartFullScreen={chartFullScreen}
                    chartsData={chartsData}
                    setChartsData={setChartsData}
                    selectedRange={selectedChartRange}
                    setSelectedRange={setSelectedChartRange}
                    isEditorMode={isEditorMode}
                    setIsEditorMode={setIsEditorMode}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="trading-dashboard">
            <ResponsiveBox
              singleColumnScreen="sm"
              screenByWidth={screen}
              style={{ paddingLeft: 20, paddingRight: 20 }}
            >
              {/* Responsive Layout */}
              <Row ratio={1}></Row>
              <Row ratio={1}></Row>
              <Row ratio={1}></Row>
              <Row ratio={1} screen="xs"></Row>
              <Row ratio={1} screen="xs"></Row>
              <Row ratio={1} screen="xs"></Row>
              <Row ratio={1} screen="xs"></Row>
              <Row ratio={4}></Row>
              <Row ratio={6} screen="xs"></Row>
              <Row ratio={1}></Row>

              <Col ratio={1} screen="lg"></Col>
              <Col ratio={1} screen="lg"></Col>
              <Col ratio={1} screen="lg"></Col>

              <Item>
                <Location row={1} col={0} colspan={5} screen="lg"></Location>
                <Location row={8} col={0} rowspan={1} screen="sm"></Location>
                <div className="pricewall-chart-container">
                  <div
                    className={`pricewall ${
                      currentTheme === "Dark"
                        ? "dark"
                        : currentTheme === "Glass"
                        ? "glass"
                        : "light"
                    }-theme-bg content-block dx-card px-item`}
                  >
                    <PricewallTable
                      // activeCompetition={activeCompetition}
                      wsBaseUrl={wsBaseUrl}
                      // setActiveOrderData={setActiveOrderData}
                    />
                  </div>
                  <div
                    className={`chart ${
                      currentTheme === "Dark"
                        ? "dark"
                        : currentTheme === "Glass"
                        ? "glass"
                        : "light"
                    }-theme-bg content-block dx-card px-item`}
                  >
                    <div className="stock-chart">
                      <ChartPanel
                        wsBaseUrl={wsBaseUrl}
                        openSearchDialog={() => setOpenSearchDialog(true)}
                        setChartFullScreen={setChartFullScreen}
                        chartFullScreen={chartFullScreen}
                        chartsData={chartsData}
                        setChartsData={setChartsData}
                        selectedRange={selectedChartRange}
                        setSelectedRange={setSelectedChartRange}
                        isEditorMode={isEditorMode}
                        setIsEditorMode={setIsEditorMode}
                      />
                    </div>
                  </div>
                </div>
              </Item>
              {/* COMPETITION PANEL */}
              <Item>
                <Location row={3} col={0} colspan={1} screen="lg"></Location>
                <Location row={8} col={0} rowspan={1} screen="sm"></Location>
                <div
                  className={`content-block ${
                    currentTheme === "Dark"
                      ? "dark"
                      : currentTheme === "Glass"
                      ? "glass"
                      : "light"
                  }-theme-bg dx-card px-item px-accounts-table`}
                  style={{ maxHeight: 400, overflow: "auto" }}
                >
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "space-between",
                      height: "30px",
                      width: "100%",
                      marginBottom: 8,
                    }}
                  >
                    <p
                      className="margin-bottom-50"
                      style={{ fontWeight: "bold", fontSize: "13px" }}
                    >
                      COMPETITION PANEL
                    </p>
                    {/* {joinedCompetition && (
                      <div>
                        {joinedCompetition[0].status === "Open" && (
                          <>
                            <p
                              style={{
                                fontSize: 12,
                                fontWeight: 600,
                                lineHeight: 1,
                              }}
                            >
                              AWAITING PLAYERS:{" "}
                            </p>
                            <p
                              id="time"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: 24,
                                lineHeight: 1,
                              }}
                            >
                              01:00
                            </p>
                          </>
                        )}
                        {joinedCompetition[0].status === "Locked" && (
                          <>
                            <p
                              style={{
                                fontSize: 12,
                                fontWeight: 600,
                                lineHeight: 1,
                              }}
                            >
                              TIME TO START:{" "}
                            </p>
                            <p
                              id="time"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: 24,
                                lineHeight: 1,
                              }}
                            >
                              {minutes + ":" + seconds}
                            </p>
                          </>
                        )}
                        {joinedCompetition[0].status === "Active" && (
                          <>
                            <p
                              style={{
                                fontSize: 12,
                                fontWeight: 600,
                                lineHeight: 1,
                              }}
                            >
                              TIME REMAINING:{" "}
                            </p>
                            <p
                              id="time"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: 24,
                                lineHeight: 1,
                              }}
                            >
                              {minutes + ":" + seconds}
                            </p>
                          </>
                        )}
                      </div>
                    )} */}
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                      fontWeight: "bold",
                    }}
                  >
                    No Data
                  </div>
                </div>
              </Item>

              {/* ORDER PANEL */}
              <Item>
                <Location row={3} col={1} colspan={1} screen="lg"></Location>
                <Location row={8} col={1} rowspan={1} screen="sm"></Location>
                <div
                  className={`content-block ${
                    currentTheme === "Dark"
                      ? "dark"
                      : currentTheme === "Glass"
                      ? "glass"
                      : "light"
                  }-theme-bg dx-card px-item px-accounts-table`}
                  style={{ maxHeight: 400, overflow: "auto" }}
                >
                  <div
                    style={{
                      display: "flex",
                      position: "relative",
                      width: "100%",
                      height: 30,
                      marginBottom: 8,
                    }}
                  >
                    <p
                      className="margin-bottom-50"
                      style={{ fontWeight: "bold", fontSize: "13px" }}
                    >
                      POSITION PANEL
                    </p>
                  </div>
                  {/* <PositionPanel accMode={accMode} siteMode={siteMode} /> */}
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                      fontWeight: "bold",
                    }}
                  >
                    No Data
                  </div>
                </div>
              </Item>
              {/* POSITION PANEL */}
              <Item>
                <Location row={3} col={2} colspan={1} screen="lg"></Location>
                <Location row={8} col={1} rowspan={1} screen="sm"></Location>
                <div
                  className={`content-block ${
                    currentTheme === "Dark"
                      ? "dark"
                      : currentTheme === "Glass"
                      ? "glass"
                      : "light"
                  }-theme-bg dx-card px-item px-accounts-table`}
                  style={{ maxHeight: 400, overflow: "auto" }}
                >
                  <div
                    style={{
                      display: "flex",
                      position: "relative",
                      width: "100%",
                      height: 30,
                      marginBottom: 8,
                    }}
                  >
                    <p
                      className="margin-bottom-50"
                      style={{ fontWeight: "bold", fontSize: "13px" }}
                    >
                      ORDER PANEL
                    </p>
                  </div>
                  {/* <OrderPanel
                    accMode={accMode}
                    activeOrderData={activeOrderData}
                    siteMode={siteMode}
                    setActiveOrderData={setActiveOrderData}
                  /> */}
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                      fontWeight: "bold",
                    }}
                  >
                    No Data
                  </div>
                </div>
              </Item>
            </ResponsiveBox>
          </div>
        )}
      </React.Fragment>

      {rankingsData && rankingsData.length > 0 && (
        <CompetitionEndMessage rankingsData={rankingsData} />
      )}

      {openSearchDialog && (
        <SearchDialog
          closeDialog={() => setOpenSearchDialog(false)}
          onMarketSelect={(market, exchange) =>
            dispatch(setChartState({ ...chartState, market, exchange }))
          }
        />
      )}

      {/* <ContextMenu
        dataSource={[{ text: "Order" }]}
        width={200}
        target="#price-box"
        // onItemClick={onItemClick}
      /> */}
    </React.Fragment>
  );
};

export default TradingDashboard;
