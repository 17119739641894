import React from "react";
import { Button } from "devextreme-react/button";
import { Popup } from "devextreme-react/popup";

const ConfirmationAskingPopup = ({ handleKeepThePage, handleClosePopup }) => {
  const renderContent = () => {
    return (
      <div>
        <p>
          If you leave this page your changes will not be saved. Do you want to
          leave this page?
        </p>
        <div style={{ display: "flex" }}>
          <Button
            width={"100%"}
            style={{marginRight: 10}}
            type="default"
            stylingMode="contained"
            onClick={handleKeepThePage}
            text="STAY"
          />
          <Button
            width={"100%"}
            type="default"
            stylingMode="contained"
            onClick={handleClosePopup}
            text="LEAVE"
          />
        </div>
      </div>
    );
  };

  return (
    <Popup
      visible={true}
      width={500}
      height={220}
      position={"top"}
      showCloseButton={false}
      closeOnOutsideClick={false}
      contentRender={renderContent}
      title={`Are you sure you want to leave it?`}
    />
  );
};

export default ConfirmationAskingPopup;
