import React, { useEffect, useMemo, useRef, useState } from "react";
import AnyChart from "anychart-react";
import anychart from "anychart";
import useWebSocket from "react-use-websocket";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button,
  MenuList,
} from "@material-ui/core";
import {
  AiOutlineDelete,
  AiOutlineSave,
  AiOutlineSliders,
  AiOutlineLineChart,
  AiOutlineClockCircle,
  AiOutlineSearch,
  AiOutlineBarChart,
  AiOutlineDotChart,
  AiOutlineAreaChart,
} from "react-icons/ai";
import { BiBarChart, BiSun, BiMoon } from "react-icons/bi";
import { BsBarChartSteps, BsBezier } from "react-icons/bs";
import { RiBarChartHorizontalLine } from "react-icons/ri";

import {
  CHART_ANNOTATIONS,
  CHART_INDICATORS,
  CHART_TIMESCALES,
  CHART_TYPES,
  MAIN_TOKEN_KEY,
  NEW_PLOTS,
  PUBLIC_TOKEN,
  TRADING_CHART_STATE,
} from "../../../constants";
import CustomTooltip from "../../../components/CustomTooltip";

// import { stockData } from "./mockStockData";

import "./PublicChart.scss";
import { useLocation } from "react-router-dom";
import SearchDialog from "../../private/trading-dashboard/SearchDialog";
import { get, set } from "../../../utils/storage";
import { camelCase } from "lodash";
import { socialApi } from "../../../service/api";

function mapChartData(data) {
  if (!data || !Array.isArray(data)) return [];

  return data.map((item) => {
    return [item.date, item.open, item.high, item.low, item.close, item.volume];
  });
}

function normalizeType(type) {
  switch (type.toLowerCase()) {
    case "candle":
      return "candlestick";
    case "bar":
      return "ohlc";
    case "line":
      return "line";
    default:
      return camelCase(type);
  }
}

const renderIcon = (name) => {
  switch (name) {
    case "area":
      return <AiOutlineAreaChart />;
    case "candlestick":
      return <AiOutlineSliders />;
    case "column":
      return <AiOutlineBarChart />;
    case "jumpLine":
      return <BsBarChartSteps />;
    case "line":
      return <AiOutlineLineChart />;
    case "marker":
      return <AiOutlineDotChart />;
    case "ohlc":
      return <BiBarChart />;
    case "rangeArea":
      return <AiOutlineAreaChart />;
    case "rangeColumn":
      return <BiBarChart />;
    case "rangeSplineArea":
      return <AiOutlineLineChart />;
    case "rangeStepArea":
      return <AiOutlineAreaChart />;
    case "spline":
      return <AiOutlineLineChart />;
    case "splineArea":
      return <AiOutlineAreaChart />;
    case "stepArea":
      return <AiOutlineAreaChart />;
    case "stepLine":
      return <BsBarChartSteps />;
    case "stick":
      return <RiBarChartHorizontalLine />;
    default:
      return <AiOutlineLineChart />;
  }
};

let iAmHereInterval = null;

const ChartContainer = () => {
  const location = useLocation();
  const isTradingChart = location.pathname.includes("/trading_chart");
  const textInputRef = useRef();
  const params = new URLSearchParams(location.search);

  const savedChartState = JSON.parse(get(TRADING_CHART_STATE));

  const savedToken = JSON.parse(get(MAIN_TOKEN_KEY));

  let token = params.get("token")
    ? params.get("token")
    : isTradingChart
    ? savedToken
    : PUBLIC_TOKEN;
  const exchange = params.get("exchange");
  const market = params.get("market");
  const period = params.get("period");
  const type = params.get("type");

  if (isTradingChart) {
    if (
      !savedChartState ||
      (savedChartState &&
        JSON.stringify(savedChartState) !==
          JSON.stringify({
            exchange,
            market,
            period,
            type,
          }))
    ) {
      set(TRADING_CHART_STATE, {
        exchange,
        market,
        period,
        type,
      });
    }
  }

  const [chartState, setChartState] = useState({
    exchange: exchange
      ? exchange
      : savedChartState
      ? savedChartState.exchange
      : "IMERS",
    market: market
      ? market
      : savedChartState
      ? savedChartState.market
      : "BTC/USD",
    period: period ? period : savedChartState ? savedChartState.period : "1h",
    type: type ? type : savedChartState ? savedChartState.type : "line",
    indicators: [],
    annotations: null,
  });
  const [selectedRange, setSelectedRange] = useState(null);
  const [chartsData, setChartsData] = useState([]);
  const [stocksData, setStocksData] = useState([]);
  const [newStocksData, setNewStocksData] = useState([]);
  const [selectedAnnotation, setSelectedAnnotation] = useState(null);
  const [connectedChartWSS, setConnectedChartWSS] = useState(false);
  const [openSearchDialog, setOpenSearchDialog] = useState(false);
  const [isEditorMode, setIsEditorMode] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(true);
  const [chartInstance, setChartInstance] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);

  let wsBaseUrl = "ss-wip.panxora.com";

  if (!window.location.host.includes("localhost")) {
    wsBaseUrl = window.location.host;
  }

  let wsChartsClient = new useWebSocket(`wss://${wsBaseUrl}/chartws/`, {
    onOpen: () => {
      setConnectedChartWSS(true);
    },
    onClose: () => {
      setConnectedChartWSS(false);
    },
    shouldReconnect: () => true,
    onMessage: (event) => {
      const parsedData = JSON.parse(event?.data);
      if (!parsedData) return;
      processChartData(parsedData);
    },
  });

  useEffect(() => {
    window.addEventListener("visibilitychange", listenScreenVisibility);
    return () =>
      window.removeEventListener(
        "visibilitychange",
        listenScreenVisibility,
        true
      );

    // eslint-disable-next-line
  }, []);

  const listenScreenVisibility = () => {
    if (!document.hidden) {
      if (iAmHereInterval) clearInterval(iAmHereInterval);
      iAmHereInterval = setInterval(async () => {
        try {
          const { data } = await socialApi.post(
            "/api/social/im_here",
            {},
            {
              headers: {
                token: PUBLIC_TOKEN,
              },
            }
          );
          console.log(data["HTTP Response"]);
        } catch (error) {
          console.log(error);
        }
      }, 1000 * 60);
    } else {
      if (iAmHereInterval) clearInterval(iAmHereInterval);
    }
    // eslint-disable-next-line
  };

  useEffect(() => {
    if (connectedChartWSS && chartState) {
      getRealTimeStocksData();
    }
    // eslint-disable-next-line
  }, [connectedChartWSS, chartState]);

  useEffect(() => {
    if (!chartsData || chartsData.length === 0) return;

    if (isEditorMode) {
      setNewStocksData(mapChartData(chartsData));
    } else {
      if (newStocksData.length > 0) {
        setStocksData(newStocksData);
        setNewStocksData([]);
      } else {
        setStocksData(mapChartData(chartsData));
      }
    }
    // eslint-disable-next-line
  }, [chartsData, isEditorMode]);

  /**
   * Process data for Charts
   */

  const processChartData = (parsedData) => {
    if (parsedData.hasOwnProperty("data")) {
      const historicalData = Object.keys(parsedData?.data).map((key) => {
        return {
          id: key,
          date: new Date(parsedData?.data[key]?.timestamp),
          close: parsedData?.data[key]?.last,
          high: parsedData?.data[key]?.high,
          low: parsedData?.data[key]?.low,
          open: parsedData?.data[key]?.open,
          volume: parsedData?.data[key]?.volume,
        };
      });

      setChartsData(historicalData);
    } else {
      const mainKey = Object.keys(parsedData);
      const secondaryKey = Object.keys(parsedData[mainKey]);
      let newOrUpdated = parsedData[mainKey][secondaryKey[0]];
      const currentStocksData = [...chartsData];

      const existingData = currentStocksData.find(
        (item) => item.id === secondaryKey[0]
      );
      const exisitingIndex = existingData
        ? currentStocksData.findIndex((item) => item.id === secondaryKey[0])
        : -1;

      newOrUpdated = {
        id: secondaryKey[0],
        date: new Date(newOrUpdated?.timestamp),
        close: newOrUpdated?.last,
        high: newOrUpdated?.high,
        low: newOrUpdated?.low,
        open: newOrUpdated?.open,
        volume: newOrUpdated?.volume,
      };

      if (exisitingIndex > -1) {
        currentStocksData[exisitingIndex] = newOrUpdated;
      } else {
        currentStocksData.push(newOrUpdated);
      }

      setChartsData(currentStocksData);
    }
  };

  const getRealTimeStocksData = () => {
    const exchange = chartState ? chartState?.exchange : "Imers";
    const market = chartState ? chartState?.market : "BTC/USD";
    const timescale = chartState
      ? CHART_TIMESCALES.find((time) => time.indicator === chartState.period)
          .name
      : "hour";

    const subscribeData = {
      token,
      type: "request",
      exchange,
      market,
      bar: timescale,
    };

    wsChartsClient.sendMessage(JSON.stringify(subscribeData));
  };

  /**
   *  Chart Configuration Started
   */
  const renderChart = useMemo(() => {
    const chartType = isTradingChart
      ? chartState.type
      : normalizeType(chartState.type);

    const stage = anychart.graphics.create();
    const dataTable = anychart.data.table(0);
    dataTable.addData(stocksData);

    const mapping = dataTable.mapAs();
    mapping.addField("open", 1, "first");
    mapping.addField("high", 2, "max");
    mapping.addField("low", 3, "min");
    mapping.addField("close", 4, "last");
    mapping.addField("value", 4, "last");
    mapping.addField("volume", 5);

    const rat_mapping = dataTable.mapAs({ priceA: 1, priceB: 2 });

    const chart = anychart.stock();
    setChartInstance(chart);
    const plot1 = chart.plot(0);
    const series = plot1[chartType](mapping);

    series.name(`${chartState.exchange}: ${chartState.market}`);
    if (["ohlc", "candlestick"].includes(chartType)) {
      series.fallingFill("#F45B3D");
      series.fallingStroke("#F45B3D");
      series.risingFill("#2EB777");
      series.risingStroke("#2EB777");
    }

    plot1.annotations().enabled(true);

    if (chartState.indicators && chartState.indicators?.length > 0) {
      const indicator = chartState.indicators[0];

      if (!indicator) return;

      if (NEW_PLOTS.includes(indicator)) {
        if (indicator === "rat") {
          chart.plot(1)[indicator](rat_mapping);
        } else {
          chart.plot(1)[indicator](mapping);
        }
        chart.plot(1).yAxis().orientation("right");
      } else {
        plot1[indicator](mapping);
      }
    }

    // chart.background("white");
    chart.background(isDarkTheme ? "#222222" : "#ffffff");
    chart.padding(2, 60, 2, 2);
    chart.scroller(true);
    chart.scroller().candlestick(mapping);
    chart.interactivity().zoomOnMouseWheel(true);
    const credits = chart.credits();
    credits.enabled(false);

    const crosshair = chart.crosshair();
    crosshair.xStroke("#676069", 0.25, "5 2");
    crosshair.yStroke("#676069", 0.25, "5 2");
    crosshair.displayMode("float");
    chart.tooltip(true);
    plot1.yAxis().orientation("right");

    const indicator = plot1.priceIndicator();
    indicator.value("last-visible");
    indicator.stroke("orange", 2, "2 2");
    indicator.label().background().fill("orange");
    indicator.label().fontColor("white");
    // let rangePicker = anychart.ui.rangePicker();
    // let rangeSelector = anychart.ui.rangeSelector();
    // rangeSelector.render(chart);
    // rangePicker.render(chart);

    chart.listen("selectedrangechangefinish", (e) => {
      setSelectedRange({
        firstSelected: new Date(e.firstSelected),
        lastSelected: new Date(e.lastSelected),
      });
    });

    if (chartState?.annotations) {
      plot1.annotations().fromJson(chartState.annotations);
    }

    chart.listen("annotationDrawingFinish", (e) => {
      const annt = e.annotation;

      setChartState({
        ...chartState,
        annotations: plot1.annotations().toJson(true),
      });

      if (annt.type === "label") {
        if (textInputRef.current?.value) {
          annt.text(textInputRef.current?.value);
        }
      }
    });

    chart.listen("annotationSelect", function (e) {
      if (e.annotation.type === "label") {
        textInputRef.current.focus();
        textInputRef.current.value = e.annotation.text();
        textInputRef.current.selectionEnd = e.annotation.text().length;
      }

      setSelectedAnnotation(e.annotation);
    });

    chart.listen("annotationUnselect", function (e) {
      if (e.annotation.type === "label") {
        textInputRef.current.value = "";
      }
      setSelectedAnnotation(null);
    });

    chart.listen("annotationChangeFinish", function (e) {
      setIsEditorMode(true);
    });

    if (selectedRange) {
      chart.selectRange(
        selectedRange?.firstSelected,
        selectedRange?.lastSelected
      );
    }

    return (
      <AnyChart
        instance={stage}
        width={"100%"}
        height={"100%"}
        charts={[chart]}
      />
    );
    // eslint-disable-next-line
  }, [chartState, stocksData, isDarkTheme]);
  /**
   *  Chart Configuration Ended
   */

  const handleOpenMenu = (event, anchorType) => {
    setSelectedMenu(anchorType);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);

    setTimeout(() => setSelectedMenu(null), 200);
  };

  const handleLabelChange = (e) => {
    if (chartInstance) {
      const at = chartInstance.plot(0).annotations().getSelectedAnnotation();
      if (at) {
        at.text(e.target.value);
      }
    }
  };

  return (
    <div
      className="public-chart-container"
      style={{
        height: !isTradingChart ? "100vh" : "calc(100vh - 94px)",
      }}
    >
      <div
        className={`chart-container ${isDarkTheme ? "dark" : "light"}-theme-bg`}
      >
        <div className="custom-toolbar">
          <textarea
            id="annotation-label"
            ref={textInputRef}
            onChange={handleLabelChange}
          ></textarea>
          <div>
            <CustomTooltip title="Market symbol e.g. BTC">
              <IconButton
                size="small"
                onClick={() => setOpenSearchDialog(true)}
              >
                <AiOutlineSearch size={"18px"} />
              </IconButton>
            </CustomTooltip>

            <CustomTooltip title="Chart">
              <IconButton
                size="small"
                onClick={(e) => handleOpenMenu(e, "chart")}
              >
                <AiOutlineSliders size={"18px"} />
              </IconButton>
            </CustomTooltip>

            <CustomTooltip title="Indicator">
              <IconButton
                size="small"
                onClick={(e) => handleOpenMenu(e, "indicator")}
              >
                <AiOutlineLineChart size={"18px"} />
              </IconButton>
            </CustomTooltip>
            <CustomTooltip
              title={
                isEditorMode
                  ? "Timescale not available during editor mode, click save changes"
                  : "Timescale"
              }
            >
              <span
                style={{ cursor: isEditorMode ? "not-allowed" : "pointer" }}
              >
                <Button
                  disabled={isEditorMode}
                  size="small"
                  style={{ fontSize: "13px", fontWeight: "bold" }}
                  onClick={(e) => handleOpenMenu(e, "timescale")}
                  color="inherit"
                  startIcon={
                    <AiOutlineClockCircle
                      size={"18px"}
                      color={isDarkTheme ? "#f1f1f1" : "#444"}
                    />
                  }
                >
                  {
                    CHART_TIMESCALES.find(
                      (item) => item.indicator === chartState?.period
                    ).indicator
                  }
                </Button>
              </span>
            </CustomTooltip>

            <CustomTooltip title="Add annotation">
              <IconButton
                size="small"
                onClick={(e) => handleOpenMenu(e, "annotation")}
              >
                <BsBezier size={"18px"} />
              </IconButton>
            </CustomTooltip>
          </div>

          <div className="config-buttons">
            {selectedAnnotation && (
              <CustomTooltip title="Remove annotation">
                <Button
                  onClick={() => {
                    if (selectedAnnotation) {
                      chartInstance
                        .plot(0)
                        .annotations()
                        .removeAnnotation(selectedAnnotation);

                      setChartState({
                        ...chartState,
                        annotations: chartInstance
                          .plot(0)
                          .annotations()
                          .toJson(true),
                      });

                      setSelectedAnnotation(null);
                    }
                  }}
                  size="small"
                  startIcon={<AiOutlineDelete />}
                >
                  Remove annotation
                </Button>
              </CustomTooltip>
            )}
            {isEditorMode && (
              <CustomTooltip title="Save changes">
                <Button
                  onClick={() => {
                    setChartState({
                      ...chartState,
                      annotations: chartInstance
                        .plot(0)
                        .annotations()
                        .toJson(true),
                    });

                    setIsEditorMode(false);
                    if (selectedAnnotation) setSelectedAnnotation(null);
                  }}
                  size="small"
                  startIcon={<AiOutlineSave />}
                >
                  Save changes
                </Button>
              </CustomTooltip>
            )}

            <CustomTooltip title={`Toggle light/dark theme`}>
              <IconButton
                size="small"
                style={{ marginLeft: 12, marginRight: 12 }}
                onClick={() => {
                  setIsDarkTheme((ps) => !ps);
                }}
              >
                {!isDarkTheme ? <BiMoon /> : <BiSun />}
              </IconButton>
            </CustomTooltip>
          </div>
        </div>
        {renderChart}
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        PaperProps={{
          style: {
            maxHeight: "calc(100vh - 400px)",
            minWidth: 80,
            backgroundColor: isDarkTheme ? "rgba(43, 29, 26, 1)" : "#ffffff",
            color: !isDarkTheme ? "#222222" : "#ffffff",
          },
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuList disableListWrap disablePadding>
          {selectedMenu === "indicator" &&
            CHART_INDICATORS.map((item) => (
              <MenuItem
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingRight: 2,
                  paddingLeft: 4,
                }}
                title={item.text}
                button={false}
                selected={item.type === chartState.indicators[0]}
                key={item.indicator + "-" + item.id}
                onClick={() =>
                  setChartState({
                    ...chartState,
                    indicators: chartState.indicators.includes(item.type)
                      ? []
                      : [item.type],
                  })
                }
              >
                <ListItemIcon
                  style={{
                    minWidth: 24,
                    color: !isDarkTheme ? "#222222" : "#ffffff",
                  }}
                >
                  <Checkbox
                    checked={chartState.indicators.includes(item.type)}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <p
                      style={{
                        lineHeight: 0,
                        fontSize: 11,
                        fontWeight: "bold",
                      }}
                    >
                      {item.text}
                    </p>
                  }
                />
              </MenuItem>
            ))}
          {selectedMenu === "chart" &&
            CHART_TYPES.map((item) => (
              <MenuItem
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingRight: 2,
                  paddingLeft: 4,
                }}
                selected={item.type === chartState.type}
                key={item.type + "-" + item.id}
                onClick={() => {
                  setChartState({ ...chartState, type: item.type });
                  handleClose();
                }}
              >
                <ListItemIcon
                  style={{
                    minWidth: 24,
                    color: !isDarkTheme ? "#222222" : "#ffffff",
                  }}
                >
                  {renderIcon(item.type)}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <p
                      style={{
                        lineHeight: 0,
                        fontSize: 11,
                        fontWeight: "bold",
                      }}
                    >
                      {item.text}
                    </p>
                  }
                />
              </MenuItem>
            ))}
          {selectedMenu === "annotation" &&
            CHART_ANNOTATIONS.map((item) => (
              <MenuItem
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingRight: 2,
                  paddingLeft: 4,
                }}
                key={item.text + "-" + item.id}
                onClick={() => {
                  if (!chartInstance) return;
                  if (!isEditorMode) {
                    setIsEditorMode(true);
                  }

                  if (item.type === "label") {
                    chartInstance.annotations().startDrawing({
                      type: "label",
                      fontSize: "16px",
                      fontColor: "#010101",
                    });
                  } else {
                    chartInstance.annotations().startDrawing(item.type);
                  }
                  handleClose();
                }}
              >
                <ListItemText
                  primary={
                    <p
                      style={{
                        lineHeight: 0,
                        fontSize: 11,
                        fontWeight: "bold",
                      }}
                    >
                      {item.text}
                    </p>
                  }
                />
              </MenuItem>
            ))}
          {selectedMenu === "timescale" &&
            CHART_TIMESCALES.map((item) => (
              <MenuItem
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingRight: 2,
                  paddingLeft: 4,
                }}
                selected={item.name === chartState.time}
                key={item.indicator + "-" + item.id}
                onClick={() => {
                  if (chartState.period !== item.indicator) {
                    setSelectedRange(null);
                  }
                  setChartState({
                    ...chartState,
                    period: item.indicator,
                  });
                  handleClose();
                }}
              >
                <ListItemText
                  primary={
                    <p
                      style={{
                        lineHeight: 0,
                        fontSize: 11,
                        fontWeight: "bold",
                      }}
                    >
                      {item.text}
                    </p>
                  }
                />
              </MenuItem>
            ))}
        </MenuList>
      </Menu>
      {openSearchDialog && (
        <SearchDialog
          closeDialog={() => setOpenSearchDialog(false)}
          onMarketSelect={(market, exchange) =>
            setChartState({ ...chartState, market, exchange })
          }
        />
      )}
    </div>
  );
};

export default ChartContainer;
