import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Prompt } from "react-router";
import Form, {
  Item,
  GroupItem,
  ButtonItem,
  Label,
  ButtonOptions,
  EmailRule,
} from "devextreme-react/form";
import "devextreme-react/text-area";
import { Button } from "devextreme-react/button";
import LoadIndicator from "devextreme-react/load-indicator";
import Error from "../Error";
import { ProfileFormEditedBefore } from "../../redux/auth/action";
import { useToast } from "../../contexts/toast";
import {
  addressDefaultOptions,
  fNameDefaultOptions,
  lNameDefaultOptions,
  lNickNameDefaultOptions,
  emailDefaultOptions,
  countryCodeDefaultOptions,
  phoneDefaultOptions,
  PROFILE_UPDATE_MSG,
  DEFAULT_TOAST_TIME,
} from "../../constants";

const ProfileForm = ({
  phoneCodes,
  fullAddress,
  handleOpenAddressPopup,
  openAddressPp,
  dispatchSelfServiceUser,
}) => {
  const formData = useRef({});
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const initialUserInfo = useSelector((state) => state.selfServiceUser.info);

  /** FORM FIELD OPTIONS */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fNameEditorOptions = React.useMemo(() => {
    return {
      ...fNameDefaultOptions,
      value: initialUserInfo && initialUserInfo.first_name,
    };
  }, [initialUserInfo]);

  const lNameEditorOptions = React.useMemo(() => {
    return {
      ...lNameDefaultOptions,
      value: initialUserInfo && initialUserInfo.last_name,
    };
  }, [initialUserInfo]);

  
  const lNickNameEditorOptions = React.useMemo(() => {
    return {
      ...lNickNameDefaultOptions,
      value: (initialUserInfo && initialUserInfo.display_name) || '',
    };
  }, [initialUserInfo]);

  const addressEditorOptions = {
    ...addressDefaultOptions,
    value: fullAddress ? fullAddress : "",
    onKeyDown: (e) => {
      if (!openAddressPp) handleOpenAddressPopup(true);
    },
    buttons: [
      {
        name: "addressAddOrEditButton",
        location: "after",
        options: {
          icon: initialUserInfo && initialUserInfo.address_id ? "edit" : "plus",
          onClick: () => {
            handleOpenAddressPopup(true);
          },
        },
      },
    ],
  };

  const emailEditorOptions = React.useMemo(() => {
    return {
      ...emailDefaultOptions,
      value: initialUserInfo && initialUserInfo.contact_email,
    };
  }, [initialUserInfo]);

  const countryCodeEditOptions = React.useMemo(() => {
    return {
      ...countryCodeDefaultOptions,
      width: "300px",
      items: phoneCodes,
      searchEnabled: true,
      searchExpr: ["country_name", "calling_code"],
      value: initialUserInfo && initialUserInfo.phone_cc,
      valueExpr: "calling_code",
      itemTemplate: function (data) {
        return data.country_name + " +(" + data.calling_code + ")";
      },
      displayExpr: function (data) {
        if (data) return "+(" + data.calling_code + ")";
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialUserInfo]);

  const phoneEditorOptions = React.useMemo(() => {
    return {
      ...phoneDefaultOptions,
      value: initialUserInfo && initialUserInfo.phone_number,
    };
  }, [initialUserInfo]);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const { first_name, last_name, display_name, contact_email, phone_cc, phone_number } =
        formData.current;

      await dispatchSelfServiceUser({
        first_name,
        last_name,
        display_name,
        contact_email,
        phone_cc,
        phone_number,
      });

      // Return to edit after update.
      handleEditForm();
      // Dispatch method.
      setLoading(false);
      showToast(PROFILE_UPDATE_MSG, "success", DEFAULT_TOAST_TIME);
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  const handleEditForm = () => {
    dispatch(ProfileFormEditedBefore(!editable));
    setEditable(!editable);
  };

  return (
    <>
      <Prompt
        when={editable ? true : false}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <div className="px-profile-container">
        <form className="px-form" onSubmit={handleSubmit}>
          {!!error && <Error>{error}</Error>}
          <Form
            formData={formData.current}
            showColonAfterLabel={false}
            disabled={loading}
          >
            <GroupItem colCount={2}>
              <Item
                dataField="first_name"
                editorType={"dxTextBox"}
                editorOptions={fNameEditorOptions}
                isRequired={false}
                disabled={!editable}
              >
                <Label visible={true} text="FIRST NAME" />
              </Item>
              <Item
                dataField="last_name"
                editorType={"dxTextBox"}
                editorOptions={lNameEditorOptions}
                isRequired={false}
                disabled={!editable}
              >
                <Label visible={true} text="LAST NAME" />
              </Item>
            </GroupItem>
            <Item
              dataField="display_name"
              editorType={"dxTextBox"}
              editorOptions={lNickNameEditorOptions}
              isRequired={false}
              disabled={!editable}
            >
              <Label visible={true} text="NICKNAME" />
            </Item>
            <Item
              dataField="address"
              editorType={"dxTextBox"}
              editorOptions={addressEditorOptions}
              isRequired={false}
              disabled={!editable}
            >
              <Label visible={true} text="ADDRESS" />
            </Item>
            <Item
              dataField={"contact_email"}
              editorType={"dxTextBox"}
              editorOptions={emailEditorOptions}
              isRequired={false}
              disabled={!editable}
            >
              <EmailRule message="Email is invalid" />
              <Label visible={true} text="REPORTING EMAIL ADDRESS" />
            </Item>
            <GroupItem colCount={2}>
              <Item
                dataField="phone_cc"
                editorType={"dxSelectBox"}
                editorOptions={countryCodeEditOptions}
                isRequired={false}
                disabled={!editable}
              >
                <Label visible={true} text="COUNTRY CODE" />
              </Item>
              <Item
                dataField="phone_number"
                editorTyp={"dxTextBox"}
                editorOptions={phoneEditorOptions}
                isRequired={false}
                disabled={!editable}
              >
                <Label visible={true} text="PHONE NUMBER" />
              </Item>
            </GroupItem>
            {editable && (
              <GroupItem colCount={2}>
                <ButtonItem horizontalAlignment="left">
                  <ButtonOptions
                    width="100%"
                    text="Add"
                    type="default"
                    useSubmitBehavior={true}
                  >
                    <span className="dx-button-text">
                      {loading ? (
                        <LoadIndicator
                          width={"24px"}
                          height={"24px"}
                          visible={true}
                        />
                      ) : (
                        "UPDATE"
                      )}
                    </span>
                  </ButtonOptions>
                </ButtonItem>
                <ButtonItem horizontalAlignment="right">
                  <ButtonOptions
                    width="100%"
                    type="normal"
                    text="Cancel"
                    useSubmitBehavior={false}
                    onClick={handleEditForm}
                  />
                </ButtonItem>
              </GroupItem>
            )}
          </Form>
        </form>
        <div className="px-actions-container">
          {!editable && (
            <Button
              width={"100%"}
              type="default"
              stylingMode="contained"
              onClick={handleEditForm}
              text="EDIT"
              disabled={loading}
              loading={loading}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileForm;
