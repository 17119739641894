import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Switch, useHistory, useLocation } from "react-router-dom";
import { Popup } from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import { Button } from "devextreme-react/button";
import ReactPlayer from "react-player/lazy";
import { SideNavInnerToolbar as SideNavBarLayout } from "./layouts";
import { Footer } from "./containers";
import Logo from "./components/Logo";
import PrivateRoute from "./components/PrivateRoute";
import {
  PANXORA_BOTTOM_RIGHT_IMERS_LOGO,
  PANXORA_BOTTOM_RIGHT_CYBERTOOLS_LOGO,
} from "./constants";
import { AppInfo } from "./app-info";
import imersRoutes from "./app-routes";
import cyberToolsRoutes from "./app-cybertools-routes";
import { enableInstructionVideo } from "./redux/dashboard/action";

const videoUrls = [
  "/imers/instructions/IMERS-place-trade.mp4",
  "/imers/instructions/IMERS-cancel-trade.mp4",
  "/imers/instructions/IMERS-join-competition.mp4",
];
const videoTitles = ["PLACE TRADE", "CANCEL TRADE", "JOIN COMPETITION"];
const Content = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const sideBarMode = useSelector((state) => state.auth.mode);
  const enableVideo = useSelector((state) => state.dashboard.enableVideo);
  const videoID = useSelector((state) => state.dashboard.videoID);
  const user = useSelector((state) => state.auth.info);
  const PANXORA_COMPANY_LOGO =
    !sideBarMode || sideBarMode === "imers"
      ? PANXORA_BOTTOM_RIGHT_IMERS_LOGO
      : PANXORA_BOTTOM_RIGHT_CYBERTOOLS_LOGO;
  // If user log in. But, did not setup 2FA. Then,
  // The user should go to security page to setup 2FA.
  console.log(pathname);
  useEffect(() => {
    if (!user.client_user_is_2fa) {
      history.push("/settings");
      return;
    }
    if (pathname === "/") history.push("/trading-dashboard");
    // Only check one time after component mounted.
    // Don't add any dependencies here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderInstructionVideo = useCallback(() => {
    return (
      <div>
        <ScrollView height="100%" width="100%">
          <ReactPlayer
            playing
            className="react-player"
            pip={false}
            stopOnUnmount={true}
            controls={true}
            url={`${process.env.REACT_APP_VIDEO_URL}${videoUrls[videoID - 1]}`}
            width="100%"
            height="100%"
          />
        </ScrollView>
        <Button
          style={{ float: "right", marginTop: 10 }}
          className="px-screen-button"
          type="default"
          stylingMode="contained"
          onClick={() => {
            dispatch(enableInstructionVideo(false));
          }}
        >
          <span className="dx-button-text">CANCEL</span>
        </Button>
      </div>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoID]);

  return (
    <SideNavBarLayout title={AppInfo.title}>
      <Switch>
        {sideBarMode === "cybertools" &&
          cyberToolsRoutes.map(({ path, component, exact }) => (
            <PrivateRoute
              key={path}
              path={path}
              exact={exact}
              component={component}
            />
          ))}
        {(sideBarMode === "imers" || !sideBarMode) &&
          imersRoutes.map(({ path, component, exact }) => (
            <PrivateRoute
              key={path}
              path={path}
              exact={exact}
              component={component}
            />
          ))}
        <Redirect to="/account-dashboard" />
      </Switch>

      <Footer>
        <Popup
          visible={enableVideo}
          width={800}
          height={600}
          position={"center"}
          closeOnOutsideClick={false}
          onHiding={() => dispatch(enableInstructionVideo(false))}
          contentRender={renderInstructionVideo}
          title={`INSTRUCTION VIDEO - ${videoTitles[videoID - 1]}`}
          resizeEnabled={true}
        />
        <Logo imgPath={PANXORA_COMPANY_LOGO} type="company" />
      </Footer>
    </SideNavBarLayout>
  );
};

export default Content;
