import React, { useEffect } from "react";
import { Redirect, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import PrivateRoute from "../../../components/PrivateRoute";
import routes from "./AccountRoute";

const AccountsIndex = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const user = useSelector((state) => state.auth.info);
  // If user log in. But, did not setup 2FA. Then,
  // The user should go to security page to setup 2FA.
  useEffect(() => {
    if (!user.client_user_is_2fa) {
      history.push("/settings");
    }
    // only check one time after component mounted.
    // don't add any dependencies here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Switch>
      {routes.map(({ path, component, exact, indexPage }) => (
        <PrivateRoute
          key={indexPage ? "index" : path}
          path={indexPage ? match.path : `${match.path}${path}`}
          exact={indexPage ? true : exact}
          component={component}
        />
      ))}
      <Redirect to={match.path} />
    </Switch>
  );
};

export default AccountsIndex;
