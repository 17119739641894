import React from "react";

const DisplayAccountInfo = ({ accountRef = "", accountBalance = 0 }) => {
  return (
    <div className="px-account-info" style={{ padding: "0 0 0 20px" }}>
      <p>
        ACCOUNT NAME: <strong>{accountRef}</strong>
      </p>
      <p>
        MANAGED ASSETS BALANCE: <strong>{accountBalance}</strong>
      </p>
    </div>
  );
};
export default DisplayAccountInfo;
