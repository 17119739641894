import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "devextreme-react/button";
import DropDownButton from "devextreme-react/drop-down-button";
import { LoadIndicator } from "devextreme-react/load-indicator";
import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from "devextreme-react/responsive-box";
import Joyride from "react-joyride";
import AccountPosition from "../../../components/dashboard/AccountPosition";
import AccountValue from "../../../components/dashboard/AccountValue";
import AssetList from "../../../components/dashboard/AssetList";
import AccountHistory from "../../../components/dashboard/AccountHistory";
import AccountModePopup from "../../../components/dashboard/AccountModePopup";
import {
  getAccountValues,
  getHolidings,
  getOnboardingElementStatusByID,
  getPerformanceGraphByChartID,
  getPerformanceGraphDropDownContents,
  getRequestStatusOfAccountHistoryFile,
  postRequestToExportAccountHistory,
  switchAccountMode,
  updateOnboardingElementStatusByID,
} from "../../../redux/dashboard/action";
import {
  getAccountInformationByID,
  getSelfServiceAccounts,
  getTradesByAccountID,
  setAccountInformation,
  setDefaultAccount,
  setManagementTypeID,
  setOriginalManagementTypeID,
  setSelectedAccount,
  updateAccountStatus,
} from "../../../redux/accounts/action";
import { useContentLoading } from "../../../contexts/contentLoading";
import { useToast } from "../../../contexts/toast";
import {
  ACCOUNT_ACTIONS,
  BASICMODE,
  DEFAULT_TOAST_TIME,
  EXPERTMODE,
  REFRESHDASHBOARD,
  SETACCOUNTASDEFAULT,
} from "../../../constants";
import BasketManagementImg from "../../../assets/imgs/icon-basket.png";
import RiskManagementImg from "../../../assets/imgs/icon-RMaaS.png";
import NoManagementImg from "../../../assets/imgs/icon-viewonly.png";

import "./AccountDashboard.scss";
import { getSelfServiceUserByID } from "../../../redux/self-service-user/action";

function screen(width) {
  return width < 700 ? "sm" : "lg";
}

const AccountDashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { showToast } = useToast();

  const account = useSelector((state) => state.accounts.account);
  const accounts = useSelector((state) => state.accounts.list);

  const { client_user_id } = useSelector((state) => state.auth.info);

  // Get the default account which account status is Active.
  const defaultAccount = accounts?.filter(
    (item) => item.default_account === true && item.account_status_id !== 0
  )[0];

  const [holdings, setHoldings] = useState();
  const [balanceHistory, setBalanceHistory] = useState();
  const [accountValues, setAccountValues] = useState();
  const [trades, setTrades] = useState();
  const [shouldBeRefreshed, setShouldBeRefreshed] = useState(false);
  const [visibleAccountModePp, setVisibleAccountModePp] = useState(false);
  const [isLoadingActionButton, setIsLoadingActionButton] = useState(false);
  const [isOpenDownloadScreen, setIsOpenDownloadScreen] = useState(false);
  const [reqStatusOfExportAccountHistory, setReqStatusOfExportAccountHistory] =
    useState(null);
  const [pFDropDownContent, setPfDropDownContent] = useState(null);
  const [performanceGraphTitle, setPerformanceGraphTitle] = useState(
    "TOTAL ACCOUNT VALUE - DAILY CHART"
  );
  const [performanceSerieName, setPerformanceSerieName] = useState("Balance");
  const [enableOnboarding, setEnableOnboarding] = useState(false);
  const [nextStepIndex, setNextStepIndex] = useState(0);
  const [onboardingStatusInfo, setOnboardingStatusInfo] = useState();
  const [performanceChartID, setPerformanceChartID] = useState(1);

  const { setLoadingIndicator } = useContentLoading();

  const accountID = account?.client_account_id || null;

  useEffect(() => {
    setNextStepIndex(0)
    if (!account) {
      if (defaultAccount) dispatch(setSelectedAccount(defaultAccount));
      else {
        const updated =
          accounts &&
          // eslint-disable-next-line array-callback-return
          accounts.filter((item) => item.account_status_id !== 2);

        if (updated && updated.length > 0)
          dispatch(setSelectedAccount(updated[0]));
      }
    } else {
      if (defaultAccount) {
        if (account.client_account_id === defaultAccount.client_account_id)
          dispatch(
            setSelectedAccount({
              ...account,
              default_account: true,
            })
          );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);

  useEffect(() => {
    // if (!accounts || accounts.length === 0) {
    setLoadingIndicator(true);
    dispatch(getSelfServiceUserByID(client_user_id));
    dispatch(getSelfServiceAccounts()).then(() => {
      setLoadingIndicator(false);
    });

    const func = () => {
      setIsOpenDownloadScreen(false);
    };

    if (document.getElementById("scroll-content"))
      document
        .getElementById("scroll-content")
        .addEventListener("scroll", func);
    return () => window.removeEventListener("scroll", func);
    // When the user dive into there to setup a new account,
    // Initialize selected account as null to avoid running testing api bar in Setup Account
    // return () => {
    //   dispatch(setSelectedAccount(null));
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // The dasboard should be refreshed whatever user refresh or select another account.
    if (accountID || shouldBeRefreshed) {
      setLoadingIndicator(true);
      Promise.all([
        dispatch(getAccountValues(accountID)),
        dispatch(getHolidings(accountID)),
        dispatch(getTradesByAccountID(accountID)),
        dispatch(getRequestStatusOfAccountHistoryFile(accountID)),
        dispatch(getPerformanceGraphDropDownContents(accountID)),
        dispatch(getPerformanceGraphByChartID(accountID, performanceChartID)),
        dispatch(getOnboardingElementStatusByID(client_user_id)),
      ]).then(
        ([
          accountValues,
          holdings,
          trades,
          reqStatus,
          pFDropDownContent,
          balanceHistory,
          onboardingInfo,
        ]) => {
          setAccountValues(accountValues);
          setHoldings(holdings);
          setTrades(trades);
          setReqStatusOfExportAccountHistory(reqStatus);
          setPfDropDownContent(pFDropDownContent);
          filterBalance(balanceHistory);
          setOnboardingStatusInfo(onboardingInfo[0]);
          setLoadingIndicator(false);

          const dashboardOnboarding =
            onboardingInfo &&
            onboardingInfo.filter((item) => item.ui_element_id === 1);
          if (dashboardOnboarding && dashboardOnboarding.length > 0) {
            const { visible } = dashboardOnboarding[0];
            setEnableOnboarding(visible);
          }

          // After refreshing the dashboard, the flag should be false again for next time to refresh.
          setShouldBeRefreshed(false);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountID, shouldBeRefreshed]);

  useEffect(() => {
    if (!reqStatusOfExportAccountHistory) return;

    const { export_status_id: exportStatusID } =
      reqStatusOfExportAccountHistory;
    var interval = null;

    if (exportStatusID === 1) {
      interval = setInterval(() => {
        fetchRequestStatus();
      }, 2000);
    } else {
      clearInterval(interval);
    }

    const fetchRequestStatus = async () => {
      const data = await dispatch(
        getRequestStatusOfAccountHistoryFile(accountID)
      );
      setReqStatusOfExportAccountHistory(data);
    };

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqStatusOfExportAccountHistory]);

  const filterBalance = (arr) => {
    const data =
      arr &&
      arr.map((item) => {
        return {
          balance_date: item[0].split("T")[0],
          percent: item[1],
        };
      });
    setBalanceHistory(data);
  };

  const handleViewRowChanged = async (value) => {
    setLoadingIndicator(true);
    await dispatch(getTradesByAccountID(accountID, value)).then((res) => {
      setTrades(res);
    });
    setLoadingIndicator(false);
  };

  const goToAddAccount = () => {
    dispatch(setAccountInformation(null));
    history.push("/accounts/add");
  };

  const renderAccountInfo = useCallback(() => {
    // No need to render if account is empty.
    if (!account) return;

    const {
      account_status_id: accStatusID,
      management_type: managementType,
      management_type_id: managementTypeID,
    } = account;
    // Define tag class and status name by particular account.
    var accountStatusInfo = null;
    switch (accStatusID) {
      case 0:
        accountStatusInfo = {
          statusClassName: "bg-normal",
          statusName: "Suspended",
        };
        break;
      case 1:
        accountStatusInfo = {
          statusClassName: "bg-success",
          statusName: "Active",
        };
        break;
      case 2:
        accountStatusInfo = {
          statusClassName: "bg-error",
          statusName: "Suspended",
        };
        break;
      default:
        break;
    }

    // Pending for now.
    let imgPath = "";
    switch (managementTypeID) {
      case 3:
        imgPath = NoManagementImg;
        break;
      case 2:
        imgPath = RiskManagementImg;
        break;
      case 1:
        imgPath = BasketManagementImg;
        break;
      default:
        imgPath = "";
        break;
    }

    if (accStatusID === 0) imgPath = NoManagementImg;

    return (
      <>
        <div
          style={{
            backgroundImage: `url(${imgPath})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            width: 50,
            height: 50,
          }}
        ></div>
        <div className="px-content">
          <p className="px-acc-reference">
            {account.account_reference}{" "}
            <span
              className={`px-acc-status-tag ${accountStatusInfo?.statusClassName}`}
            >
              {" "}
              {accountStatusInfo?.statusName}{" "}
            </span>
          </p>
          <p className="px-acc-type">
            MANAGEMENT TYPE:{" "}
            {accStatusID !== 0 ? managementType : "NOT YET SELECTED"}{" "}
          </p>
        </div>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const refreshDashboard = () => {
    setShouldBeRefreshed(true);
  };

  const handleAccountStatus = async (value) => {
    const message = value === 1 ? "activated" : "suspended";
    // setPosition({ of: "#edit-client-user" });
    setLoadingIndicator(true);
    await dispatch(updateAccountStatus(accountID, value, accounts));

    // When user tries to suspend the account and go to the dashboard.
    // This account should not be selected because of suspended.
    // if (value !== 1) {
    await dispatch(
      setSelectedAccount({
        ...account,
        account_status_id: value,
        status_name: value === 1 ? "ACTIVE" : "SUSPEND",
      })
    );
    // }

    showToast(`The account has been ${message}`, "success", DEFAULT_TOAST_TIME);
    await dispatch(getAccountInformationByID(accountID));

    setLoadingIndicator(false);
  };

  const handleDefaultAccount = () => {
    setLoadingIndicator(true);
    if (accountID)
      dispatch(setDefaultAccount(accountID, account)).then(() => {
        accounts.map((item) => {
          if (item.client_account_id === accountID) item.default_account = true;
          else item.default_account = false;
          return item;
        });

        showToast(
          `The ${account.account_reference} has been set as the default account.`,
          "success",
          4000
        );
        setLoadingIndicator(false);
      });
  };

  const handleAccountMode = async (priority) => {
    setIsLoadingActionButton(true);
    // Call the account mode endpoint here.
    await dispatch(switchAccountMode(accountID, !account.expert_gui)).then(
      (res) => {
        setIsLoadingActionButton(false);
        showToast(
          `The account mode has been changed`,
          "success",
          DEFAULT_TOAST_TIME
        );
      }
    );
    await dispatch(setSelectedAccount({ ...account, expert_gui: true }));
    await dispatch(setAccountInformation({ ...account, expert_gui: true }));
    // Next is to handle the what the next screen should be.
    if (priority === 0) {
      await dispatch(setManagementTypeID(account.management_type_id));
      await dispatch(setOriginalManagementTypeID(account.management_type_id));
      history.push(`/accounts/edit/${accountID}`, { tabIndex: 2 });
    }

    setVisibleAccountModePp(false);
  };

  const handleSpreedSheet = async () => {
    if (!reqStatusOfExportAccountHistory) return;

    const { export_status_id: exportStatusID, file_name: fileName } =
      reqStatusOfExportAccountHistory;

    switch (exportStatusID) {
      case 0:
        // Create a new request.
        const data = await dispatch(
          postRequestToExportAccountHistory(accountID)
        );
        if (data)
          setReqStatusOfExportAccountHistory({
            ...reqStatusOfExportAccountHistory,
            export_status_id: 1,
          });
        break;
      case 1:
        // Still In Progress
        break;
      case 3:
        // Download Account History File
        const newWindow = window.open(
          process.env.REACT_APP_DOWNLOAD_BASE_URL + "/" + fileName,
          "_blank",
          "noopener,noreferrer"
        );

        // Close the tooltip.
        setIsOpenDownloadScreen(false);

        // Destroy the new window object.
        if (newWindow) {
          newWindow.opener = null;
        }
        break;
      default:
        break;
    }
  };

  const renderAccountTool = useCallback(() => {
    if (!account) return;

    const {
      default_account: isDefaultAccount,
      account_status_id: accountStatusID,
      expert_gui: expertGUI,
      management_type_id: managementTypeID,
    } = account;

    var accountAction =
      accountStatusID === 1 ? ACCOUNT_ACTIONS[1] : ACCOUNT_ACTIONS[0];
    var ACCOUNTMODE = expertGUI ? BASICMODE : EXPERTMODE;

    return (
      <>
        <Button
          className="px-switch-account-mode"
          type="normal"
          stylingMode="text"
          disabled={accountStatusID && managementTypeID === 2 ? false : true}
          onClick={async () => {
            if (!expertGUI) setVisibleAccountModePp(true);
            if (expertGUI) {
              setIsLoadingActionButton(true);
              await dispatch(switchAccountMode(accountID, false)).then(
                (res) => {
                  setIsLoadingActionButton(false);
                  showToast(
                    `The account mode has been changed`,
                    "success",
                    DEFAULT_TOAST_TIME
                  );
                }
              );
              await dispatch(
                setSelectedAccount({ ...account, expert_gui: false })
              );
            }
          }}
          Tooltip="Reload"
        >
          <span className="dx-button-text">{`SWITCH TO ${ACCOUNTMODE}`}</span>
          <LoadIndicator
            className="button-indicator"
            visible={isLoadingActionButton}
            width={20}
            height={20}
          />
        </Button>
        <div className="divider" />
        <Button
          className="px-suspend-account"
          text={
            accountStatusID !== 0 ? accountAction["name"] : "CONTINUE TO SETUP"
          }
          type="normal"
          stylingMode="text"
          onClick={() => {
            if (accountStatusID !== 0)
              handleAccountStatus(accountAction["value"]);
            else {
              // Go to Setup account screen to finish setup account.

              // Set selected account to store to run testing api automatically.
              var selectedAccount = accounts.filter(
                (item) => item.client_account_id === accountID
              );
              // Set the target as 1 as default.
              selectedAccount[0].current_management_type_id = 1;
              dispatch(setAccountInformation(selectedAccount[0]));

              history.push("/accounts/add");
            }
          }}
        />
        <div className="divider" />
        <Button
          className="px-default-account"
          text={`${isDefaultAccount ? "DEFAULT ACCOUNT" : SETACCOUNTASDEFAULT}`}
          type="normal"
          stylingMode="text"
          disabled={isDefaultAccount}
          onClick={handleDefaultAccount}
        />
        <div className="divider" />
        <Button
          className="px-refresh-dashboard"
          text={REFRESHDASHBOARD}
          type="normal"
          stylingMode="text"
          onClick={refreshDashboard}
        />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, isLoadingActionButton]);

  const renderHistoryTableActions = useCallback(() => {
    if (!reqStatusOfExportAccountHistory) return;

    const { export_status_id: exportStatusID } =
      reqStatusOfExportAccountHistory;

    return (
      <>
        <Button
          text={"View Top 100 Rows"}
          type="normal"
          stylingMode="text"
          onClick={() => handleViewRowChanged(100)}
        />
        <div className="divider" />
        <Button
          text={"View All Rows"}
          type="normal"
          stylingMode="text"
          onClick={() => handleViewRowChanged(0)}
        />
        <div className="divider" />
        <Button
          className="px-download-btn"
          text={
            exportStatusID === 3
              ? "DOWNLOAD"
              : exportStatusID === 2 || exportStatusID === 1
                ? "IN PROGRESS"
                : "EXPORT"
          }
          type="normal"
          stylingMode="text"
          onClick={handleSpreedSheet}
        />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqStatusOfExportAccountHistory, isOpenDownloadScreen]);

  const handleAction = async (e) => {
    const { title, series_name, chart_id } = e.itemData;

    setPerformanceGraphTitle(title);
    setPerformanceSerieName(series_name);
    setPerformanceChartID(chart_id);
    setLoadingIndicator(true);
    const data = await dispatch(
      getPerformanceGraphByChartID(accountID, chart_id)
    );

    filterBalance(data);
    setLoadingIndicator(false);
  };

  useEffect(() => {
    if (nextStepIndex === 10) {
      setTimeout(() => {
        var checkbox = document.getElementById("checkbox");
        if (checkbox) {
          checkbox.addEventListener("change", async function () {
            const { ui_element_id } = onboardingStatusInfo;
            if (this.checked) {
              await dispatch(
                updateOnboardingElementStatusByID(
                  client_user_id,
                  ui_element_id,
                  false
                )
              );
            } else {
              await dispatch(
                updateOnboardingElementStatusByID(
                  client_user_id,
                  ui_element_id,
                  true
                )
              );
            }
          });
        }
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextStepIndex]);

  return (
    <React.Fragment>
      {accounts && accounts.length === 0 && (
        <div className={"content-block dx-card px-item px-accounts-table"}>
          <div className="px-notification-view">
            <p>Please setup your account first</p>

            <div className="indicator">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <Button
              icon="add"
              className="px-plus-btn"
              onClick={() => goToAddAccount()}
            />
          </div>
        </div>
      )}
      {accounts && accounts.length !== 0 && (
        <React.Fragment>
          <ResponsiveBox
            singleColumnScreen="sm"
            screenByWidth={screen}
            style={{ paddingLeft: 20, paddingRight: 20 }}
          >
            {/* Responsive Layout */}
            <Row ratio={1}></Row>
            <Row ratio={1}></Row>
            <Row ratio={1}></Row>
            <Row ratio={1}></Row>
            <Row ratio={1} screen="xs"></Row>
            <Row ratio={1} screen="xs"></Row>
            <Row ratio={1} screen="xs"></Row>
            <Row ratio={1} screen="xs"></Row>
            <Row ratio={4}></Row>
            <Row ratio={6} screen="xs"></Row>
            <Row ratio={1}></Row>

            <Col ratio={1}></Col>
            <Col ratio={1} screen="lg"></Col>
            <Col ratio={1} screen="lg"></Col>
            <Col ratio={1} screen="lg"></Col>

            {account && <Item>
              <Location
                row={0}
                col={0}
                colspan={4}
                rowspan={1}
                screen="lg"
              ></Location>
              <Location row={0} col={0} rowspan={2} screen="sm"></Location>
              <div className={"content-block dx-card px-item px-accounts-table"} style={{ overflow: "auto" }}>
                <div className="px-account-tool-bar">
                  <div className="px-account-intro">{renderAccountInfo()}</div>
                  <div className="px-account-actions">{renderAccountTool()}</div>
                </div>
              </div>
            </Item>}
            {/* Total Account Value - CARD */}
            <Item>
              <Location
                row={1}
                col={0}
                colspan={1}
                rowspan={2}
                screen="lg"
              ></Location>
              <Location row={2} col={0} rowspan={2} screen="sm"></Location>
              <div className={"content-block dx-card px-item px-accounts-table"}>
                <div className="px-card-item px-first-card">
                  <p className="px-title">TOTAL ACCOUNT VALUE</p>
                  <p className="px-value">
                    {accountValues
                      ? `${Math.round(
                        accountValues &&
                        accountValues.total_account_value * 100
                      ) / 100
                      }`
                      : 0}
                  </p>
                  <div className="px-sub-info">
                    <div className="px-abc">
                      <div
                        className={`triangle ${accountValues && accountValues.current_quarter > 0
                          ? "triangle-up"
                          : "triangle-down"
                          }`}
                      ></div>
                      <p>
                        {accountValues
                          ? `${Math.round(
                            accountValues &&
                            accountValues.current_quarter * 100
                          ) / 100
                          }%`
                          : 0}
                      </p>
                      <span>Current Quarter</span>
                    </div>
                    <div className="px-abc">
                      <div
                        className={`triangle ${accountValues &&
                          accountValues.since_hedge_commenced > 0
                          ? "triangle-up"
                          : "triangle-down"
                          }`}
                      ></div>
                      <p>
                        {accountValues
                          ? `${Math.round(
                            accountValues &&
                            accountValues.since_hedge_commenced * 100
                          ) / 100
                          }%`
                          : 0}
                      </p>
                      <span>Life of Account</span>
                    </div>
                  </div>
                </div>
              </div>
            </Item>

            {/* Account Hedged - Sharpe Ratio */}
            <Item>
              <Location
                row={1}
                col={1}
                colspan={1}
                rowspan={1}
                screen="lg"
              ></Location>
              <Location row={4} col={0} rowspan={1} screen="sm"></Location>
              <div className={"content-block dx-card px-item px-accounts-table"}>
                <div className="px-card-item">
                  <div>
                    <p className="px-title">ACCOUNT</p>
                    <p className="px-description">Sharpe Ratio</p>
                  </div>
                  <p className="px-value">
                    {accountValues
                      ? `${Math.round(
                        accountValues &&
                        accountValues.sharpe_ratio_hedged * 100
                      ) / 100
                      }`
                      : 0}
                  </p>
                </div>
              </div>
            </Item>

            {/* Account Hedged - Sortino Ratio */}
            <Item>
              <Location
                row={2}
                col={1}
                colspan={1}
                rowspan={1}
                screen="lg"
              ></Location>
              <Location row={5} col={0} rowspan={1} screen="sm"></Location>
              <div className={"content-block dx-card px-item px-accounts-table"}>
                <div className="px-card-item">
                  <div>
                    <p className="px-title">ACCOUNT</p>
                    <p className="px-description">90 Day Return</p>
                  </div>
                  <p className="px-value">
                    {accountValues
                      ? `${Math.round(
                        accountValues &&
                        accountValues.ninety_day_return * 100
                      ) / 100
                      }%`
                      : `0%`}
                  </p>
                </div>
              </div>
            </Item>

            {/* Account UnHedged - Sharpe Ratio */}
            <Item>
              <Location
                row={2}
                col={2}
                colspan={1}
                rowspan={1}
                screen="lg"
              ></Location>
              <Location row={6} col={0} rowspan={1} screen="sm"></Location>
              <div className={"content-block dx-card px-item px-accounts-table"}>
                <div className="px-card-item">
                  <div>
                    <p className="px-title">ACCOUNT</p>
                    <p className="px-description">Sortino Ratio</p>
                  </div>
                  <p className="px-value">
                    {accountValues
                      ? `${Math.round(
                        accountValues &&
                        accountValues.sortino_ratio_hedged * 100
                      ) / 100
                      }`
                      : 0}
                  </p>
                </div>
              </div>
            </Item>

            {/* Account Unhedged - Sortino Ratio */}
            <Item>
              <Location
                row={1}
                col={2}
                colspan={1}
                rowspan={1}
                screen="lg"
              ></Location>
              <Location row={5} col={0} rowspan={1} screen="sm"></Location>
              <div className={"content-block dx-card px-item px-accounts-table"}>
                <div className="px-card-item">
                  <div>
                    <p className="px-title">ACCOUNT</p>
                    <p className="px-description">Ranking</p>
                  </div>
                  <p className="px-value">
                    {accountValues
                      ? `${Math.round(
                        accountValues && accountValues.account_ranking * 100
                      ) / 100
                      }`
                      : 0}
                  </p>
                </div>
              </div>
            </Item>

            {/* Current Account Positions - Card */}
            <Item>
              <Location
                row={1}
                col={3}
                colspan={1}
                rowspan={3}
                screen="lg"
              ></Location>
              <Location row={7} col={0} rowspan={1} screen="sm"></Location>
              <div className={"content-block dx-card px-item px-accounts-table"}>
                <div
                  style={{
                    margin: "10px 0 20px 0",
                    position: "relative",
                    height: "30px",
                    width: "100%",
                  }}
                >
                  <p className="px-title margin-bottom-50">
                    CURRENT ACCOUNT POSITIONS
                  </p>
                </div>
                <AccountPosition holdings={holdings} />
              </div>
            </Item>

            {/* Total Account Value - Chart */}
            <Item>
              <Location row={3} col={0} colspan={3} screen="lg"></Location>
              <Location row={7} col={0} rowspan={1} screen="sm"></Location>
              <div className={"content-block dx-card px-item px-accounts-table"}>
                <div
                  className="px-total-account-value"
                >
                  <p className="px-title margin-bottom-50">
                    {performanceGraphTitle}
                  </p>
                  <DropDownButton
                    className="px-performance-graph-options"
                    width={80}
                    splitButton={true}
                    useSelectMode={false}
                    icon="bulletlist"
                    items={pFDropDownContent || []}
                    displayExpr="name"
                    valueExpr="title"
                    keyExpr="chart_id"
                    onItemClick={handleAction}
                  />
                </div>
                <AccountValue
                  balanceHistory={balanceHistory}
                  serieName={performanceSerieName}
                />
              </div>
            </Item>

            {/* ASSET LIST TABLE */}
            <Item>
              <Location row={4} col={0} colspan={2} screen="lg"></Location>
              <Location row={8} col={0} rowspan={1} screen="sm"></Location>
              <div className={"content-block dx-card px-item px-accounts-table"}>
                <div
                  style={{
                    position: "relative",
                    height: "30px",
                    width: "100%",
                  }}
                >
                  <p className="px-title margin-bottom-50">ASSET LIST</p>
                </div>
                <AssetList
                  accountID={accountID}
                  holdings={holdings}
                  baseCurrency={account?.base_currency}
                />
              </div>
            </Item>

            {/* ACCOUNT HISTORY TABLE */}
            <Item>
              <Location row={4} col={2} colspan={2} screen="lg"></Location>
              <Location row={9} col={1} rowspan={1} screen="sm"></Location>
              <div className={"content-block dx-card px-item px-accounts-table"} style={{ overflow: "auto" }}>
                <div
                  className="px-account-history-table"
                >
                  <p className="px-title margin-bottom-50">ACCOUNT HISTORY</p>

                  <div className="px-account-actions">
                    {renderHistoryTableActions()}
                  </div>
                </div>

                <AccountHistory trades={trades} />
              </div>
            </Item>
          </ResponsiveBox>
          {visibleAccountModePp && (
            <AccountModePopup
              handleAccountMode={handleAccountMode}
              setVisibleAccountModePp={setVisibleAccountModePp}
            />
          )}

          {account &&
            trades &&
            trades.length > 0 &&
            holdings &&
            holdings.length > 0 && (
              <Joyride
                continuous={true}
                run={enableOnboarding}
                scrollToFirstStep={true}
                showProgress={true}
                showSkipButton={false}
                steps={[
                  {
                    title: (
                      <p style={{ textAlign: "left" }}>
                        WELCOME TO CYBERTOOLS CLUB
                      </p>
                    ),
                    content: (
                      <p style={{ textAlign: "left" }}>
                        Let’s take a tour of your new dashboard functions.
                      </p>
                    ),
                    floaterProps: {
                      disableAnimation: true,
                    },
                    spotlightPadding: 10,
                    target: "body",
                    disableBeacon: true,
                    placement: "center",
                  },
                  {
                    title: <p style={{ textAlign: "left" }}>SWITCH ACCOUNTS</p>,
                    content: (
                      <p style={{ textAlign: "left" }}>
                        Using the drop down quickly switch between accounts on
                        various exchanges.
                      </p>
                    ),
                    floaterProps: {
                      disableAnimation: true,
                    },
                    spotlightPadding: 10,
                    target: ".px-account-tool",
                    disableBeacon: true,
                    placement: "bottom",
                  },
                  {
                    title: <p style={{ textAlign: "left" }}>SETUP ACCOUNT</p>,
                    content: (
                      <p style={{ textAlign: "left" }}>
                        Get started by adding an exchange account to CyberTools
                        management services. Make sure you have your exchange
                        account api keys and secret at the ready!
                      </p>
                    ),
                    floaterProps: {
                      disableAnimation: true,
                    },
                    spotlightPadding: 10,
                    target: ".px-plus-btn",
                    disableBeacon: true,
                    placement: "bottom",
                  },
                  {
                    title: (
                      <p style={{ textAlign: "left" }}>
                        UPDATE PROFILE & LOGOUT
                      </p>
                    ),
                    content: (
                      <p style={{ textAlign: "left" }}>
                        Add your address and contact details to your profile. To
                        also support a more secure service we recommend you
                        update your password and 2FA every quarter.
                      </p>
                    ),
                    floaterProps: {
                      disableAnimation: true,
                    },
                    spotlightPadding: 10,
                    target: ".user-info",
                    disableBeacon: true,
                    placement: "bottom",
                  },
                  {
                    title: <p style={{ textAlign: "left" }}>SWITCH MODES</p>,
                    content: (
                      <p style={{ textAlign: "left" }}>
                        Risk management accounts can switch between basic and
                        advanced modes. Advanced mode options allow you to add
                        new assets and adjust current asset quantities on the
                        account.
                      </p>
                    ),
                    floaterProps: {
                      disableAnimation: true,
                    },
                    spotlightPadding: 10,
                    target: ".px-switch-account-mode",
                    disableBeacon: true,
                    placement: "bottom",
                  },
                  {
                    title: (
                      <p style={{ textAlign: "left" }}>
                        SUSPEND & ACTIVATE ACCOUNT
                      </p>
                    ),
                    content: (
                      <p style={{ textAlign: "left" }}>
                        The button switches between your account between a
                        suspended and active status. The suspended account will
                        continue to deliver account balances, asset price and
                        risk rating updates. Active account revert the account
                        back to the previous management strategy model.
                      </p>
                    ),
                    floaterProps: {
                      disableAnimation: true,
                    },
                    spotlightPadding: 10,
                    target: ".px-suspend-account",
                    disableBeacon: true,
                    placement: "bottom",
                  },
                  {
                    title: (
                      <p style={{ textAlign: "left" }}>
                        SET ACCOUNT AS DEFAULT
                      </p>
                    ),
                    content: (
                      <p style={{ textAlign: "left" }}>
                        Choose the current account as the one that is viewed
                        immediately when logging in to cybertools.
                      </p>
                    ),
                    floaterProps: {
                      disableAnimation: true,
                    },
                    spotlightPadding: 10,
                    target: ".px-default-account",
                    disableBeacon: true,
                    placement: "bottom",
                  },
                  {
                    title: (
                      <p style={{ textAlign: "left" }}>REFRESH DASHBOARD</p>
                    ),
                    content: (
                      <p style={{ textAlign: "left" }}>
                        Clicking ‘Refresh Dashboard’ brings all account
                        performance and balance data from the currently selected
                        exchange account up to date.
                      </p>
                    ),
                    floaterProps: {
                      disableAnimation: true,
                    },
                    spotlightPadding: 10,
                    target: ".px-refresh-dashboard",
                    disableBeacon: true,
                    placement: "bottom",
                  },
                  {
                    title: (
                      <p style={{ textAlign: "left" }}>
                        SELECT ASSET RISK RATING ICON
                      </p>
                    ),
                    content: (
                      <p style={{ textAlign: "left" }}>
                        To view details about the risk management model
                        assessment for a particular cryptocurrency click on the
                        Risk Ring icon. The report will show the current
                        relative hypothetical performance between holding a
                        specific cryptocurrency and using Panxora's risk
                        management models to improve the risk adjusted returns
                        of holding the asset. These models drive the risk
                        management tool.
                      </p>
                    ),
                    floaterProps: {
                      disableAnimation: true,
                    },
                    spotlightPadding: 10,
                    target: ".px-risk-report-donut",
                    disableBeacon: true,
                    placement: "bottom",
                  },
                  {
                    title: (
                      <p style={{ textAlign: "left" }}>
                        DOWNLOAD TRADING HISTORY
                      </p>
                    ),
                    content: (
                      <p style={{ textAlign: "left" }}>
                        Export the trading history of this cryptocurrency
                        exchange account to a CSV file. (Please note this is
                        updated once every 2 hours."
                      </p>
                    ),
                    floaterProps: {
                      disableAnimation: true,
                    },
                    spotlightPadding: 10,
                    target: ".px-download-btn",
                    disableBeacon: true,
                    placement: "bottom",
                  },
                  {
                    title: (
                      <p style={{ textAlign: "left" }}>
                        ENJOY YOUR NEW DASHBOARD
                      </p>
                    ),
                    content: (
                      <div style={{ textAlign: "left" }}>
                        <p>
                          If you wish to turn off these hints and tips, please
                          check the box below.
                        </p>
                        <input
                          id="checkbox"
                          type="checkbox"
                          onChange={async (e) => {
                            const { ui_element_id } = onboardingStatusInfo;
                            if (e.target.checked) {
                              await dispatch(
                                updateOnboardingElementStatusByID(
                                  client_user_id,
                                  ui_element_id,
                                  false
                                )
                              );
                            } else {
                              await dispatch(
                                updateOnboardingElementStatusByID(
                                  client_user_id,
                                  ui_element_id,
                                  true
                                )
                              );
                            }
                          }}
                        />
                        <label for="vehicle1"> Don't show this again</label>
                        <br />
                      </div>
                    ),
                    floaterProps: {
                      disableAnimation: true,
                    },
                    spotlightPadding: 10,
                    target: "body",
                    disableBeacon: true,
                    placement: "center",
                  },
                ]}
              />
            )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default AccountDashboard;
