export const SET_SELECTED_ACCOUNT = "SET_SELECTED_ACCOUNT";
export const SET_ACCOUNT_INFORMATION = "SET_ACCOUNT_INFORMATION";
export const SET_ACCOUNT = "SET_ACCOUNT";
export const SET_SELF_SERVICE_ACCOUNTS = "SET_SELF_SERVICE_ACCOUNTS";
export const SET_CREATED_CLIENT_ACCOUNT_ID = "SET_CREATED_CLIENT_ACCOUNT_ID";
export const SET_ACCOUNT_HOLDINGS = "SET_ACCOUNT_HOLDINGS";
export const SET_ACCOUNT_STATS = "SET_ACCOUNT_STATS";
export const SET_FULL_ASSET_LIST = "SET_FULL_ASSET_LIST";
export const ADD_NEW_ACCOUNT_TO_LIST = "ADD_NEW_ACCOUNT_TO_LIST";
export const REMOVE_USER_DATA = "REMOVE_USER_DATA";
export const SET_MANAGEMENT_TYPE_ID = "SET_MANAGEMENT_TYPE_ID";
export const SET_ORIGINAL_MANAGEMENT_TYPE_ID =
  "SET_ORIGINAL_MANAGEMENT_TYPE_ID";
export const SET_BASKET_ASSET_LIST = "SET_BASKET_ASSET_LIST";
export const SET_RISK_MANAGED_ASSET_LIST = "SET_RISK_MANAGED_ASSET_LIST";
export const SET_RISK_REPORT_DATA = "SET_RISK_REPORT_DATA";
export const SET_ACCOUNT_API_INFO = "SET_ACCOUNT_API_INFO";
export const SET_RISK_RATING_INFO = "SET_RISK_RATING_INFO";
export const SET_ACTIVE_COMPETITIONS = "SET_ACTIVE_COMPETITIONS";
