import React, { useState, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch, Link } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import { passwordResetChange } from "../../redux/auth/action";
import Error from "../../components/Error";

const ChangePasswordForm = () => {
  const history = useHistory();
  const formData = useRef({});
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const passwordEditorOptions = {
    stylingMode: "filled",
    placeholder: "Password",
    mode: "password",
    valueChangeEvent: "keyup",
    onValueChanged: (e) => {
      console.log(e);
    },
  };

  const confirmedPasswordEditorOptions = {
    stylingMode: "filled",
    placeholder: "Confirm Password",
    mode: "password",
  };
  
  const onSubmit = async (e) => {
    e.preventDefault();

    const { password } = formData.current;
    setLoading(true);

    try {
      await dispatch(passwordResetChange(password, match.params.token));
      setError("");
      setLoading(false);
      history.push("/login");
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const confirmPassword = useCallback(
    ({ value }) => value === formData.current.password,
    []
  );

  return (
    <form onSubmit={onSubmit}>
      {!!error && <Error>{error}</Error>}
      <Form formData={formData.current} disabled={loading}>
        <Item cssClass="no-margin"
          dataField={"password"}
          editorType={"dxTextBox"}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <Label visible={true} text="Enter New Password" />
        </Item>
        <Item cssClass="no-margin"
          dataField={"confirmedPassword"}
          editorType={"dxTextBox"}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <CustomRule
            message={"Passwords do not match"}
            validationCallback={confirmPassword}
          />
          <Label visible={true} text="Confirm New Password" />
        </Item>
        {/* <Item cssClass="validation-process">
          <ul>
            <li><p className={`${classNames[0]}`}>At least 8 characters long.</p></li>
            <li><p className={`${classNames[1]}`}>At least 1 capital letter.</p></li>
            <li><p className={`${classNames[2]}`}>At least 1 lowercase letter.</p></li>
            <li><p className={`${classNames[3]}`}>At least 1 special character.</p></li>
            <li><p className={`${classNames[4]}`}>At least 1 numeric character.</p></li>
          </ul>
        </Item> */}
        <ButtonItem>
          <ButtonOptions
            width={"100%"}
            type={"default"}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {loading ? (
                <LoadIndicator width={"24px"} height={"24px"} visible={true} />
              ) : (
                "Continue"
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
          <Item>
            <div className={"link center"} style={{ fontSize: 20 }}>
              Return to <Link to={"/login"}>Sign In</Link>
            </div>
          </Item>
      </Form>
    </form>
  );
};

export default ChangePasswordForm;
