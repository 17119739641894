import React, { useState } from "react";
import RadioGroup from "devextreme-react/radio-group";
import { Popup } from "devextreme-react/popup";
import { Position, ToolbarItem } from "devextreme-react/autocomplete";

export const priorityEntitiesForRiskManaged = [
  { id: 1, text: "Reset allocated assets to 100%" },
  { id: 2, text: "Accept over-allocation of assets" },
  { id: 3, text: "Return to risk management setup" },
];
export const priorityEntitiesForBasket = [
  { id: 1, text: "Scale basket holdings to 100%" },
  { id: 2, text: "Return to basket trading setup" },
];
const AllocationWarningPopup = ({
  managementTypeID,
  setVisibleWarningPopup,
  handleWarningOption,
}) => {
  const [selectionPriority, setSelectionPriority] = useState(false);

  const changeSelectionPriority = (e) => {
    setSelectionPriority(e.value);
  };
  return (
    <Popup
      visible={true}
      onHiding={setVisibleWarningPopup}
      dragEnabled={true}
      closeOnOutsideClick={false}
      showCloseButton={false}
      showTitle={true}
      title={
        managementTypeID === 1
          ? "BASKET MANAGEMENT WARNING"
          : "RISK MANAGEMENT WARNING"
      }
      className="px-allocation-warning-popup"
      container=".dx-viewport"
      width={400}
      height={220}
    >
      <Position at="center" my="center" />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        options={{
          text: "Okay",
          onClick: () => handleWarningOption(selectionPriority),
        }}
      />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        options={{
          text: "Cancel",
          onClick: () => setVisibleWarningPopup(false),
        }}
      />
      <RadioGroup
        id="radio-group-with-selection"
        items={
          managementTypeID === 1
            ? priorityEntitiesForBasket
            : priorityEntitiesForRiskManaged
        }
        value={selectionPriority}
        valueExpr="id"
        displayExpr="text"
        onValueChanged={changeSelectionPriority}
      />
    </Popup>
  );
};
export default AllocationWarningPopup;
