import {
  SET_EXCHANGE_ACCOUNT_LIST,
  SET_SUSPEND_ACTIONS,
  SET_SELF_SERVICE_USER,
  SET_BILLING_RATES_INFO,
  SET_BILLING_ACCOUNTS_INFO,
  REMOVE_USER_DATA,
} from "./constant";

const DEFAULT_STATE = {
  exchanges: null,
  suspendActions: null,
  info: null,
  billingRates: null,
  billingAccounts: null,
};

const selfServiceUserReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_EXCHANGE_ACCOUNT_LIST:
      return { ...state, exchanges: action.payload };
    case SET_SUSPEND_ACTIONS:
      return { ...state, suspendActions: action.payload };
    case SET_SELF_SERVICE_USER:
      return { ...state, info: action.payload };
    case SET_BILLING_RATES_INFO:
      return { ...state, billingRates: action.payload };
    case SET_BILLING_ACCOUNTS_INFO:
      return { ...state, billingAccounts: action.payload };
    // After call logout action.
    case REMOVE_USER_DATA:
      return DEFAULT_STATE;
    default:
      return state;
  }
};

export default selfServiceUserReducer;
