import React, { useEffect, Fragment, useState } from "react";
import useWebSocket from "react-use-websocket";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from "devextreme-react/responsive-box";
import { Popup } from "devextreme-react/popup";
import { NumberBox } from "devextreme-react/number-box";
import ScrollView from "devextreme-react/scroll-view";
import Button from "devextreme-react/button";
import Joyride, { EVENTS } from "react-joyride";

import {
  getAllExchangeListByUserID,
  getMarketListByUserID,
  getScreenByID,
  getScreens,
} from "../../../redux/tradingDashboard/action";
import {
  setRedirectedFromDemo,
  startStopTour,
} from "../../../redux/app-tour/action";
import { useContentLoading } from "../../../contexts/contentLoading";
import ChangeCell from "./ChangeCell";
import CommonCell from "./CommonCell";
import { get } from "../../../utils/storage";
import { TRADING_DASHBOARD_STEPS, MAIN_TOKEN_KEY } from "../../../constants";
import CompetitionEndMessageIcon from "../../../assets/imgs/competition-cup-stars.png";
import "./DemoDashboard.scss";

function screen(width) {
  return width < 700 ? "sm" : "lg";
}

const mockCompetitionData = [
  {
    duration: 15,
    status: "Open",
    end_time: 1670427584,
    entrants: [1, 2, 3, 4, 5],
  },
  {
    duration: 30,
    status: "Locked",
    end_time: 1670429761,
    entrants: [1, 2, 3, 4, 5, 6, 7, 8],
  },
  {
    duration: 15,
    status: "Active",
    end_time: 1670428182,
    entrants: [1, 2, 3, 4, 5, 6, 7, 8],
  },
  {
    duration: 60,
    status: "Active",
    end_time: 1670428295,
    entrants: [1, 2, 3, 4, 5, 6, 7, 8],
  },
  {
    duration: 60,
    status: "Active",
    end_time: 1670430628,
    entrants: [1, 2, 3, 4, 5, 6, 7, 7],
  },
];

const initialOrdersData = {
  orderId: 543383,
  buysell: "buy",
  quantity: 2,
  order_type: "market",
  price_level: 19.254424,
  status: 3,
  status_str: "Filled",
  reason: "",
  quantity_remaining: 2,
  external_ref: "",
  exchange: "IMERS",
  market: "",
};

let awaitingTimerInterval = null;
let timerRemainingInterval = null;
let timeInMs = 0;

const DemoTradingDashboard = () => {
  const dispatch = useDispatch();

  const account = useSelector((state) => state.accounts.account);
  const accMode = useSelector((state) => state.auth.accMode);
  const siteMode = useSelector((state) => state.auth.mode);
  const { startDemo } = useSelector((state) => state.tourState);
  const [stepIndex, setStepIndex] = useState(0);
  const [run, setRun] = useState(false);
  const [steps, setSteps] = useState([]);

  const history = useHistory();

  const [priceWallData, setPriceWallData] = useState();
  const [oldData, setOldData] = useState({});
  const [isOrderPad, setIsOrderPad] = useState(false);
  const [orderPadData, setOrderPadData] = useState();
  const [createdOrderData, setCreatedOrderData] = useState(null);
  const [connectedWSS, setConnectedWSS] = useState(false);
  const [selectedScreenID, setSelectedScreenID] = useState(null);
  const [joinedCompetition, setJoinedCompetition] = useState(null);
  const [competitionEnd, setCompetitionEnd] = useState(false);
  const [selectedMarket, setSelectedMarket] = useState("");
  const [entrants, setEntrants] = useState([
    {
      rank: 1,
      user_id: 1004,
      game_name: "You",
      balance: 0,
    },
    {
      rank: 2,
      user_id: 852,
      game_name: "Austin",
      balance: 0,
    },
    {
      rank: 3,
      user_id: 637,
      game_name: "Loomis",
      balance: 0,
    },
    {
      rank: 4,
      user_id: 604,
      game_name: "Blueforce",
      balance: 0,
    },
    {
      rank: 5,
      user_id: 768,
      game_name: "RichRicher",
      balance: 0,
    },
  ]);
  const [awaitingTimer, setAwaitingTimer] = useState("00");
  const [reminingTimer, setReminingTimer] = useState("00");
  const [type, setType] = useState("market");
  const [quantity, setQuantity] = useState(0);
  const [orderPrice, setOrderPrice] = useState(
    parseFloat(orderPadData?.oldValue)
  );

  const { setLoadingIndicator } = useContentLoading();

  const token = get(MAIN_TOKEN_KEY);

  let wsBaseUrl = "ss-wip.panxora.com";

  if (!window.location.host.includes("localhost"))
    wsBaseUrl = window.location.host;

  const func = () => {
    if (document.hidden) {
      wsClient.sendMessage("unsubscribe");
      console.log(
        "Browser tab is hidden and unsubscribe action should be here"
      );
    } else {
      getRealTimeDataFromWebsocket();
      console.log("Browser tab is visible");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // const socket  = useEventSource(`wss://${wsBaseUrl}/ws/`);

  let wsClient = new useWebSocket(`wss://${wsBaseUrl}/ws/`, {
    onOpen: () => {
      console.log("WebSocket connection opened.");
      setConnectedWSS(true);
      document.addEventListener("visibilitychange", func, true);
    },
    onClose: () => {
      window.removeEventListener("visibilitychange", func, false);
      console.log("WebSocket connection closed.");
      setConnectedWSS(false);
    },
    shouldReconnect: (closeEvent) => true,
    onMessage: (event) => processPriceData(event?.data),
  });

  useEffect(() => {
    return () => window.removeEventListener("visibilitychange", func, true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!startDemo) return;
    setSteps(TRADING_DASHBOARD_STEPS);
    setStepIndex(0);
    let timeout = setTimeout(() => {
      setRun(true);
    }, 1800);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedScreenID) return;
    const fetchInitialData = async () => {
      setLoadingIndicator(true);
      await getInitialData();
    };

    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScreenID]);

  useEffect(() => {
    const fetchScreens = async () => {
      setLoadingIndicator(true);
      await getInitialScreensData();
    };
    fetchScreens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitialScreensData = async () => {
    const data = await dispatch(getScreens());
    // setScreens(data);
    await getLastViewedScreen(data);
  };

  const getLastViewedScreen = async (arr) => {
    const defaultScreen = arr.filter((item) => item.last_viewed === true);
    if (defaultScreen && defaultScreen.length > 0) {
      setSelectedScreenID(defaultScreen[0].user_screen_id);
    }
  };

  const generateArrayByObjectWithKeyValue = (arr, isRegistered) => {
    let newArray = [];
    if (!arr) return;

    Object.values(arr).map((oVal, ovIndex) => {
      if (oVal === isRegistered) {
        const filteredData = Object.keys(arr).filter(
          (oKey, okIndex) => ovIndex === okIndex
        );
        newArray = [...newArray, ...filteredData];
      }
      return oVal;
    });

    return newArray;
  };

  const getInitialData = async () => {
    Promise.all([
      dispatch(getAllExchangeListByUserID(selectedScreenID)),
      dispatch(getMarketListByUserID(selectedScreenID)),
      dispatch(getScreenByID(selectedScreenID)),
    ]).then(async ([exchanges, markets, selectedScreenData]) => {
      await generateInitialPriceWallData(
        exchanges,
        markets,
        selectedScreenData?.prices
      );
    });
  };

  const generateInitialPriceWallData = async (exchanges, markets, prices) => {
    let pricesData = {};

    // Sort exchange list first.
    let newExchanges = generateArrayByObjectWithKeyValue(
      exchanges,
      true
    )?.sort();
    let newMarkets = generateArrayByObjectWithKeyValue(markets, true)?.sort();

    let newPrices = prices;

    let exchangeSource = [];
    let marketSource = [];

    newExchanges.forEach((item, index) => {
      exchangeSource[index] = {
        exchange: item,
      };
    });
    newMarkets.forEach((item, index) => {
      marketSource[index] = {
        market: item,
      };
    });

    newExchanges.map((item) => {
      let data = [];

      Object.keys(newPrices[item])
        .sort()
        .map((keyName) => {
          data = [...data, parseFloat(newPrices[item][keyName]).toFixed(6)];

          return keyName;
        });

      pricesData = { ...pricesData, [item]: data };
      return item;
    });
    const dataSource = {
      exchanges: exchangeSource,
      markets: marketSource,
      prices: pricesData,
    };

    setPriceWallData(dataSource);
    setLoadingIndicator(false);
  };

  const getRealTimeDataFromWebsocket = () => {
    const filteredToken = token.split('"')[1];
    wsClient.sendMessage(`${filteredToken}`);
    priceWallData?.exchanges
      ?.map((item) => item.exchange)
      .map((exchange) => {
        priceWallData?.markets
          ?.map((marketData) => marketData.market)
          .map((market) => {
            wsClient.sendMessage(`${filteredToken},${exchange},${market}`);
            return market;
          });
        return exchange;
      });
  };

  useEffect(() => {
    if (connectedWSS) {
      getRealTimeDataFromWebsocket();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedWSS, priceWallData, selectedScreenID]);

  const processPriceData = (data) => {
    const exchangeName = data?.split(",")?.[0];
    const marketName = data?.split(",")?.[1];
    let oldPriceData = 0;
    const marketPrice = parseFloat(data?.split(",")?.[2]).toFixed(6);
    const updated = priceWallData?.prices?.[exchangeName]?.map(
      (item, index) => {
        if (
          index ===
          priceWallData?.markets?.map((item) => item.market).indexOf(marketName)
        ) {
          oldPriceData = item;
          item = marketPrice;
        }
        return item;
      }
    );
    setOldData({
      ...oldData,
      [exchangeName]: {
        exchange: exchangeName,
        indexOf: priceWallData?.markets
          ?.map((item) => item.market)
          .indexOf(marketName),
        price: oldPriceData,
      },
    });
    const updatedPrices = { ...priceWallData?.prices, [exchangeName]: updated };
    setPriceWallData({ ...priceWallData, prices: updatedPrices });
  };

  const handleChangeCell = (cellID, exchangeName, marketName, oldValue) => {
    console.log(entrants.length, stepIndex);
    if (entrants.length >= 7 || stepIndex >= 4) {
      setTimeout(() => {
        setStepIndex(6);
      }, 200);
    } else {
      setTimeout(() => {
        setStepIndex(2);
      }, 200);
    }
    setIsOrderPad(true);
    setSelectedMarket(marketName);
    setOrderPadData({
      exchangeName,
      marketName,
      oldValue,
      accountName:
        account?.account_reference + ":" + account?.exchange_account_type,
      accMode,
    });
  };

  const startAwaitingTimer = () => {
    timeInMs = 10 * 1000;
    awaitingTimerInterval = setInterval(() => {
      if (timeInMs < 1000) {
        setAwaitingTimer("00");
        clearInterval(awaitingTimerInterval);
        setJoinedCompetition({ status: "Active" });
        setStepIndex(5);
        timeInMs = 0;
        return;
      }

      if (timeInMs < 8000 && timeInMs > 6000) {
        setEntrants((ps) => [
          ...ps,
          {
            rank: ps.length + 1,
            user_id: 329,
            game_name: "Novella Spirito",
            balance: 0,
          },
        ]);
      }

      if (timeInMs < 6000 && timeInMs > 4000) {
        setEntrants((ps) => [
          ...ps,
          {
            rank: ps.length + 1,
            user_id: 444,
            game_name: "Lowenan-Menhennick",
            balance: 0,
          },
        ]);
      }

      if (timeInMs < 4000 && timeInMs > 2000) {
        setEntrants((ps) => [
          ...ps,
          {
            rank: ps.length + 1,
            user_id: 448,
            game_name: "Olinda",
            balance: 0,
          },
        ]);
      }

      let seconds = Math.floor((timeInMs / 1000) % 60);
      seconds = String(seconds % 60).padStart(2, 0);

      setAwaitingTimer(seconds);
      timeInMs -= 1000;
    }, 1000);
  };

  const startTimeRemainingTimer = () => {
    timeInMs = 10 * 1000;
    timerRemainingInterval = setInterval(() => {
      if (timeInMs < 1000) {
        setReminingTimer("00");
        clearInterval(timerRemainingInterval);
        timeInMs = 0;

        setTimeout(() => {
          setStepIndex(9);
        }, 200);
        setCompetitionEnd(true);

        return;
      }

      if (timeInMs < 5000 && timeInMs > 3000) {
        setEntrants((ps) =>
          ps.map((user) => {
            if (user.rank === 1) {
              user.balance = 50;
            }
            if (user.rank === 2) {
              user.balance = 40;
            }
            if (user.rank === 3) {
              user.balance = 25;
            }
            return user;
          })
        );
      }

      if (timeInMs < 3000 && timeInMs > 1000) {
        setEntrants((ps) =>
          ps.map((user) => {
            if (user.rank === 4) {
              user.balance = 20;
            }
            if (user.rank === 5) {
              user.balance = 10;
            }
            if (user.rank === 6) {
              user.balance = 5;
            }
            if (user.rank === 7) {
              user.balance = 2;
            }
            return user;
          })
        );
      }

      let seconds = Math.floor((timeInMs / 1000) % 60);
      seconds = String(seconds % 60).padStart(2, 0);

      setReminingTimer(seconds);
      timeInMs -= 1000;
    }, 1000);
  };

  const onClickJoinButton = () => {
    setJoinedCompetition({
      status: "Open",
    });

    setStepIndex(1);
  };

  /**
   * RENDER CONTENT FOR ORDER POPUP
   *
   */

  const renderPopupContent = () => {
    return (
      <div>
        <div
          className="px-order-pad-header"
          style={{
            height: 100,
            marginBottom: 20,
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div className="px-property">
            <p style={{ fontSize: 20 }}>EXCHANGE NAME</p>
            <span style={{ fontSize: 20, fontWeight: "bold" }}>
              {orderPadData?.exchangeName.toUpperCase()}
            </span>
          </div>
          <div className="px-property">
            <p style={{ fontSize: 20 }}>MARKET NAME</p>
            <span style={{ fontSize: 20, fontWeight: "bold" }}>
              {orderPadData?.marketName}
            </span>
          </div>
          <div className="px-property">
            <p style={{ fontSize: 20 }}>ACCOUNT NAME</p>
            <span style={{ fontSize: 20, fontWeight: "bold" }}>DEMO</span>
          </div>
        </div>
        <div
          className="px-order-pad-content"
          style={{ marginTop: 40, height: 200, display: "flex" }}
        >
          <div
            style={{ width: "70%", height: "100%", padding: "0px 20px 0 0" }}
          >
            <div className="dx-field" style={{ display: "grid" }}>
              {(type === "limit" || type === "stop") && (
                <>
                  <div
                    className="dx-field-label"
                    style={{ fontSize: 14 }}
                  >{`${type.toUpperCase()} PRICE`}</div>
                  <div className="dx-field-value" style={{ width: "80%" }}>
                    <NumberBox
                      id="tour_orderPriceBox"
                      width={"100%"}
                      style={{ marginBottom: 20 }}
                      showSpinButtons={true}
                      format={"#.##"}
                      value={orderPrice}
                      onValueChanged={(e) => setOrderPrice(e.value)}
                    ></NumberBox>
                  </div>
                </>
              )}
              <>
                <div className="dx-field-label" style={{ fontSize: 14 }}>
                  QUANTITY
                </div>
                <div className="dx-field-value" style={{ width: "80%" }}>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();

                      if (stepIndex === 2 && quantity) {
                        setStepIndex(3);
                      }
                      if (stepIndex === 6 && quantity) {
                        setStepIndex(7);
                      }
                    }}
                  >
                    <NumberBox
                      id="tour_orderQuantityBox"
                      width={"100%"}
                      format={"#.##"}
                      showSpinButtons={true}
                      value={quantity}
                      onValueChanged={(e) => {
                        setQuantity(e.value);
                      }}
                    ></NumberBox>
                  </form>
                </div>
              </>
            </div>
          </div>
          <div style={{ width: "30%", height: "100%", paddingTop: 20 }}>
            <Button
              width={"100%"}
              style={{ marginBottom: 20 }}
              type={`${type === "market" ? "default" : "normal"}`}
              stylingMode="contained"
              text="MARKET"
              id="tour_orderMarketButton"
              onClick={() => setType("market")}
            />
            <Button
              width={"100%"}
              style={{ marginBottom: 20 }}
              type={`${type === "limit" ? "default" : "normal"}`}
              stylingMode="contained"
              text="LIMIT"
              id="tour_orderLimitButton"
              onClick={() => setType("limit")}
            />
            <Button
              width={"100%"}
              style={{ marginBottom: 20 }}
              type={`${type === "stop" ? "default" : "normal"}`}
              stylingMode="contained"
              text="STOP"
              id="tour_orderStopButton"
              onClick={() => setType("stop")}
            />
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <Button
            id="tour_orderBuyButton"
            width={"100%"}
            style={{ marginRight: 10 }}
            type="success"
            stylingMode="contained"
            text="BUY"
            onClick={(e) => {
              if (stepIndex < 4) {
                setStepIndex(4);
                startAwaitingTimer();
                setQuantity(0);
              } else {
                setStepIndex(8);
                startTimeRemainingTimer();
                const orderData = {
                  ...initialOrdersData,
                  quantity,
                  market: selectedMarket,
                };
                setCreatedOrderData(orderData);
                setQuantity(0);
              }
              setOrderPadData(null);
              setIsOrderPad(false);
            }}
          />
          <Button
            id="tour_orderSellButton"
            width={"100%"}
            type="danger"
            stylingMode="contained"
            text="SELL"
            onClick={(e) => {
              if (stepIndex < 4) {
                setStepIndex(4);
                startAwaitingTimer();
                setQuantity(0);
              } else {
                setStepIndex(8);
                startTimeRemainingTimer();
                const orderData = {
                  ...initialOrdersData,
                  quantity,
                  market: selectedMarket,
                };
                setCreatedOrderData(orderData);
                setQuantity(0);
              }
              setOrderPadData(null);
              setIsOrderPad(false);
            }}
          />
        </div>
      </div>
    );
  };

  const handleCallback = (data) => {
    const { type, index } = data;
    if (EVENTS.STEP_AFTER === type && index === 9) {
      setRun(false);
      setSteps([]);
      setStepIndex(0);
    }
    // do something here to handle callbacks from JoyRide component
  };

  return (
    <Fragment>
      <Joyride
        callback={handleCallback}
        continuous
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        stepIndex={stepIndex}
        steps={steps}
        disableCloseOnEsc
      />
      <div className="demo-dashboard-container">
        <ResponsiveBox
          singleColumnScreen="sm"
          screenByWidth={screen}
          style={{ paddingLeft: 20, paddingRight: 20 }}
        >
          {/* Responsive Layout */}
          <Row ratio={1}></Row>
          <Row ratio={1}></Row>
          <Row ratio={1}></Row>
          <Row ratio={1} screen="xs"></Row>
          <Row ratio={1} screen="xs"></Row>
          <Row ratio={1} screen="xs"></Row>
          <Row ratio={1} screen="xs"></Row>
          <Row ratio={4}></Row>
          <Row ratio={6} screen="xs"></Row>
          <Row ratio={1}></Row>

          <Col ratio={1} screen="lg"></Col>
          <Col ratio={1} screen="lg"></Col>
          <Col ratio={1} screen="lg"></Col>
          <Item>
            <Location row={1} col={0} colspan={5} screen="lg"></Location>
            <Location row={8} col={0} rowspan={1} screen="sm"></Location>
            <div className={"content-block dx-card px-item"}>
              <div className="px-pricewall-container">
                <ScrollView
                  direction="both"
                  id="scrollview"
                  scrollByContent={true}
                  showScrollbarMode={"always"}
                >
                  <div
                    className="px-pricewall-body text-content"
                    id="px-pricewall-table"
                  >
                    <div className="px-pricewall-exchanges">
                      <CommonCell noContent={true} />
                      {priceWallData?.exchanges.map((item, index) => {
                        return (
                          <CommonCell
                            key={index}
                            item={item.exchange}
                            siteMode={"Practice"}
                            accMode={accMode}
                            activeCompetition={false}
                            type="exchange"
                          />
                        );
                      })}
                    </div>
                    <div
                      className="px-pricewall-content"
                      id="gridContainer"
                      style={{ width: "auto" }}
                    >
                      <div className="px-pricewall-header">
                        {/* <CommonCell noContent={true} />  */}
                        {priceWallData?.markets.map((item, index) => {
                          return (
                            <CommonCell
                              key={index}
                              item={item.market}
                              type="market"
                            />
                          );
                        })}
                      </div>
                      {priceWallData &&
                        priceWallData.prices &&
                        Object.keys(priceWallData?.prices).map(
                          (item, index) => {
                            return (
                              <div
                                key={item + "-" + index}
                                className="px-pricewall-header"
                                id={
                                  item && item === "IMERS"
                                    ? "tour_selectMarket"
                                    : ""
                                }
                              >
                                {priceWallData?.prices[item]?.map(
                                  (value, priceIndex) => {
                                    let oldValue = null;
                                    if (oldData) {
                                      if (
                                        oldData[item] &&
                                        oldData[item].indexOf === priceIndex
                                      ) {
                                        oldValue = oldData[item].price;
                                      }
                                    }
                                    if (!oldValue) oldValue = value;
                                    return (
                                      <ChangeCell
                                        isActiveMarket={
                                          item && item === "IMERS"
                                            ? true
                                            : false
                                        }
                                        key={priceIndex}
                                        value={value}
                                        oldValue={oldValue}
                                        siteMode={siteMode}
                                        accMode={accMode}
                                        activeCompetition={
                                          item && item === "IMERS"
                                            ? true
                                            : false
                                        }
                                        selectChart={null}
                                        handleChangeCell={() =>
                                          handleChangeCell(
                                            priceIndex,
                                            item,
                                            priceWallData?.markets?.[priceIndex]
                                              ?.market,
                                            oldValue
                                          )
                                        }
                                      />
                                    );
                                  }
                                )}
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                </ScrollView>
              </div>
            </div>
          </Item>

          <Item>
            <Location row={3} col={0} colspan={1} screen="lg"></Location>
            <Location row={8} col={0} rowspan={1} screen="sm"></Location>
            <div
              id="tour_competitionPanel"
              className={"content-block dx-card px-item px-accounts-table"}
              style={{ maxHeight: 400, overflow: "auto" }}
            >
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "space-between",
                  height: "30px",
                  width: "100%",
                  marginBottom: 30,
                }}
              >
                <p
                  className="px-title margin-bottom-50"
                  style={{ fontWeight: "bold" }}
                >
                  COMPETITION PANEL
                </p>
                {joinedCompetition && (
                  <div>
                    {joinedCompetition?.status === "Open" && (
                      <>
                        <p style={{ fontSize: 16, fontWeight: 600 }}>
                          AWAITING PLAYERS:{" "}
                        </p>
                        <p
                          id="time"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 35,
                          }}
                        >
                          00:{awaitingTimer}
                        </p>
                      </>
                    )}
                    {joinedCompetition?.status === "Locked" && (
                      <>
                        <p style={{ fontSize: 16, fontWeight: 600 }}>
                          TIME TO START:{" "}
                        </p>
                        <p
                          id="time"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 35,
                          }}
                        >
                          01:00
                        </p>
                      </>
                    )}
                    {joinedCompetition?.status === "Active" && (
                      <>
                        <p style={{ fontSize: 16, fontWeight: 600 }}>
                          TIME REMAINING:{" "}
                        </p>
                        <p
                          id="time"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 35,
                          }}
                        >
                          00:{reminingTimer}
                        </p>
                      </>
                    )}
                  </div>
                )}
              </div>
              <>
                {["Open", "Active"].includes(joinedCompetition?.status) ? (
                  <table className="px-competition-table">
                    <thead>
                      <tr>
                        <th>RANK</th>
                        <th>TRADER</th>
                        <th>PROFIT</th>
                      </tr>
                    </thead>
                    <tbody>
                      {entrants?.map((item) => (
                        <tr key={item.user_id}>
                          <td>{item.rank}</td>
                          <td>{item.game_name}</td>
                          <td>{Math.floor(item.balance)}</td>
                        </tr>
                      ))}
                      {(!entrants ||
                        (entrants &&
                          mockCompetitionData[0].entrants.length === 0)) && (
                        <tr className="no-data">
                          <td colSpan={10}>No data</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                ) : (
                  <table className="px-competition-table">
                    <thead>
                      <tr>
                        <th>STATUS</th>
                        <th>DURATION</th>
                        <th>END TIME</th>
                        <th>ENTRANTS</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {mockCompetitionData?.map((item, index) => (
                        <tr key={index}>
                          <td>{item.status}</td>
                          <td>{item.duration} Min</td>
                          <td>
                            {item.status.toLowerCase() !== "pending"
                              ? new Date(
                                  item.end_time * 1000
                                ).toLocaleTimeString()
                              : "-"}
                          </td>
                          <td>{item.entrants?.length}</td>
                          <td>
                            {item.status.toLowerCase() === "open" && (
                              <Button
                                id="tour_joinButton"
                                className="px-join-btn"
                                type="default"
                                text="Join"
                                onClick={onClickJoinButton}
                              ></Button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </>
            </div>
          </Item>
          {/* ORDER PANEL */}
          <Item>
            <Location row={3} col={1} colspan={1} screen="lg"></Location>
            <Location row={8} col={1} rowspan={1} screen="sm"></Location>
            <div
              className={"content-block dx-card px-item px-accounts-table"}
              style={{ maxHeight: 400, overflow: "auto" }}
            >
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  width: "100%",
                  height: 30,
                  marginBottom: 30,
                }}
              >
                <p
                  className="px-title margin-bottom-50"
                  style={{ fontWeight: "bold" }}
                >
                  POSITION PANEL
                </p>
              </div>
              <table className="px-competition-table">
                <thead>
                  <tr>
                    <th>CASH POSITIONS</th>
                    <th>QUANTITY</th>
                    <th>USD PRICE</th>
                    <th>USD VALUE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="no-data">
                    <td colSpan={10}>No data</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Item>
          {/* POSITION PANEL */}
          <Item>
            <Location row={3} col={2} colspan={1} screen="lg"></Location>
            <Location row={8} col={1} rowspan={1} screen="sm"></Location>
            <div
              className={"content-block dx-card px-item px-accounts-table"}
              style={{ maxHeight: 400, overflow: "auto" }}
            >
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  width: "100%",
                  height: 30,
                  marginBottom: 30,
                }}
              >
                <p
                  className="px-title margin-bottom-50"
                  style={{ fontWeight: "bold" }}
                >
                  ORDER PANEL
                </p>
              </div>

              <table className="px-competition-table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>TYPE</th>
                    <th>BUY/SELL</th>
                    <th>QUANTITY</th>
                    <th>EXCHANGE</th>
                    <th>INSTRUMENT</th>
                    <th>PRICE</th>
                    <th>STATUS</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {createdOrderData &&
                    Object.values(createdOrderData).length > 0 && (
                      <tr className={`tr-status-filled`}>
                        <td>{createdOrderData.orderId}</td>
                        <td>{createdOrderData.order_type}</td>
                        <td>{createdOrderData.buysell}</td>
                        <td>{createdOrderData.quantity}</td>
                        <td>{createdOrderData.exchange}</td>
                        <td>{createdOrderData.market}</td>
                        <td>{createdOrderData.price_level}</td>
                        <td>{createdOrderData.status_str.toUpperCase()}</td>
                        <td
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          CANCEL
                        </td>
                      </tr>
                    )}
                  {(!createdOrderData ||
                    Object.keys(createdOrderData).length === 0) && (
                    <tr className="no-data">
                      <td colSpan={10}>No data</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Item>
        </ResponsiveBox>

        <Popup
          visible={isOrderPad}
          width={800}
          height={500}
          position={"center"}
          showCloseButton={true}
          closeOnOutsideClick={false}
          contentRender={renderPopupContent}
          onHiding={() => setIsOrderPad(false)}
          title={``}
        />

        {competitionEnd && (
          <CompetitionEndDialog
            history={history}
            dispatch={dispatch}
            entrants={entrants}
            closePopup={() => setCompetitionEnd(false)}
          />
        )}
      </div>
    </Fragment>
  );
};

const CompetitionEndDialog = ({ dispatch, entrants, closePopup }) => {
  const rankOne = entrants?.[0];
  const rankingsDataExceptTopOne = entrants.filter(
    (item) => item.rank !== rankOne.rank
  );
  const myRank = entrants.find(
    (item) => item.user_id === rankOne.user_id
  )?.rank;

  const handleValuesWithSuffix = (value) => {
    let parsedValue = parseInt(value);
    let numberStr = "";

    if (parsedValue >= 10 && parsedValue < 20) {
      numberStr = parsedValue + "th";
    } else {
      const numberMod = parsedValue % 10;
      switch (numberMod) {
        case 1:
          numberStr = parsedValue + "st";
          break;
        case 2:
          numberStr = parsedValue + "nd";
          break;

        case 3:
          numberStr = parsedValue + "rd";
          break;
        default:
          numberStr = parsedValue + "th";
          break;
      }
    }

    return numberStr;
  };

  /**
   * RENDER CONTENT FOR WINNER POPUP
   */

  const renderContent = () => {
    return (
      <div id="tour_competitionEndContent">
        <div className="px-header">
          <div
            style={{
              backgroundImage: `url(${CompetitionEndMessageIcon})`,
              height: 250,
              backgroundSize: "contain",
              backgroundColor: "#143A65",
              position: "relative",
            }}
          >
            <p
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                textAlign: "center",
                fontSize: 20,
                color: rankOne.rank === 1 ? "green" : "yellow",
                marginBottom: 5,
              }}
            >
              1st Place - {rankOne?.game_name}
            </p>
          </div>
        </div>
        <div className="px-content">
          <ul
            style={{
              listStyle: "none",
              padding: 0,
            }}
          >
            {rankingsDataExceptTopOne &&
              rankingsDataExceptTopOne.length > 0 &&
              rankingsDataExceptTopOne.map((item) => {
                // Since original data was spliced to pick up the first rank.
                // We need to plus 1 to filter correctly.
                if (item.rank === 2 || item.rank === 3) {
                  return (
                    <li
                      key={item.rank}
                      style={{
                        backgroundColor:
                          myRank === item.rank ? "green" : "#143A65",
                        padding: "5px 10px",
                        color: "white",
                        marginBottom: 5,
                        textAlign: "center",
                      }}
                    >
                      {handleValuesWithSuffix(item.rank)} - {item.game_name}
                    </li>
                  );
                }
                if (item.rank === 4 && myRank > 4) {
                  return (
                    <li
                      key={item.rank}
                      style={{
                        backgroundColor: "#143A65",
                        padding: "5px 10px",
                        color: "white",
                        marginBottom: 5,
                        textAlign: "center",
                      }}
                    >
                      {"..."}{" "}
                    </li>
                  );
                }
                if (item.rank > 4 && myRank === item.rank) {
                  return (
                    <li
                      key={item.rank}
                      style={{
                        backgroundColor:
                          myRank === item.rank ? "green" : "#143A65",
                        padding: "5px 10px",
                        color: "white",
                        marginBottom: 5,
                        textAlign: "center",
                      }}
                    >
                      {handleValuesWithSuffix(item.rank)} - {item.game_name}{" "}
                    </li>
                  );
                }
                return <></>;
              })}
          </ul>
        </div>
        <Button
          onClick={() => {
            dispatch(setRedirectedFromDemo(true));
            dispatch(startStopTour(true));
            closePopup();
          }}
          text="OK"
          type="primary"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      </div>
    );
  };

  return (
    <Popup
      visible={true}
      width={300}
      height={550}
      position={"center"}
      showCloseButton={false}
      closeOnOutsideClick={false}
      contentRender={renderContent}
      className={"px-competition-end-message-popup"}
      titleRender={(e) => (
        <div style={{ width: "100%" }}>
          <p
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 26,
              margin: "15px 15px",
            }}
          >
            WINNERS
          </p>
        </div>
      )}
    />
  );
};

export default DemoTradingDashboard;
