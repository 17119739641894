import React from "react";
import DataGrid, {
  Column,
  Editing,
  Scrolling,
  Pager,
  Paging,
} from "devextreme-react/data-grid";
import { ALLOWED_PAGE_SIZES_SMALL } from "../../constants";

const BillingAccounts = ({ accounts, handleRemoveRow,  }) => {
  return (
      <DataGrid
        className={"dx-card wide-card px-table"}
        dataSource={accounts}
        showBorders={true}
        keyExpr="account_id"
        focusedRowEnabled={true}
        defaultFocusedRowIndex={false}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        allowEditing={true}
        onRowRemoving={handleRemoveRow}
        calculateCell
      >
        <Scrolling rowRenderingMode="virtual"></Scrolling>
        <Paging defaultPageSize={ALLOWED_PAGE_SIZES_SMALL[0]} />
        <Pager
          visible={true}
          allowedPageSizes={ALLOWED_PAGE_SIZES_SMALL}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Column
          dataField={"account"}
          caption={"ACCOUNT REFERENCE"}
          hidingPriority={2}
        />
        <Column
          dataField={"account_status_name"}
          caption={"ACCOUNT STATUS"}
          hidingPriority={2}
          style={{ cursor: "pointer" }}
        />
        <Column
          dataField={"account_balance"}
          caption={"ACCOUNT BALANCE"}
          hidingPriority={2}
          style={{ cursor: "pointer" }}
        />
        <Editing
          mode="row"
          allowDeleting={true}
          useIcons={true}
        />
      </DataGrid>
  );
};
export default BillingAccounts;