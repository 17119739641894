import React, { Fragment, useState, useEffect } from "react";
import useWebSocket from "react-use-websocket";
import { useDispatch, useSelector } from "react-redux";

import { useSocialContext } from "../../../contexts/socialDashboard";
import ActiveCompetitions from "../../../components/social-dashboard/ActiveCompetitions";
import AvailableCompetitions from "../../../components/social-dashboard/AvailableCompetition";
import { get } from "../../../utils/storage";
import { MAIN_TOKEN_KEY } from "../../../constants";

import GroupIcon from "../../../assets/imgs/icon-friends.png";
import MessageIcon from "../../../assets/imgs/icon-message.png";
import { tradeApi } from "../../../service/api";

import "./SocialDashboard.scss";

const SocialDashboard = () => {
  const dispatch = useDispatch();
  const siteMode = useSelector((state) => state.auth.mode);

  const user = useSelector((state) => state.auth.info);

  const { counts, setOpenSocialDialog } = useSocialContext();

  // const [connectedWSS, setConnectedWSS] = useState(false);
  const [competitions, setCompetitions] = useState([]);
  const [groupRules, setGroupRules] = useState(null);
  const [joinedCompetition, setJoinedCompetition] = useState([]);

  const token = get(MAIN_TOKEN_KEY);
  let wsBaseUrl = "ss-wip.panxora.com";

  if (!window.location.host.includes("localhost"))
    wsBaseUrl = window.location.host;

  let wsCompetitionClient = new useWebSocket(
    `wss://${wsBaseUrl}/competitionws/`,
    {
      onOpen: () => {
        // setConnectedWSS(true);
        const filteredToken = token.split('"')[1];

        wsCompetitionClient.sendMessage(`${filteredToken}`);
        document.addEventListener("visibilitychange", func, true);
      },
      onClose: () => {
        // setConnectedWSS(false);
        window.removeEventListener("visibilitychange", func, false);
      },
      shouldReconnect: (closeEvent) => true,
      onMessage: async (event) => {
        let rules = groupRules;
        if (!rules) {
          try {
            rules = await tradeApi.get("/competition_groups");
            rules = rules.data.message;
            setGroupRules(rules);
          } catch (error) {}
        }
        let allComp = JSON.parse(event?.data).competitions;
        let joinedList = JSON.parse(event?.data).player.competition_id_list;

        if (rules) {
          allComp = findRule(allComp, rules, false);

          if (joinedList && Array.isArray(joinedList)) {
            joinedList = allComp
              .filter(
                (c) =>
                  joinedList.findIndex(
                    (j) => c.competition_id === j.competition_id
                  ) > -1
              )
              .map((c) => {
                const joinData = joinedList.find(
                  (j) => c.competition_id === j.competition_id
                );

                return {
                  ...c,
                  ...joinData,
                };
              });

            joinedList = findRule(joinedList, rules, true);
          }
        }
        let data = allComp.filter(
          (item) =>
            ["Open", "Pending"].includes(item.status) ||
            (["Open", "Pending", "Active"].includes(item.status) &&
              item.group_type === 2)
        );

        setJoinedCompetition(joinedList);
        setCompetitions(filterByRule(joinedList, data));
      },
    }
  );

  const func = () => {
    if (document.hidden) {
      wsCompetitionClient.sendMessage("unsubscribe");
      console.log(
        "Browser tab is hidden and unsubscribe action should be here"
      );
    } else {
      console.log("Browser tab is visible");
    }
    // eslint-disable-next-line
  };

  useEffect(() => {
    if (!siteMode) return;

    if (siteMode === "cybertools") {
      setCompetitions(null);
      wsCompetitionClient.sendMessage("unsubscribe");
    }
    // eslint-disable-next-line
  }, [siteMode]);

  const filterByRule = (joinedList, compList) => {
    if (!joinedList || joinedList.length === 0) return compList;

    const groupOneExist = joinedList?.findIndex((j) => j.group_type === 1) > -1;

    return compList.filter((c) =>
      groupOneExist &&
      c.group_type === 1 &&
      c.status.toLowerCase() !== "pending"
        ? false
        : true
    );
  };

  const findRule = (aCompetitions, rules, forJoin) => {
    if (aCompetitions?.length === 0 || !rules) return [];
    const type = aCompetitions.map((_comp) => {
      const comp_type = rules.competition_types.find(
        (item) => item.competition_type === _comp.competition_type_id
      );
      const group_type = rules.group_types.find(
        (item) => comp_type && item.group_type === comp_type.group_type
      );

      if (comp_type && group_type) {
        let statuses = group_type.status_id;
        const canJoin = statuses?.find((_i) => {
          const key = Object.keys(_i)[0];
          if (_comp.status_id === parseInt(key)) {
            return true;
          } else {
            return false;
          }
        });

        if (!forJoin) {
          if (canJoin && Object.values(canJoin)[0] === "yes") {
            _comp.canJoin = true;
          } else {
            _comp.canJoin = false;
          }
        }
      }

      _comp = { ..._comp, group_type: group_type.group_type };

      return _comp;
    });
    return type;
  };

  return (
    <Fragment>
      <div className="main-container">
        <div className="grid-container">
          <div
            className="dx-card grid-item"
            onClick={() => setOpenSocialDialog({ open: true, tab: 0 })}
          >
            <p>Friends ({counts?.friendsCount})</p>
            <img src={GroupIcon} alt="Friends" />
          </div>
          <div
            className="dx-card grid-item"
            onClick={() => setOpenSocialDialog({ open: true, tab: 4 })}
          >
            <p>Messages ({counts?.messagesCount})</p>
            <img src={MessageIcon} alt="Messages" />
          </div>
          <div
            className="dx-card grid-item dark-bg"
            // onClick={() => setActiveCompDialog(true)}
          >
            {/* <p>Active Competitions</p>
            <img src={ActiveCompetitionIcon} alt="Active Competition" /> */}
            <ActiveCompetitions
              joinedCompetition={joinedCompetition}
              competitions={competitions}
              siteMode={siteMode}
              dispatch={dispatch}
              user={user}
            />
          </div>
          <div
            className="dx-card grid-item dark-bg"
            // onClick={() => setAvailableCompDialog(true)}
          >
            {/* <p>Available Competitions</p>
            <img src={AvailableCompetitionIcon} alt="Available Competition" /> */}
            <AvailableCompetitions
              joinedCompetition={joinedCompetition}
              setJoinedCompetition={setJoinedCompetition}
              competitions={competitions}
              setCompetitions={setCompetitions}
              dispatch={dispatch}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SocialDashboard;
