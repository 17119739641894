import React from "react";
import {
  Chart,
  Size,
  Series,
  ArgumentAxis,
  CommonSeriesSettings,
  Legend,
  Margin,
  Tooltip,
  Grid,
  Point,
  ValueAxis,
} from "devextreme-react/chart";
import { useScreenSize } from "../../../utils/media-query.js";

const serieSources = [{ value: "percent", name: "Risk Measure" }];

const RiskMeasure90Days = ({ risk_history }) => {
  const screenSize = useScreenSize();

  const newRiskHistory =
    risk_history &&
    risk_history.map((item) => {
      return {
        day: item[0],
        percent: item[1],
      };
    });

  // Display date and account value in the tooltip.
  const customizeTooltip = (pointInfo) => {
    return { text: pointInfo.argument + "\n" + pointInfo.value };
  };

  return (
    <React.Fragment>
      <Chart palette="Violet" dataSource={newRiskHistory} sortingMethod={false}>
        {screenSize.isXSmall && <Size width={280} height={800} />}
        {serieSources.map(function (item) {
          return (
            <Series key={item.value} valueField={item.value} name={item.name} />
          );
        })}
        <CommonSeriesSettings
          argumentField="day"
          type={"line"}
          color={"#1a598f"}
        >
          <Point size={2} />
        </CommonSeriesSettings>
        <Margin bottom={20} />
        <ValueAxis maxValueMargin={0} />
        <ArgumentAxis
          visualRange={[0, 100]}
          valueMarginsEnabled={false}
          discreteAxisDivisionMode="crossLabels"
          inverted={true}
        >
          <Grid visible={true} />
        </ArgumentAxis>
        <Legend
          verticalAlignment="bottom"
          horizontalAlignment="center"
          itemTextPosition="bottom"
        />
        <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
      </Chart>
    </React.Fragment>
  );
};

export default RiskMeasure90Days;
