import SetupAccount from "./SetupAccount";
import EditAccount from "./EditAccount";
import Accounts from "./Accounts";

const routes = [
  {
    indexPage: true,
    component: Accounts,
  },
  {
    path: "/add",
    component: SetupAccount,
  },
  {
    path: "/edit/:accountID",
    component: EditAccount,
  },
];

export default routes;
