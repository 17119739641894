import React, { useRef, useCallback, useState, useEffect } from "react";
import { useHistory, useRouteMatch, Link } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CompareRule,
} from "devextreme-react/form";
import TextBox from "devextreme-react/text-box";
import LoadIndicator from "devextreme-react/load-indicator";
import { api } from "../../service";
import Error from "../../components/Error";
import "./RegisterForm.scss";

const SetupPasswordForm = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const formData = useRef({});
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [passwordMode, setPasswordMode] = useState("password");

  const passwordEditorOptions = {
    stylingMode: "filled",
    placeholder: "Password",
    mode: passwordMode,
    buttons: [
      {
        name: "password",
        location: "after",
        options: {
          icon: "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/icons/eye.png",
          type: "default",
          onClick: () => {
            const mode =
            passwordMode === "text" ? "password" : "text";
            setPasswordMode(mode)
          },
        },
      },
    ],
  };
  
  const confirmPasswordEditorOptions = {
    stylingMode: "filled",
    placeholder: "Confirm Password",
    mode: "password",
  };

  useEffect(() => {
    const fetchEmail = async () => {
      const { data } = await api.get(`/auth/invites/email`, {
        headers: {
          "X-RMaaS-Token": match.params.token,
        },
      });
      setEmail(data[0].email_address);
    };
    fetchEmail();
  }, [match.params.token]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      const { email, password } = formData.current;
      try {
        await api.post(
          `/auth/invites/confirm`,
          {
            email_address: email,
            password,
          },
          {
            headers: {
              "X-RMaaS-Token": match.params.token,
            },
          }
        );
        setError("");
        setLoading(false);
        history.push("/login");
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : { message: error.message };
        setError(message);
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [match.params.token]
  );

  const passwordComparison = () => {
    return formData.current.password;
  };

  return (
    <>
      {/* <div className="dx-field px-email-container" style={{ margin: "0 0 0 80px" }}> */}
      <div className="dx-field px-email-container">
        <div
          className="dx-field-value"
          style={{ width: "500px", margin: "10px 0 40px 0" }}
        >
          <TextBox value={email} />
        </div>
      </div>
      <form className={"px-account-form"} onSubmit={onSubmit}>
        {!!error && <Error>{error}</Error>}
        <Form formData={formData.current} disabled={loading}>
          <Item
            dataField={"password"}
            editorType={"dxTextBox"}
            cssClass={"px-acc-password"}
            editorOptions={passwordEditorOptions}
          >
            <RequiredRule message="Password is required" />
            <Label visible={false} />
          </Item>
          <Item
            dataField={"confirmPassword"}
            editorType={"dxTextBox"}
            editorOptions={confirmPasswordEditorOptions}
          >
            <RequiredRule message="Confirm Password is required" />
            <CompareRule
              message="Password and Confirm Password do not match"
              comparisonTarget={passwordComparison}
            />
            <Label visible={false} />
          </Item>
          <Item>
            <span className={"description no-margin"}>
              Password must include at least one upper case letter, one lower
              case letter, one digit and one special character. Non-ASCII
              characters are not allowed.
            </span>
          </Item>
          <ButtonItem>
            <ButtonOptions
              width={"100%"}
              type={"default"}
              useSubmitBehavior={true}
            >
              <span className="dx-button-text">
                {loading ? (
                  <LoadIndicator
                    width={"24px"}
                    height={"24px"}
                    visible={true}
                  />
                ) : (
                  "COMPLETE REGISTRATION"
                )}
              </span>
            </ButtonOptions>
          </ButtonItem>
          <Item>
            <div className={"link center"}>
              Return to
              <Link to={"/login"} className={"normal"}>
                account login
              </Link>
            </div>
          </Item>
        </Form>
      </form>
    </>
  );
};


export default SetupPasswordForm;
