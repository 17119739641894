import React, { useRef, useState } from "react";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Popup } from "devextreme-react/popup";
import Form, {
  Item,
  GroupItem,
  ButtonItem,
  Label,
  ButtonOptions,
} from "devextreme-react/form";
import Error from "../../components/Error";
import { defaultOptions } from "../../constants";

const AddressPopup = ({
  dispatchUpdateOrAddAddress,
  handleCloseAddressPopup,
  initialAddress,
  phoneCodes,
}) => {
  const formData = useRef({});
  const [loading, setLoading] = useState(false);
  const [addressInfo, setAddressInfo] = useState(initialAddress);
  const [error, setError] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const values = formData.current;
      // To avoid calling the same api.
      setAddressInfo(values);

      await dispatchUpdateOrAddAddress(values);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const renderContent = () => {
    return (
      <div>
        <form className="px-form" onSubmit={handleSubmit}>
          {!!error && <Error>{error}</Error>}
          <Form formData={formData.current} showColonAfterLabel={false} className={"expanded"}>
            <Item
              dataField="address_line1"
              editorOptions={{
                ...defaultOptions,
                value: addressInfo && addressInfo.address_line1,
              }}
              isRequired={true}
            >
              <Label visible={true} text="ADDRESS l" />
            </Item>
            <GroupItem colCount={2}>
              <Item
                dataField="address_line2"
                editorOptions={{
                  ...defaultOptions,
                  value: addressInfo && addressInfo.address_line2,
                }}
                isRequired={false}
              >
                <Label visible={true} text="ADDRESS 2" />
              </Item>
              <Item
                dataField="address_line3"
                editorOptions={{
                  ...defaultOptions,
                  value: addressInfo && addressInfo.address_line3,
                }}
                isRequired={false}
              >
                <Label visible={true} text="ADDRESS 3" />
              </Item>
            </GroupItem>
            <GroupItem colCount={2}>
              <Item
                dataField="city"
                editorOptions={{
                  ...defaultOptions,
                  value: addressInfo && addressInfo.city,
                }}
                isRequired={true}
              >
                <Label visible={true} text="CITY" />
              </Item>
              <Item
                dataField={"state"}
                editorOptions={{
                  ...defaultOptions,
                  value: addressInfo && addressInfo.state,
                }}
                isRequired={true}
              >
                <Label visible={true} text="STATE" />
              </Item>
              <Item
                dataField="postal_code"
                editorOptions={{
                  ...defaultOptions,
                  value: addressInfo && addressInfo.postal_code,
                }}
                isRequired={true}
              >
                <Label visible={true} text="POSTAL CODE" />
              </Item>
              <Item
                dataField="country_code"
                editorType="dxSelectBox"
                editorOptions={{
                  ...defaultOptions,
                  searchEnabled: true,
                  searchExpr: ["country_name", "country_code"],
                  inputAttr: {
                    autocomplete: "new-address"
                  },
                  value: addressInfo && addressInfo.country_code,
                  items: phoneCodes,
                  valueExpr: "country_code",
                  itemTemplate: function (data) {
                    return data.country_name + " (" + data.country_code + ")";
                  },
                  displayExpr: function (data) {
                    if (data) return data.country_code;
                  },
                }}
                isRequired={true}
              >
                <Label visible={true} text="COUNTRY CODE" />
              </Item>
            </GroupItem>
            <GroupItem colCount={2}>
              <ButtonItem horizontalAlignment="left">
                <ButtonOptions
                  width="100%"
                  text="Add"
                  type="default"
                  useSubmitBehavior={true}
                >
                  <span className="dx-button-text">
                    {loading ? (
                      <LoadIndicator
                        width={"24px"}
                        height={"24px"}
                        visible={true}
                      />
                    ) : (
                      "UPDATE"
                    )}
                  </span>
                </ButtonOptions>
              </ButtonItem>
              <ButtonItem horizontalAlignment="right">
                <ButtonOptions
                  width="100%"
                  type="normal"
                  text="Cancel"
                  useSubmitBehavior={false}
                  onClick={handleCloseAddressPopup}
                />
              </ButtonItem>
            </GroupItem>
          </Form>
        </form>
      </div>
    );
  };

  return (
    <Popup
      visible={true}
      width={700}
      height={630}
      position={"center"}
      showCloseButton={false}
      closeOnOutsideClick={false}
      contentRender={renderContent}
      title={`Address Information`}
    />
  );
};

export default AddressPopup;
