export const MAIN_TOKEN_KEY = "PANXORA_SS_TOKEN_KEY";
export const LASTPATH = "PANXORA_LAST_PATH";
export const IDLE_USER_LIMIT = 7200000;
export const CONFIRM_CODE_SUCCESS_MSG =
  "The Code has been confirmed successfully!";
export const DEVICE_REGISTERED_SUCCESS_MSG =
  "The Device has been registered successfully!";
export const DEVICE_REGISTERED_FAIL_MSG =
  "The pairing code you have entered is invalid. Please re-enter a new valid pairing code.";
export const DEVICE_REGISTERED_TIMED_OUT_MSG =
  "The pairing code enter had timed-out. Please re-enter a new valid pairing code.";
export const UPDATE_PASSWORD_SUCCESS_MSG =
  "The Password has been updated successfully!";
export const CREATE_ACCOUNT_INFORMATION =
  "The Account information has been saved successfully. Testing the API key will commence shortly.";
export const UPDATE_ACCOUNT_INFORMATION =
  "The Account information has been updated successfully. Testing the API key will commence shortly.";
export const SETUP_ACCOUNT_SUCCESS_MSG =
  "Congratulation! The account has been created successfully.";
export const DELETE_ACCOUNT_MSG = "The Account has been removed successfully!";
export const PROFILE_UPDATE_MSG = "The Profile has been updated successfully!";
export const IMERS_PROGRESS_MSG =
  "You have made it to the one quarter mark! Looking good!";
export const PUBLIC_TOKEN = "ABCBA-99999";
export const THEME_STATE = "THEME_STATE";
export const TRADING_CHART_STATE = "TRADING_CHART_STATE";
export const DEFAULT_TOAST_TIME = 2000;
export const RESET_TWO_FACTOR = "RESET_TWO_FACTOR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const MENU_ITEMS = [
  // {
  //   id: "1",
  //   name: "CYBER TOOLS",
  //   parent: true,
  //   items: [
  //     {
  //       id: "1_1",
  //       name: "Risk Management as a Service",
  //       price: 220,
  //     },
  //     {
  //       id: "1_2",
  //       name: "Basket Trading | ETF Builder",
  //       price: 270,
  //     },
  //     {
  //       id: "1_3",
  //       name: "CyberTools Library",
  //       price: 270,
  //     },
  //   ],
  // },
  // {
  //   id: "2",
  //   name: "PANXORA. LABS",
  //   parent: true,
  //   items: [
  //     {
  //       id: "2_1",
  //       name: "Market Making Services",
  //       // icon: "images/products/7.png",
  //       price: 1200,
  //     },
  //     {
  //       id: "2_2",
  //       name: "Model Technology",
  //       price: 1450,
  //     },
  //     // {
  //     //   id: "2_4",
  //     //   name: "SuperLCD 55 (Not available)",
  //     //   price: 1350,
  //     //   disabled: true,
  //     // },
  //   ],
  // },
  // {
  //   id: "3",
  //   name: "NEWS & INSIGHTS",
  //   parent: true,
  //   items: [
  //     {
  //       id: "3_1",
  //       name: "News & Insights",
  //     },
  //     {
  //       id: "3_2",
  //       name: "Panxora in the news",
  //       // items: [
  //       //   {
  //       //     id: "3_2_1",
  //       //     name: "DesktopLCD 21",
  //       //     price: 170,
  //       //   },
  //       //   {
  //       //     id: "3_2_2",
  //       //     name: "DesktopLED 21",
  //       //     price: 175,
  //       //   },
  //       // ],
  //     },
  //   ],
  // },
  {
    id: "4",
    name: "CONTACT US",
    url: "https://cybertools.club/contact-us/",
    parent: false,
    items: [],
  },
  {
    id: "5",
    name: "VR REGISTRATION",
    url: "/vr-register",
    parent: false,
    items: [],
  },
  // {
  //   id: "4",
  //   name: "JOIN NOW",
  //   parent: false,
  //   items: [],
  // },
  // {
  //   id: "4",
  //   name: "SIGN IN",
  //   parent: false,
  //   items: [],
  // },
];
export const TRADE_OPTIONS = [
  {
    id: 1,
    title: "View Top 100 Rows",
    amount: 100,
  },
  {
    id: 2,
    title: "View All Rows",
    amount: 0,
  },
];

// API_STATUS_NAMES
export const API_STATUS_PENDING = 1;
export const API_STATUS_SUCCEEDED = 4;

// ACCOUNT_STATUS_NAMES
export const ACCOUNT_STATUS_SUSPENDED = "Suspended";
export const ACCOUNT_STATUS_PENDING = "Pending Setup";

// ACCOUNT ACTION NAMES
export const ACCOUNT_ACTIONS = [
  {
    id: 1,
    name: "ACTIVATE ACCOUNT",
    icon: "unpin",
    value: 1,
  },
  {
    id: 2,
    name: "SUSPEND ACCOUNT",
    icon: "pin",
    value: 2,
  },
];

export const PANXORA_PUBLIC_LOGO = "/company/imers/IMERS-land-trans.png";
export const PANXORA_COMPANY_LOGO = "panxora-rmaas-gui-brand.png";
export const PANXORA_WORDPRESS_LOGO_URL = "company/panxora/wp-panxora-logo.png";
export const PANXORA_TOP_LEFT_IMERS_LOGO = "/company/imers/v2/imers-logo.png";
export const PANXORA_TOP_LEFT_CYBERTOOLS_LOGO =
  "/company/cybertools/Cybertools-logo-white-trans.png";
export const PANXORA_BOTTOM_RIGHT_IMERS_LOGO =
  "/company/imers/v2/imers-land-logo.png";
export const PANXORA_BOTTOM_RIGHT_CYBERTOOLS_LOGO =
  "/company/cybertools/Cybertools-logo-trans.png";

export const ALLOWED_PAGE_SIZES_BIG = [5, 25, 0];
export const ALLOWED_PAGE_SIZES_SMALL = [5, 10, 0];
export const ALLOWED_PAGE_SIZES_MEDIUM = [10, 25, 0];
export const SOURCE_TYPE = [
  {
    label: "RMaaS",
    value: "RMaaS",
  },
  {
    label: "LEAVE",
    value: "LEAVE",
  },
];
export const ADVANCED_ACCOUNT_TABS = [
  {
    id: 0,
    text: "Account Information",
    icon: "user",
  },
  {
    id: 1,
    text: "Asset Allocation",
    icon: "share",
  },
  {
    id: 2,
    text: "Advanced Asset Management",
    icon: "money",
  },
  {
    id: 3,
    text: "Account Stats",
    icon: "chart",
  },
];

export const BASIC_ACCOUNT_TABS = [
  {
    id: 0,
    text: "Account Information",
    icon: "user",
  },
  {
    id: 1,
    text: "Asset Allocation",
    icon: "share",
  },
  {
    id: 2,
    text: "Account Stats",
    icon: "chart",
  },
];
export const RADIO_TARGET_TYPES = [
  {
    id: 1,
    label: "BASKET TRADING",
    value: "target_percentage",
  },
  {
    id: 2,
    label: "RISK MANAGEMENT",
    value: "target_quantity",
  },
];
export const BASIC_SETUP_MODE_STEPPER = [
  "ACCOUNT INFORMATION",
  "API KEY",
  "STRATEGY",
  "COMPLETE SETUP",
];
export const ADVANCED_SETUP_MODE_STEPPER = [
  "ACCOUNT INFORMATION",
  "API KEY",
  "STRATEGY",
  "ASSET ALLOCATION",
  "COMPLETE SETUP",
];

export const COLOR_PALETTE = [
  "#97C22C",
  "#81A6C1",
  "#0A3557",
  "#EACA53",
  "#35948F",
  "#1A598F",
];

export const serieSources = [{ value: "balance", name: "Balance" }];
export const communityTabs = [
  {
    id: 0,
    text: "AVAILABLE",
    icon: "",
  },
  {
    id: 1,
    text: "SUBSCRIBED",
    icon: "",
  },
];
export const settingsTabs = [
  {
    id: 0,
    text: "USER DETAILS",
    icon: "user",
  },
  {
    id: 1,
    text: "NOTIFICATIONS",
    icon: "message",
  },
  {
    id: 2,
    text: "AFFILIATES",
    icon: "group",
  },
  {
    id: 3,
    text: "Two-Factor Authentication",
    icon: "product",
  },
  {
    id: 4,
    text: "Change Password",
    icon: "key",
  },
  {
    id: 5,
    text: "Manage Billing",
    icon: "bold",
    disabled: true,
  },
];

export const list = [
  {
    date_time: "10.02.2021 12:10:10 UTC",
    buy_sell: "BUY",
    quantity: "1.11334871",
    trading_pair: "ADAUSD",
    price: "1.14",
  },
  {
    date_time: "10.02.2021 12:08:10 UTC",
    buy_sell: "BUY",
    quantity: "12.11334871",
    trading_pair: "ADAUSD",
    price: "1.10",
  },
  {
    date_time: "10.02.2021 12:02:10 UTC",
    buy_sell: "BUY",
    quantity: "12020.11334871",
    trading_pair: "ADAUSD",
    price: "1.12",
  },
  {
    date_time: "23.01.2021 04:11:04 UTC",
    buy_sell: "BUY",
    quantity: "18023.11334871",
    trading_pair: "BTCUSD",
    price: "38,985.22",
  },
];

export const defaultOptions = {
  stylingMode: "filled",
  placeholder: "",
  mode: "text",
};

export const fNameDefaultOptions = {
  stylingMode: "filled",
  placeholder: "",
  mode: "text",
};

export const lNameDefaultOptions = {
  stylingMode: "filled",
  placeholder: "",
  mode: "text",
};

export const lNickNameDefaultOptions = {
  stylingMode: "filled",
  placeholder: "",
  mode: "text",
};

export const checkBoxOptions = {
  mode: "checkbox",
  value: false,
};

export const addressDefaultOptions = {
  stylingMode: "filled",
  placeholder: "",
  mode: "text",
};

export const emailDefaultOptions = {
  stylingMode: "filled",
  placeholder: "",
  mode: "email",
  value: "starcoma1015@gmail.com",
};

export const countryCodeDefaultOptions = {
  stylingMode: "filled",
  placeholder: "",
  mode: "text",
};

export const phoneDefaultOptions = {
  stylingMode: "filled",
  placeholder: "",
  mode: "number",
};

export const codeEditorOptions = {
  stylingMode: "filled",
  placeholder: "Please enter TOTP code",
  mode: "text",
};

export const accountDefaultOption = {
  stylingMode: "filled",
  placeholder: "",
  mode: "text",
};

export const exchangeDefaultOption = {
  stylingMode: "filled",
  placeholder: "",
  mode: "text",
  valueExpr: "exchange_account_id",
  displayExpr: "exchange_account_name",
};

export const apiKeyDefaultOption = {
  stylingMode: "filled",
  placeholder: "",
  mode: "text",
};

export const apiSecretDefaultOption = {
  stylingMode: "filled",
  placeholder: "",
  mode: "text",
};

export const exchangeMarketDefaultOption = {
  stylingMode: "filled",
  mode: "text",
};

// Forgot password description.
// title="Forgot Password"
// description="Please enter the email address that you used to register, and we will send you a link to reset your password via Email."

export const BASICMODE = "BASIC MODE";
export const EXPERTMODE = "ADVANCED MODE";
export const RISKMANAGEMENTMODE = "RISK MANAGEMENT";
export const SUSPENDACCOUNT = "SUSPEND";
export const ACTIVATEACCOUNT = "ACTIVATE";
export const SETACCOUNTASDEFAULT = "SET ACCOUNT AS DEFAULT";
export const REFRESHDASHBOARD = "REFRESH DASHBOARD";

export const IMERS_MESSAGES = {
  0: { title: "START YOUR JOURNEY TO EARNING BIG PRIZES TODAY" },
  1: {
    title:
      "A JOURNEY OF A 1000 MILES STARTS WITH A SINGLE STEP - YOU'RE DOING GREAT.",
  },
  2: {
    title: "ALREADY 20% IN THE BLACK - THE NEXT 80% WILL BE YOURS IN NO TIME.",
  },
  3: {
    title:
      "ALREADY ONE THIRD OF THE WAY THERE - YOU'LL BE RUNNING A GUILD IN NO TIME.",
  },
  4: {
    title:
      "40% IS JUST SHY OF 50% AND THAT'S HALF THE WAY TO A GUILDHALL IN A CHOICE LOCALE.",
  },
  5: {
    title:
      "YOU ARE HALF WAY THERE. YES, THERE ARE PEOPLE AHEAD OF YOU SO DO NOT STOP NOW!",
  },
  6: { title: "60% IS OVER THE HUMP AND YOU ARE ON YOUR WAY TO VICTORY" },
  7: {
    title:
      "REMEMBER WHEN YOU HAS ONLY 30% OF THE TOKENS YOU NEEDED, NOW YOU ONLY NEED 30% MORE TO REACH YOUR GOAL!",
  },
  8: {
    title:
      "THE CLOCK IS TICKING BUT YOU’VE ONLY GOT 20% MORE IMERS TO STAKE A GUILDHALL",
  },
  9: { title: "10% TO GO, ALMOST THERE" },
  10: {
    title: "WELCOME TO THE CYBERTOOLS METAVERSE. YOU ARE PLAYING TO W-I-N! ",
  },
};

export const CHART_TIMESCALES = [
  { id: 1, name: "1 min", indicator: "1m", text: "1 min" },
  {
    id: 2,
    name: "5 min",
    indicator: "5m",
    text: "5 min",
  },
  {
    id: 3,
    name: "15 min",
    indicator: "15m",
    text: "15 min",
  },
  {
    id: 4,
    name: "hour",
    indicator: "1h",
    text: "1 hour",
  },
  {
    id: 5,
    name: "2 hour",
    indicator: "2h",
    text: "2 hours",
  },
  {
    id: 5,
    name: "4 hour",
    indicator: "4h",
    text: "4 hours",
  },
  {
    id: 6,
    name: "Day",
    indicator: "1d",
    text: "1 Day",
  },
];

export const CHART_TYPES = [
  { id: 1, type: "area", text: "Area" },
  { id: 2, type: "candlestick", text: "Candle Stick" },
  { id: 3, type: "column", text: "Column" },
  { id: 4, type: "jumpLine", text: "Jump Line" },
  { id: 5, type: "line", text: "Line" },
  { id: 6, type: "marker", text: "Marker" },
  { id: 7, type: "ohlc", text: "OHLC" },
  { id: 8, type: "rangeArea", text: "Range Area" },
  { id: 9, type: "rangeColumn", text: "Range Column" },
  { id: 10, type: "rangeSplineArea", text: "Range Spline Area" },
  { id: 11, type: "rangeStepArea", text: "Range Step Area" },
  { id: 12, type: "spline", text: "Spline" },
  { id: 13, type: "splineArea", text: "Spline Area" },
  { id: 14, type: "stepArea", text: "Step Area" },
  { id: 15, type: "stepLine", text: "Step Line" },
  { id: 16, type: "stick", text: "Stick" },
];

export const CHART_INDICATORS = [
  { id: 1, text: "Accumulation Distribution Line", type: "adl" },
  { id: 2, text: "Adaptive Moving Average", type: "ama" },
  { id: 3, text: "Aroon", type: "aroon" },
  { id: 4, text: "Average True Range (ATR)", type: "atr" },
  { id: 5, text: "Bollinger Bands", type: "bbands" },
  { id: 6, text: "Bollinger Bands %B", type: "bbandsB" },
  { id: 7, text: "Bollinger Bands Width", type: "bbandsWidth" },
  { id: 8, text: "Chaikin Money Flow", type: "cmf" },
  { id: 9, text: "Chaikin Oscillator", type: "cho" },
  { id: 10, text: "Commodity Channel Index", type: "cci" },
  { id: 11, text: "Directional Movement Indicator", type: "dmi" },
  { id: 12, text: "Envelope (ENV)", type: "env" },
  { id: 13, text: "Exponential Moving Average (EMA)", type: "ema" },
  { id: 14, text: "Heikin-Ashi", type: "ha" },
  { id: 15, text: "Ichimoku Cloud (IKH)", type: "ikh" },
  { id: 16, text: "KDJ", type: "kdj" },
  { id: 17, text: "Keltner Channels", type: "keltnerChannels" },
  { id: 18, text: "Modified (Smoothed) Moving Average", type: "mma" },
  { id: 19, text: "Momentum", type: "momentum" },
  { id: 20, text: "Money Flow Index (MFI)", type: "mfi" },
  {
    id: 21,
    text: "Moving Average Convergence Divergence (MACD)",
    type: "macd",
  },
  { id: 22, text: "On Balance Volume (OBV)", type: "obv" },
  { id: 23, text: "Parabolic SAR (PSAR)", type: "psar" },
  { id: 24, text: "Price Channels", type: "priceChannels" },
  { id: 25, text: "Price Oscillator (PPO)", type: "ppo" },
  { id: 26, text: "Psychological Line (PSY)", type: "psy" },
  { id: 27, text: "Rank Correlation Index (RCI)", type: "rci" },
  { id: 28, text: "Rate of Change (ROC)", type: "roc" },
  { id: 29, text: "Ratiocator (RAT)", type: "rat" },
  { id: 30, text: "Relative Strength Index (RSI)", type: "rsi" },
  { id: 31, text: "Running Moving Average (RMA)", type: "mma" },
  { id: 32, text: "Simple Moving Average (SMA)", type: "sma" },
  { id: 34, text: "Stochastic Oscillator", type: "stochastic" },
  { id: 35, text: "Triple Exponential Moving Average (TRIX)", type: "trix" },
  { id: 36, text: "Volume + Moving Average", type: "volumeMa" },
  { id: 37, text: "Williams %R", type: "williamsR" },
];

export const CHART_ANNOTATIONS = [
  { id: 2, text: "Andrews' Pitchfork", type: "andrews-pitchfork" },
  { id: 3, text: "Ellipse", type: "ellipse" },
  { id: 4, text: "Fibonacci", type: "fibonacci-arc" },
  { id: 5, text: "Fibonacci Fan", type: "fibonacci-fan" },
  { id: 6, text: "Fibonacci Retracement", type: "fibonacci-retracement" },
  { id: 7, text: "Fibonacci Time Zones", type: "fibonacci-timezones" },
  { id: 8, text: "Horizontal Line", type: "horizontal-line" },
  { id: 9, text: "Infinite Line", type: "infinite-line" },
  { id: 10, text: "Line Segment", type: "line" },
  { id: 11, text: "Marker", type: "marker" },
  { id: 12, text: "Ray", type: "ray" },
  { id: 13, text: "Rectangle", type: "rectangle" },
  { id: 14, text: "Trend Channel", type: "trend-channel" },
  { id: 15, text: "Triangle", type: "triangle" },
  { id: 16, text: "Vertical Line", type: "vertical-line" },
  { id: 16, text: "Label", type: "label" },
];

export const NEW_PLOTS = [
  "kdj",
  "adl",
  "aroon",
  "atr",
  "bbandsB",
  "bbandsWidth",
  "cmf",
  "cho",
  "cci",
  "dmi",
  "dmi",
  "momentum",
  "mfi",
  "macd",
  "obv",
  "ppo",
  "psy",
  "rci",
  "roc",
  "rsi",
  "rat",
  "stochastic",
  "trix",
  "volumeMa",
  "williamsR",
];
