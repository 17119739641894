import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "devextreme-react/button";
import Joyride from "react-joyride";
import { setManagementTypeID } from "../../redux/accounts/action";
import { ProfileFormEditedBefore } from "../../redux/auth/action";

const Strategy = ({
  nextStep,
  isSetup,
  handleManagementType,
  onboardingStatusInfo = null,
}) => {
  const dispatch = useDispatch();
  const [enableOnboarding, setEnableOnboarding] = useState();

  useEffect(() => {
    if (!onboardingStatusInfo) return;
    setEnableOnboarding(onboardingStatusInfo.visible);
  }, [onboardingStatusInfo]);

  const managementTypeID = useSelector(
    (state) => state.accounts.curManagementTypeID
  );
  
  const originalManagementTypeID = useSelector(
    (state) => state.accounts.originalManagementTypeID
  );

  const setManagementType = (value) => {
    dispatch(setManagementTypeID(value));
    // If user is in the setup wizard. then, It takes user to the next step.
    // If user is in the outside of wizard(Edit account screen). then, display the Basic screen by management type.
    if (!isSetup) handleManagementType(value);
    else {
      if (nextStep) nextStep();
    }
  };
  return (
    <div className={"px-strategy"}>
      {isSetup && <Joyride
        continuous={true}
        run={enableOnboarding}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={false}
        steps={[
          {
            title: <p style={{ textAlign: "left" }}>SELECT MANAGEMENT TYPE</p>,
            content: (
              <p style={{ textAlign: "left" }}>
                Allows you to toggle between all your accounts throughout the
                day, looking for opportunities or just to see where you are
                without any AI automatic trading.
              </p>
            ),
            floaterProps: {
              disableAnimation: true,
            },
            spotlightPadding: 10,
            target: ".px-view-only",
            disableBeacon: true,
            placement: "left",
          },
          {
            title: <p style={{ textAlign: "left" }}>SELECT MANAGEMENT TYPE</p>,
            content: (
              <p style={{ textAlign: "left" }}>
                Basket trading allows you to select a basket of assets and
                define fixed ratios between the assets in that basket. When an
                asset rises in value throwing the ratios out of alignment, the
                software sells some of the rising asset, purchasing the others
                to re-establish the original ratio, if possible, locking in
                profit.
              </p>
            ),
            floaterProps: {
              disableAnimation: true,
            },
            spotlightPadding: 10,
            target: ".px-basket",
            disableBeacon: true,
            placement: "bottom",
          },
          {
            title: <p style={{ textAlign: "left" }}>SELECT MANAGEMENT TYPE</p>,
            content: (
              <p style={{ textAlign: "left" }}>
                Risk management is designed to move your crypto investment
                assets into cash when it detects an increase in market
                volatility. It does this 24-hours a day, even when you sleep.
              </p>
            ),
            floaterProps: {
              disableAnimation: true,
            },
            spotlightPadding: 10,
            target: ".px-risk-management",
            disableBeacon: true,
            placement: "right",
          },
        ]}
      />}
      <div
        className={`${
          managementTypeID === 3 ? "px-view-only selected" : "px-view-only"
        }`}
        style={{
          width: `${isSetup ? "300px" : "250px"}`,
          height: `${isSetup ? "300px" : "250px"}`,
        }}
        onClick={() => {
          if (!isSetup && originalManagementTypeID !== 3)
            dispatch(ProfileFormEditedBefore(true));
          else
            dispatch(ProfileFormEditedBefore(false));
          setManagementType(3);
        }}
      >
        <div className={"px-stg-content"}>
          <div className={"icon-container"}></div>
          <Button
            text="VIEW ONLY"
            type="normal"
            width="100%"
            stylingMode="text"
          />
          <div className={"px-description"}></div>
        </div>
        <Button className={"px-selected-type"} icon="check" type="success" />
      </div>
      <div
        className={`${
          managementTypeID === 1 ? "px-basket selected" : "px-basket"
        }`}
        style={{
          width: `${isSetup ? "300px" : "250px"}`,
          height: `${isSetup ? "300px" : "250px"}`,
        }}
        onClick={() => {
          if (!isSetup && originalManagementTypeID !== 1)
            dispatch(ProfileFormEditedBefore(true));
          else
            dispatch(ProfileFormEditedBefore(false));
          setManagementType(1);
        }}
      >
        <div className={"px-stg-content"}>
          <div className={"icon-container"}></div>
          <Button
            text="BASKET / ETF CREATION"
            type="normal"
            width="100%"
            stylingMode="text"
          />
          <div className={"px-description"}>
            <p>Keeping your portfolio in balance</p>
          </div>
        </div>
        <Button className={"px-selected-type"} icon="check" type="success" />
      </div>
      <div
        className={`${
          managementTypeID === 2
            ? "px-risk-management selected"
            : "px-risk-management"
        }`}
        style={{
          width: `${isSetup ? "300px" : "250px"}`,
          height: `${isSetup ? "300px" : "250px"}`,
        }}
        onClick={() => {
          if (!isSetup && originalManagementTypeID !== 2)
            dispatch(ProfileFormEditedBefore(true));
          else
            dispatch(ProfileFormEditedBefore(false));
          setManagementType(2);
        }}
      >
        <div className={"px-stg-content"}>
          <div className={"icon-container"}></div>
          <Button
            text="RISK MANAGEMENT"
            type="normal"
            width="100%"
            stylingMode="text"
          />
          <div className={"px-description"}>
            <p>Avoid the crashes</p>
            <p>and profit takes care of itself</p>
          </div>
        </div>
        <Button className={"px-selected-type"} icon="check" type="success" />
      </div>
    </div>
  );
};
export default Strategy;
