import React from "react";
import "./SingleCard.scss";

const SingleCard = ({ isSecondbannerEnable = true, title, description, children, className = "" }) => {

  return (
    <div className={"px-card-content"}>
      <div style={{
        display: "flex",
        backgroundColor: "#2B1D1A",
        opacity: 0.9,
        padding: "50px 50px"
      }}>
        <div className="px-left-section" style={{ position: "relative" }}>
          <div className="px-logo-view">
            <div
              className={`px-logo px-public-header-icon`}
            ></div>
            {isSecondbannerEnable && <div
              className={`px-logo px-public-content-icon`}
            ></div>}
          </div>
        </div>
        <div className={`${className} px-right-section`}>
          <p className={"title"}>{title}</p>
          {description}
          {children}
        </div>
      </div>
    </div>
  );
}

export default SingleCard;
