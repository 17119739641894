import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "devextreme-react/tabs";
import ProfileForm from "../../../components/profile-settings/ProfileForm";
import Notifications from "../../../components/profile-settings/Notifications";
import AccountPasswordForm from "../../../components/profile-settings/AccountPasswordForm";
import Account2FAForm from "../../../components/profile-settings/Account2FAForm";
import ConfirmationAskingPopup from "../../../components/profile-settings/ConfirmationAskingPopup";
import ManageBilling from "../../../components/profile-settings/ManageBilling";
import AddressPopup from "../../../components/profile-settings/AddressPopup";
import {
  ProfileFormEditedBefore,
  changeUserPassword,
  clear2FA,
  connect,
} from "../../../redux/auth/action";
import {
  addAddress,
  updateAddress,
  getAddressByID,
  getSelfServiceUserByID,
  updateSelfServiceUser,
} from "../../../redux/self-service-user/action";
import { getPhoneCodes } from "../../../redux/references/action";
import { remove, set } from "../../../utils/storage";
import { MAIN_TOKEN_KEY, settingsTabs } from "../../../constants";
import Affiliates from "../../../components/profile-settings/Affiliates";

const AccountInfo = (props) => {
  const { user } = props;

  const dispatch = useDispatch();

  const [openAddressPp, setOpenAddressPp] = useState(false);
  const [openConfirmPp, setOpenConfirmPp] = useState(false);
  const [nextTabIndex, setNextTabIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [addressInfo, setAddressInfo] = useState();
  const [fullAddress, setFullAddress] = useState();
  const [addressID, setAddressID] = useState(null);
  const [isUpdatedBefore, setIsUpdatedBefore] = useState(false);

  const { client_user_id } = useSelector((state) => state.auth.info);
  const isEdited = useSelector((state) => state.auth.isEdited);
  const phoneCodes = useSelector((state) => state.reference.phoneCodes);

  window.onbeforeunload = function (e) {
    if (isEdited) return "Dialog text here.";
  };

  /**
   * Get phone codes and config action in beforeunload.
   */
  useEffect(() => {
    dispatch(getPhoneCodes());
    dispatch(ProfileFormEditedBefore(false));
    window.beforeunload = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };
  }, [dispatch]);

  /**
   * Get self-service user by ID.
   * And set address id to get address info concurrently.
   */
  useEffect(() => {
    dispatch(getSelfServiceUserByID(client_user_id)).then((userData) => {
      setAddressID(userData ? userData.address_id : null);
      setFullAddress(userData?.address);
      setIsUpdatedBefore(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdatedBefore, dispatch]);

  /**
   * Get address info by address id.
   */
  useEffect(() => {
    if (addressID) {
      dispatchAddressByID();
      setIsUpdatedBefore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressID, isUpdatedBefore]);

  const dispatchAddressByID = async () => {
    await dispatch(getAddressByID(client_user_id, addressID)).then((res) => {
      setAddressInfo(res);
      setFullAddress(res.full_address);
    });
  };

  const dispatchChangePassword = async (oldPassword, newPassword, code) => {
    const data = await dispatch(
      changeUserPassword(oldPassword, newPassword, code)
    );
    remove(MAIN_TOKEN_KEY);
    set(MAIN_TOKEN_KEY, data.token);
  };

  const dispatchChange2FA = async (code) => {
    await dispatch(clear2FA(code));
    await dispatch(connect());
  };

  const dispatchSelfServiceUser = async (values) => {
    await dispatch(updateSelfServiceUser(client_user_id, values));
    setIsUpdatedBefore(true);
  };

  const dispatchUpdateOrAddAddress = async (values) => {
    if (addressID) {
      await dispatch(updateAddress(client_user_id, addressID, values)).then((res) => {
        setFullAddress(res.address);
        setAddressInfo(values);
      });
      
    } else {
      await dispatch(addAddress(client_user_id, values)).then((res) => {
        setFullAddress(res.address);
        setAddressInfo(values);
        setAddressID(res.address_id);
      });
    }
    // setIsUpdatedBefore(true);
    setOpenAddressPp(false);
  };

  const onTabsSelectionChanged = (args) => {
    if (args.name === "selectedIndex") {
      if (args.value !== 0 && isEdited) {
        setOpenConfirmPp(true);
        setNextTabIndex(args.value);
      } else {
        setSelectedIndex(args.value);
      }
    }
  };

  const handleCloseAddressPopup = () => {
    setOpenAddressPp(false);
  };

  const handleOpenAddressPopup = () => {
    setOpenAddressPp(true);
  };

  const handleCloseConfirmationPopup = () => {
    setOpenConfirmPp(false);
    dispatch(ProfileFormEditedBefore(false));
    setSelectedIndex(nextTabIndex);
  };

  const handleKeepThePage = () => {
    setSelectedIndex(0);
    setOpenConfirmPp(false);
    dispatch(ProfileFormEditedBefore(true));
  };

  return (
    <React.Fragment>
      <Tabs
        dataSource={settingsTabs}
        selectedIndex={selectedIndex}
        onOptionChanged={onTabsSelectionChanged}
      />

      <div className="content dx-fieldset">
        <div className="dx-field">
          <div className={"dx-field-value width-100"}>
            {selectedIndex === 0 && (
              <ProfileForm
                phoneCodes={phoneCodes}
                fullAddress={fullAddress}
                handleOpenAddressPopup={handleOpenAddressPopup}
                dispatchSelfServiceUser={dispatchSelfServiceUser}
                openAddressPp={openAddressPp}
              />
            )}
            {selectedIndex === 1 && (
              <Notifications />
            )}
            {selectedIndex === 2 && (
              <Affiliates />
            )}
            {selectedIndex === 3 && (
              <Account2FAForm
                dispatchChange2FA={dispatchChange2FA}
                user={user}
              />
            )}
            {selectedIndex === 4 && (
              <AccountPasswordForm
                dispatchChangePassword={dispatchChangePassword}
              />
            )}
            {selectedIndex === 5 && (
              <ManageBilling />
            )}
          </div>
        </div>

        {openAddressPp && (
          <AddressPopup
            handleCloseAddressPopup={handleCloseAddressPopup}
            dispatchUpdateOrAddAddress={dispatchUpdateOrAddAddress}
            initialAddress={addressInfo}
            phoneCodes={phoneCodes}
          />
        )}
        {openConfirmPp && (
          <ConfirmationAskingPopup
            handleClosePopup={handleCloseConfirmationPopup}
            handleKeepThePage={handleKeepThePage}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default AccountInfo;
