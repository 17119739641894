import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from "devextreme-react/responsive-box";
import DataGrid, { Column } from "devextreme-react/data-grid";
import CurrentRiskRating from "./CurrentRiskRating";
import RiskMeasure90Days from "./RiskMeasure90Days";
import HypotheticalComparison from "./HypotheticalComparison";
import { getRiskReportData } from "../../../redux/accounts/action";
import { Button } from "devextreme-react";

function screen(width) {
  return width < 700 ? "sm" : "lg";
}
const RiskReport = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const riskReportInfo = useSelector((state) => state.accounts.riskReportInfo);
  const riskRatingInfo = useSelector((state) => state.accounts.riskRatingInfo);

  const newRiskStats =
    riskReportInfo &&
    riskReportInfo.risk_report.risk_stats.map((item, index) => {
      return {
        id: index,
        title: item[0],
        buy_and_hold: item[1],
        risk_managed: item[2],
      };
    });

  useEffect(() => {
    if (!riskRatingInfo) return;
    const { instrument_id } = riskRatingInfo;

    dispatch(getRiskReportData(instrument_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riskRatingInfo]);

  const goBack = () => {
    history.goBack();
  };

  return (
    <div id="page">
      <div className={"header"}>
        <h2 className={"content-block"} style={{ color: "white" }}>
          <Button
            icon="arrowleft"
            className="margin-right-20"
            onClick={goBack}
            style={{ marginRight: 10 }}
          />
          DASHBOARD
        </h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <span style={{ fontSize: 20, fontWeight: "bold", color: "white" }}>
            {" "}
            DATA SHEET FOR {riskRatingInfo && riskRatingInfo.asset}{" "}
          </span>
        </div>
      </div>
      <ResponsiveBox
        singleColumnScreen="sm"
        screenByWidth={screen}
        style={{ paddingLeft: 250, paddingRight: 250 }}
      >
        {/* Responsive Layout */}
        <Row ratio={1}></Row>
        <Row ratio={1}></Row>
        <Row ratio={1} screen="xs"></Row>
        <Row ratio={1} screen="xs"></Row>
        <Row ratio={2}></Row>
        <Row ratio={4} screen="xs"></Row>
        <Row ratio={1}></Row>

        <Col ratio={1}></Col>
        <Col ratio={1} screen="lg"></Col>
        <Item>
          <Location row={1} col={0} screen="lg"></Location>
          <Location row={1} col={0} colspan={2} screen="sm"></Location>
          <div className={"content-block dx-card px-item"}>
            <div
              style={{
                margin: "10px 0 20px 0",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "30px",
                width: "100%",
              }}
            >
              <p className="px-title margin-bottom-50">CURRENT RISK RATING</p>
            </div>
            <CurrentRiskRating
              risk_rating={
                riskReportInfo && riskReportInfo.risk_report.risk_rating
              }
            />
          </div>
        </Item>
        <Item>
          <Location row={1} col={1} screen="lg"></Location>
          <Location row={2} col={0} screen="sm"></Location>
          <div className={"content-block dx-card px-item"}>
            <div
              style={{
                margin: "10px 0 20px 0",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "30px",
                width: "100%",
              }}
            >
              <p className="px-title margin-bottom-50">ASSET RISK COMPARISON</p>
            </div>

            <DataGrid
              className={"dx-card wide-card px-risk-stats"}
              dataSource={
                riskReportInfo && riskReportInfo.risk_report.risk_stats
                  ? newRiskStats
                  : null
              }
              loadPanel={null}
              showBorders={true}
              keyExpr="id"
              focusedRowEnabled={false}
              defaultFocusedRowIndex={false}
              columnAutoWidth={true}
              columnHidingEnabled={true}
              allowEditing={true}
              height={400}
            >
              <Column
                dataField={"title"}
                sortOrder={false}
                caption={""}
                hidingPriority={6}
              />
              <Column
                dataField={"buy_and_hold"}
                caption={"Buy and Hold"}
                sortOrder={false}
                hidingPriority={6}
              />
              <Column
                dataField={"risk_managed"}
                caption={"Risk Managed"}
                sortOrder={false}
                hidingPriority={2}
              />
            </DataGrid>
          </div>
        </Item>
        <Item>
          <Location row={2} col={0} screen="lg"></Location>
          <Location row={2} col={0} screen="sm"></Location>
          <div className={"content-block dx-card px-item"}>
            <div
              style={{
                margin: "10px 0 20px 0",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "30px",
                width: "100%",
              }}
            >
              <p className="px-title margin-bottom-50">
                CONFIDENCE MEASURE
              </p>
            </div>
            <RiskMeasure90Days
              risk_history={
                riskReportInfo && riskReportInfo.risk_report.risk_history
              }
            />
          </div>
        </Item>
        <Item>
          <Location row={2} col={1} screen="lg"></Location>
          <Location row={4} col={0} screen="sm"></Location>
          <div className={"content-block dx-card px-item"}>
            <div
              style={{
                margin: "10px 0 20px 0",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "30px",
                width: "100%",
              }}
            >
              <p className="px-title margin-bottom-50">
                HYPOTHETICAL PERFORMANCE COMPARISON
              </p>
            </div>
            <HypotheticalComparison
              risk_history={
                riskReportInfo &&
                riskReportInfo.risk_report.hypothetical_performance
              }
            />
          </div>
        </Item>
      </ResponsiveBox>
    </div>
  );
};
export default RiskReport;
