import React, { useEffect, useState, memo } from "react";
import { IconButton } from "@material-ui/core";
import { BsFillBarChartFill } from "react-icons/bs";

function ChangeCell(props) {
  const {
    id = null,
    value,
    oldValue,
    handleChangeCell,
    isActiveMarket,
    activeCompetition,
    accMode,
    selectChart,
  } = props;
  const [showComponent, setShowComponent] = useState(false);
  const [className, setClassName] = useState("");
  useEffect(() => {
    if (value !== oldValue) {
      if (value > oldValue) setClassName("inc");
      if (value < oldValue) setClassName("dec");
      setShowComponent(true);
    }
  }, [value, oldValue]);

  useEffect(() => {
    if (showComponent) {
      const toRef = setTimeout(() => {
        setClassName("");
        setShowComponent(false);
        clearTimeout(toRef);
      }, 3000);
    }
  }, [showComponent]);

  return (
    <div
      id={id || ""}
      className={`px-common-cell ${className} px-${
        ((activeCompetition && accMode === "competition") ||
          accMode === "practice") &&
        isActiveMarket &&
        !isNaN(props.value)
          ? "active"
          : "deactive"
      }-market`}
      onClick={() =>
        ((activeCompetition && accMode === "competition") ||
          accMode === "practice") &&
        isActiveMarket &&
        !isNaN(props.value)
          ? handleChangeCell()
          : null
      }
    >
      {!isNaN(props.value) ? (
        <div className="current-value">
          <span className="price">{props.value}</span>
          {selectChart && (
            <span
              className={className}
              onClick={(e) => {
                e.stopPropagation();
                selectChart();
              }}
            >
              <IconButton size="small">
                <BsFillBarChartFill size={10} />
              </IconButton>
            </span>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default memo(ChangeCell);
