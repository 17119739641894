import React from "react";

const Logo = ({ imgPath, type }) => {
  return (
    <div className="px-logo-view">
      <div
        className={`px-logo ${type}`}
        style={{
          backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}/${imgPath})`,
        }}
      ></div>
    </div>
  );
};

export default Logo;
