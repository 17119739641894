import { Switch } from "devextreme-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmailCommunicationStatus,
  getHintsTipsStatus,
  updateEmailCommunicationStatus,
  updateHintsTipsStatus,
} from "../../redux/dashboard/action";

const Notifications = () => {
  const dispatch = useDispatch();
  const { client_user_id } = useSelector((state) => state.auth.info);
  const [hintsAndTipsVisibility, setHintsAndTipsVisibility] = useState();
  const [receiveEmailStatus, setReceiveEmailStatus] = useState();
  useEffect(() => {
    Promise.all([
      dispatch(getHintsTipsStatus(client_user_id)),
      dispatch(getEmailCommunicationStatus(client_user_id)),
    ]).then(([data1, data2]) => {
      setHintsAndTipsVisibility(
        data1?.hints_tips_visibility === 1 ? true : false
      );
      setReceiveEmailStatus(data2?.receive_emails);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReceiveEmailStatus = (e) => {
    setReceiveEmailStatus(e.value);
    dispatch(updateEmailCommunicationStatus(client_user_id, e.value));
  };

  const handleHintsAndTipsValue = (e) => {
    setHintsAndTipsVisibility(e.value);
    dispatch(updateHintsTipsStatus(client_user_id, e.value));
  };

  return (
    <div style={{ display: "grid" }}>
      <div style={{ display: "flex" }}>
        <Switch value={receiveEmailStatus} onValueChanged={handleReceiveEmailStatus} />
        <span style={{ marginLeft: 5 }}>RECEIVE EMAIL COMMUNICATION</span>
      </div>
      <div style={{ display: "flex" }}>
        <Switch
          value={hintsAndTipsVisibility}
          onValueChanged={handleHintsAndTipsValue}
        />
        <span style={{ marginLeft: 5 }}>TURN ON HINTS & TIPS</span>
      </div>
    </div>
  );
};
export default Notifications;
