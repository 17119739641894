import axios from "axios";
import { get, remove } from "../utils/storage";
import logger from "../utils/logger";
import { MAIN_TOKEN_KEY } from "../constants";

const baseApi = axios.create({
  baseURL: process.env.REACT_APP_API_KEY,
});

export const tradeApi = axios.create({
  baseURL: process.env.REACT_APP_TRADE_API_KEY,
});

tradeApi.interceptors.request.use(
  async (config) => {
    // Do something before request is sent, like we're inserting a autherization header
    const token = get(MAIN_TOKEN_KEY);
    // eslint-disable-next-line no-param-reassign
    if (token) {
      var item = {
        token: `${token.split('"')[1]}`,
      };

      config.headers = { ...config.headers, ...item };
    }

    logger.debug(config.method, config.url, {
      data: config.data,
      header: config.headers,
    });

    return config;
  },
  (error) => {
    // Do something with request error
    logger.error(error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
tradeApi.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    const errObj = { error };
    // When the token has been expired. It takes the user to the login page.
    if (errObj && errObj.error?.response?.status === 403) {
      // remove token
      remove(MAIN_TOKEN_KEY);

      window.location.href = "/login";
    }

    logger.error(error);
    // Do something with response error
    return Promise.reject(error);
  }
);

export const socialApi = axios.create({
  baseURL: process.env.REACT_APP_SOCIAL_API_KEY,
});

socialApi.interceptors.request.use(
  async (config) => {
    // Do something before request is sent, like we're inserting a autherization header
    const token = get(MAIN_TOKEN_KEY);
    // eslint-disable-next-line no-param-reassign
    if (token) {
      var item = {
        token: `${token.split('"')[1]}`,
      };

      config.headers = { ...config.headers, ...item };
    }

    logger.debug(config.method, config.url, {
      data: config.data,
      header: config.headers,
    });

    return config;
  },
  (error) => {
    // Do something with request error
    logger.error(error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
socialApi.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    const errObj = { error };
    // When the token has been expired. It takes the user to the login page.
    if (errObj && errObj.error?.response?.status === 403) {
      // remove token
      remove(MAIN_TOKEN_KEY);

      window.location.href = "/login";
    }

    logger.error(error);
    // Do something with response error
    return Promise.reject(error);
  }
);

/**
 * Custom BASE URL For Social Api
 */

baseApi.interceptors.request.use(
  async (config) => {
    // Do something before request is sent, like we're inserting a autherization header
    const token = get(MAIN_TOKEN_KEY);
    // eslint-disable-next-line no-param-reassign
    if (token) {
      var item = {
        "x-RMaas-Token": `${token.split('"')[1]}`,
      };

      config.headers = { ...config.headers, ...item };
    }

    logger.debug(config.method, config.url, {
      data: config.data,
      header: config.headers,
    });

    return config;
  },
  (error) => {
    // Do something with request error
    logger.error(error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
baseApi.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    const errObj = { error };
    // When the token has been expired. It takes the user to the login page.
    if (errObj && errObj.error.response.status === 403) {
      // remove token
      remove(MAIN_TOKEN_KEY);

      window.location.href = "/login";
    }

    logger.error(error);
    // Do something with response error
    return Promise.reject(error);
  }
);

export default baseApi;
