export const imersNavigation = [
  {
    text: "SOCIAL DASHBOARD",
    icon: "user",
    path: "/social-dashboard",
  },
  {
    text: "TRADING DASHBOARD",
    icon: "detailslayout",
    path: "/trading-dashboard",
  },
  {
    text: "ACCOUNT DASHBOARD",
    icon: "chart",
    path: "/account-dashboard",
  },
  {
    text: "SETTINGS",
    icon: "preferences",
    path: "/settings",
  },
  {
    text: "WEALTH",
    icon: "money",
    path: "/wealth",
    disabled: false,
  },
  {
    text: "COMMUNITY",
    icon: "group",
    path: "/community",
  },
  {
    text: "VR DEVICE PAIRING",
    icon: "link",
    path: "/device-pairing",
  },
  {
    text: "INSTRUCTION VIDEO",
    icon: "video",
    path: "",
    items: [
      {
        id: 1,
        text: "PLACE TRADE",
        mode: "instruction_play",
      },
      {
        id: 2,
        text: "CANCEL TRADE",
        mode: "instruction_play",
      },
      {
        id: 3,
        text: "JOIN COMPETITION",
        mode: "instruction_play",
      },
    ],
  },
  // {
  //   text: "SWITCH TO CYBERTOOLS",
  //   icon: "refresh",
  //   mode: "cybertools"
  // },
];
