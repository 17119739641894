import { withNavigationWatcher } from "./contexts/navigation";
import {
  TradingDashboard,
  AccountDashboard,
  Security,
  Accounts,
  RiskReport,
} from "./containers/private";

const routes = [
  {
    path: "/trading-dashboard",
    component: TradingDashboard,
  },
  {
    path: "/account-dashboard",
    component: AccountDashboard,
  },
  {
    path: "/settings",
    component: Security,
  },
  {
    path: "/accounts",
    component: Accounts,
  },
  {
    path: "/riskreport",
    component: RiskReport,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    component: withNavigationWatcher(route.component),
  };
});
