import React, { useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import Error from "../../components/Error";
import { login, connect, saveTempToken } from "../../redux/auth/action";
import { useAppData } from "../../contexts/app";
import { set } from "../../utils/storage";
import { MAIN_TOKEN_KEY } from "../../constants";
import "./LoginForm.scss";

const LoginForm = () => {
  const history = useHistory();
  const location = useLocation();
  const formData = useRef({});
  const dispatch = useDispatch();
  const { loading, setLoading, loginErrorMsg, setLoginErrorMsg } = useAppData();

  const onSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = formData.current;
    setLoading(true);
    const redirectTo =
      location.state && location.state.from ? location.state.from : "/";

    try {
      const data = await dispatch(login(email, password));
      if (data && data.client_user_is_2fa) {
        await dispatch(saveTempToken(data.client_user_token));
        history.push("/login/2fa");
      } else {
        set(MAIN_TOKEN_KEY, data.client_user_token);
        await dispatch(connect());
        history.replace(redirectTo);
      }
      setLoading(false);
      setLoginErrorMsg("");
    } catch (error) {
      setLoginErrorMsg(error.message);
      setLoading(false);
    }
  };

  return (
    <form className={"px-account-form"} onSubmit={onSubmit}>
      {!!loginErrorMsg && <Error>{loginErrorMsg}</Error>}
      <Form formData={formData.current} disabled={loading}>
        <Item>
          <div className={"link"}>
            <Link  to={"/register"}>Register an account</Link>
          </div>
        </Item>
        <Item
          dataField={"email"}
          editorType={"dxTextBox"}
          cssClass={"px-email-input"}
          editorOptions={emailEditorOptions}
        >
          <RequiredRule message="Email is required" />
          <EmailRule message="Email is invalid" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={"password"}
          editorType={"dxTextBox"}
          cssClass={"px-password-input"}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <ButtonItem cssClass={"px-login-button"}>
          <ButtonOptions
            width={"100%"}
            height={50}
            type={"default"}
            style={{ margin: "10px 0 10px 0" }}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {loading ? (
                <LoadIndicator width={"24px"} height={"24px"} visible={true} />
              ) : (
                "LOGIN"
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={"link center"}>
            <Link to={"/forgot-password"} className={"normal"}>Forgot password?</Link>
          </div>
        </Item>
      </Form>
    </form>
  );
};

const emailEditorOptions = {
  stylingMode: "filled",
  placeholder: "Email Address",
  mode: "text",
};
const passwordEditorOptions = {
  stylingMode: "filled",
  placeholder: "Password",
  mode: "password",
};

export default LoginForm;
