import React from "react";

import DialogPortal from "../DialogPortal";
import { socialApi } from "../../service/api";

import CloseIcon from "../../assets/imgs/icon-close-white.png";

const SendMessageDialog = ({
  closeDialog,
  data,
  messages,
  getOnlineStatus,
  markMessagesReadFn,
}) => {
  const [value, setValue] = React.useState("");
  const [allMessages, setAllMessages] = React.useState([]);
  const textAreaRef = React.useRef();

  React.useEffect(() => {
    if (allMessages.length === 0) return;

    let timeout = setTimeout(() => {
      if (
        allMessages[0]?.action === "received" &&
        allMessages[0]?.status === "unread"
      ) {
        markMessagesReadFn(data.user_id);
      }
    }, 400);

    return () => clearTimeout(timeout);

    // eslint-disable-next-line
  }, [allMessages]);

  React.useEffect(() => {
    if (messages && messages.length > 0) {
      setAllMessages(messages.filter((item) => item.user_id === data.user_id));
    }
    // eslint-disable-next-line
  }, [messages]);

  React.useEffect(() => {
    // For animating Modal
    const dialogContainer = document.querySelector(".message-dialog-content");

    const timeout = setTimeout(() => {
      dialogContainer.classList.add("animate-modal");
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  const handleClose = () => {
    const dialogContainer = document.querySelector(".message-dialog-content");
    dialogContainer.classList.remove("animate-modal");

    setTimeout(() => {
      closeDialog();
    }, 200);
  };

  const sendMessageFn = async (message, userid) => {
    try {
      const { data } = await socialApi.post(
        "/api/social/send_message",
        {},
        {
          headers: {
            message,
            userid,
          },
        }
      );

      if (data["HTTP Response"] === "OK") {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = () => {
    if (!value.trim()) return;
    setAllMessages((ps) => [
      {
        user_id: data.user_id,
        message: value,
        when_sent: new Date().toISOString(),
        action: "sent",
        status: "pending",
      },
      ...ps,
    ]);
    sendMessageFn(value.replaceAll(`\n`, `<br/>`), data.user_id);
    setValue("");
    textAreaRef.current.style.height = "30px";
  };

  const handleKeyPress = (evt) => {
    if (evt.keyCode === 13 && evt.shiftKey) {
      if (evt.type === "keyup") {
        const position = evt.target.selectionEnd;
        evt.target.value =
          evt.target.value.substring(0, position) +
          "" +
          evt.target.value.substring(position);
        evt.target.selectionEnd = position;
      }
    } else if (evt.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleChange = (e) => {
    const target = e.target;
    textAreaRef.current.style.height = "30px";
    textAreaRef.current.style.height = `${target.scrollHeight}px`;
    setValue(target.value.trimStart());
  };

  return (
    <DialogPortal className="social-dashboard-dialog">
      <div className="message-dialog-content">
        <div className="social-dashboard-dialog-header">
          <div className="header-content">
            <div className="header-title">
              <div className="profile-avatar">
                <p>{data.game_name.charAt(0)}</p>
              </div>
              {data.game_name}
              <span
                className="friend-status"
                style={{
                  backgroundColor: getOnlineStatus(data.user_id)
                    ? "greenyellow"
                    : "transparent",
                }}
              />
            </div>
            <div
              className="header-close-btn"
              onClick={handleClose}
              style={{ paddingTop: "14px", paddingBottom: "14px" }}
            >
              <img src={CloseIcon} alt="Close" />
            </div>
          </div>
        </div>
        <div className="social-dashboard-dialog-container">
          <div className="messages-inner-content">
            {allMessages.map((item, indx) => (
              <div
                className={`messages-inner-content-item ${
                  item.action === "sent" ? "sent" : "received"
                }`}
                key={indx}
              >
                <div className="message-body">
                  <div className="message-text">
                    {item.message.replaceAll("<br/>", `\n`)}
                  </div>
                  <div className="message-time">{item.when_sent}</div>
                </div>
                {item?.status === "pending" && (
                  <div className="message-status">Pending...</div>
                )}
              </div>
            ))}
          </div>
          <div className="message-dialog-form">
            <div className="form">
              <textarea
                ref={textAreaRef}
                rows={1}
                value={value}
                onChange={handleChange}
                type="text"
                placeholder="Type your message..."
                onKeyUp={handleKeyPress}
              />
              <button disabled={!Boolean(value)} onClick={handleSubmit}>
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </DialogPortal>
  );
};

export default SendMessageDialog;
