import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CheckBox } from "devextreme-react";
import DataGrid, { Column } from "devextreme-react/data-grid";
import { NumberBox } from "devextreme-react/number-box";
import { Button } from "devextreme-react/button";
import Strategy from "./Strategy";
import AllocationWarningPopup from "./AllocationWarningPopup";
import {
  getAssetAllocationByAccountID,
  updateAssetAllocationByAccountID,
} from "../../redux/manage-asset/action";
import {
  addNewAccountToList,
  setAccountInformation,
  setSelectedAccount,
  setSelfServiceAccounts,
} from "../../redux/accounts/action";
import { useToast } from "../../contexts/toast";
import { useContentLoading } from "../../contexts/contentLoading";
import { changeReqPayloadFormat } from "../../utils/custom-functions";
import { SETUP_ACCOUNT_SUCCESS_MSG } from "../../constants";
import { useScreenSize } from "../../utils/media-query";
import ConfirmationAskingPopup from "../profile-settings/ConfirmationAskingPopup";
import { ProfileFormEditedBefore } from "../../redux/auth/action";

const BasicRiskManagement = ({
  accountID,
  isSetup,
  expertGUI,
  managementTypeID,
  previousStep = null,
  setSelectedIndex = null,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const { setLoadingIndicator } = useContentLoading();
  const screenSize = useScreenSize();
  const accounts = useSelector((state) => state.accounts.list);
  const account = useSelector((state) => state.accounts.info);
  const assetsInfo = useSelector((state) => state.manageAsset.assetsInfo);

  const [commitableAllocations, setCommitableAllocations] = useState(null);
  const [opened, setOpened] = useState(false);
  const [allocatedAccountBalance, setAllocatedAccountBalance] = useState(0);
  const [visibleOptionsPopup, setVisibleWarningPopup] = useState(false);
  const [isPopup, setIsPopup] = useState(false);

  const screenWidth =
    screenSize.isLarge || screenSize.isMedium ? "100%" : "100%";
  const displayMethod =
    screenSize.isLarge || screenSize.isMedium ? "flex" : "block";

  useEffect(() => {
    if (!assetsInfo) return;

    // Clone the allocations data from the source.
    const data = JSON.parse(JSON.stringify(assetsInfo.allocations));
    // data.map((item) => {
    //   // Assign the current target percentage of each asset to the target percent basket value.
    //   item.target_quantity = item.target_quantity ? item.target_quantity : "-";

    //   const availableModels = item.available_models;
    //   if (availableModels && availableModels.length !== 0) {
    //     const modelsByTarget = availableModels[managementTypeID - 1].model;
    //     item.current_model_id = modelsByTarget[managementTypeID].id;
    //     item.current_model_name = modelsByTarget[managementTypeID].name;
    //   }
    //   return item;
    // });
    setCommitableAllocations(data);

    return () => {
      setOpened(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetsInfo]);

  useEffect(() => {
    return () => {
      if (account?.management_type_id !== managementTypeID) {
        // Okay, cancel alert.
        // Content
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managementTypeID]);

  // Calculate the Allocated Account Balance.
  useEffect(() => {
    if (!commitableAllocations) return;

    var totalHoldingValue = 0;
    var totalTargetValue = 0;
    var allocatedAccountBalance = 0;
    var hasRiskManagedAsset = false;

    // The calculation method runs on Risk-managed assets.
    // Total Holding = sum of each asset's holding + base currency.
    // Total Target = sum of each asset's target.
    // Holding = Actual position * price.
    // Target = Target Quantity * price.
    const { base_currency_id } = account;
    const baseCurrencyAsset = commitableAllocations.filter(
      (item) => item.instrument_id === base_currency_id
    );

    commitableAllocations.map((item) => {
      const { current_model_id: curModelID } = item;
      if (curModelID === 3) {
        // Check if at least one risk-managed asset is.
        if (!hasRiskManagedAsset) hasRiskManagedAsset = true;
        const { position, price, target_quantity } = item;
        totalHoldingValue += position * price;
        totalTargetValue += target_quantity * price;
      }
      return item;
    });

    const { position, price } = baseCurrencyAsset[0];
    totalHoldingValue += position * price; // + base_currency

    allocatedAccountBalance = Math.floor(
      (totalTargetValue / totalHoldingValue) * 100
    );

    if (!totalTargetValue && !totalHoldingValue) {
      setAllocatedAccountBalance(0);
    }
    if (hasRiskManagedAsset) {
      setAllocatedAccountBalance(allocatedAccountBalance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commitableAllocations]);

  const handleManagementType = (pxValue) => {
    // Pending for setting management type.

    setOpened(false);
  };

  const handleRowPrepared = (e) => {
    if (e.data) {
      const availableModels = e.data.available_models;
      if (
        availableModels &&
        availableModels.length === 0 &&
        e.rowType === "data"
      ) {
        window.$(e.rowElement).addClass("disabledRow");
      }
    }
  };

  const handleWarningOption = (value) => {
    switch (value) {
      case 1:
        allocateEachTargetQuantity();
        dispatchCommitAllocations();
        setVisibleWarningPopup(false);
        break;
      case 2:
        dispatchCommitAllocations();
        setVisibleWarningPopup(false);
        break;
      case 3:
        setVisibleWarningPopup(false);
        break;
      default:
        break;
    }
  };

  const dispatchCommitAllocations = async () => {
    dispatch(ProfileFormEditedBefore(false));

    const data = {
      management_type_id: managementTypeID,
      allocations: changeReqPayloadFormat(commitableAllocations),
    };

    // Update target percent values as zero if dash to avoid the api call issue.
    data.allocations.map((item) => {
      if (item.target_quantity === "-") item.target_quantity = 0;
      return item;
    });

    setLoadingIndicator(true);
    await dispatch(updateAssetAllocationByAccountID(accountID, data)).then(
      async (res) => {
        if (isSetup) {
          dispatch(setSelectedAccount(res[0]));
          const data =
            accounts &&
            accounts.filter(
              (item) => item.client_account_id === res[0].client_account_id
            );

          if (data && data.length === 0) dispatch(addNewAccountToList(res[0]));
          else {
            const updates = accounts.map((item) => {
              if (item.client_account_id === res[0].client_account_id) {
                return {
                  ...item,
                  account_status_id: 4,
                  account_status: "Active",
                };
              } else return item;
            });
            dispatch(setSelfServiceAccounts(updates));
          }

          showToast(SETUP_ACCOUNT_SUCCESS_MSG, "success", 4000);
          await dispatch(getAssetAllocationByAccountID(accountID));
          setLoadingIndicator(false);
          history.push("/account-dashboard");
        } else {
          const { management_type_id, expert_gui } = res[0];
          res[0].management_type_id = management_type_id;
          dispatch(setAccountInformation(res[0]));
          dispatch(setSelectedAccount(res[0]));
          dispatch(getAssetAllocationByAccountID(accountID));
          setLoadingIndicator(false);
          // Redirect user to next tab or dashboard by the account mode.
          if (expert_gui) setSelectedIndex(2);
          else history.push("/account-dashboard");
        }
      }
    );
  };

  const setSepecificAssestToRiskManaged = (value, instrumentID) => {
    const tempAllocations = [...commitableAllocations];
    tempAllocations.forEach((item) => {
      if (item.instrument_id === instrumentID) {
        if (value) {
          // Calculation method of the risk managed asset's target position.
          item.target_quantity = item.position;
          item.current_model_id = 3;
        } else {
          item.target_quantity = "-";
          item.current_model_id = 1;
        }
      }
      return item;
    });
    // Compare the updated array and original one to get the status: isEdited.
    if (getDifference(tempAllocations, assetsInfo.allocations).length === 0)
      dispatch(ProfileFormEditedBefore(false));
    else dispatch(ProfileFormEditedBefore(true));

    setCommitableAllocations(tempAllocations);
  };

  const getDifference = (array1, array2) => {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        return (
          object1.current_model_id === object2.current_model_id &&
          object1.instrument_id === object2.instrument_id
        );
      });
    });
  };

  const allocateEachTargetQuantity = async () => {
    const allocatedRatio = allocatedAccountBalance / 100;
    const tempAllocations = [...commitableAllocations];
    
    if (
      getDifferenceByTargetQuantity(tempAllocations, assetsInfo.allocations)
        .length === 0
    )
      dispatch(ProfileFormEditedBefore(false));
    else dispatch(ProfileFormEditedBefore(true));

    tempAllocations.map((item) => {
      if (item.current_model_id === 3) {
        item.target_quantity = item.target_quantity / allocatedRatio;
      }
      return item;
    });
    setCommitableAllocations(tempAllocations);
  };

  const renderRiskManageContent = (pxContainer) => {
    const { instrument_id: InstrmentID, current_model_id: curModelID } =
      pxContainer.data;

    const availableModels = pxContainer.data.available_models;

    if (availableModels && availableModels.length !== 0) {
      const modelsByTarget = availableModels[managementTypeID - 1];
      if (
        modelsByTarget &&
        modelsByTarget.model &&
        modelsByTarget.model.length === 2
      ) {
        return (
          <CheckBox
            value={curModelID === 3 ? true : false}
            onValueChanged={(e) => {
              const { value } = e;
              setSepecificAssestToRiskManaged(value, InstrmentID);
            }}
          />
        );
      } else {
        return "";
      }
    }
  };

  const handleTargetPosition = (value, instrumentID) => {
    const tempAllocations = [...commitableAllocations];
    tempAllocations.map((item) => {
      if (item.instrument_id === instrumentID) {
        item.target_quantity = value;
      }
      return item;
    });
    if (
      getDifferenceByTargetQuantity(tempAllocations, assetsInfo.allocations)
        .length === 0
    )
      dispatch(ProfileFormEditedBefore(false));
    else dispatch(ProfileFormEditedBefore(true));

    setCommitableAllocations(tempAllocations);
  };

  const getDifferenceByTargetQuantity = (array1, array2) => {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        return (
          object1.current_model_id === object2.current_model_id &&
          object1.target_quantity === object2.target_quantity
        );
      });
    });
  };

  const renderTargetPosition = useCallback(
    (e) => {
      if (!e && !commitableAllocations) return;

      const { current_model_id: curModelID, instrument_id: instrumentID } =
        e.data;
      if (curModelID === 3) {
        return (
          <NumberBox
            showClearButton={false}
            showSpinButtons={false}
            format={"0#.####"}
            min="0"
            value={e.data.target_quantity}
            onValueChange={(value) => handleTargetPosition(value, instrumentID)}
            style={{ height: 40 }}
          />
        );
      } else return <span>{"-"}</span>;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [commitableAllocations]
  );

  const renderExchangeSymbol = (e) => {
    const { instrument_id: instrumentID } = e.data;
    if (instrumentID === account.base_currency_id)
      return (
        <span style={{ backgroundColor: "green", color: "white" }}>
          {e.data.exchange_symbol}
        </span>
      );
    return e.data.exchange_symbol;
  };

  return (
    <div
      style={{ display: displayMethod }}
      className="px-asset-allocation-container"
    >
      {!isSetup && (
        <div
          id="mySidepanel"
          className={`${opened ? "sidepanel open" : "sidepanel"}`}
        >
          <Strategy
            isSetup={false}
            handleManagementType={handleManagementType}
          />
        </div>
      )}
      <div
        style={{
          width: screenWidth,
          padding: "20px 20px",
        }}
      >
        {/* ACCOUNT BALANCE VIEW */}
        <div className="px-assets-header">
          <p className="px-asset-title no-padding">RISK MANAGEMENT SETUP</p>
          <div className="px-btn-tools">
            {!isSetup && (
              <>
                <Button
                  text="CHANGE MANAGEMENT TYPE"
                  onClick={() => setOpened(!opened)}
                  style={{ marginRight: 10 }}
                />
                <Button
                  text={"COMMIT CHANGES"}
                  disabled={
                    commitableAllocations && commitableAllocations.length !== 0
                      ? false
                      : true
                  }
                  onClick={() => {
                    if (allocatedAccountBalance !== 100) {
                      setVisibleWarningPopup(true);
                    } else {
                      dispatchCommitAllocations();
                    }
                  }}
                />
              </>
            )}
            {isSetup && (
              <>
                <Button
                  text="PREVIOUS STEP"
                  onClick={previousStep}
                  style={{ marginRight: 10 }}
                />
                <Button
                  text={`${expertGUI ? "CONTINUE SETUP" : "COMPLETE SETUP"}`}
                  disabled={
                    assetsInfo && assetsInfo.allocations.length !== 0
                      ? false
                      : true
                  }
                  onClick={() => {
                    if (allocatedAccountBalance !== 100) {
                      setVisibleWarningPopup(true);
                    } else {
                      dispatchCommitAllocations();
                    }
                  }}
                  style={{ marginRight: 10 }}
                />
              </>
            )}
          </div>
        </div>
        <div
          className="px-account-info"
          style={{ maxWidth: 800, width: 800, position: "relative" }}
        >
          <p>
            ACCOUNT NAME: <strong>{account?.account_reference}</strong>
          </p>
          <p>
            ALLOCATED ACCOUNT BALANCE %:{" "}
            <strong>{allocatedAccountBalance}</strong>
          </p>
          <Button
            text={"ALLOCATE 100%"}
            type="default"
            disabled={
              commitableAllocations && commitableAllocations.length !== 0
                ? false
                : true
            }
            style={{ position: "absolute", right: 0, top: 20 }}
            onClick={allocateEachTargetQuantity}
          />
        </div>
        <DataGrid
          className={"dx-card wide-card"}
          dataSource={
            commitableAllocations && commitableAllocations.length !== 0
              ? commitableAllocations
              : null
          }
          loadPanel={null}
          showBorders={true}
          keyExpr="instrument_id"
          focusedRowEnabled={false}
          defaultFocusedRowIndex={false}
          columnAutoWidth={true}
          columnHidingEnabled={true}
          allowEditing={true}
          onRowPrepared={handleRowPrepared}
        >
          <Column
            dataField={"exchange_symbol"}
            caption={"ASSET"}
            hidingPriority={6}
            cellRender={renderExchangeSymbol}
            width={200}
            alignment={"left"}
          />
          <Column
            dataField={"instrument_id"}
            caption={"RISK MANAGE"}
            hidingPriority={6}
            cellRender={renderRiskManageContent}
            allowSorting={false}
            width={200}
            alignment={"left"}
          />
          <Column
            dataField={"position"}
            caption={"ACTUAL QUANTITY"}
            hidingPriority={2}
            width={200}
            alignment={"left"}
          />
          <Column
            dataField={"target_quantity"}
            caption={"TARGET QUANTITY"}
            hidingPriority={2}
            width={200}
            alignment={"left"}
            cellRender={(e) => renderTargetPosition(e)}
          />
        </DataGrid>
      </div>
      {isPopup && (
        <ConfirmationAskingPopup
          handleClosePopup={() => setIsPopup(false)}
          handleKeepThePage={dispatchCommitAllocations}
        />
      )}
      {visibleOptionsPopup && (
        <AllocationWarningPopup
          managementTypeID={managementTypeID}
          setVisibleWarningPopup={setVisibleWarningPopup}
          handleWarningOption={handleWarningOption}
        />
      )}
    </div>
  );
};

export default BasicRiskManagement;
