import React from "react";
import DataGrid, {
  Column,
  Scrolling,
  Pager,
  Paging,
} from "devextreme-react/data-grid";
import "devextreme/data/odata/store";
import { ALLOWED_PAGE_SIZES_BIG } from "../../constants";

const AccountHistory = ({ trades }) => {
  const updated =
    trades &&
    trades.map((item) => {
      return {
        ...item,
        trade_date_time: item.trade_date_time
          .replace("Z", " ")
          .replace("T", " "),
      };
    });
  
  return (
    <React.Fragment>
      <DataGrid
        dataSource={updated}
        showBorders={false}
        keyExpr="quantity"
        focusedRowEnabled={false}
        defaultFocusedRowIndex={false}
        columnAutoWidth={true}
        loadPanel={null}
      >
        <Scrolling rowRenderingMode="virtual"></Scrolling>
        <Paging defaultPageSize={ALLOWED_PAGE_SIZES_BIG[0]} />
        {updated && updated.length > 10 && (
          <Pager
            visible={true}
            allowedPageSizes={ALLOWED_PAGE_SIZES_BIG}
            displayMode={"full"}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
        )}
        <Column
          cssClass="px-column-name"
          alignment="center"
          dataField={"trading_pair"}
          caption={"TRADING PAIR"}
          hidingPriority={2}
        />
        <Column
          cssClass="px-column-name"
          alignment="center"
          dataField={"buy_sell"}
          caption={"BUY/SELL"}
          hidingPriority={6}
        />
        <Column
          cssClass="px-column-name"
          alignment="right"
          showBorders={false}
          dataField={"quantity"}
          caption={"QUANTITY"}
          hidingPriority={2}
        />
        <Column
          allowSorting={true}
          cssClass="px-column-name"
          alignment="right"
          dataField={"price"}
          caption={"PRICE"}
          hidingPriority={2}
        />
        <Column
          cssClass="px-column-name"
          alignment="center"
          dataField={"trade_date_time"}
          caption={"DATE/TIME"}
          hidingPriority={2}
        />
      </DataGrid>
    </React.Fragment>
  );
};

export default AccountHistory;
