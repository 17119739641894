import React from "react";
import "./Footer.scss";

const Footer = ({ ...rest }) => {
  return (
    <footer className={"px-footer"} {...rest}>
      <div className={"top-section"}>
        <div className={"quick-links"}>
          <p className={"title"}>QUICK LINKS</p>
          <ul>
            <li>
              <a
                href="https://imers.land/#team"
                aria-current="page"
                rel="noreferrer"
                target="_blank"
              >
                About Us
              </a>
            </li>
            <li>
              <a
                href="https://imers.land/category/game-play/"
                aria-current="page"
                rel="noreferrer"
                target="_blank"
              >
                Game Play
              </a>
            </li>
            <li>
              <a
                href="https://imers.land/category/metaverse-guilds/"
                aria-current="page"
                rel="noreferrer"
                target="_blank"
              >
                Metaverse & Guilds
              </a>
            </li>
            <li>
              <a
                href="https://imers.land/category/trading-hub/"
                aria-current="page"
                rel="noreferrer"
                target="_blank"
              >
                Immersive VR Trading Hub
              </a>
            </li>
            <li>
              <a
                href="https://imers.land/faq/"
                aria-current="page"
                rel="noreferrer"
                target="_blank"
              >
                Frequently Asked Questions
              </a>
            </li>
            <li>
              <a
                href="https://cybertools.club/privacy-policy/"
                rel="noreferrer"
                target="_blank"
              >
                Privacy Policy
              </a>
            </li>              
          </ul>
        </div>
      </div>
      <div className={"middle-section"}>
        <p className={"title"}>FOLLOW US</p>
        <ul className={"social-menu"}>
          <li>
            <a
              href="https://www.facebook.com/IMERS.LAND"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-facebook"></i>
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/IMERS_LAND"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-twitter"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UCKlqrDLjKrx8sHrRMfDgO6Q"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-youtube"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/81433072/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-linkedin"></i>
            </a>
          </li>
          <li>
            <a
              href="https://t.me/Imers_Land"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-telegram"></i>
            </a>
          </li>
          <li>
            <a
              href="https://discord.gg/6EdzDW3smK"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-discord" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/imers.land/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-instagram" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.tiktok.com/@imers_land"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-tiktok" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.reddit.com/user/ImersLand"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-reddit" aria-hidden="true"></i>
            </a>
          </li>
        </ul>
      </div>
      <div className={"bottom-section"}>
        <p className={"disclaimer"}>
          This site is not intended to provide any investment, financial, legal,
          regulatory, accounting, tax or similar advice, and nothing on this
          site should be construed as a recommendation by CyberTools OU, its
          affiliates, or any third party to acquire or dispose of any
          investment, or to engage in any investment or trading strategy or
          transaction. An investment in any strategy involves a high degree of
          risk and there is always the possibility of loss, including the
          complete loss of principal. Nothing in this site may be considered as
          an offer or solicitation to purchase or sell currency, securities or
          other services.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
