import React from "react";
import LoadIndicator from "devextreme-react/load-indicator";
import Button from "devextreme-react/button";
import { uniq } from "lodash";

import ReportDialog from "./ReportDialog";
import DialogPortal from "../DialogPortal";
import { socialApi } from "../../service/api";
import { useToast } from "../../contexts/toast";
import SendMessageDialog from "./SendMessageDialog";
import CloseIcon from "../../assets/imgs/icon-close-white.png";
import RemoveUserIcon from "../../assets/imgs/icon-user-remove.png";
import BlockUserIcon from "../../assets/imgs/icon-block-user.png";
import ReportUserIcon from "../../assets/imgs/icon-report-user.png";
import SendMessageIcon from "../../assets/imgs/icon-send-message.png";
import AddUserIcon from "../../assets/imgs/icon-add-user.png";
import AcceptRequestIcon from "../../assets/imgs/icon-accept-request.png";
import RejectRequestIcon from "../../assets/imgs/icon-reject-request.png";
import CancelRequestIcon from "../../assets/imgs/icon-cancel-request.png";

const FriendsDialog = ({ closeDialog, socialData, tab }) => {
  const { showToast } = useToast();

  const [friends, setFriends] = React.useState([]);
  const [blocked, setBlocked] = React.useState([]);
  const [online, setOnline] = React.useState([]);
  const [messages, setMessages] = React.useState([]);
  const [requests, setRequests] = React.useState({
    sent: [],
    received: [],
  });

  const [activeTab, setActiveTab] = React.useState(0);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [inputError, setInputError] = React.useState(null);
  const [searchResults, setSearchResults] = React.useState(null);

  const [loadingUsers, setLoadingUsers] = React.useState(false);
  const [tabCounts, setTabCounts] = React.useState({
    friends: 0,
    requests: 0,
    online: 0,
    blocked: 0,
    messages: 0,
  });

  const [sentRequests, setSentRequests] = React.useState({});
  const [cancelRequests, setCancelRequests] = React.useState({});
  const [rejectRequests, setRejectRequests] = React.useState({});
  const [acceptRequests, setAcceptRequests] = React.useState({});
  const [blockedUsers, setBlockedUsers] = React.useState({});
  const [unblockedUsers, setUnblockedUsers] = React.useState({});
  const [unfriendUsers, setUnfriendUsers] = React.useState({});

  const [messageByUsers, setMessageByUsers] = React.useState([]);
  const [showReportDialog, setShowReportDialog] = React.useState({
    open: false,
    data: null,
  });
  const [showMessageDialog, setShowMessageDialog] = React.useState({
    open: false,
    user: null,
  });

  React.useEffect(() => {
    if (tab === null || tab === undefined) return;
    setActiveTab(tab);
  }, [tab]);

  React.useEffect(() => {
    // For animating Modal
    const dialogContainer = document.querySelector(
      ".social-dashboard-dialog-content"
    );

    const timeout = setTimeout(() => {
      dialogContainer.classList.add("animate-modal");
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  React.useEffect(() => {
    if (
      !socialData ||
      (typeof socialData === "object" && Object.keys(socialData).length === 0)
    )
      return;

    // set state back to initial
    setAcceptRequests({});
    setCancelRequests({});
    setBlockedUsers({});
    setRejectRequests({});
    setUnfriendUsers({});
    setSentRequests({});

    const { friends, blocked, online, messages } = socialData;

    const blockedUser = blocked?.length
      ? blocked.map((item) => item.user_id)
      : [];

    const allFriends = friends?.filter(
      (item) =>
        !blockedUser.includes(item.user_id) && item.friend_status === "friend"
    );

    const allReceivedRequests = friends?.filter(
      (item) => item.friend_status === "received friend request"
    );

    const allSentRequests = friends?.filter(
      (item) => item.friend_status === "sent friend request"
    );

    setFriends(allFriends);
    setRequests({ sent: allSentRequests, received: allReceivedRequests });
    setOnline(online?.filter((item) => !blockedUser.includes(item.user_id)));
    setBlocked(blocked);

    setTabCounts({
      friends: allFriends.length ?? 0,
      requests:
        friends?.filter(
          (item) => item.friend_status === "received friend request"
        )?.length ?? 0,
      online: online?.length ?? 0,
      blocked: blocked?.length ?? 0,
      messages:
        messages?.filter(
          (msg) => msg.action === "received" && msg.status === "unread"
        )?.length ?? 0,
    });

    let messageGroup = messages?.map((item) => item.user_id);
    messageGroup = uniq(messageGroup);
    const newMessageData = messageGroup.map((item) => {
      const userMessages = messages.filter(
        (messageItem) => messageItem.user_id === item
      );

      return {
        user_id: userMessages[0].user_id,
        game_name: userMessages[0].game_name,
        lastMessage: userMessages[0].message,
        when_sent: userMessages[0].when_sent,
        unreadMessages:
          userMessages.filter(
            (msg) => msg.action === "received" && msg?.status === "unread"
          )?.length ?? 0,
        messages: userMessages,
      };
    });

    setMessageByUsers(newMessageData);

    setMessages(messages);
  }, [socialData]);

  const handleClose = () => {
    const dialogContainer = document.querySelector(
      ".social-dashboard-dialog-content"
    );
    dialogContainer.classList.remove("animate-modal");

    setTimeout(() => {
      closeDialog();
    }, 200);
  };

  const handleChangeTab = (value) => {
    if (activeTab === value) return;
    setActiveTab(value);
  };

  // Search for users...

  const handleSearchUser = async (event) => {
    if (event) {
      event.preventDefault();
    }

    let err = null;

    if (searchQuery.length < 3) {
      err = "Min 3 characters required";
    } else if (!/^[A-Za-z0-9]*[-_]?[A-Za-z0-9]*$/.test(searchQuery)) {
      err = "Only special characters are allowed (_,-)";
    } else {
      err = null;
    }

    if (err) {
      setInputError(err);
      return;
    } else {
      setInputError(null);
    }

    setLoadingUsers(true);

    const { data } = await socialApi.get(`/api/social/search_user`, {
      headers: {
        search: searchQuery,
      },
    });
    setLoadingUsers(false);

    if (data["HTTP Response"] === "OK") {
      const { message } = data;

      setSearchResults((ps) => {
        let updatedSearch = message.return_message ?? [];
        if (socialData) {
          const { blocked } = socialData;

          const blockedUser = blocked?.map((item) => item.user_id);

          if (blockedUser.length > 0) {
            updatedSearch = updatedSearch.filter(
              (item) => !blockedUser.includes(item.user_id)
            );
          }
        }

        return updatedSearch;
      });
    }
  };

  // Send friend request...
  const sendRequestFn = async (user_id) => {
    const { data } = await socialApi.post(
      "/api/social/friend_request",
      {},
      {
        headers: {
          friend: user_id,
        },
      }
    );

    if (data["HTTP Response"] === "Bad Request") {
      showToast(data.message.return_message, "error", 5000);
    }

    if (data["HTTP Response"] === "OK") {
      showToast(data.message.return_message, "success", 5000);
      setSentRequests((prevState) => ({ ...prevState, [user_id]: true }));
    }
  };

  // Block user...
  const blockUserFn = async (user_id) => {
    const { data } = await socialApi.post(
      "/api/social/block_user",
      {},
      {
        headers: {
          friend: user_id,
        },
      }
    );

    if (data["HTTP Response"] === "Bad Request") {
      showToast(data.message.return_message, "error", 5000);
    }

    if (data["HTTP Response"] === "OK") {
      showToast(data.message.return_message, "success", 5000);
      setBlockedUsers((prevState) => ({ ...prevState, [user_id]: true }));
    }
  };

  // Report user...
  const reportUserFn = async (userid, text) => {
    const { data } = await socialApi.post(
      "/api/social/report_user",
      {},
      {
        headers: {
          message: text,
          userid,
        },
      }
    );

    if (data["HTTP Response"] === "Bad Request") {
      showToast(data.message.return_message, "error", 5000);
    }

    if (data["HTTP Response"] === "OK") {
      showToast(data.message.return_message, "success", 5000);
    }
  };

  // Unfriend user...
  const unfriendUserFn = async (user_id) => {
    const { data } = await socialApi.post(
      "/api/social/unfriend_request",
      {},
      {
        headers: {
          friend: user_id,
        },
      }
    );

    if (data["HTTP Response"] === "Bad Request") {
      showToast(data.message.return_message, "error", 5000);
    }

    if (data["HTTP Response"] === "OK") {
      showToast(data.message.return_message, "success", 5000);
      setUnfriendUsers((prevState) => ({ ...prevState, [user_id]: true }));
    }
  };

  // Reject request...
  const rejectRequestFn = async (user_id) => {
    const { data } = await socialApi.post(
      "/api/social/friend_request_reject",
      {},
      {
        headers: {
          friend: user_id,
        },
      }
    );

    if (data["HTTP Response"] === "Bad Request") {
      showToast(data.message.return_message, "error", 5000);
    }

    if (data["HTTP Response"] === "OK") {
      showToast(data.message.return_message, "success", 5000);
      setRejectRequests((prevState) => ({ ...prevState, [user_id]: true }));
    }
  };

  // Cancel request...
  const cancelRequestFn = async (user_id) => {
    const { data } = await socialApi.post(
      "/api/social/friend_request_cancel",
      {},
      {
        headers: {
          friend: user_id,
        },
      }
    );

    if (data["HTTP Response"] === "Bad Request") {
      showToast(data.message.return_message, "error", 5000);
    }

    if (data["HTTP Response"] === "OK") {
      showToast(data.message.return_message, "success", 5000);
      setCancelRequests((prevState) => ({ ...prevState, [user_id]: true }));
    }
  };

  // Accept request...
  const acceptRequestFn = async (user_id) => {
    try {
      const { data } = await socialApi.post(
        "/api/social/friend_request_accept",
        {},
        {
          headers: {
            friend: user_id,
          },
        }
      );
      if (data["HTTP Response"] === "OK") {
        showToast(data.message.return_message, "success", 5000);
        setAcceptRequests((prevState) => ({ ...prevState, [user_id]: true }));
      }
    } catch (error) {
      console.log(error);
    }

    // if (data["HTTP Response"] === "Bad Request") {
    //   showToast(data.message.return_message, "error", 5000);
    // }
  };

  const unblockUserFn = async (user_id) => {
    const { data } = await socialApi.post(
      "/api/social/unblock_user",
      {},
      {
        headers: {
          friend: user_id,
        },
      }
    );

    if (data["HTTP Response"] === "OK") {
      setUnblockedUsers((prevState) => ({ ...prevState, [user_id]: true }));
    }
  };

  const markMessagesReadFn = async (userid) => {
    const { data } = await socialApi.post(
      "/api/social/mark_messages_read",
      {},
      {
        headers: {
          userid,
        },
      }
    );

    if (data["HTTP Response"] === "OK") {
    }
  };

  const handleMessageDialog = (data) => {
    setShowMessageDialog({ open: true, user: data });
  };

  const getOnlineStatus = (user_id) => {
    if (online.length === 0) return false;

    return online.filter((item) => item.user_id === user_id).length > 0;
  };

  return (
    <DialogPortal className="social-dashboard-dialog">
      <div className="social-dashboard-dialog-content">
        <div className="social-dashboard-dialog-header">
          <div className="header-content">
            <div className="friends-dialog-tabs">
              <div
                className={`friends-dialog-tab ${
                  activeTab === 0 ? " active" : ""
                }`}
                onClick={() => {
                  handleChangeTab(0);
                }}
              >
                Friends ({tabCounts.friends})
              </div>
              <div
                className={`friends-dialog-tab ${
                  activeTab === 1 ? " active" : ""
                }`}
                onClick={() => {
                  handleChangeTab(1);
                }}
              >
                Requests ({tabCounts.requests})
              </div>
              <div
                className={`friends-dialog-tab ${
                  activeTab === 2 ? " active" : ""
                }`}
                onClick={() => {
                  handleChangeTab(2);
                }}
              >
                Online ({tabCounts.online})
              </div>
              <div
                className={`friends-dialog-tab ${
                  activeTab === 3 ? " active" : ""
                }`}
                onClick={() => {
                  handleChangeTab(3);
                }}
              >
                Blocked ({tabCounts.blocked})
              </div>
              <div
                className={`friends-dialog-tab ${
                  activeTab === 4 ? " active" : ""
                }`}
                onClick={() => {
                  handleChangeTab(4);
                }}
              >
                Messages ({tabCounts.messages})
              </div>
            </div>
            <div className="header-close-btn" onClick={handleClose}>
              <img src={CloseIcon} alt="Close" />
            </div>
          </div>
        </div>

        <div className="social-dashboard-dialog-container">
          {activeTab === 0 && (
            <div className="friends-tab-one">
              <div className="friends-tab-one-header">
                <div className="friends-tab-one-header-title-content">
                  {searchResults && (
                    <Button
                      icon="chevronleft"
                      onClick={() => {
                        setSearchResults(null);
                        setSearchQuery("");
                      }}
                    />
                  )}
                  <div className="friends-tab-one-header-title">
                    {searchResults && "Search"}
                  </div>
                </div>
                <div>
                  <form onSubmit={handleSearchUser}>
                    <input
                      placeholder="Search"
                      type="search"
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value.trim());
                      }}
                    />
                    {inputError && <span>{inputError}</span>}
                  </form>
                </div>
              </div>

              <div className="friends-list-container">
                {loadingUsers ? (
                  <div className="friend-list-loader">
                    <LoadIndicator height={40} width={40} />
                  </div>
                ) : searchResults ? (
                  searchResults.length > 0 ? (
                    searchResults.map((item) => (
                      <div className="friend-item" key={item.user_id}>
                        <div className="profile-avatar">
                          {/* <img /> */}
                          <p>{item.game_name?.charAt(0)}</p>
                        </div>
                        <div className="friend-item-content">
                          <div className="friend-item-details">
                            <div className="title">
                              <p>{item.game_name}</p>
                              <span
                                className="friend-status"
                                style={{
                                  backgroundColor: getOnlineStatus(item.user_id)
                                    ? "greenyellow"
                                    : "transparent",
                                }}
                              />
                            </div>
                            {/* <p className="subtitle">{item.game_name}</p> */}
                          </div>
                          <div className="friend-item-actions">
                            {[...requests.sent, ...requests.received].findIndex(
                              (f) => f.user_id === item.user_id
                            ) > -1 ||
                            friends.findIndex(
                              (f) => f.user_id === item.user_id
                            ) > -1 ? (
                              ""
                            ) : (
                              <div
                                className={`tooltip`}
                                onClick={() => {
                                  sendRequestFn(item.user_id);
                                }}
                              >
                                {!sentRequests[item.user_id] && (
                                  <div className="tooltip-text">Add</div>
                                )}
                                {sentRequests[item.user_id] && (
                                  <div className="disable-button" />
                                )}
                                <img
                                  style={{
                                    cursor: sentRequests[item.user_id]
                                      ? "not-allowed"
                                      : "pointer",
                                  }}
                                  alt="Add"
                                  src={AddUserIcon}
                                />
                              </div>
                            )}
                            <div className="tooltip">
                              <div className="tooltip-text">Message</div>
                              <img
                                style={{
                                  cursor: sentRequests[item.user_id]
                                    ? "not-allowed"
                                    : "pointer",
                                }}
                                alt="Send"
                                src={SendMessageIcon}
                                onClick={() => handleMessageDialog(item)}
                              />
                            </div>

                            <div
                              className="tooltip"
                              onClick={() => blockUserFn(item.user_id)}
                            >
                              {!blockedUsers[item.user_id] && (
                                <div className="tooltip-text">Block</div>
                              )}
                              {blockedUsers[item.user_id] && (
                                <div className="disable-button" />
                              )}
                              <img
                                style={{
                                  cursor: blockedUsers[item.user_id]
                                    ? "not-allowed"
                                    : "pointer",
                                }}
                                alt="Block"
                                src={BlockUserIcon}
                              />
                            </div>
                            <div className="tooltip">
                              <div className="tooltip-text">Report</div>
                              <img
                                alt="Report"
                                src={ReportUserIcon}
                                onClick={() =>
                                  setShowReportDialog({
                                    open: true,
                                    data: item,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="user-not-found">
                      <p>No user found!</p>
                    </div>
                  )
                ) : friends.length > 0 ? (
                  friends.map((item) => (
                    <div className="friend-item" key={item.user_id}>
                      <div className="profile-avatar">
                        {/* <img /> */}
                        <p>{item.game_name?.charAt(0)}</p>
                      </div>
                      <div className="friend-item-content">
                        <div className="friend-item-details">
                          <div className="title">
                            <p>{item.game_name}</p>
                            <span
                              className="friend-status"
                              style={{
                                backgroundColor: getOnlineStatus(item.user_id)
                                  ? "greenyellow"
                                  : "transparent",
                              }}
                            />
                          </div>
                          {/* <p className="subtitle">{item.game_name}</p> */}
                        </div>
                        <div className="friend-item-actions">
                          <div
                            className="tooltip"
                            onClick={() => unfriendUserFn(item.user_id)}
                          >
                            {!unfriendUsers[item.user_id] && (
                              <div className="tooltip-text">Remove</div>
                            )}
                            {unfriendUsers[item.user_id] && (
                              <div className="disable-button" />
                            )}
                            <img
                              style={{
                                cursor: unfriendUsers[item.user_id]
                                  ? "not-allowed"
                                  : "pointer",
                              }}
                              alt="Remove"
                              src={RemoveUserIcon}
                            />
                          </div>
                          <div className="tooltip">
                            <div className="tooltip-text">Message</div>
                            <img
                              alt="Send Message"
                              src={SendMessageIcon}
                              onClick={() => handleMessageDialog(item)}
                            />
                          </div>
                          <div
                            className="tooltip"
                            onClick={() => blockUserFn(item.user_id)}
                          >
                            {!blockedUsers[item.user_id] && (
                              <div className="tooltip-text">Block</div>
                            )}
                            {blockedUsers[item.user_id] && (
                              <div className="disable-button" />
                            )}
                            <img
                              style={{
                                cursor: blockedUsers[item.user_id]
                                  ? "not-allowed"
                                  : "pointer",
                              }}
                              alt="Block"
                              src={BlockUserIcon}
                            />
                          </div>
                          <div className="tooltip">
                            <div className="tooltip-text">Report</div>
                            <img
                              alt="Report"
                              src={ReportUserIcon}
                              onClick={() =>
                                setShowReportDialog({ open: true, data: item })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="user-not-found">
                    <p>No friends found</p>
                  </div>
                )}
              </div>
            </div>
          )}

          {activeTab === 2 && (
            <div
              className="friends-list-container"
              style={{ marginTop: "16px", height: "calc(72vh - 16px)" }}
            >
              {socialData && online.length > 0 ? (
                online.map((item) => (
                  <div className="friend-item" key={item.user_id}>
                    <div className="profile-avatar">
                      {/* <img /> */}
                      <p>{item.game_name?.charAt(0)}</p>
                    </div>
                    <div className="friend-item-content">
                      <div className="friend-item-details">
                        <div className="title">
                          <p>{item.game_name}</p>
                          <span
                            className="friend-status"
                            style={{
                              backgroundColor:
                                item.online_status !== 0
                                  ? "greenyellow"
                                  : "transparent",
                            }}
                          />
                        </div>
                      </div>
                      <div className="friend-item-actions">
                        {[...requests.sent, ...requests.received].findIndex(
                          (f) => f.user_id === item.user_id
                        ) > -1 ||
                        friends.findIndex((f) => f.user_id === item.user_id) >
                          -1 ? (
                          ""
                        ) : (
                          <div
                            className={`tooltip`}
                            onClick={() => {
                              sendRequestFn(item.user_id);
                            }}
                          >
                            {!sentRequests[item.user_id] && (
                              <div className="tooltip-text">Add</div>
                            )}
                            {sentRequests[item.user_id] && (
                              <div className="disable-button" />
                            )}
                            <img
                              style={{
                                cursor: sentRequests[item.user_id]
                                  ? "not-allowed"
                                  : "pointer",
                              }}
                              alt="Add"
                              src={AddUserIcon}
                            />
                          </div>
                        )}
                        <div className="tooltip">
                          <div className="tooltip-text">Message</div>
                          <img
                            alt="Send Message"
                            src={SendMessageIcon}
                            onClick={() => handleMessageDialog(item)}
                          />
                        </div>
                        <div
                          className="tooltip"
                          onClick={() => blockUserFn(item.user_id)}
                        >
                          {!blockedUsers[item.user_id] && (
                            <div className="tooltip-text">Block</div>
                          )}
                          {blockedUsers[item.user_id] && (
                            <div className="disable-button" />
                          )}
                          <img
                            style={{
                              cursor: blockedUsers[item.user_id]
                                ? "not-allowed"
                                : "pointer",
                            }}
                            alt="Block"
                            src={BlockUserIcon}
                          />
                        </div>
                        <div className="tooltip">
                          <div className="tooltip-text">Report</div>
                          <img
                            alt="Report"
                            src={ReportUserIcon}
                            onClick={() =>
                              setShowReportDialog({ open: true, data: item })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          )}
          {activeTab === 1 && (
            <div
              className="friends-list-container"
              style={{ marginTop: "16px" }}
            >
              {socialData ? (
                <>
                  {requests.received.length > 0 && (
                    <>
                      <h6 className="requests-heading">Received</h6>
                      {requests.received.map((item) => (
                        <div className="friend-item" key={item.user_id}>
                          <div className="profile-avatar">
                            {/* <img /> */}
                            <p>{item.game_name?.charAt(0)}</p>
                          </div>
                          <div className="friend-item-content">
                            <div className="friend-item-details">
                              <div className="title">
                                <p>{item.game_name}</p>
                                {/* <span
                              className="friend-status"
                              style={{
                                backgroundColor:
                                  item.online_status !== 0
                                    ? "greenyellow"
                                    : "transparent",
                              }}
                            /> */}
                              </div>
                            </div>
                            <div className="friend-item-actions">
                              <>
                                <div
                                  className="tooltip"
                                  onClick={() => acceptRequestFn(item.user_id)}
                                >
                                  {!acceptRequests[item.user_id] && (
                                    <div className="tooltip-text">Accept</div>
                                  )}
                                  {acceptRequests[item.user_id] && (
                                    <div className="disable-button" />
                                  )}
                                  <img
                                    style={{
                                      cursor: acceptRequests[item.user_id]
                                        ? "not-allowed"
                                        : "pointer",
                                    }}
                                    alt="Accept"
                                    src={AcceptRequestIcon}
                                  />
                                </div>
                                <div
                                  className="tooltip"
                                  onClick={() => rejectRequestFn(item.user_id)}
                                >
                                  {!rejectRequests[item.user_id] && (
                                    <div className="tooltip-text">Reject</div>
                                  )}
                                  {rejectRequests[item.user_id] && (
                                    <div className="disable-button" />
                                  )}
                                  <img
                                    style={{
                                      cursor: rejectRequests[item.user_id]
                                        ? "not-allowed"
                                        : "pointer",
                                    }}
                                    alt="Reject"
                                    src={RejectRequestIcon}
                                  />
                                </div>
                                <div
                                  className="tooltip"
                                  onClick={() => blockUserFn(item.user_id)}
                                >
                                  {!blockedUsers[item.user_id] && (
                                    <div className="tooltip-text">Block</div>
                                  )}
                                  {blockedUsers[item.user_id] && (
                                    <div className="disable-button" />
                                  )}
                                  <img
                                    style={{
                                      cursor: blockedUsers[item.user_id]
                                        ? "not-allowed"
                                        : "pointer",
                                    }}
                                    alt="Block"
                                    src={BlockUserIcon}
                                  />
                                </div>
                              </>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                  {requests.sent.length > 0 && (
                    <>
                      <h6 className="requests-heading">Sent</h6>
                      {requests.sent.map((item) => (
                        <div className="friend-item" key={item.user_id}>
                          <div className="profile-avatar">
                            {/* <img /> */}
                            <p>{item.game_name?.charAt(0)}</p>
                          </div>
                          <div className="friend-item-content">
                            <div className="friend-item-details">
                              <div className="title">
                                <p>{item.game_name}</p>
                                {/* <span
                              className="friend-status"
                              style={{
                                backgroundColor:
                                  item.online_status !== 0
                                    ? "greenyellow"
                                    : "transparent",
                              }}
                            /> */}
                              </div>
                            </div>
                            <div className="friend-item-actions">
                              <>
                                <div
                                  className="tooltip"
                                  onClick={() => cancelRequestFn(item.user_id)}
                                >
                                  {!cancelRequests[item.user_id] && (
                                    <div className="tooltip-text">Cancel</div>
                                  )}
                                  {cancelRequests[item.user_id] && (
                                    <div className="disable-button" />
                                  )}
                                  <img
                                    style={{
                                      cursor: cancelRequests[item.user_id]
                                        ? "not-allowed"
                                        : "pointer",
                                    }}
                                    alt="Cancel"
                                    src={CancelRequestIcon}
                                  />
                                </div>
                                <div
                                  className="tooltip"
                                  onClick={() => blockUserFn(item.user_id)}
                                >
                                  {!blockedUsers[item.user_id] && (
                                    <div className="tooltip-text">Block</div>
                                  )}
                                  {blockedUsers[item.user_id] && (
                                    <div className="disable-button" />
                                  )}
                                  <img
                                    style={{
                                      cursor: blockedUsers[item.user_id]
                                        ? "not-allowed"
                                        : "pointer",
                                    }}
                                    alt="Block"
                                    src={BlockUserIcon}
                                  />
                                </div>
                              </>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </>
              ) : (
                <div className="user-not-found">
                  <p>No requests</p>
                </div>
              )}
            </div>
          )}
          {activeTab === 3 && (
            <div
              className="friends-list-container"
              style={{ marginTop: "16px" }}
            >
              {socialData && blocked.length > 0 ? (
                blocked.map((item) => (
                  <div className="friend-item" key={item.user_id}>
                    <div className="profile-avatar">
                      {/* <img /> */} <p>{item.game_name?.charAt(0)}</p>
                    </div>
                    <div className="friend-item-content">
                      <div className="friend-item-details">
                        <div className="title">
                          <p>{item.game_name}</p>
                        </div>
                      </div>
                      <div className="friend-item-actions">
                        <div
                          className="tooltip"
                          onClick={() => unblockUserFn(item.user_id)}
                        >
                          {!unblockedUsers[item.user_id] && (
                            <div className="tooltip-text">Unblock</div>
                          )}
                          {unblockedUsers[item.user_id] && (
                            <div className="disable-button" />
                          )}
                          <img
                            style={{
                              cursor: unblockedUsers[item.user_id]
                                ? "not-allowed"
                                : "pointer",
                            }}
                            alt="Unblock"
                            src={AddUserIcon}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          )}
          {activeTab === 4 && (
            <div
              className="messages-list-container"
              style={{ marginTop: "16px" }}
            >
              {socialData && messageByUsers.length > 0 ? (
                messageByUsers.map((item) => (
                  <div
                    className="message-item"
                    key={item.user_id}
                    onClick={() => {
                      setShowMessageDialog({ open: true, user: item });
                      if (item.unreadMessages > 0) {
                        markMessagesReadFn(item.user_id);
                      }
                    }}
                  >
                    <div className="profile-avatar">
                      <p>{item.game_name?.charAt(0)}</p>
                    </div>
                    <div className="message-item-content">
                      <div className="message-item-details">
                        <div className="title-container">
                          <div className="title">
                            <p>{item.game_name}</p>
                            <span
                              className="friend-status"
                              style={{
                                backgroundColor: getOnlineStatus(item.user_id)
                                  ? "greenyellow"
                                  : "transparent",
                              }}
                            />
                          </div>

                          {item.unreadMessages > 0 ? (
                            <span className="unread-count">
                              {item.unreadMessages}
                            </span>
                          ) : (
                            <Button className="more-icon" icon="chevrondown" />
                          )}
                        </div>
                        <div className="message-body">
                          <p
                            className={`message ${
                              item.unreadMessages > 0 && "unread-message"
                            }`}
                          >
                            "{item.lastMessage.replaceAll("<br/>", " ")}"
                          </p>
                          <p className="time">{item.when_sent}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      </div>
      {showReportDialog.open && (
        <ReportDialog
          closeDialog={() => setShowReportDialog({ open: false, data: null })}
          reportUser={reportUserFn}
          data={showReportDialog.data}
        />
      )}
      {showMessageDialog.open && (
        <SendMessageDialog
          closeDialog={() => setShowMessageDialog({ open: false, user: null })}
          data={showMessageDialog.user}
          messages={messages}
          getOnlineStatus={getOnlineStatus}
          markMessagesReadFn={markMessagesReadFn}
        />
      )}
    </DialogPortal>
  );
};

export default FriendsDialog;
