import React from "react";
import { useSelector } from "react-redux";
import {
  Switch,
  Route,
  Redirect,
  matchPath,
  useLocation,
} from "react-router-dom";
import SingleCard from "./components/single-card/SingleCard";
import Footer from "./Footer";

const publicUrls = [
  "/login",
  "/login/2fa",
  "/register",
  "/vr-register",
  "/register/:referrelCode",
  "/self-service/confirm/:token",
  "/forgot-password",
  "/reset-password/:token",
  "/base_chart",
];

const LoginFormPage = React.lazy(() =>
  import("./containers/login-form/LoginForm")
);
const Login2FAFormPage = React.lazy(() =>
  import("./containers/login-form/Login2faForm")
);
const ResetPasswordFormPage = React.lazy(() =>
  import("./containers/reset-password-form/ResetPasswordForm")
);
const ChangePasswordFormPage = React.lazy(() =>
  import("./containers/change-password-form/ChangePasswordForm")
);
const OpenAccountFormPage = React.lazy(() =>
  import("./containers/register-form/OpenAccountForm")
);
const OpenVRAccountFormPage = React.lazy(() =>
  import("./containers/register-form/OpenVRAccountForm")
);
const SetupPasswordFormPage = React.lazy(() =>
  import("./containers/register-form/SetupPasswordForm")
);
const ChartContainer = React.lazy(() =>
  import("./containers/public/trading-dashboard/ChartContainer")
);

const UnauthenticatedContent = () => {
  const { pathname } = useLocation();
  const isRegisterDone = useSelector((state) => state.auth.isRegisterDone);
  const isRequestDone = useSelector((state) => state.auth.isRequestDone);
  // const registerPath = ["/register/:referrelCode", "/register"];
  return (
    <div>
      <React.Suspense fallback={<div>Loading...</div>}>
        <div className={"container"}>
          <div id="scrollview" className={"with-footer px-single-card"}>
            <Switch>
              <Route exact path="/login">
                <SingleCard title={""} isSecondbannerEnable={false}>
                  <LoginFormPage />
                </SingleCard>
              </Route>
              <Route exact path="/login/2fa">
                <SingleCard
                  resize={false}
                  title={""}
                  isSecondbannerEnable={false}
                >
                  <Login2FAFormPage />
                </SingleCard>
              </Route>
              <Route exact path={"/register"}>
                <SingleCard
                  resize={isRegisterDone}
                  title={`${isRegisterDone ? "" : ""}`}
                >
                  <OpenAccountFormPage />
                </SingleCard>
              </Route>
              <Route exact path={"/vr-register"}>
                <SingleCard resize={isRegisterDone} title={""}>
                  <OpenVRAccountFormPage />
                </SingleCard>
              </Route>
              <Route exact path="/self-service/confirm/:token">
                <SingleCard
                  resize={true}
                  width="400px"
                  logo={false}
                  title="Complete Registration"
                  description={
                    <>
                      <p className={"description no-margin"}>
                        Thank you for confirming your email. You may now
                        complete the registration process to access your new
                        account.
                      </p>
                    </>
                  }
                >
                  <SetupPasswordFormPage />
                </SingleCard>
              </Route>
              <Route exact path="/forgot-password">
                <SingleCard
                  title={`${isRequestDone ? "" : "Forgot Password"}`}
                  resize={isRequestDone}
                  isSecondbannerEnable={false}
                >
                  <ResetPasswordFormPage />
                </SingleCard>
              </Route>
              <Route exact path="/reset-password/:token">
                <SingleCard resize={true} width="450px" title="Reset Password">
                  <ChangePasswordFormPage />
                </SingleCard>
              </Route>
              <Route exact path="/base_chart">
                <ChartContainer />
              </Route>
              <Redirect to={"/login"} />
            </Switch>
            {!pathname.includes("base_chart") && <Footer />}
          </div>
        </div>
      </React.Suspense>
    </div>
  );
};

export const isPublicContent = (pathname) => {
  return publicUrls.some(
    (path) => !!matchPath(pathname, { path, exact: true })
  );
};

export default UnauthenticatedContent;
