import React from "react";
import DataGrid, { Column, Paging, Pager } from "devextreme-react/data-grid";
import { ALLOWED_PAGE_SIZES_SMALL } from "../../constants";

const AccountStatsAndHoldings = ({ holdings, stats }) => {
  const updatedStats =
    stats &&
    stats.map((item) => {
      return {
        ...item,
        stats_date: item.stats_date.replace("Z", " ").replace("T", " "),
      };
    });

  return (
    <React.Fragment>
      <p className="tab-title">HOLDINGS</p>
      <DataGrid
        className={"dx-card wide-card"}
        dataSource={holdings}
        showBorders={true}
        keyExpr="holding_id"
        focusedRowEnabled={true}
        defaultFocusedRowIndex={false}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        allowEditing={true}
      >
        <Paging defaultPageSize={ALLOWED_PAGE_SIZES_SMALL[0]} />
        <Pager
          visible={true}
          allowedPageSizes={ALLOWED_PAGE_SIZES_SMALL}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Column dataField={"asset"} caption={"ASSET"} hidingPriority={6} />
        <Column
          dataField={"price"}
          caption={"CURRENT PRICE"}
          hidingPriority={2}
        />
        <Column
          dataField={"holding"}
          caption={"QUANTITY HELD"}
          hidingPriority={2}
        />
        <Column
          dataField={"asset_value"}
          caption={"ASSET VALUE"}
          hidingPriority={2}
        />
        <Column
          dataField={"percent_account_value"}
          caption={"% ACCOUNT VALUE"}
          hidingPriority={2}
        />
      </DataGrid>
      <p className="tab-title">STATS</p>
      <DataGrid
        width={"100%"}
        className={"dx-card wide-card"}
        dataSource={updatedStats}
        showBorders={true}
        keyExpr="stats_id"
        focusedRowEnabled={true}
        defaultFocusedRowIndex={false}
        columnAutoWidth={true}
        columnHidingEnabled={true}
      >
        <Paging defaultPageSize={ALLOWED_PAGE_SIZES_SMALL[0]} />
        <Pager
          visible={true}
          allowedPageSizes={ALLOWED_PAGE_SIZES_SMALL}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Column
          dataField={"stats_date"}
          caption={"STATS DATE"}
          hidingPriority={2}
          alignment={"center"}
        />
        <Column
          dataField={"value_at_opening"}
          caption={"VALUE AT OPENING"}
          hidingPriority={2}
          alignment={"center"}
        />
        <Column
          dataField={"value_at_start_of_quarter"}
          caption={"VALUE AT START OF QUARTER"}
          hidingPriority={2}
          alignment={"center"}
        />
        <Column
          dataField={"value_now"}
          caption={"VALUE NOW"}
          hidingPriority={2}
          alignment={"center"}
        />
        <Column
          dataField={"sharpe_ratio"}
          caption={"SHARPE RATIO"}
          alignment={"center"}
          hidingPriority={6}
        />
        <Column
          dataField={"sortino_ratio"}
          caption={"SORTINO RATIO"}
          alignment={"center"}
          hidingPriority={6}
        />
      </DataGrid>
    </React.Fragment>
  );
};

export default AccountStatsAndHoldings;
