import React, { useState, createContext, useContext } from "react";

function AppDataProvider(props) {
  const [loading, setLoading] = useState(true);
  const [loginErrorMsg, setLoginErrorMsg] = useState("");

  return (
    <AppDataContext.Provider
      value={{ loading, setLoading, loginErrorMsg, setLoginErrorMsg }}
      {...props}
    />
  );
}

const AppDataContext = createContext({});
const useAppData = () => useContext(AppDataContext);

export { AppDataProvider, useAppData };
