import React, { useEffect, useState } from "react";
import { ScrollView } from "devextreme-react";
import { useHistory } from "react-router-dom";

import { FiTarget } from "react-icons/fi";
import { IconButton, Button } from "@material-ui/core";
import {
  MdOutlineKeyboardArrowUp,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";

import { useSelector } from "react-redux";

import { setRankingsData } from "../../redux/dashboard/action";
import { setSelectedAccount } from "../../redux/accounts/action";
import { handleAccountMode } from "../../redux/auth/action";

const ActiveCompetitions = ({ joinedCompetition, competitions, dispatch }) => {
  const history = useHistory();
  const currentTheme = useSelector(
    (state) => state.tradingDashboard.currentTheme
  );
  const user = useSelector((state) => state.auth.info);
  const selectedAccount = useSelector((state) => state.accounts.account);
  const activeAccounts = useSelector(
    (state) => state.accounts.activeCompetitions
  );

  useEffect(() => {
    const fetchData = async () => {
      const suspendedItems = joinedCompetition.find(
        (item) => item.status?.toLowerCase() === "suspended"
      );
      if (suspendedItems) {
        const data = suspendedItems.entrants?.map((item) => {
          return {
            rank: item.rank,
            game_name: item.game_name,
            user_id: item.user_id,
          };
        });
        await dispatch(setRankingsData(data));
      }
    };

    fetchData();

    // eslint-disable-next-line
  }, [competitions]);

  return (
    <>
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          height: "30px",
          width: "100%",
          marginBottom: 8,
        }}
      >
        <p
          className="margin-bottom-50"
          style={{
            fontWeight: "bold",
            fontSize: "13px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FiTarget color="#8bc441" size={24} style={{ marginRight: "8px" }} />{" "}
          JOINED COMPETITION
        </p>
      </div>
      <ScrollView direction="vertical">
        <div className="table-container">
          <table className="px-competition-table">
            <thead>
              <tr>
                <th style={{ width: "20px" }}></th>
                {/* <th>ID</th>
                  <th>DURATION</th> */}
                <th>NAME</th>
                <th>END TIME</th>
                <th>SWITCH</th>
                {/* <th>PROFIT</th> */}
              </tr>
            </thead>
            <tbody>
              {joinedCompetition && joinedCompetition.length > 0 ? (
                joinedCompetition.map((item) => (
                  <Row
                    key={item.competition_id}
                    row={item}
                    user={user}
                    currentTheme={currentTheme}
                    selectedAccount={selectedAccount}
                    activeAccounts={activeAccounts}
                    history={history}
                    dispatch={dispatch}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan={10}>No data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </ScrollView>
    </>
  );
};

const Row = ({
  user,
  row,
  currentTheme,
  selectedAccount,
  dispatch,
  activeAccounts,
  history,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <tr style={{ borderBottom: "unset" }}>
        <td style={{ width: "20px" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            style={{ background: "transparent" }}
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <MdOutlineKeyboardArrowUp
                size={18}
                color={currentTheme === "Light" ? "#444444" : "#ffffff"}
              />
            ) : (
              <MdOutlineKeyboardArrowDown
                size={18}
                color={currentTheme === "Light" ? "#444444" : "#ffffff"}
              />
            )}
          </IconButton>
        </td>
        <td>{row?.description}</td>
        {/* <td >{row?.duration} Min</td> */}
        <td>
          {row.status.toLowerCase() !== "pending"
            ? new Date(row.end_time * 1000).toLocaleString()
            : "-"}
        </td>
        {/* <td >
                      {row?.entrants?.find(
                        (row) => row.game_name === "Pranav"
                      )?.rank ?? ""}
                    </td>
                    <td >
                      {row?.entrants?.find(
                        (row) => row.game_name === "Pranav"
                      )?.balance ?? 0}
                    </td> */}
        <td style={{ width: "60px" }}>
          {
            <Button
              size="small"
              fullWidth
              variant="text"
              onClick={() => {
                if (activeAccounts) {
                  const findAccount = activeAccounts.find(
                    (ac) => ac.competition_id === row.competition_id
                  );

                  if (findAccount) {
                    if (
                      selectedAccount &&
                      selectedAccount.competition_id !==
                        findAccount.competition_id
                    ) {
                      dispatch(setSelectedAccount(findAccount));
                      dispatch(handleAccountMode("competition"));
                    }
                  }
                  history.push("/trading-dashboard");
                }
              }}
            >
              SELECT
            </Button>
          }
        </td>
      </tr>
      {open && (
        <tr>
          <td colSpan={5}>
            <table className="joined-inner-table">
              <thead>
                <tr>
                  <th>RANK</th>
                  <th>TRADERS</th>
                  <th>PROFIT</th>
                </tr>
              </thead>
              <tbody>
                {row.entrants.length > 10 ? (
                  row.entrants
                    .slice(0, 10)
                    .find((e) => e.user_id === user.client_user_id) ? (
                    row.entrants.slice(0, 10).map((e) => (
                      <tr key={e.user_id}>
                        <td>{e.rank}</td>
                        <td>{e.game_name}</td>
                        <td>{e.balance?.toFixed(2)}</td>
                      </tr>
                    ))
                  ) : (
                    <>
                      {row.entrants.slice(0, 9).map((e) => (
                        <tr key={e.user_id}>
                          <td>{e.rank}</td>
                          <td>{e.game_name}</td>
                          <td>{e.balance?.toFixed(2)}</td>
                        </tr>
                      ))}

                      {row.entrants.find(
                        (e) => e.user_id === user.client_user_id
                      ) ? (
                        <tr>
                          <td>
                            {
                              row.entrants.find(
                                (e) => e.user_id === user.client_user_id
                              ).rank
                            }
                          </td>
                          <td>
                            {
                              row.entrants.find(
                                (e) => e.user_id === user.client_user_id
                              ).game_name
                            }
                          </td>
                          <td>
                            {row.entrants
                              .find((e) => e.user_id === user.client_user_id)
                              .balance?.toFixed(2)}
                          </td>
                        </tr>
                      ) : null}
                    </>
                  )
                ) : (
                  row.entrants.map((e) => (
                    <tr key={e.user_id}>
                      <td>{e.rank}</td>
                      <td>{e.game_name}</td>
                      <td>{e.balance?.toFixed(2)}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

export default ActiveCompetitions;
