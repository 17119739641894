import React from "react";

import Chart, {
  ArgumentAxis,
  CommonSeriesSettings,
  Series,
  Tooltip,
  ValueAxis,
  Label,
  Legend,
} from 'devextreme-react/chart';

function customizeTooltip(pointInfo, competitionReverse) {
  return {
    html: `<div><div class="tooltip-header">${pointInfo.argumentText
      }</div><div class="tooltip-body"><div class="series-name"><span class='top-series-name'>Total_${competitionReverse}</span>: </div><div class="value-text"><span class='top-series-value'>${pointInfo.points[0].valueText
      }</span></div><div class="series-name"><span class='bottom-series-name'>${competitionReverse}</span>: </div><div class="value-text"><span class='bottom-series-value'>${pointInfo.points[1].valueText}</span></div></div></div>`,
  };
}

const TotalImersGemsChart = ({ earningsData, competitionReverse }) => {

  return (
    <Chart
      title=""
      dataSource={earningsData}
      palette="Harmony Light"
      id="chart"
    >
      <CommonSeriesSettings argumentField="date" />
      <Series
        name="Total Imers"
        valueField="balance"
        axis="totalImersAxis"
        type="spline"
        color="green"
      />
      <Series
        name="Imers"
        valueField="quantity"
        axis="imersAxis"
        type="bar"
        barWidth={20}
        color="lightblue"
      />
      <ArgumentAxis visualRange={{ startValue: 0, endValue: 100 }}>
        <Label overlappingBehavior="stagger" />
      </ArgumentAxis>
      <ArgumentAxis argumentType="" />
      <ValueAxis
        name="totalImersAxis"
        position="left"
      />
      <ValueAxis
        name="imersAxis"
        position="right"
        showZero={true}
        valueMarginsEnabled={false}
      >
      </ValueAxis>
      <Legend visible={false} />
      <Tooltip
        enabled={true}
        shared={true}
        customizeTooltip={(e) => customizeTooltip(e, competitionReverse)}
      />
    </Chart>
  );
};
export default TotalImersGemsChart;