import React, { useState } from "react";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Button } from "devextreme-react/button";

export default function CustomItem({ data, clickHandler, removeChannel }) {
  const {
    user_social_network_id,
    social_network_id,
    IconSrc,
    status_name,
    name,
    url,
    description,
    status,
  } = data;
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="px-accordion-content">
      <div style={{ display: "grid" }}>
        <div
          style={{
            backgroundImage: `url(${IconSrc})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            width: 60,
            height: 60,
            backgroundRepeat: "no-repeat",
          }}
        />
        <span className="px-title">
          {name}
          <i
            class="dx-icon-link"
            style={{
              cursor: "pointer",
              fontSize: "20px",
              verticalAlign: "bottom",
              marginLeft: 5
            }}
            onClick={() => {
              window.open(url, "_blank");
            }}
          ></i>
        </span>
        <span style={{ fontSize: 16, fontWeight: "bold", marginTop: 10 }}>
          STATUS: {status_name}
        </span>
        <p>
          {description} {name}
        </p>
        {status !== 1 && (
          <div style={{ display: "flex" }}>
            <Button
              className="px-channel-buttons"
              style={{ marginRight: 10 }}
              width={"50%"}
              onClick={async () => {
                setIsLoading(true);
                await removeChannel(user_social_network_id);
                setIsLoading(false);
              }}
            >
              <LoadIndicator className="button-indicator" visible={isLoading} />
              <span className="dx-button-text">REMOVE</span>
            </Button>
            {/* <Button type="primary" text="REMOVE" style={{ marginRight: 10 }} onClick={() => removeChannel(social_network_id)} /> */}
            <Button
              type="default"
              text="UPDATE"
              width={"50%"}
              style={{ marginRight: 10 }}
              onClick={() => clickHandler(social_network_id)}
            />
          </div>
        )}
        {status === 1 && (
          <Button
            type="default"
            text="JOIN"
            style={{ marginRight: 10 }}
            onClick={() => clickHandler(social_network_id)}
          />
        )}
      </div>
    </div>
  );
}
