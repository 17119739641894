import React from "react";
import { useState } from "react";
import { Button } from "devextreme-react/button";
import { Popup } from "devextreme-react/popup";
import { NumberBox } from "devextreme-react/number-box";
import { MAIN_TOKEN_KEY } from "../constants";
import { get } from "../utils/storage";
import { useDispatch } from "react-redux";
import { placeOrderData } from "../redux/tradingDashboard/action";

const OrderPadPopup = (props) => {
  const dispatch = useDispatch();
  const { handlePopup, setActiveOrderData, orderPadData } = props;

  const [type, setType] = useState("market");
  const [quantity, setQuantity] = useState(0);
  const [orderPrice, setOrderPrice] = useState(
    parseFloat(orderPadData?.oldValue)
  );

  const handleBuySell = async (buysellType) => {
    const haderParams = {
      token: `${get(MAIN_TOKEN_KEY).split('"')[1]}`,
      exchange: orderPadData?.exchangeName || "IMERS",
      buysell: buysellType,
      quantity,
      basecurrency: orderPadData?.marketName?.split?.("/")?.[0],
      quotecurrency: orderPadData?.marketName?.split?.("/")?.[1],
      ordertype: type,
      orderprice: type !== "market" ? orderPrice : 0,
      ordermode: orderPadData?.accMode,
      clientaccountid: orderPadData.accountId,
    };
    const orderId = await dispatch(placeOrderData(haderParams));

    const activeOrderData = {
      orderId,
      buysell: buysellType,
      quantity,
      order_type: type,
      price_level: type !== "market" ? orderPrice : 0,
      status: 1,
      status_str: "Requested",
      reason: "",
      quantity_remaining: quantity,
      external_ref: "",
      exchange: orderPadData?.exchangeName || "IMERS",
      base_symbol: orderPadData?.marketName?.split?.("/")?.[0],
      quote_symbol: orderPadData?.marketName?.split?.("/")?.[1],
    };

    if (orderId) setActiveOrderData(activeOrderData);
    handlePopup();
  };
  const renderContent = () => {
    return (
      <div>
        <div
          className="px-order-pad-header"
          style={{
            height: 100,
            marginBottom: 20,
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div className="px-property">
            <p style={{ fontSize: 20 }}>EXCHANGE NAME</p>
            <span style={{ fontSize: 20, fontWeight: "bold" }}>
              {orderPadData?.exchangeName.toUpperCase()}
            </span>
          </div>
          <div className="px-property">
            <p style={{ fontSize: 20 }}>MARKET NAME</p>
            <span style={{ fontSize: 20, fontWeight: "bold" }}>
              {orderPadData?.marketName}
            </span>
          </div>
          <div className="px-property">
            <p style={{ fontSize: 20 }}>ACCOUNT NAME</p>
            <span style={{ fontSize: 20, fontWeight: "bold" }}>
              {orderPadData?.accountName}
            </span>
          </div>
        </div>
        <div
          className="px-order-pad-content"
          style={{ marginTop: 40, height: 200, display: "flex" }}
        >
          <div
            style={{ width: "70%", height: "100%", padding: "0px 20px 0 0" }}
          >
            <div className="dx-field" style={{ display: "grid" }}>
              {(type === "limit" || type === "stop") && (
                <>
                  <div
                    className="dx-field-label"
                    style={{ fontSize: 14 }}
                  >{`${type.toUpperCase()} PRICE`}</div>
                  <div className="dx-field-value" style={{ width: "80%" }}>
                    <NumberBox
                      width={"100%"}
                      style={{ marginBottom: 20 }}
                      showSpinButtons={true}
                      format={"#.##"}
                      value={orderPrice}
                      onValueChanged={(e) => setOrderPrice(e.value)}
                    ></NumberBox>
                  </div>
                </>
              )}
              <>
                <div className="dx-field-label" style={{ fontSize: 14 }}>
                  QUANTITY
                </div>
                <div className="dx-field-value" style={{ width: "80%" }}>
                  <NumberBox
                    width={"100%"}
                    format={"#.##"}
                    showSpinButtons={true}
                    value={quantity}
                    onValueChanged={(e) => setQuantity(e.value)}
                  ></NumberBox>
                </div>
              </>
            </div>
          </div>
          <div style={{ width: "30%", height: "100%", paddingTop: 20 }}>
            <Button
              width={"100%"}
              style={{ marginBottom: 20 }}
              type={`${type === "market" ? "default" : "normal"}`}
              stylingMode="contained"
              text="MARKET"
              onClick={() => setType("market")}
            />
            <Button
              width={"100%"}
              style={{ marginBottom: 20 }}
              type={`${type === "limit" ? "default" : "normal"}`}
              stylingMode="contained"
              text="LIMIT"
              onClick={() => setType("limit")}
            />
            <Button
              width={"100%"}
              style={{ marginBottom: 20 }}
              type={`${type === "stop" ? "default" : "normal"}`}
              stylingMode="contained"
              text="STOP"
              onClick={() => setType("stop")}
            />
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <Button
            width={"100%"}
            style={{ marginRight: 10 }}
            type="success"
            stylingMode="contained"
            text="BUY"
            onClick={(e) => handleBuySell("buy")}
          />
          <Button
            width={"100%"}
            type="danger"
            stylingMode="contained"
            text="SELL"
            onClick={(e) => handleBuySell("sell")}
          />
        </div>
      </div>
    );
  };

  return (
    <Popup
      visible={true}
      width={800}
      height={500}
      position={"center"}
      showCloseButton={true}
      closeOnOutsideClick={false}
      contentRender={renderContent}
      onHiding={handlePopup}
      title={``}
    />
  );
};

export default OrderPadPopup;
