import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ContextMenu, { Position } from "devextreme-react/context-menu";
import List from "devextreme-react/list";
import { logout } from "../../redux/auth/action";
// import { useSocialContext } from "../../contexts/socialDashboard";
import "./UserPanel.scss";

const UserPanel = ({ pxMenuMode }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const { setOpenSocialDialog, counts } = useSocialContext();
  const user = useSelector((state) => state.auth.info);
  const initialUserInfo = useSelector((state) => state.selfServiceUser.info);

  const handleLogOut = () => {
    dispatch(logout());
  };

  const goToProfile = () => {
    history.push("/settings");
  };

  // const openSocialDashboard = () => {
  //   setOpenSocialDialog({ open: true, tab: 0 });
  // };

  const menuItems = useMemo(
    () => [
      // {
      //   text: `Notifications (${counts?.friendsCount + counts?.messagesCount})`,
      //   icon: "fa fa-bell",
      //   onClick: openSocialDashboard,
      // },
      {
        text: "Profile",
        icon: "user",
        onClick: goToProfile,
      },
      {
        text: "Logout",
        icon: "runner",
        onClick: handleLogOut,
      },
      /* eslint-disable react-hooks/exhaustive-deps */
    ],
    []
  );

  const truncateDisplayName = (name) => {
    if (name?.length > 19) {
      return name.slice(0, 16) + "...";
    } else return name;
  };

  return (
    <div className={"user-panel"}>
      <div
        className={"user-info"}
        style={{
          height: 60,
          display: "inline-grid",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className={"image-container"}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "grey",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              color: "white",
              fontWeight: "bold",
            }}
            className={"user-image"}
          >
            {user?.initials}
          </div>
        </div>
        <span style={{ fontSize: 16, fontWeight: "Bold" }}>
          {truncateDisplayName(initialUserInfo?.display_name)}
        </span>
      </div>
      {pxMenuMode === "context" && (
        <ContextMenu
          items={menuItems}
          target={".user-button"}
          showEvent={"dxclick"}
          width={210}
          cssClass={"user-menu"}
        >
          <Position my={"top center"} at={"bottom center"} />
        </ContextMenu>
      )}
      {pxMenuMode === "list" && (
        <List className={"dx-toolbar-menu-action"} items={menuItems} />
      )}
    </div>
  );
};

export default UserPanel;
