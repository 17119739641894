import React, { useMemo, useRef, useState } from "react";
import Form, {
  Item,
  GroupItem,
  ButtonItem,
  RequiredRule,
  Label,
  ButtonOptions,
} from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import LoadIndicator from "devextreme-react/load-indicator";
import Error from "./Error";
import {
  CONFIRM_CODE_SUCCESS_MSG,
  exchangeMarketDefaultOption,
} from "../constants";
import { useToast } from "../contexts/toast";
import { useDispatch } from "react-redux";
import {
  addNewExchange,
  addNewMarket,
  createScreen,
} from "../redux/tradingDashboard/action";

const AddExchangeMarketPopup = ({
  type = null,
  handlePopup,
  getInitialData,
  getInitialScreensData,
  dataSource,
  selectedScreenID,
  setLoadingIndicator,
}) => {
  const dispatch = useDispatch();
  const formData = useRef({});
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const defaultOption = useMemo(() => {
    if (type !== "Screen") {
      return {
        ...exchangeMarketDefaultOption,
        width: "100%",
        items: dataSource,
        searchEnabled: true,
        searchExpr: [type.toLowerCase()],
        valueExpr: type.toLowerCase(),
        displayExpr: function (data) {
          if (data) return data[type.toLowerCase()];
        },
      };
    }

    return exchangeMarketDefaultOption;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, type]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name } = formData.current;

    setLoading(true);
    setErrorMsg("");
    try {
      // Dispatch
      if (type === "Market")
        await dispatch(addNewMarket(selectedScreenID, { market: name }));
      if (type === "Exchange")
        await dispatch(addNewExchange(selectedScreenID, { exchange: name }));
      if (type === "NewScreen")
        await dispatch(createScreen({ screen_name: name }));
      if (type === "CloneScreen")
        await dispatch(
          createScreen({ screen_name: name, clone_screen_id: selectedScreenID })
        );

      setLoading(false);
      showToast(CONFIRM_CODE_SUCCESS_MSG, "success");
      handlePopup();
      setLoadingIndicator(true);
      type !== "Market" && type !== "Exchange"
        ? await getInitialScreensData()
        : await getInitialData();
    } catch (error) {
      setLoading(false);
      setErrorMsg(error.message);
    }
  };

  const cleanFormData = () => {
    return (formData.current.code = null);
  };

  const renderContent = () => {
    return (
      <form className={"change-password-form"} onSubmit={handleSubmit}>
        {!!errorMsg && <Error>{errorMsg}</Error>}
        <Form
          formData={formData.current}
          disabled={loading}
          readOnly={false}
          showColonAfterLabel={true}
          validationGroup="currentData"
        >
          <Item
            dataField="name"
            editorType={`${
              !type.includes("Screen") ? "dxSelectBox" : "dxTextBox"
            }`}
            editorOptions={defaultOption}
          >
            <RequiredRule message="The name is required" />
            <Label visible={false} />
          </Item>
          <GroupItem colCount={2}>
            <ButtonItem>
              <ButtonOptions
                width={"100%"}
                type={"default"}
                useSubmitBehavior={true}
              >
                <span className="dx-button-text">
                  {loading ? (
                    <LoadIndicator
                      width={"24px"}
                      height={"24px"}
                      visible={true}
                    />
                  ) : (
                    "Confirm"
                  )}
                </span>
              </ButtonOptions>
            </ButtonItem>
            <ButtonItem horizontalAlignment="right">
              <ButtonOptions
                width="100%"
                type="normal"
                text="Cancel"
                useSubmitBehavior={false}
                onClick={() => {
                  handlePopup();
                  setErrorMsg("");
                  cleanFormData();
                }}
              />
            </ButtonItem>
          </GroupItem>
        </Form>
      </form>
    );
  };

  return (
    <Popup
      visible={true}
      width={500}
      height={280}
      showCloseButton={false}
      className="px-exchange-market-popup"
      position={"center"}
      closeOnOutsideClick={false}
      contentRender={renderContent}
      title={`Add ${type}`}
    />
  );
};

export default AddExchangeMarketPopup;
