import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid";
import AllocationWarningPopup from "./AllocationWarningPopup";
import {
  getAssetAllocationByAccountID,
  updateAssetAllocationByAccountID,
} from "../../redux/manage-asset/action";
import {
  addNewAccountToList,
  setAccountInformation,
  setSelectedAccount,
  setSelfServiceAccounts,
} from "../../redux/accounts/action";
import { useToast } from "../../contexts/toast";
import { useContentLoading } from "../../contexts/contentLoading";
import {
  changeReqPayloadFormat,
  joinWithoutDupes,
} from "../../utils/custom-functions";
import { SETUP_ACCOUNT_SUCCESS_MSG } from "../../constants";
import { useScreenSize } from "../../utils/media-query";
import Strategy from "./Strategy";
import { NumberBox } from "devextreme-react";
import { updateOnboardingElementStatusByID } from "../../redux/dashboard/action";
import ConfirmationAskingPopup from "../profile-settings/ConfirmationAskingPopup";
import { ProfileFormEditedBefore } from "../../redux/auth/action";
import Joyride, { STATUS } from "react-joyride";

const BasicBasketTrading = ({
  accountID,
  isSetup,
  expertGUI,
  managementTypeID,
  previousStep = null,
  setSelectedIndex = null,
  onboardingStatusInfo = null,
}) => {
  const assetsInAccountDataGrid = useRef(null);
  const assetsForBasketDataGrid = useRef(null);
  const assetsInBasketDataGrid = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const { setLoadingIndicator } = useContentLoading();
  const screenSize = useScreenSize();
  const accounts = useSelector((state) => state.accounts.list);
  const account = useSelector((state) => state.accounts.info);
  const assetsInfo = useSelector((state) => state.manageAsset.assetsInfo);
  const newAssetsForBasket = useSelector(
    (state) => state.accounts.basketAssets
  );
  const { client_user_id } = useSelector((state) => state.auth.info);

  const [commitableAllocations, setCommitableAllocations] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedIndexInBasket, setSelectedIndexInBasket] = useState(null);
  const [selectedBasketAssets, setSelectedBasketAssets] = useState([]);
  const [opened, setOpened] = useState(false);
  const [allocatedAccountBalance, setAllocatedAccountBalance] = useState(0);
  const [visibleOptionsPopup, setVisibleWarningPopup] = useState(false);
  const [enableOnboarding, setEnableOnboarding] = useState(
    onboardingStatusInfo?.visible || false
  );
  const [isPopup, setIsPopup] = useState(false);

  const screenWidth =
    screenSize.isLarge || screenSize.isMedium ? "100%" : "100%";
  const displayMethod =
    screenSize.isLarge || screenSize.isMedium ? "flex" : "block";

  const steps = [
    {
      title: <p style={{ textAlign: "left" }}>ADD & REMOVING ASSETS</p>,
      content:
        <p style={{ textAlign: "left" }}>Adding and removing assets to your basket as simple as selecting from the list and clicking the appropriate arrow.</p>,
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 10,
      target: "#px-chevron-buttons",
      disableBeacon: true,
    },
    {
      title: <p style={{ textAlign: "left" }}>ADJUST ASSET BASKET</p>,
      content:
        <p style={{ textAlign: "left" }}>It is as simple as typing in the percentage you wish your asset value to be and you will start utilising the CyberTools management services.</p>,
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 10,
      target: "#px-number-box",
      disableBeacon: false,
    },
    {
      title: <p style={{ textAlign: "left" }}>COMPLETE SETUP</p>,
      content: (
        <div style={{ display: "grid", textAlign: "left" }}>
          <>Click 'COMPLETE SETUP' to view your account dashboard.</>
          <p>
            <input
              id="checkbox"
              type="checkbox"
              onChange={async (e) => {
                const { ui_element_id } = onboardingStatusInfo;
                if (e.target.checked) {
                  await dispatch(
                    updateOnboardingElementStatusByID(
                      client_user_id,
                      ui_element_id,
                      false
                    )
                  );
                } else {
                  await dispatch(
                    updateOnboardingElementStatusByID(
                      client_user_id,
                      ui_element_id,
                      true
                    )
                  );
                }
              }}
            />
            Don't show this again
          </p>
        </div>
      ),
      floaterProps: {
        disableAnimation: true,
      },
      target: "#px-setup-button",
      disableBeacon: false,
    },
  ];

  useEffect(() => {
    if (!assetsInfo) return;

    // Clone the allocations data from the source.
    const data = JSON.parse(JSON.stringify(assetsInfo.allocations));
    const basketAssetsInAllocationsData = data.filter(
      (item) => item.current_model_id === 2
    );
    setSelectedBasketAssets(basketAssetsInAllocationsData);
    setCommitableAllocations(data);

    return () => {
      setOpened(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetsInfo]);

  useEffect(() => {
    return () => {
      if (account?.management_type_id !== managementTypeID) setIsPopup(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managementTypeID]);

  useEffect(() => {
    if (!commitableAllocations) return;

    var totalTargetPercentage = 0;

    // The calculation method runs on Risk-managed assets.
    // Total Holding = sum of each asset's holding + base currency.
    // Total Target = sum of each asset's target.
    // Holding = Actual position * price.
    // Target = Target Quantity * price.
    selectedBasketAssets.map((item) => {
      const { current_model_id: curModelID } = item;
      if (curModelID === 2) {
        const { target_percentage } = item;
        totalTargetPercentage += target_percentage;
      }
      return item;
    });
    setAllocatedAccountBalance(totalTargetPercentage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBasketAssets]);

  const handleManagementType = (pxValue) => {
    // Pending for setting management type.

    setOpened(false);
  };

  const handleWarningOption = (value) => {
    switch (value) {
      case 1:
        allocateEachTargetPercentage();
        dispatchCommitAllocations();
        setVisibleWarningPopup(false);
        break;
      case 2:
        setVisibleWarningPopup(false);
        break;
      default:
        break;
    }
  };

  const dispatchCommitAllocations = async () => {
    dispatch(ProfileFormEditedBefore(false));
    // Merge two arrays
    const mixedAllocations = joinWithoutDupes(
      commitableAllocations,
      selectedBasketAssets
    );

    const data = {
      management_type_id: managementTypeID,
      allocations: changeReqPayloadFormat(mixedAllocations),
    };

    setLoadingIndicator(true);
    await dispatch(updateAssetAllocationByAccountID(accountID, data)).then(
      async (res) => {
        if (isSetup) {
          dispatch(setSelectedAccount(res[0]));
          const data =
            accounts &&
            accounts.filter(
              (item) => item.client_account_id === res[0].client_account_id
            );

          if (data && data.length === 0) dispatch(addNewAccountToList(res[0]));
          else {
            const updates = accounts.map((item) => {
              if (item.client_account_id === res[0].client_account_id) {
                return {
                  ...item,
                  account_status_id: 4,
                  account_status: "Active",
                };
              } else return item;
            });
            dispatch(setSelfServiceAccounts(updates));
          }

          showToast(SETUP_ACCOUNT_SUCCESS_MSG, "success", 4000);
          await dispatch(getAssetAllocationByAccountID(accountID));
          setLoadingIndicator(false);
          history.push("/account-dashboard");
        } else {
          const { management_type_id, expert_gui } = res[0];
          res[0].management_type_id = management_type_id;
          dispatch(setAccountInformation(res[0]));
          dispatch(setSelectedAccount(res[0]));
          dispatch(getAssetAllocationByAccountID(accountID));
          setLoadingIndicator(false);
          // Redirect user to next tab or dashboard by the account mode.
          if (expert_gui) setSelectedIndex(2);
          else history.push("/account-dashboard");
        }
      }
    );
  };

  const allocateEachTargetPercentage = async () => {
    const temp = [...selectedBasketAssets];
    var totalTargetPercentage = 0;
    const data1 = temp.map((item) => {
      if (!item.target_percentage)
        item.target_percentage = 100 / selectedBasketAssets.length;
      totalTargetPercentage += item.target_percentage;
      return item;
    });
    const data2 = data1.map((item) => {
      item.target_percentage =
        (item.target_percentage / totalTargetPercentage) * 100;
      return item;
    });
    setSelectedBasketAssets(data2);
  };

  const onFocusedRowIndexChangeOnAssetsInAccount = (instrument_id) => {
    if (assetsInBasketDataGrid.current.instance) {
      assetsInBasketDataGrid.current.instance.clearSelection();
      assetsInBasketDataGrid.current.instance.option("focusedRowIndex", -1);
    }

    if (assetsForBasketDataGrid.current.instance) {
      assetsForBasketDataGrid.current.instance.clearSelection();
      assetsForBasketDataGrid.current.instance.option("focusedRowIndex", -1);
    }

    const selectedAsset = commitableAllocations.filter(
      (item) => item.instrument_id === instrument_id
    );
    setSelectedAsset(selectedAsset[0]);
  };

  const onFocusedRowIndexChangeOnNewAssetsForBasket = (instrument_id) => {
    if (assetsInBasketDataGrid.current.instance) {
      assetsInBasketDataGrid.current.instance.clearSelection();
      assetsInBasketDataGrid.current.instance.option("focusedRowIndex", -1);
    }
    if (assetsInAccountDataGrid.current.instance) {
      assetsInAccountDataGrid.current.instance.clearSelection();
      assetsInAccountDataGrid.current.instance.option("focusedRowIndex", -1);
    }

    const selectedAsset = newAssetsForBasket.filter(
      (item) => item.instrument_id === instrument_id
    );
    if (selectedAsset && selectedAsset.length > 0)
      setSelectedAsset(selectedAsset[0]);
  };

  const onFocusedRowIndexChangeOnAssetsInBasket = (instrument_id) => {
    if (assetsInAccountDataGrid.current.instance) {
      assetsInAccountDataGrid.current.instance.clearSelection();
      assetsInAccountDataGrid.current.instance.option("focusedRowIndex", -1);
    }
    if (assetsForBasketDataGrid.current.instance) {
      assetsForBasketDataGrid.current.instance.clearSelection();
      assetsForBasketDataGrid.current.instance.option("focusedRowIndex", -1);
    }
    setSelectedIndexInBasket(instrument_id);
  };

  const addAssetToBasketList = () => {
    if (!selectedAsset) return;

    dispatch(ProfileFormEditedBefore(true));

    const { instrument_id } = selectedAsset;
    const data = selectedBasketAssets.filter(
      (item) => item.instrument_id === instrument_id
    );

    if (data && data.length !== 0) return;

    setSelectedBasketAssets([
      ...selectedBasketAssets,
      {
        ...selectedAsset,
        target_percentage: 0,
        target_quantity: selectedAsset.target_quantity
          ? selectedAsset.target_quantity
          : 0,
        current_model_id: 2,
      },
    ]);
  };

  const removeAssetInBasketList = () => {
    if (!selectedBasketAssets) return;

    dispatch(ProfileFormEditedBefore(true));

    const data = selectedBasketAssets.filter(
      (item, index) => item.instrument_id !== selectedIndexInBasket
    );

    const deletedBasketAsset = selectedBasketAssets.filter(
      (item, index) => item.instrument_id === selectedIndexInBasket
    );

    const tempAllocations = [...commitableAllocations];
    const filterData = tempAllocations.map((item) => {
      if (item.instrument_id === deletedBasketAsset[0].instrument_id) {
        item.target_percentage = 0;
        item.current_model_id = 1;
      }
      return item;
    });
    setCommitableAllocations(filterData);

    const clonedData = JSON.parse(JSON.stringify(assetsInfo.allocations));
    const basketAssetsInAllocationsData = clonedData.filter(
      (item) => item.current_model_id === 2
    );
    // Compare the updated array and original one to get the status: isEdited.
    if (getDifference(data, basketAssetsInAllocationsData).length === 0) dispatch(ProfileFormEditedBefore(false));

    setSelectedBasketAssets(data);
  };

  const getDifference = (array1, array2) => {
    return array1.filter(object1 => {
      return !array2.some(object2 => {
        return object1.current_model_id === object2.current_model_id && object1.instrument_id === object2.instrument_id;
      });
    });
  }

  const handleTargetPosition = (value, instrumentID) => {
    const temp = [...selectedBasketAssets];
    temp.map((item) => {
      if (item.instrument_id === instrumentID) item.target_percentage = value;
      return item;
    });
    setSelectedBasketAssets(temp);
  };

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setEnableOnboarding(false);
    }
  };

  return (
    <div
      style={{ display: displayMethod }}
      className="px-asset-allocation-container"
    >
      {isSetup && (
        <Joyride
          callback={handleJoyrideCallback}
          continuous={true}
          run={enableOnboarding}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={false}
          steps={steps}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      )}
      {!isSetup && (
        <div
          id="mySidepanel"
          className={`${opened ? "sidepanel open" : "sidepanel"}`}
        >
          <Strategy
            isSetup={false}
            handleManagementType={handleManagementType}
          />
        </div>
      )}
      <div
        style={{
          width: screenWidth,
          padding: "20px 20px",
        }}
      >
        {/* ACCOUNT BALANCE VIEW */}
        <div className="px-assets-header">
          <p className="px-asset-title no-padding">BASKET TRADING SETUP</p>
          <div className="px-btn-tools">
            {!isSetup && (
              <>
                <Button
                  text="CHANGE MANAGEMENT TYPE"
                  onClick={() => setOpened(!opened)}
                  style={{ marginRight: 10 }}
                />
                <Button
                  text={`${isSetup ? "CONTINUE SETUP" : "COMMIT CHANGES"}`}
                  disabled={
                    commitableAllocations && commitableAllocations.length !== 0
                      ? false
                      : true
                  }
                  onClick={() => {
                    if (allocatedAccountBalance !== 100) {
                      setVisibleWarningPopup(true);
                    } else {
                      dispatchCommitAllocations();
                    }
                  }}
                />
              </>
            )}
            {isSetup && (
              <>
                <Button
                  text="PREVIOUS STEP"
                  onClick={previousStep}
                  style={{ marginRight: 10 }}
                />
                <Button
                  id="px-setup-button"
                  text={`${expertGUI ? "CONTINUE SETUP" : "COMPLETE SETUP"}`}
                  disabled={
                    assetsInfo && assetsInfo.allocations.length !== 0
                      ? false
                      : true
                  }
                  onClick={() => {
                    if (allocatedAccountBalance !== 100) {
                      setVisibleWarningPopup(true);
                    } else {
                      dispatchCommitAllocations();
                    }
                  }}
                  style={{ marginRight: 10 }}
                />
              </>
            )}
          </div>
        </div>
        <div
          className="px-account-info"
          style={{
            maxWidth: "62%",
            width: "62%",
            height: 50,
            position: "relative",
          }}
        >
          <p>
            ACCOUNT NAME: <strong>{account?.account_reference}</strong>
          </p>
          <Button
            text={"ALLOCATE 100%"}
            type="default"
            disabled={
              commitableAllocations && commitableAllocations.length !== 0
                ? false
                : true
            }
            style={{ position: "absolute", right: 0, top: 0 }}
            onClick={allocateEachTargetPercentage}
          />
        </div>
        <div style={{ display: "flex" }}>
          <div>
            <DataGrid
              ref={assetsInAccountDataGrid}
              className={"dx-card px-holding-accounts-dt full-height"}
              dataSource={
                commitableAllocations && commitableAllocations.length !== 0
                  ? commitableAllocations
                  : null
              }
              loadPanel={null}
              showBorders={true}
              keyExpr="instrument_id"
              focusedRowEnabled={true}
              defaultFocusedRowIndex={-1}
              hoverStateEnabled={true}
              columnHidingEnabled={true}
              allowEditing={true}
              onFocusedRowChanged={(e) => {
                if (e && e.row) {
                  const { instrument_id } = e.row.data;
                  onFocusedRowIndexChangeOnAssetsInAccount(instrument_id);
                }
              }}
              height={400}
            >
              <Scrolling mode="virtual" />
              <Column
                dataField={"exchange_symbol"}
                caption={"ASSETS IN ACCOUNT"}
                hidingPriority={6}
                width={160}
                alignment={"left"}
              />
              <Column
                dataField={"position"}
                caption={"ACTUAL QUANTITY"}
                hidingPriority={2}
                width={100}
                alignment={"left"}
              />
            </DataGrid>
            <DataGrid
              ref={assetsForBasketDataGrid}
              className={"dx-card px-holding-accounts-dt full-height"}
              dataSource={
                newAssetsForBasket && newAssetsForBasket.length !== 0
                  ? newAssetsForBasket
                  : null
              }
              loadPanel={null}
              showBorders={true}
              keyExpr="instrument_id"
              focusedRowEnabled={true}
              defaultFocusedRowIndex={-1}
              hoverStateEnabled={true}
              columnHidingEnabled={true}
              allowEditing={true}
              onFocusedRowChanged={(e) => {
                if (e && e.row) {
                  const { instrument_id } = e.row.data;
                  onFocusedRowIndexChangeOnNewAssetsForBasket(instrument_id);
                }
              }}
              height={400}
            >
              <FilterRow visible={true} />
              <Scrolling mode="virtual" />
              <Column
                dataField={"exchange_symbol"}
                caption={"NEW ASSETS FOR BASKET"}
                hidingPriority={6}
                width={160}
                alignment={"left"}
              />
            </DataGrid>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "25px 25px",
            }}
          >
            <div id="px-chevron-buttons" style={{ display: "grid" }}>
              <Button
                type="default"
                icon="chevrondoubleright"
                style={{ marginBottom: 10 }}
                disabled={
                  commitableAllocations && commitableAllocations.length !== 0
                    ? false
                    : true
                }
                onClick={addAssetToBasketList}
              />
              <Button
                type="default"
                icon="chevrondoubleleft"
                disabled={
                  commitableAllocations && commitableAllocations.length !== 0
                    ? false
                    : true
                }
                onClick={removeAssetInBasketList}
              />
            </div>
          </div>
          <DataGrid
            ref={assetsInBasketDataGrid}
            className={"dx-card px-holding-accounts-dt px-basket-table"}
            dataSource={
              selectedBasketAssets && selectedBasketAssets.length !== 0
                ? selectedBasketAssets
                : null
            }
            loadPanel={null}
            showBorders={true}
            keyExpr="instrument_id"
            focusedRowEnabled={true}
            defaultFocusedRowIndex={-1}
            columnHidingEnabled={true}
            columnAutoWidth={false}
            allowEditing={true}
            onFocusedRowChanged={(e) => {
              if (e && e.row) {
                const { instrument_id } = e.row.data;
                onFocusedRowIndexChangeOnAssetsInBasket(instrument_id);
              }
            }}
            height={800}
          >
            <Scrolling mode="virtual" />
            <Column
              dataField={"exchange_symbol"}
              caption={"ASSETS IN BASKET"}
              hidingPriority={6}
              width={150}
              alignment={"left"}
            />
            <Column
              dataField={"position"}
              caption={"BASKET PERCENTAGE"}
              hidingPriority={2}
              width={100}
              alignment={"left"}
              cellRender={(e) => {
                return (
                  <NumberBox
                    id="px-number-box"
                    showClearButton={false}
                    showSpinButtons={false}
                    format={"#0.####"}
                    min="0"
                    value={e.data.target_percentage}
                    onValueChange={(value) =>
                      handleTargetPosition(value, e.data.instrument_id)
                    }
                    style={{ height: 40 }}
                  />
                );
              }}
            />
          </DataGrid>
        </div>
      </div>
      {isPopup && (
        <ConfirmationAskingPopup
          handleClosePopup={() => setIsPopup(false)}
          handleKeepThePage={dispatchCommitAllocations}
        />
      )}
      {visibleOptionsPopup && (
        <AllocationWarningPopup
          managementTypeID={managementTypeID}
          setVisibleWarningPopup={setVisibleWarningPopup}
          handleWarningOption={handleWarningOption}
        />
      )}
    </div>
  );
};

export default BasicBasketTrading;
