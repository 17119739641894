import logger from './logger';
import { MAIN_TOKEN_KEY } from '../constants';

/**
 * Set item into the localstorage.
 * @param {string} name 
 * @param {string} value 
 * @returns 
 */
export const set = (name, value) => {
  try {
    localStorage.setItem(name, JSON.stringify(value));

    return true;
  } catch (err) {
    logger.error(err);
    return false;
  }
};

/**
 * Get item from the localstorage.
 * @param {string} name 
 * @returns 
 */
export const get = (name) => {
  try {
    return localStorage.getItem(name);
  } catch (err) {
    logger.error(err);
    return false;
  }
};

/**
 * Remove item from the localstorage.
 * @param {string} name 
 * @returns 
 */
export const remove = (name) => {
  try {
    localStorage.removeItem(name);

    return true;
  } catch (err) {
    logger.error(err);
    return false;
  }
};

/**
 * Check If token exists in the localstorage.
 * @returns
 */
export const loggedIn = () => {
  const token = get(MAIN_TOKEN_KEY);
  return !!token;
};

export const setCookie = (ckey, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires="+d.toUTCString();
  document.cookie = ckey + "=" + cvalue + ";" + expires + ";path=/";
}

export const getCookie = (ckey) => {
  let name = ckey + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const checkCookie = (ckey) => {
  let user = getCookie(ckey);
  if (user !== "") {
    return true;
  } else {
    return false;
  }
}