import { withNavigationWatcher } from "./contexts/navigation";
import {
  TradingDashboard,
  AccountDashboard,
  Security,
  Competition,
  Community,
  RiskReport,
  VrDevicePairing,
  DemoTradingDashboard,
  SocialDashboard,
} from "./containers/private";
import { ChartContainer } from "./containers/public";

const routes = [
  {
    path: "/social-dashboard",
    component: SocialDashboard,
  },
  {
    path: "/trading-dashboard",
    component: TradingDashboard,
  },
  {
    path: "/demo-trading-dashboard",
    component: DemoTradingDashboard,
  },
  {
    path: "/account-dashboard",
    component: AccountDashboard,
  },
  {
    path: "/riskreport",
    component: RiskReport,
  },
  {
    path: "/settings",
    component: Security,
  },
  {
    path: "/wealth",
    component: Competition,
  },
  {
    path: "/community",
    component: Community,
  },
  {
    path: "/device-pairing",
    component: VrDevicePairing,
  },
  {
    path: "/trading_chart",
    component: ChartContainer,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    component: withNavigationWatcher(route.component),
  };
});
