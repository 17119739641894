/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "devextreme-react/accordion";
import { TextBox } from "devextreme-react/text-box";
import { Button } from "devextreme-react/button";
import CustomTitle from "./CustomTitle.js";
import CustomItem from "./CustomItem.js";
import { LoadIndicator } from "devextreme-react/load-indicator";
import {
  addSocialNetworkToChannel,
  getImersProgress,
  getSocialNetworks,
  removeSocialNetwork,
  updateSocialNetworkToChannel,
} from "../../../redux/self-service-user/action.js";
import { getChannels } from "../../../redux/references/action";
import "./Main.scss";
import Error from "../../../components/Error.js";
import { useHistory } from "react-router-dom";

const strIncludesJoined = ["discord", "telegram", "reddit"];
const strIncludesFollowIn = ["pinterest"];
const strThreadOn = ["bitcointalk.org"];

const MainComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const [hasJoinedChannel, setHasJoinedChannel] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [channels, setChannels] = useState();
  const [userID, setUserID] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

  const { client_user_id: clientUserID } = useSelector(
    (state) => state.auth.info
  );

  const fetchData = async () => {
    setIsLoading(true);
    Promise.all([
      dispatch(getChannels()),
      dispatch(getSocialNetworks(clientUserID)),
      dispatch(getImersProgress(clientUserID)),
    ]).then(([originalChannelsData, joinedChannelsData, imersProgressData]) => {
      var updated = originalChannelsData.map(function (o1) {
        o1.IconSrc =
          `${process.env.REACT_APP_IMAGE_URL}/` +
          o1.name.toLowerCase() +
          "_logo.png";

        let status_name = "none";
        let status_class_name = "status-none";
        let identifier1 = "";
        let userSocialNetworkID = null;

        joinedChannelsData.forEach(function (o2) {
          if (o1.social_network_id === o2.social_network_id) {
            status_name = o2.status_name;
            if (status_name === "No Longer Valid") {
              status_class_name = `status-no-longer-valid`;
            } else {
              status_class_name = `status-${o2.status_name.toLowerCase()}`;
            }
            identifier1 = o2.identifier1;
            userSocialNetworkID = o2.user_social_network_id;
          }
        });
        o1.status_name = status_name;
        o1.status_class_name = status_class_name;
        o1.identifier1 = identifier1;
        o1.user_social_network_id = userSocialNetworkID;

        const { description, status } = generateDescription(o1);

        o1.description = description;
        o1.status = status;

        return o1;
      });
      setChannels(updated);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateDescription = (item) => {
    const { name, status_name } = item;
    let description;
    let status;
    let present;
    if (status_name === "none" || status_name === "") {
      present = "";
      status = 1;
    } else if (status_name === "Requested") {
      // setHasJoinedChannel(true);
      present = "ing";
      status = 2;
    } else if (status_name === "Approved") {
      // setHasJoinedChannel(true);
      present = "ed";
      status = 3;
    } else if (status_name === "No Longer Valid") {
      present = "";
      status = 4;
    }

    if (strIncludesJoined.includes(name.toLowerCase())) {
      description = `Join${present} the IMERS Land `;
    } else if (strIncludesFollowIn.includes(name.toLowerCase())) {
      description = `Follow${present} IMERS Land in `;
    } else if (strThreadOn.includes(name.toLowerCase())) {
      description = `Follow${present} our thread on `;
    } else {
      description = `Follow${present} IMERS Land on `;
    }

    return { description, status };
  };

  const clickHandler = (socialNetworkID) => {
    const selectedData = channels.filter(
      (item) => item.social_network_id === socialNetworkID
    );
    setUserID(selectedData[0].identifier1);
    setSelectedItem(selectedData[0]);
  };

  const joinChannel = async () => {
    setErrMsg("");
    const { user_social_network_id, social_network_id } = selectedItem;

    var payload = {
      identifier1: userID,
      identifier2: null,
      identifier3: null,
    };

    setIsLoading(true);
    if (selectedItem.status !== 1) {
      try {
        await dispatch(
          updateSocialNetworkToChannel(
            clientUserID,
            user_social_network_id,
            payload
          )
        );
      } catch (error) {
        setIsLoading(false);
        setErrMsg(error.message);
      }
    } else {
      payload.social_network_id = social_network_id;
      try {
        await dispatch(addSocialNetworkToChannel(clientUserID, payload));
      } catch (error) {
        setIsLoading(false);
        setErrMsg(error.message);
      }
    }
    await fetchData();
    setSelectedItem(null);
    setUserID("");
    setIsLoading(false);
  };

  const removeChannel = async (userSocialNetworkID) => {
    setSelectedItem(null);
    setIsLoading(true);
    await dispatch(removeSocialNetwork(clientUserID, userSocialNetworkID));
    await fetchData();
    setUserID("");
    setIsLoading(false);
  };

  return (
    <div className="px-community-container">
      <div className="px-header">
        <pre>
          Start earning IMERS tokens by helping us spread the word about the
          project.
        </pre>
        <pre>
          As the project grows you'll be getting closer to owning your first
          Guildhall in the IMERS metaverse.
        </pre>
      </div>
      <div className="px-main-container">
        <div className="px-channels-list">
          <p style={{ marginTop: 50 }}>
            You've earned 100 IMERS just for joining. Enter your social network
            details below to be approved for the IMERS bounty campaign. You can
            EARN IMERS tokens for every action you take that helps us build the
            community.
          </p>
          <div className="px-intro-status">
            <div
              style={{ marginRight: 10, position: "absolute", right: "400px" }}
            >
              <div
                className="dot status-none"
                style={{ marginRight: 10 }}
              ></div>
              <span style={{ verticalAlign: "super" }}>NONE</span>
            </div>
            <div
              style={{ marginRight: 10, position: "absolute", right: "280px" }}
            >
              <div
                className="dot status-requested"
                style={{ marginRight: 10 }}
              ></div>
              <span style={{ verticalAlign: "super" }}>REQUESTED</span>
            </div>
            <div
              style={{ marginRight: 10, position: "absolute", right: "160px" }}
            >
              <div
                className="dot status-approved"
                style={{ marginRight: 10 }}
              ></div>
              <span style={{ verticalAlign: "super" }}>APPROVED</span>
            </div>
            <div
              style={{ marginRight: 10, position: "absolute", right: "0px" }}
            >
              <div
                className="dot status-no-longer-valid"
                style={{ marginRight: 10 }}
              ></div>
              <span style={{ verticalAlign: "super" }}>NO LONGER VALID</span>
            </div>
          </div>
          <Accordion
            dataSource={channels}
            collapsible={true}
            multiple={false}
            animationDuration={300}
            itemTitleRender={(data) => {
              return <CustomTitle data={data} />;
            }}
            itemRender={(data) => {
              return (
                <CustomItem
                  data={data}
                  clickHandler={clickHandler}
                  removeChannel={removeChannel}
                  isLoading={isLoading}
                />
              );
            }}
            rtlEnabled={false}
          />
          <div className="px-footer-content">
            <pre>
              Old Guildhalls have the highest value. Make sure you get in early.
            </pre>
          </div>
        </div>
        <div className="px-join-channel">
          {selectedItem && (
            <React.Fragment>
              <div className="px-main-screen">
                <div className="px-channel-icon">
                  <div>
                    <div
                      style={{
                        backgroundImage: `url(${selectedItem.IconSrc})`,
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        width: "100%",
                        height: 120,
                        backgroundRepeat: "no-repeat",
                        margin: "auto",
                      }}
                    />
                    <p
                      style={{
                        fontSize: 30,
                        margin: "0 0 10px 0",
                        fontWeight: "bold",
                      }}
                    >
                      {selectedItem.name}
                    </p>
                    <div className="px-title-divider"></div>
                  </div>
                </div>
                <div className="px-channel-screen">
                  <div className="px-main">
                    <p style={{ margin: "0 0 30px 0" }}>
                      Collect 10 IMERS by following this channel
                    </p>
                    <a
                      href="https://imers.land/what-is-the-imers-metaverse"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      What is IMERS?
                    </a>
                    <p>
                      IMERS Land Club {selectedItem.name}{" "}
                      <i
                        class="dx-icon-link"
                        style={{
                          cursor: "pointer",
                          fontSize: "20px",
                          verticalAlign: "bottom",
                        }}
                        onClick={() => {
                          window.open(selectedItem.url, "_blank");
                        }}
                      ></i>
                    </p>
                    {!!errMsg && <Error>{errMsg}</Error>}
                    <TextBox
                      value={userID}
                      width={"100%"}
                      onValueChange={(e) => {
                        setUserID(e);
                      }}
                      style={{ marginTop: 10, marginBottom: 10 }}
                      placeholder={`${selectedItem.status === 1
                          ? `Input ${selectedItem.name} ID...`
                          : `Update ${selectedItem.name} ID...`
                        }`}
                    />
                    <Button
                      className="px-channel-buttons"
                      width={"100%"}
                      height={40}
                      onClick={joinChannel}
                    >
                      <LoadIndicator
                        className="button-indicator"
                        visible={isLoading}
                      />
                      <span className="dx-button-text">COMPLETE</span>
                    </Button>
                  </div>
                </div>
              </div>
              <Button type="default" text="HOW MUCH IMERS HAVE I EARNED?" style={{
                fontSize: 20,
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                margin: "20px auto",
                width: "60%"
              }} onClick={() => history.push("/wealth")} />
            </React.Fragment>
          )}
          {!selectedItem && (
            <React.Fragment>
              <div className="imers-tokens-earnt-channel">
                <div style={{
                  backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}/company/imers/IMERS-token.png)`,
                  height: 260,
                  backgroundSize: "cover"
                }} />
                <Button type="default" text="IMERS TOKENS EARNED" style={{
                  fontSize: 20,
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  margin: "40px auto",
                }} onClick={() => history.push("/wealth")} />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainComponent;
