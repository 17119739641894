import {
  REMOVE_USER_DATA,
  SET_GENERATED_2FA_DATA,
  SET_QR_INFO,
  SET_USER_DATA,
  REGISTER_EMAIL_PROCESS,
  RESET_PASSWORD_REQUEST,
  SAVE_TEMP_TOKEN,
  BEFORE_PROFILE_FORM_EDITED,
  SIDE_BAR_MODE_CHANGE,
  ACCOUNT_MODE_CHANGE,
} from "./constant";

const DEFAULT_STATE = {
  twoFa: null,
  info: null,
  qr: null,
  tmpToken: null,
  isRegisterDone: false,
  isRequestDone: false,
  isEdited: false,
  mode: "cybertools",
  accMode: "live",
};

const authReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case REGISTER_EMAIL_PROCESS:
      return { ...state, isRegisterDone: action.payload };
    case RESET_PASSWORD_REQUEST:
      return { ...state, isRequestDone: action.payload };
    // After call connect action.
    case SET_USER_DATA:
      return { ...state, info: action.payload };
    // After call generate secret/otpauth_uri action.
    case SET_GENERATED_2FA_DATA:
      return { ...state, twoFa: action.payload };
    // After call generate QrCode Image action.
    case SET_QR_INFO:
      return { ...state, qr: action.payload };
    case SAVE_TEMP_TOKEN:
      return { ...state, tmpToken: action.payload };
    case BEFORE_PROFILE_FORM_EDITED:
      return { ...state, isEdited: action.payload };
    case SIDE_BAR_MODE_CHANGE:
      return { ...state, mode: action.payload };
    case ACCOUNT_MODE_CHANGE:
      return { ...state, accMode: action.payload };
    // After call logout action.
    case REMOVE_USER_DATA:
      return DEFAULT_STATE;
    default:
      return state;
  }
};

export default authReducer;
