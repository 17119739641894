import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "devextreme-react/button";
import { LoadIndicator } from "devextreme-react/load-indicator";
import BillingAccounts from "./BillingAccounts";
import { getExchangeAccounts } from "../../redux/references/action";
import { useScreenSize } from "../../utils/media-query";
import {
  getBillingAccounts,
  getBillingRates,
} from "../../redux/self-service-user/action";

const ManageBilling = () => {
  const screenSize = useScreenSize();
  const dispatch = useDispatch();
  const [isRefresh, setIsRefresh] = useState(false);

  const leftScreenWidth = screenSize.isLarge || screenSize.isMedium ? 40 : 100;
  const rightScreenWidth = screenSize.isLarge || screenSize.isMedium ? 60 : 100;

  const displayMethod =
    screenSize.isLarge || screenSize.isMedium ? "flex" : "block";

  const userInfo = useSelector((state) => state.auth.info);
  const billingRates = useSelector(
    (state) => state.selfServiceUser.billingRates
  );
  const billingAccounts = useSelector(
    (state) => state.selfServiceUser.billingAccounts
  );

  const { client_user_id: clientUserID } = userInfo;

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    setIsRefresh(true);
    Promise.all([
      dispatch(getBillingRates(clientUserID)),
      dispatch(getBillingAccounts(clientUserID)),
      dispatch(getExchangeAccounts()),
    ]).then(() => {
      setIsRefresh(false);
    });
  };

  return (
    <React.Fragment>
      <Button
        id="button"
        width={70}
        height={30}
        onClick={loadData}
        Tooltip="Reload"
        disabled={isRefresh}
      >
        <LoadIndicator className="button-indicator" visible={isRefresh} width={20} height={20} />
        <span className="dx-button-text">Reload</span>
      </Button>
      <div style={{ display: displayMethod }}>
        <div
          style={{
            width: `${leftScreenWidth}%`,
            padding: "20px 20px",
            fontSize: 14,
            background: "#f7f7f7",
          }}
        >
          <div className="px-billing-info">
            <p>Expiry Date</p>
            <p>5th July 2021 21:05</p>
          </div>
          <div className="px-billing-info">
            <p>Allocated XAI</p>
            <p>21,456</p>
          </div>
          <div className="px-billing-info">
            <p>Payment Address</p>
            <p>ux123456789Uabcd</p>
          </div>
          <div className="px-billing-info">
            <p>Active Accounts Cost for 30 days</p>
            <p>USDt x.xxxx</p>
          </div>
          <div className="px-billing-info">
            <p>Active Accounts Locked XAI required</p>
            <p>xxxx</p>
          </div>
          <div className="px-billing-info">
            <p>XAI Holding Address</p>
            <p>Ux1223456789Uabcd</p>
          </div>
          <div className="px-payment-tokens">
            <p className="px-payment-description">
              Payment is made by depositing USDt into your payment address.
            </p>
            <p className="px-payment-description">
              You can also pay using the following tokens.
            </p>
            <p className="px-payment-description">
              The exchange rate used will be the following rates.
            </p>
            <ul>
              {billingRates &&
                billingRates.map((item) => {
                  return (
                    <li key={item.instrument_id}>
                      {/* <img src={USDTPNG} width="20" height="20" alt="coin" /> */}
                      {item.instrument_name}: ${item.instrument_price}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        <div
          className="px-allocation-info"
          style={{
            width: `${rightScreenWidth}%`,
            padding: "20px 20px",
          }}
        >
          <span style={{ fontSize: 18 }}>
            To allocate XAI in your private wallet do the following:
          </span>
          <div className="px-allocation-guide">
            <ul>
              <li>
                Enter the public address where you will store the XAI by
                clicking
                <a href="mailto:support@Panxora.io"> here</a>.
              </li>
              <li>
                Copy the signing template to your clipboard by clicking{" "}
                <a href="mailto:support@Panxora.io">here</a>.
              </li>
              <li>
                Sign this template offline using your private key. TO see a
                video showing how this is done on MEW click{" "}
                <a href="mailto:support@Panxora.io">here</a>.
              </li>
              <li>
                Paste the signed template by clicking{" "}
                <a href="mailto:support@Panxora.io">here</a>.
              </li>
            </ul>
            <p>
              This address only needs verification once. It will then be tied to
              your account. XAI can be purchased on Uniswap.
            </p>
            <p>
              Please make sure you purchase the correct token XAI is labelled on
              Uniswap as AICoin (XAI) and has the contract address
              Ux234293102323.
            </p>
          </div>
          <BillingAccounts accounts={billingAccounts} />
        </div>
      </div>
    </React.Fragment>
  );
};
export default ManageBilling;
