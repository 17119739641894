import { useState, useEffect, memo } from "react";
import useWebSocket from "react-use-websocket";
import { useHistory } from "react-router-dom";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  ListSubheader,
  Button,
  ListItemSecondaryAction,
  MenuList,
} from "@material-ui/core";
import ScrollView from "devextreme-react/scroll-view";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { BsCurrencyExchange, BsCurrencyDollar } from "react-icons/bs";
import { AiOutlineRead } from "react-icons/ai";
import { BiChevronDown, BiSun } from "react-icons/bi";

import ChangeCell from "./ChangeCell";
import CommonCell from "./CommonCell";
import {
  deleteExchange,
  deleteMarket,
  getAllExchangeListByUserID,
  getMarketListByUserID,
  getScreenByID,
  getScreens,
} from "../../../redux/tradingDashboard/action";
import {
  setRedirectedFromDemo,
  setStartDemo,
} from "../../../redux/app-tour/action";
import {
  setChartState,
  setThemeState,
} from "../../../redux/tradingDashboard/action";

import { useContentLoading } from "../../../contexts/contentLoading";
import { MAIN_TOKEN_KEY } from "../../../constants";
import { get } from "../../../utils/storage";
// import { getScreenSize } from "../../../utils/media-query";
import AddExchangeMarketPopup from "../../../components/AddExchangeMarketPopup";
import OrderPadPopup from "../../../components/OrderPadPopup";
import CustomTooltip from "../../../components/CustomTooltip";

const PricewallTable = ({
  wsBaseUrl,
  activeCompetition,
  setActiveOrderData,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.accounts.account);
  const chartState = useSelector((state) => state.tradingDashboard.chartState);
  const currentTheme = useSelector(
    (state) => state.tradingDashboard.currentTheme
  );
  const accMode = useSelector((state) => state.auth.accMode);
  const siteMode = useSelector((state) => state.auth.mode);
  const { startDemo, redirectedDuringDemo } = useSelector(
    (state) => state.tourState
  );

  const [allExchanges, setAllExchanges] = useState();
  const [allMarkets, setAllMarkets] = useState();
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState("");
  const [selectedScreenID, setSelectedScreenID] = useState(null);
  const [priceWallData, setPriceWallData] = useState();
  const [oldData, setOldData] = useState({});
  const [connectedWSS, setConnectedWSS] = useState(false);
  const [disableNoPriceTimeout, setDisableNoPriceTimeout] = useState(true);
  const [screens, setScreens] = useState(null);
  const [screensDropdown, setScreensDropdown] = useState([]);
  const [isOrderPad, setIsOrderPad] = useState(false);
  const [orderPadData, setOrderPadData] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);

  // const { isXSmall, isSmall } = getScreenSize();
  const { setLoadingIndicator } = useContentLoading();

  const token = get(MAIN_TOKEN_KEY);

  if (!window.location.host.includes("localhost"))
    wsBaseUrl = window.location.host;

  let wsClient = new useWebSocket(`wss://${wsBaseUrl}/ws/`, {
    onOpen: () => {
      console.log("WebSocket connection opened.");
      setConnectedWSS(true);
      document.addEventListener(
        "visibilitychange",
        listenScreenVisibility,
        true
      );
    },
    onClose: () => {
      window.removeEventListener(
        "visibilitychange",
        listenScreenVisibility,
        false
      );
      console.log("WebSocket connection closed.");
      setConnectedWSS(false);
    },
    shouldReconnect: (closeEvent) => true,
    onMessage: (event) => processPriceData(event?.data),
  });

  useEffect(() => {
    return () =>
      window.removeEventListener(
        "visibilitychange",
        listenScreenVisibility,
        true
      );

    // eslint-disable-next-line
  }, []);

  const listenScreenVisibility = () => {
    if (document.hidden) {
      wsClient.sendMessage("unsubscribe");
    } else {
      getRealTimeDataFromWebsocket();
    }
    // eslint-disable-next-line
  };

  const getRealTimeDataFromWebsocket = () => {
    const filteredToken = token.split('"')[1];
    wsClient.sendMessage(`${filteredToken}`);
    priceWallData?.exchanges
      ?.map((item) => item.exchange)
      .map((exchange) => {
        priceWallData?.markets
          ?.map((marketData) => marketData.market)
          .map((market) => {
            wsClient.sendMessage(`${filteredToken},${exchange},${market}`);
            return market;
          });
        return exchange;
      });
  };

  useEffect(() => {
    if (connectedWSS) {
      getRealTimeDataFromWebsocket();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedWSS, priceWallData, selectedScreenID]);

  useEffect(() => {
    let timerId;

    if (!disableNoPriceTimeout) {
      timerId = setInterval(() => {
        wsClient.sendMessage("unsubscribe");
        getRealTimeDataFromWebsocket();
        setDisableNoPriceTimeout(true);
      }, 15000);
    } else {
      if (timerId) clearInterval(timerId);
    }

    return () => clearInterval(timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableNoPriceTimeout]);

  useEffect(() => {
    if (!oldData && !priceWallData) return;

    if (isEqual(oldData, priceWallData)) setDisableNoPriceTimeout(false);
  }, [oldData, priceWallData]);

  useEffect(() => {
    if (!selectedScreenID) return;
    const fetchInitialData = async () => {
      setLoadingIndicator(true);
      await getInitialData();
    };

    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScreenID]);

  useEffect(() => {
    const fetchScreens = async () => {
      setLoadingIndicator(true);
      await getInitialScreensData();
    };
    fetchScreens();

    if (startDemo && redirectedDuringDemo) {
      dispatch(setRedirectedFromDemo(false));
      dispatch(setStartDemo(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Process data for price wall
   */

  const processPriceData = (data) => {
    const exchangeName = data?.split(",")?.[0];
    const marketName = data?.split(",")?.[1];
    let oldPriceData = 0;
    const marketPrice = parseFloat(data?.split(",")?.[2]).toFixed(6);
    const updated = priceWallData?.prices?.[exchangeName]?.map(
      (item, index) => {
        if (
          index ===
          priceWallData?.markets?.map((item) => item.market).indexOf(marketName)
        ) {
          oldPriceData = item;
          item = marketPrice;
        }
        return item;
      }
    );
    setOldData({
      ...oldData,
      [exchangeName]: {
        exchange: exchangeName,
        indexOf: priceWallData?.markets
          ?.map((item) => item.market)
          .indexOf(marketName),
        price: oldPriceData,
      },
    });
    const updatedPrices = { ...priceWallData?.prices, [exchangeName]: updated };
    setPriceWallData({ ...priceWallData, prices: updatedPrices });
  };

  const getInitialScreensData = async () => {
    const data = await dispatch(getScreens());
    setScreens(data);
    setScreensDropdown(
      data
        ? data?.map((item) => ({
            id: item.user_screen_id,
            name: item.screen_name,
          }))
        : []
    );
    await getLastViewedScreen(data);
  };

  const getInitialData = async () => {
    Promise.all([
      dispatch(getAllExchangeListByUserID(selectedScreenID)),
      dispatch(getMarketListByUserID(selectedScreenID)),
      dispatch(getScreenByID(selectedScreenID)),
    ]).then(async ([exchanges, markets, selectedScreenData]) => {
      await generateInitialPriceWallData(
        exchanges,
        markets,
        selectedScreenData?.prices
      );
    });
  };

  const getLastViewedScreen = async (arr) => {
    const defaultScreen = arr.filter((item) => item.last_viewed === true);
    if (defaultScreen && defaultScreen.length > 0) {
      setSelectedScreenID(defaultScreen[0].user_screen_id);
    }
  };

  const generateArrayByObjectWithKeyValue = (arr, isRegistered) => {
    let newArray = [];
    if (!arr) return;

    Object.values(arr).map((oVal, ovIndex) => {
      if (oVal === isRegistered) {
        const filteredData = Object.keys(arr).filter(
          (oKey, okIndex) => ovIndex === okIndex
        );
        newArray = [...newArray, ...filteredData];
      }
      return oVal;
    });

    return newArray;
  };

  const generateInitialPriceWallData = async (exchanges, markets, prices) => {
    let pricesData = {};
    // Sort exchange list first.
    let newExchanges = generateArrayByObjectWithKeyValue(
      exchanges,
      true
    )?.sort();
    let newMarkets = generateArrayByObjectWithKeyValue(markets, true)?.sort();

    const unregisteredExchanges = generateArrayByObjectWithKeyValue(
      exchanges,
      false
    )
      ?.sort()
      .map((item) => {
        const newItem = {
          exchange: item,
        };
        return newItem;
      });
    const unregisteredMarkets = generateArrayByObjectWithKeyValue(
      markets,
      false
    )
      ?.sort()
      .map((item) => {
        const newItem = {
          market: item,
        };
        return newItem;
      });

    setAllExchanges(unregisteredExchanges);
    setAllMarkets(unregisteredMarkets);
    let newPrices = prices;

    let exchangeSource = [];
    let marketSource = [];

    newExchanges.forEach((item, index) => {
      exchangeSource[index] = {
        exchange: item,
      };
    });
    newMarkets.forEach((item, index) => {
      marketSource[index] = {
        market: item,
      };
    });

    newExchanges.map((item) => {
      let data = [];
      if (Object.keys(newPrices).length < 1) return [];

      Object.keys(newPrices[item])
        .sort()
        .map((keyName) => {
          data = [...data, parseFloat(newPrices[item][keyName]).toFixed(6)];

          return keyName;
        });

      pricesData = { ...pricesData, [item]: data };
      return item;
    });
    const dataSource = {
      exchanges: exchangeSource,
      markets: marketSource,
      prices: pricesData,
    };

    setPriceWallData(dataSource);
    setLoadingIndicator(false);
  };

  // Removes market from price wall

  const handleRemoveMarket = async (marketName) => {
    const payload = {
      market: marketName,
    };
    setLoadingIndicator(true);
    // call dispatch action to remove market.
    await dispatch(deleteMarket(selectedScreenID, payload));
    await getInitialData();
  };

  // Removes exchange from price wall

  const handleRemoveExchange = async (exchangeName) => {
    const payload = {
      exchange: exchangeName,
    };
    setLoadingIndicator(true);
    // call dispatch action to remove market.
    await dispatch(deleteExchange(selectedScreenID, payload));
    await getInitialData();
  };

  const handleItemClick = (id) => {
    if (id === 12) {
      setVisible(true);
      setType("NewScreen");
    }

    if (id === 22) {
      setVisible(true);
      setType("CloneScreen");
    }

    if (id !== 12 && id !== 22) {
      handleScreenChanged(id);
    }
  };

  const handleChangeCell = (cellID, exchangeName, marketName, oldValue) => {
    setIsOrderPad(true);
    setOrderPadData({
      exchangeName,
      marketName,
      oldValue,
      accountName: account?.account_name,
      accMode,
      accountId: account?.client_account_id,
    });
  };

  const handleScreenChanged = async (id) => {
    setLoadingIndicator(true);
    setSelectedScreenID(id);
  };

  const handlePopup = () => {
    setVisible(false);
  };

  return (
    <div className="px-pricewall-container">
      <div className="px-pricwall-tool">
        <div className="px-screen-dropdown">
          {" "}
          <CustomTooltip title="Screens">
            <Button
              className="screens-dropdown"
              variant="outlined"
              endIcon={<BiChevronDown fontSize={10} color="#777777" />}
              onClick={(e) => {
                setSelectedMenu("screens");
                setAnchorEl(e.currentTarget);
              }}
            >
              {screens?.find(
                (item) => item?.user_screen_id === selectedScreenID
              )?.screen_name ?? "Screens"}
            </Button>
          </CustomTooltip>
        </div>
        <div className="px-screen-actions">
          {priceWallData && (
            <>
              <CustomTooltip title="Add exchange">
                <IconButton
                  size="small"
                  style={{ marginRight: 12, marginLeft: 12 }}
                  onClick={() => {
                    setVisible(true);
                    setType("Exchange");
                  }}
                >
                  <BsCurrencyExchange />
                </IconButton>
              </CustomTooltip>
              <div className="divider" />

              <CustomTooltip title="Add market">
                <IconButton
                  size="small"
                  style={{ marginRight: 12, marginLeft: 12 }}
                  onClick={() => {
                    setVisible(true);
                    setType("Market");
                  }}
                >
                  <BsCurrencyDollar />
                </IconButton>
              </CustomTooltip>
              <div className="divider" />
              <CustomTooltip title="Begin tutorial">
                <IconButton
                  size="small"
                  style={{ marginLeft: 12, marginRight: 12 }}
                  onClick={() => {
                    dispatch(setStartDemo(true));
                    history.push("/demo-trading-dashboard");
                  }}
                >
                  <AiOutlineRead />
                </IconButton>
              </CustomTooltip>
              <div className="divider" />
              <CustomTooltip title={`Toggle theme`}>
                <IconButton
                  size="small"
                  style={{ marginLeft: 12, marginRight: 12 }}
                  onClick={(e) => {
                    setSelectedMenu("themes");
                    setAnchorEl(e.currentTarget);
                  }}
                >
                  <BiSun />
                </IconButton>
              </CustomTooltip>
            </>
          )}
        </div>
      </div>
      {priceWallData?.exchanges?.length === 0 &&
      priceWallData?.markets?.length === 0 ? (
        <div className="table-nodata"></div>
      ) : (
        <ScrollView
          direction="both"
          id="scrollview"
          scrollByContent={true}
          showScrollbarMode={"always"}
        >
          <div
            className="px-pricewall-body text-content"
            id="px-pricewall-table"
          >
            <div className="px-pricewall-exchanges">
              <CommonCell noContent={true} />
              {priceWallData?.exchanges.map((item, index) => {
                return (
                  <CommonCell
                    key={index}
                    item={item.exchange}
                    siteMode={siteMode}
                    accMode={accMode}
                    activeCompetition={activeCompetition}
                    type="exchange"
                    removeCell={handleRemoveExchange}
                  />
                );
              })}
            </div>
            <div className="px-pricewall-content" id="gridContainer">
              <div className="px-pricewall-header">
                {/* <CommonCell noContent={true} />  */}
                {priceWallData?.markets.map((item, index) => {
                  return (
                    <CommonCell
                      key={index}
                      item={item.market}
                      type="market"
                      removeCell={handleRemoveMarket}
                    />
                  );
                })}
              </div>
              {priceWallData &&
                priceWallData.prices &&
                Object.keys(priceWallData?.prices).map((item, index) => {
                  return (
                    <div
                      className="px-pricewall-header"
                      key={item + "-" + index}
                    >
                      {priceWallData?.prices[item]?.map((value, priceIndex) => {
                        let oldValue = null;
                        if (oldData) {
                          if (
                            oldData[item] &&
                            oldData[item].indexOf === priceIndex
                          ) {
                            oldValue = oldData[item].price;
                          }
                        }
                        if (!oldValue) oldValue = value;
                        return (
                          <div key={priceIndex} id="price-box">
                            <ChangeCell
                              isActiveMarket={
                                item && item === "IMERS" ? true : false
                              }
                              value={value}
                              oldValue={oldValue}
                              siteMode={siteMode}
                              accMode={accMode}
                              activeCompetition={activeCompetition}
                              selectChart={() => {
                                dispatch(
                                  setChartState({
                                    ...chartState,
                                    exchange: item,
                                    market:
                                      priceWallData?.markets?.[priceIndex]
                                        ?.market,
                                  })
                                );
                              }}
                              handleChangeCell={() =>
                                handleChangeCell(
                                  priceIndex,
                                  item,
                                  priceWallData?.markets?.[priceIndex]?.market,
                                  oldValue
                                )
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
            </div>
          </div>
        </ScrollView>
      )}

      {visible && (
        <AddExchangeMarketPopup
          type={type}
          handlePopup={handlePopup}
          setLoadingIndicator={setLoadingIndicator}
          selectedScreenID={selectedScreenID}
          getInitialScreensData={getInitialScreensData}
          getInitialData={getInitialData}
          dataSource={type === "Exchange" ? allExchanges : allMarkets}
        />
      )}
      {isOrderPad && (
        <OrderPadPopup
          handlePopup={() => setIsOrderPad(!isOrderPad)}
          orderPadData={orderPadData}
          setActiveOrderData={setActiveOrderData}
        />
      )}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        PaperProps={{
          style: {
            maxHeight: "calc(100vh - 400px)",
            minWidth: 80,
            backgroundColor:
              currentTheme === "Glass" || currentTheme === "Dark"
                ? "rgba(43, 29, 26, 0.9)"
                : "#ffffff",
            color:
              currentTheme === "Glass" || currentTheme === "Dark"
                ? "#ffffff"
                : "#222222",
          },
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {selectedMenu === "screens" && (
          <MenuList dense disablePadding disableListWrap>
            {[
              { id: 12, name: "Create new screen" },
              {
                id: 22,
                name: "Clone existing screen",
              },
            ].map((item) => (
              <MenuItem
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingRight: 2,
                  paddingLeft: 4,
                }}
                key={item.id}
                onClick={() => {
                  handleItemClick(item.id);
                  setAnchorEl(null);
                  setSelectedMenu(null);
                }}
              >
                <ListItemText
                  primary={
                    <p
                      style={{
                        lineHeight: 0,
                        fontSize: 11,
                        fontWeight: "bold",
                      }}
                    >
                      {item.name}
                    </p>
                  }
                />
              </MenuItem>
            ))}
            <ListSubheader
              style={{
                color:
                  currentTheme === "Dark" || currentTheme === "Glass"
                    ? "#f1f1f188"
                    : "#01010188",
                fontSize: 12,
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: 2,
                paddingLeft: 4,
              }}
            >
              Saved Screens
            </ListSubheader>
            {screensDropdown.map((item) => (
              <MenuItem
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingRight: 2,
                  paddingLeft: 4,
                }}
                key={item.id}
                onClick={() => {
                  setAnchorEl(null);
                  handleItemClick(item.id);
                  setSelectedMenu(null);
                }}
              >
                <ListItemText
                  primary={
                    <p
                      style={{
                        lineHeight: 0,
                        fontSize: 11,
                        fontWeight: "bold",
                      }}
                    >
                      {item.name}
                    </p>
                  }
                />
                {item.last_viewed && (
                  <ListItemSecondaryAction>Last Viewd</ListItemSecondaryAction>
                )}
              </MenuItem>
            ))}
          </MenuList>
        )}
        {selectedMenu === "themes" && (
          <MenuList dense disablePadding disableListWrap>
            {["Light", "Dark", "Glass"].map((item) => (
              <MenuItem
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingRight: 2,
                  paddingLeft: 4,
                }}
                key={item}
                onClick={() => {
                  setAnchorEl(null);
                  setSelectedMenu(null);
                  dispatch(setThemeState(item));
                }}
              >
                <ListItemText
                  primary={
                    <p
                      style={{
                        lineHeight: 0,
                        fontSize: 11,
                        fontWeight: "bold",
                      }}
                    >
                      {item}
                    </p>
                  }
                />
              </MenuItem>
            ))}
          </MenuList>
        )}
      </Menu>
    </div>
  );
};

export default memo(PricewallTable);
