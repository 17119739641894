import React, { useRef, useState } from "react";

const IncrementDecrementButton = ({
  min,
  max,
  value,
  targetElementID,
  onChange,
  buttonTitle,
  ...props
}) => {
  const stepRef = useRef(min);
  const intervalHandlerRef = useRef();
  const [currentValue, setCurrentValue] = useState(value);
  const timeoutHandlerRef = useRef();

  const handleMouseDown = () => {
    intervalHandlerRef.current = setInterval(() => {
      setCurrentValue((cur) => {
        let fixedValue = parseFloat((cur + stepRef.current).toFixed(1));

        if (fixedValue <= 0) fixedValue = 0;

        if (document.getElementById(targetElementID)) document.getElementById(targetElementID).textContent = fixedValue;
        return fixedValue;
      });
    }, 200);

    timeoutHandlerRef.current = setTimeout(() => {
      stepRef.current = max;
    }, 3000);
  };

  const handleMouseUp = () => {
    clearInterval(intervalHandlerRef.current);
    clearTimeout(timeoutHandlerRef.current);
    
    stepRef.current = min;

    onChange(currentValue);
  };

  const handleClick = () => {
    if (currentValue <= 0 && min === -0.1) return;
    onChange(parseFloat((currentValue + stepRef.current).toFixed(1)));
  };

  return (
    <button
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onClick={handleClick}
    >
      {buttonTitle}
    </button>
  );
};

export default IncrementDecrementButton;
