import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useDigitInput from "react-digit-input";
import Error from "../../components/Error";
import { connect, validateCode } from "../../redux/auth/action";
import { useAppData } from "../../contexts/app";
import "./LoginForm.scss";

const Login2faForm = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { setLoading, setLoginErrorMsg, loginErrorMsg } = useAppData(false);
  const token = useSelector((state) => state.auth.tmpToken);
  const [value, onChange] = useState("");

  useEffect(() => {
    if (!token)
      history.push("/login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 6,
    value,
    onChange,
  });

  const getCodes = useCallback(
    async (res) => {
      setLoading(true);
      const redirectTo =
        location.state && location.state.from ? location.state.from : "/";
      try {
        const data = await dispatch(validateCode(res, token));
        if (data) {
          await dispatch(connect());
          history.replace(redirectTo);
        }

        setLoading(false);
        setLoginErrorMsg("");
      } catch (error) {
        setLoginErrorMsg(error.message);
        setLoading(false);

        //To set auto-focus after getting the error message.
        var firstDigitElement = document.getElementById("first-digit");
        firstDigitElement.focus();
      }
    },
    [setLoading, location, dispatch, token, history, setLoginErrorMsg]
  );

  const isArrayFull = (arr) => {
    if (arr.length === 0) return false;
    for (var i = 0, l = arr.length; i < l; i++) {
      if (" " === arr[i]) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (isArrayFull(value.split(""))) {
      setTimeout(() => {
        getCodes(value);
      }, 200);
    }
  }, [getCodes, value]);

  return (
    <div className={"px-account-form tfa-card"}>
      {!!loginErrorMsg && <Error>{loginErrorMsg}</Error>}
      <div className="description">
        <span>
          Enter the One Time Password (OTP) generated by your Authentication app
        </span>
      </div>
      <div className="input-group">
        <input
          id="first-digit"
          className="digit"
          inputMode="decimal"
          autoFocus={true}
          {...digits[0]}
        />
        <input name="digit" className="digit" inputMode="decimal" {...digits[1]} />
        <input name="digit"  className="digit" inputMode="decimal" {...digits[2]} />
        <span className="hyphen" />
        <input name="digit"  className="digit" inputMode="decimal" {...digits[3]} />
        <input name="digit"  className="digit" inputMode="decimal" {...digits[4]} />
        <input name="digit"  className="digit" inputMode="decimal" {...digits[5]} />
      </div>
    </div>
  );
};

export default Login2faForm;
