import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Editing,
  Scrolling,
  Pager,
  Paging
} from "devextreme-react/data-grid";
import { Button } from "devextreme-react/button";
import {
  getSelfServiceAccounts,
  removeAccountByID,
  setAccountInformation,
  setManagementTypeID,
  setOriginalManagementTypeID,
  setSelectedAccount,
} from "../../../redux/accounts/action";
import { getExchangeAccounts } from "../../../redux/references/action";
import { useToast } from "../../../contexts/toast";
import {
  ACCOUNT_STATUS_PENDING,
  ALLOWED_PAGE_SIZES_BIG,
  DELETE_ACCOUNT_MSG,
} from "../../../constants";

const Accounts = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const accounts = useSelector((state) => state.accounts.list);
  console.log(accounts)
  const account = useSelector((state) => state.accounts.account);

  useEffect(() => {
    Promise.all([
      dispatch(getSelfServiceAccounts()),
      dispatch(getExchangeAccounts()),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEditingStart = async (e) => {
    // Go to Account detail screen if account status is not pending.
    if (e.data.account_status_id !== 0) {
      await dispatch(setAccountInformation(e.data));
      await dispatch(setManagementTypeID(e.data.management_type_id));
      await dispatch(setOriginalManagementTypeID(e.data.management_type_id));
      history.push("/accounts/edit/" + e.data.client_account_id);
    }
    else {
      // Go to Setup account screen to finish setup account.

      // Set selected account to store to run testing api automatically.
      var selectedAccount = accounts.filter(
        (item) => item.client_account_id === e.data.client_account_id
      );
      // Set the target as 1 as default.
      selectedAccount[0].current_management_type_id = 1;
      dispatch(setAccountInformation(selectedAccount[0]));

      history.push("/accounts/add");
    }
  };

  const handleRemoveRow = async (e) => {
    await dispatch(removeAccountByID(e.data.client_account_id));

    // If the account that has been removed is the account that is selected account.
    // Then, it has not to display in the Dashboard again.
    if (account.client_account_id === e.data.client_account_id) {
      await dispatch(setSelectedAccount(null));
    }

    showToast(DELETE_ACCOUNT_MSG, "success", 3000);
  };

  const handleCellPrepared = (e) => {
    if (e.rowType === "data" && e.columnIndex === 3) {
      if (e.cellElement) {
        window
          .$(e.cellElement)
          .find(".dx-link-delete")
          .attr("title", "Delete");
        if (e.data.account_status !== ACCOUNT_STATUS_PENDING) {
          window
            .$(e.cellElement)
            .find(".dx-icon-edit")
            .addClass("px-customize-icon");
        }
      }
    }
  };

  const goToAdd = () => {
    dispatch(setAccountInformation(null));
    history.push("/accounts/add");
  };

  return (
    <React.Fragment>
      <div className={"content-block header-block"} style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          className="add-btn"
          type="default"
          width={120}
          onClick={goToAdd}
        >
          ADD
        </Button>
      </div>
      <div className={"content-block dx-card responsive-paddings"}>
        <h2 className={"content-block"} style={{ marginLeft: 0, marginBottom: 30}}>ACCOUNTS</h2>
        <DataGrid
          className={"dx-card wide-card px-table"}
          dataSource={accounts}
          showBorders={true}
          keyExpr="client_account_id"
          focusedRowEnabled={true}
          defaultFocusedRowIndex={false}
          columnAutoWidth={true}
          columnHidingEnabled={true}
          allowEditing={true}
          onRowRemoved={handleRemoveRow}
          onEditingStart={onEditingStart}
          onCellPrepared={handleCellPrepared}
          calculateCell
        >
          <Scrolling rowRenderingMode="virtual"></Scrolling>
          <Paging defaultPageSize={ALLOWED_PAGE_SIZES_BIG[0]} />
          <Pager
            visible={true}
            allowedPageSizes={ALLOWED_PAGE_SIZES_BIG}
            displayMode={"full"}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
          <Column
            dataField={"account_reference"}
            caption={"ACCOUNT REFERENCE"}
            hidingPriority={2}
          />
          <Column
            calculateCellValue={(rowData) => {
              return (
                rowData.exchange_name + " - " + rowData.exchange_account_type
              );
            }}
            dataField="exchange_name"
            caption={"EXCHANGE"}
            hidingPriority={6}
          />
          <Column
            dataField={"account_status"}
            caption={"ACCOUNT STATUS"}
            hidingPriority={2}
            style={{ cursor: "pointer" }}
            // cellRender={(e) => {
            //   const { account_status } = e.data;
            //   let statusName = account_status;
            //   if (account_status === "Suspended") statusName = "View Only";
            //   return statusName;
            // }}
          />
          <Editing
            mode="row"
            allowUpdating={true}
            allowDeleting={true}
            texts={{
              confirmDeleteMessage:
                "Are you sure you want to delete the account?",
            }}
            useIcons={true}
          ></Editing>
        </DataGrid>
      </div>
    </React.Fragment>
  );
};

export default Accounts;
