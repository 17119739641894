import {
  SET_SELECTED_ACCOUNT,
  SET_ACCOUNT_INFORMATION,
  SET_SELF_SERVICE_ACCOUNTS,
  SET_ACCOUNT_HOLDINGS,
  SET_ACCOUNT_STATS,
  ADD_NEW_ACCOUNT_TO_LIST,
  SET_FULL_ASSET_LIST,
  SET_MANAGEMENT_TYPE_ID,
  SET_ORIGINAL_MANAGEMENT_TYPE_ID,
  SET_BASKET_ASSET_LIST,
  SET_RISK_MANAGED_ASSET_LIST,
  SET_RISK_REPORT_DATA,
  SET_ACCOUNT_API_INFO,
  SET_RISK_RATING_INFO,
  SET_ACTIVE_COMPETITIONS,
} from "./constant";
import { api } from "../../service";
import { tradeApi } from "../../service/api";

export const setDefaultAccount =
  (pxAccountID, pxAccount) => async (dispatch) => {
    await api.put(`/accounts/${pxAccountID}/default`);

    dispatch({ type: SET_SELECTED_ACCOUNT, payload: pxAccount });

    return "";
  };

/**
 * Get account by ID.
 *
 * @param {string} pxAccountID
 * @returns
 */
export const getAccountInformationByID = (pxAccountID) => async (dispatch) => {
  const { data } = await api.get(`/accounts/${pxAccountID}`);

  if (data) dispatch({ type: SET_ACCOUNT_INFORMATION, payload: data[0] });

  return data;
};

export const setAccountInformation = (pxAccountInfo) => async (dispatch) => {
  return dispatch({ type: SET_ACCOUNT_INFORMATION, payload: pxAccountInfo });
};

/**
 * Get Active Accounts
 */

export const getActiveCompetitions = () => async (dispatch) => {
  const { data } = await tradeApi.get(`/active_competitions`);

  if (data)
    dispatch({
      type: SET_ACTIVE_COMPETITIONS,
      payload: data.message?.accounts,
    });

  return data.message?.accounts;
};

export const setActiveCompetitions = (data) => async (dispatch) => {
  dispatch({
    type: SET_ACTIVE_COMPETITIONS,
    payload: data,
  });
};

/**
 * Set the selected account.
 *
 * @param {object} pxAccount
 * @returns
 */
export const setSelectedAccount = (pxAccount) => async (dispatch) => {
  return dispatch({ type: SET_SELECTED_ACCOUNT, payload: pxAccount });
};

export const addNewAccountToList = (pxAccount) => async (dispatch) => {
  return dispatch({ type: ADD_NEW_ACCOUNT_TO_LIST, payload: pxAccount });
};

export const removeSpecificAccountFromList =
  (pxCurrentAccounts, pxAccountID) => async (dispatch) => {
    const updated = pxCurrentAccounts.filter(
      (item) => item.client_account_id !== pxAccountID
    );

    if (updated)
      dispatch({ type: SET_SELF_SERVICE_ACCOUNTS, payload: updated });
  };

export const setSelfServiceAccounts = (pxAccounts) => async (dispatch) => {
  if (pxAccounts)
    dispatch({ type: SET_SELF_SERVICE_ACCOUNTS, payload: pxAccounts });
};
/**
 * Get all accounts.
 *
 * @returns Array
 */
export const getSelfServiceAccounts = () => async (dispatch) => {
  const { data } = await api.get(`/accounts`);

  if (data) dispatch({ type: SET_SELF_SERVICE_ACCOUNTS, payload: data });

  return data;
};

/**
 * Create an account.
 *
 * @param {object} pxAccountInfo
 * @returns
 */
export const createSelfServiceAccount = (pxAccountInfo) => async (dispatch) => {
  try {
    const { data } = await api.post(`/accounts`, pxAccountInfo);

    if (data) {
      data[0].exchange_account_id = pxAccountInfo.exchange_account_id;
      data[0].account_reference = pxAccountInfo.account_reference;
      dispatch({ type: SET_ACCOUNT_INFORMATION, payload: data[0] });
    }

    return data;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Update account by ID.
 *
 * @param {object} pxAccountInfo
 * @param {string} pxAccountID
 * @returns
 */
export const updateSelfServiceAccount =
  (pxAccountInfo, pxAccountID) => async (dispatch) => {
    try {
      const { data } = await api.put(`/accounts/${pxAccountID}`, pxAccountInfo);

      return data;
    } catch (error) {
      const message = error.response
        ? error.response.data.message
        : { message: error.message };
      throw new Error(message);
    }
  };

/**
 * Update the api key and secret.
 *
 * @param {object} pxApiInfo
 * @param {string} pxAccountID
 * @returns
 */
export const updateApiKeyAndSecret =
  (pxApiInfo, pxAccountID) => async (dispatch) => {
    try {
      const { data } = await api.put(
        `/accounts/${pxAccountID}/broker-secrets`,
        pxApiInfo
      );

      if (data) dispatch({ type: SET_ACCOUNT_API_INFO, payload: data });

      return data;
    } catch (error) {
      const message = error.response
        ? error.response.data.message
        : { message: error.message };
      throw new Error(message);
    }
  };

/**
 * Get the api key and secret.
 *
 * @param {string} pxAccountID
 * @returns
 */
export const getApiKeyAndSecret = (pxAccountID) => async (dispatch) => {
  const { data } = await api.get(`/accounts/${pxAccountID}/broker-secrets`);

  if (data) dispatch({ type: SET_ACCOUNT_API_INFO, payload: data });

  return data;
};

/**
 * Remove the account by ID.
 *
 * @param {string} pxAccountID
 * @returns
 */
export const removeAccountByID = (pxAccountID) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/accounts/${pxAccountID}`);

    if (data) dispatch({ type: SET_SELF_SERVICE_ACCOUNTS, payload: data });

    return data;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Suspend the specific client account.
 *
 * @param {string} pxAccountID
 * @param {string} pxStatusID
 * @returns
 */
export const updateAccountStatus =
  (pxAccountID, pxStatusID, pxCurrentAccounts) => async (dispatch) => {
    const { data } = await api.put(`/accounts/${pxAccountID}/status`, {
      account_status_id: pxStatusID,
    });

    const updated = pxCurrentAccounts.map((item) => {
      if (item.client_account_id === parseInt(pxAccountID)) {
        item.account_status_id = pxStatusID;
        if (pxStatusID === 1) item.account_status = "Active";
        if (pxStatusID === 2) item.account_status = "Suspended";
      }
      return item;
    });

    dispatch({ type: SET_SELF_SERVICE_ACCOUNTS, payload: updated });

    return data;
  };

/**
 * Suspend action for the specific client user.
 *
 * @param {String} pxAccountID
 * @param {String} pxSuspendActionID
 * @returns
 */
export const suspendAction = (pxAccountID, pxSuspendActionID) => async () => {
  const { data } = await api.put(`/accounts/${pxAccountID}/suspend-action`, {
    on_suspend_action_id: pxSuspendActionID,
  });

  return data;
};

/**
 * *********   CLIENT - STATS   *********
 */

/**
 * Get the specific client user's holdings.
 *
 * @param {string} pxAccountID
 * @returns
 */
export const getAccountHoldings = (pxAccountID) => async (dispatch) => {
  const { data } = await api.get(`/accounts/${pxAccountID}/holdings`);

  if (data) dispatch({ type: SET_ACCOUNT_HOLDINGS, payload: data });

  var sumOfAssetValue = 0;
  const updated = data.map((item) => {
    item.asset_value = item.price * item.holding;
    sumOfAssetValue += item.asset_value;
    return item;
  });

  updated.map((item) => {
    item.percent_account_value = (item.asset_value / sumOfAssetValue) * 100;
    return item;
  });

  return updated;
};

/**
 * Get the specific client user's stats.
 *
 * @param {String} pxAccountID
 * @returns
 */
export const getAccountStats = (pxAccountID) => async (dispatch) => {
  const { data } = await api.get(`/accounts/${pxAccountID}/stats`);

  if (data) dispatch({ type: SET_ACCOUNT_STATS, payload: data });

  return data;
};

/**
 * Get full assets by Account ID.
 *
 * @param {string} pxAccountID
 * @returns
 */
export const getFullAssetsByAccountID = (pxAccountID) => async (dispatch) => {
  const { data } = await api.get(`/accounts/${pxAccountID}/assets`);

  if (data) dispatch({ type: SET_FULL_ASSET_LIST, payload: data });

  return data;
};

/**
 * Get Basket assets and assets that could be basket.
 *
 * @param {string} pxAccountID
 * @returns
 */
export const getBasketAssetsWithAssetsThatCouldBeBasket =
  (pxAccountID) => async (dispatch) => {
    const { data } = await api.get(`/accounts/${pxAccountID}/assets/basket`);

    if (data) dispatch({ type: SET_BASKET_ASSET_LIST, payload: data });

    return data;
  };

/**
 * Get Risk managed assets and assets that could be basket.
 *
 * @param {string} pxAccountID
 * @returns
 */
export const getRiskManagedAssetsWithAssetsThatCouldBeRiskManaged =
  (pxAccountID) => async (dispatch) => {
    const { data } = await api.get(
      `/accounts/${pxAccountID}/assets/risk-managed`
    );

    if (data) dispatch({ type: SET_RISK_MANAGED_ASSET_LIST, payload: data });

    return data;
  };

/**
 * Get trades info by account id.
 *
 * @param {string} pxAccountID
 * @returns
 */
export const getTradesByAccountID =
  (pxAccountID, maxEntries = undefined) =>
  async (dispatch) => {
    const { data } = await api.get(
      `/accounts/${pxAccountID}/trades`,
      (maxEntries || maxEntries === 0) && {
        headers: {
          "X-RMaaS-Max-Entries": maxEntries,
        },
      }
    );

    return data;
  };

/**
 * Set management type.
 * Ex: risk-management, basket-trading, view-only
 *
 * @param {number} value
 * @returns
 */
export const setManagementTypeID = (value) => async (dispatch) => {
  dispatch({ type: SET_MANAGEMENT_TYPE_ID, payload: value });
};

export const setOriginalManagementTypeID = (value) => async (dispatch) => {
  dispatch({ type: SET_ORIGINAL_MANAGEMENT_TYPE_ID, payload: value });
};

export const getRiskReportData = (pxAssetID) => async (dispatch) => {
  const { data } = await api.get(`/reference/assets/${pxAssetID}/risk-report`);

  if (data) dispatch({ type: SET_RISK_REPORT_DATA, payload: data });

  return data;
};

/**
 * Save specific risk-report info.
 *
 * @param {string} pxRiskReportInfo
 * @returns
 */
export const saveSelectedRiskReportInfo =
  (pxRiskReportInfo) => async (dispatch) => {
    dispatch({ type: SET_RISK_RATING_INFO, payload: pxRiskReportInfo });
  };
