import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import Error from "../../components/Error";
import Logo from "../../components/Logo";
import { clearRequestStatus, passwordResetRequest } from "../../redux/auth/action";
import { PANXORA_WORDPRESS_LOGO_URL } from "../../constants";
import { getScreenSize } from "../../utils/media-query";
import "./ResetPasswordForm.scss";

const ResetPasswordForm = () => {
  const formData = useRef({});
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [isFinalView, setIsFinalView] = useState(false);
  const [maxWidth, setMaxWidth] = useState("500px");

  const updateMaxWidth = () => {
    const { isXSmall, isSmall, isMedium, isLarge } = getScreenSize();
    var width = "500px";
    if (isXSmall) width = "100%";
    if (isSmall) width = "100%";
    if (isMedium) width = "100%";
    if (isLarge && isFinalView) width = "660px";
    setMaxWidth(width);
  };

  useEffect(() => {
    dispatch(clearRequestStatus());
    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", updateMaxWidth);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    updateMaxWidth();
    // set resize listener
    window.addEventListener("resize", updateMaxWidth);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinalView]);
  const onSubmit = async (e) => {
    e.preventDefault();
    const { email } = formData.current;
    setLoading(true);

    try {
      await dispatch(passwordResetRequest(email));
      setError("");
      setLoading(false);
      setIsFinalView(true);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  return (
    <form className={"px-account-form"} onSubmit={onSubmit}>
      {!!error && <Error>{error}</Error>}
      {!isFinalView && (
        <Form formData={formData.current} disabled={loading}>
          <Item>
            <div className={"link"}>
              <Link to={"/login"}>Go to account login</Link>
            </div>
          </Item>
          <Item
            dataField={"email"}
            editorType={"dxTextBox"}
            editorOptions={emailEditorOptions}
          >
            <RequiredRule message="Email is required" />
            <EmailRule message="Email is invalid" />
            <Label visible={false} />
          </Item>
          <ButtonItem>
            <ButtonOptions
              elementAttr={submitButtonAttributes}
              width={"100%"}
              type={"default"}
              useSubmitBehavior={true}
            >
              <span className="dx-button-text">
                {loading ? (
                  <LoadIndicator
                    width={"24px"}
                    height={"24px"}
                    visible={true}
                  />
                ) : (
                  "Send Reset Link"
                )}
              </span>
            </ButtonOptions>
          </ButtonItem>
        </Form>
      )}
      {isFinalView && (
        <div className="px-welcome-view" style={{ width: `${maxWidth}` }}>
        <Logo imgPath={PANXORA_WORDPRESS_LOGO_URL} type="public" />
          <div className="px-description">
            We've sent a confirmation email to{" "}
            <strong>{formData.current.email}</strong>. Please follow the link in
            that email to reset your password. If you haven't received the email
            in 15 minutes, please check the following:
          </div>
          <ol>
            <li>Check if you have used the correct email.</li>
            <li>Check your spam folder.</li>
            <li>
              Email <a href="mailto:support@cybertools.club">support@cybertools.club</a>
            </li>
          </ol>
        </div>
      )}
    </form>
  );
};

const emailEditorOptions = {
  stylingMode: "filled",
  placeholder: "Email",
  mode: "email",
};
const submitButtonAttributes = { class: "submit-button" };

export default ResetPasswordForm;
