import React from "react";
import { Button } from "devextreme-react";
import ViewportList from "react-viewport-list";
import { useLocation } from "react-router-dom";
import { LoadIndicator } from "devextreme-react/load-indicator";

import DialogPortal from "../../../components/DialogPortal";
import { get } from "../../../utils/storage";
import { MAIN_TOKEN_KEY, PUBLIC_TOKEN } from "../../../constants";
import { tradeApi } from "../../../service/api";

const SearchDialog = ({ closeDialog, onMarketSelect }) => {
  const location = useLocation();
  const isPublicChart = location.pathname.includes("/base_chart");
  const [searchQuery, setSearchQuery] = React.useState("");
  const [marketsData, setmarketsData] = React.useState([]);
  const [loadingAllMarkets, setLoadingAllMarkets] = React.useState(false);

  const token = isPublicChart ? PUBLIC_TOKEN : JSON.parse(get(MAIN_TOKEN_KEY));

  React.useEffect(() => {
    // For animating Modal

    searchSymbol();
    const dialogContainer = document.querySelector(".search-dialog-content");

    const timeout = setTimeout(() => {
      dialogContainer.classList.add("animate-modal");
    }, 100);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, []);

  const searchSymbol = async (query) => {
    try {
      setLoadingAllMarkets(true);
      const { data } = await tradeApi.get(`/get_symbols`, {
        headers: {
          token,
          search: query ?? "",
        },
      });
      const { message } = data;
      // process markets data
      const marketKeys = Object.keys(message);
      const newMarketData = [];
      marketKeys.forEach((market, marketIndex) => {
        message[market].forEach((exchange, exIndex) => {
          newMarketData.push({
            id: `${marketIndex}.${exIndex}`,
            market,
            exchange,
          });
        });
      });
      setmarketsData(newMarketData);
      setLoadingAllMarkets(false);
    } catch (error) {
      setLoadingAllMarkets(false);
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    let value = e.target.value;
    setSearchQuery(value);

    let timeout = null;

    if (timeout) {
      clearTimeout(timeout);
    } else {
      timeout = setTimeout(() => {
        searchSymbol(value);
      }, 200);
    }
  };

  return (
    <DialogPortal className="trading-search-dialog">
      <div className="search-dialog-content">
        <div className="search-dialog-header">
          <div className="header-content">
            <div className="header-search-bar">
              <form>
                <input
                  placeholder="Search market"
                  value={searchQuery}
                  onChange={handleSearch}
                  type="search"
                />
              </form>
            </div>

            <div className="header-close-btn" onClick={closeDialog}>
              <Button icon="close" />
            </div>
          </div>
        </div>
        <div className="search-dialog-container">
          {loadingAllMarkets ? (
            <div className="loader">
              <LoadIndicator id="small-indicator" height={24} width={24} />
            </div>
          ) : (
            <div className="list-container">
              <ViewportList items={marketsData}>
                {(item) => (
                  <div
                    key={item.id}
                    className="list-item"
                    onClick={() => {
                      onMarketSelect(item.market, item.exchange);
                      closeDialog();
                    }}
                  >
                    <div className="market-title">{item.market}</div>
                    <div className="exchange-title">{item.exchange}</div>
                  </div>
                )}
              </ViewportList>
            </div>
          )}
        </div>
      </div>
    </DialogPortal>
  );
};

export default SearchDialog;
