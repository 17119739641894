import React, { useState } from "react";
import {
  Chart,
  Series,
  ArgumentAxis,
  CommonSeriesSettings,
  Export,
  Legend,
  Margin,
  Tooltip,
  Grid,
  Point,
  Size,
} from "devextreme-react/chart";
import { useScreenSize } from "../../utils/media-query.js";

const AccountValue = (props) => {
  const screenSize = useScreenSize();
  const [type] = useState("line");


  const serieSources = [{ value: "percent", name: props.serieName }];
  // Display date and account value in the tooltip.
  const customizeTooltip = (pointInfo) => {
    return { text: pointInfo.argument + "\n" + pointInfo.value}
  };

  return (
    <React.Fragment>
      <Chart
        palette="Violet"
        dataSource={props.balanceHistory}
        rotated={
          screenSize.isLarge || screenSize.isMedium || screenSize.isSmall
            ? false
            : true
        }
      >
        {screenSize.isXSmall && <Size width={280} height={800} />}
        <CommonSeriesSettings
          argumentField="balance_date"
          type={type}
          color={"#1a598f"}
        >
          <Point size={5} />
        </CommonSeriesSettings>
        {serieSources.map(function (item) {
          return (
            <Series key={item.value} valueField={item.value} name={item.name} />
          );
        })}
        <Margin bottom={20} />
        <ArgumentAxis
          valueMarginsEnabled={false}
          inverted={true}
          discreteAxisDivisionMode="crossLabels"
        >
          <Grid visible={true} />
        </ArgumentAxis>
        <Legend
          verticalAlignment="bottom"
          horizontalAlignment="center"
          itemTextPosition="bottom"
        />
        <Export enabled={false} />
        <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
      </Chart>
    </React.Fragment>
  );
};

export default AccountValue;
