import React, { useEffect, useState } from "react";
import axios from "axios";
import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from "devextreme-react/responsive-box";
import DataGrid, {
  Column,
  Scrolling,
  Pager,
  Paging,
} from "devextreme-react/data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Joyride, { ACTIONS, EVENTS } from "react-joyride";

import {
  setRedirectedFromDemo,
  setStartDemo,
  startStopTour,

} from "../../../redux/app-tour/action";
import TotalImersGemsChart from "./TotalImersGemsChart";
import {
  ALLOWED_PAGE_SIZES_MEDIUM,
  ALLOWED_PAGE_SIZES_SMALL,
  WEALTH_PAGE_STEPS,
  MAIN_TOKEN_KEY,
} from "../../../constants";
import { Button } from "devextreme-react";
import "./Competition.scss";
import { get } from "../../../utils/storage";

function screen(width) {
  return width < 700 ? "sm" : "lg";
}

const Competition = () => {
  const dispatch = useDispatch();
  const {startDemo, redirectedDuringDemo} = useSelector((state) => state.tourState)
  const history = useHistory();
  const [competitionReverse, setCompetitionReverse] = useState("imers");
  const [earningsData, setEarningsData] = useState();
  const [currentRankings, setCurrentRankings] = useState();
  const [stepIndex, setStepIndex] = useState(0);
  const [run, setRun] = useState(false);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_TRADE_API_KEY}/token_earnings`,
        {
          headers: {
            token: `${get(MAIN_TOKEN_KEY).split('"')[1]}`,
            coin: competitionReverse,
          },
        }
      );

      const { message } = data;

      let rowDatas = message.map((item, index) => {
        return { ...item.row_data, id: index };
      });

      rowDatas = rowDatas?.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      );
      setEarningsData(rowDatas);
    };

    fetchData();
  }, [competitionReverse]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_TRADE_API_KEY}/current_rankings`,
        {
          headers: {
            token: `${get(MAIN_TOKEN_KEY).split('"')[1]}`,
          },
        }
      );

      const { message } = data || {};

      setCurrentRankings(message);
    };

    dispatch(startStopTour(false));
    
    let timeout = setTimeout(() => {
      if(redirectedDuringDemo && startDemo) {
        setStepIndex(0);
        setSteps(WEALTH_PAGE_STEPS);
        setRun(true);
      }
    }, 1000);

    fetchData();

    return () => clearTimeout(timeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCallback = (data) => {
    const { action, index, type } = data;

    if ([EVENTS.STEP_AFTER].includes(type)) {
      if (index === 3 && ACTIONS.NEXT === action) {
        setTimeout(() => {
          if(startDemo && redirectedDuringDemo) {
            dispatch(setRedirectedFromDemo(false))
            dispatch(setStartDemo(false))
          }
          history.push("/trading-dashboard");
        }, 200);
      } else {
        const newIndex =
          index +
          (action === ACTIONS.NEXT ? 1 : action === ACTIONS.PREV ? -1 : 1);

        if (newIndex === 2) {
          const element = document.getElementById("tour_topTradersTable");
          element.scrollIntoView({ behavior: "smooth" });

          setTimeout(() => {
            setStepIndex(newIndex);
          }, 300);
        } else {
          setStepIndex(newIndex);
        }
      }
    }
  };

  return (
    <React.Fragment>
      <Joyride
        callback={handleCallback}
        continuous
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        disableCloseOnEsc
        stepIndex={stepIndex}
        steps={steps}
      />
      <div className="px-competition-dashboard">
        <div
          className={"content-block dx-card responsive-paddings"}
          style={{ padding: "20px 50px", margin: 8 }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <div>
              <h5
                className={"content-block"}
                style={{
                  marginLeft: 0,
                  marginBottom: 5,
                  marginTop: 0,
                  fontWeight: 600,
                }}
              >
                WEALTH
              </h5>
            </div>
            <div
              id="tour_wealthPageSwitch"
              className="px-account-actions"
              style={{ display: "flex" }}
            >
              <Button
                className={`px-switch-account-mode ${
                  competitionReverse === "gems" ? "selected" : ""
                }`}
                type="normal"
                stylingMode="text"
                onClick={() => setCompetitionReverse("gems")}
                Tooltip="Reload"
              >
                <span className="dx-button-text medium-size">GEMS</span>
              </Button>
              <div className="divider medium-size" />
              <Button
                className={`px-switch-account-mode ${
                  competitionReverse === "imers" ? "selected" : ""
                }`}
                type="normal"
                stylingMode="text"
                onClick={() => setCompetitionReverse("imers")}
                Tooltip="Reload"
              >
                <span className="dx-button-text medium-size">IMERS</span>
              </Button>
            </div>
          </div>
        </div>
        <ResponsiveBox singleColumnScreen="sm" screenByWidth={screen}>
          {/* Responsive Layout */}
          <Row ratio={1}></Row>
          <Row ratio={1}></Row>
          <Row ratio={1} screen="xs"></Row>
          <Row ratio={1} screen="xs"></Row>
          <Row ratio={2}></Row>
          <Row ratio={4} screen="xs"></Row>
          <Row ratio={1}></Row>

          <Col ratio={1}></Col>
          <Col ratio={1} screen="lg"></Col>
          <Item>
            <Location row={1} col={0} screen="lg"></Location>
            <Location row={1} col={0} colspan={2} screen="sm"></Location>
            <div className={"content-block dx-card px-item"}>
              <div
                style={{
                  margin: "10px 0 20px 0",
                  position: "relative",
                  display: "inline-grid",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <p
                  className="px-title margin-bottom-50"
                  style={{ fontSize: 20, fontWeight: "bold", marginBottom: 0 }}
                >
                  EARNINGS
                </p>
                <p style={{ marginTop: 0, marginBottom: 10 }}>
                  Review your daily ranking and earnings.
                </p>
              </div>
              <DataGrid
                id="tour_earningsTable"
                className={"dx-card wide-card px-competition-tables"}
                style={{ margin: "0", width: "100%" }}
                dataSource={earningsData || []}
                showBorders={true}
                keyExpr="id"
                focusedRowEnabled={true}
                // defaultFocusedRowIndex={-1}
                columnAutoWidth={true}
                columnHidingEnabled={true}
                allowEditing={true}
                calculateCell
                onRowPrepared={(e) => (e.rowElement.style.height = "20px")}
              >
                <Scrolling rowRenderingMode="virtual"></Scrolling>
                <Paging defaultPageSize={ALLOWED_PAGE_SIZES_MEDIUM[0]} />
                <Pager
                  visible={true}
                  allowedPageSizes={ALLOWED_PAGE_SIZES_MEDIUM}
                  displayMode={"full"}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <Column
                  dataField={"date"}
                  caption={"DATE"}
                  alignment="left"
                  width={200}
                  hidingPriority={2}
                />
                <Column
                  dataField={"type"}
                  caption={"TYPE"}
                  alignment="left"
                  width={200}
                  hidingPriority={2}
                />
                <Column
                  dataField={"ranking"}
                  caption={"RANKING"}
                  alignment="left"
                  width={100}
                  hidingPriority={2}
                />
                <Column
                  dataField={"quantity"}
                  caption={competitionReverse.toUpperCase()}
                  width={100}
                  alignment="left"
                  hidingPriority={2}
                />
                <Column
                  dataField={"balance"}
                  caption={`TOTAL ` + competitionReverse.toUpperCase()}
                  alignment="left"
                  width={100}
                  hidingPriority={2}
                />
              </DataGrid>
            </div>
          </Item>
          <Item>
            <Location row={1} col={1} screen="lg"></Location>
            <Location row={2} col={0} screen="sm"></Location>
            <div className={"content-block dx-card px-item"}>
              <div
                style={{
                  margin: "10px 0 20px 0",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "30px",
                  width: "100%",
                }}
              ></div>

              <TotalImersGemsChart
                earningsData={earningsData}
                competitionReverse={competitionReverse}
              />
            </div>
          </Item>
          <Item>
            <Location row={2} col={0} screen="lg"></Location>
            <Location row={2} col={0} screen="sm"></Location>
            <div className={"content-block dx-card px-item"}>
              <div
                style={{
                  margin: "10px 0 20px 0",
                  position: "relative",
                  display: "inline-grid",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <p
                  className="px-title margin-bottom-50"
                  style={{ fontSize: 20, fontWeight: "bold", marginBottom: 0 }}
                >
                  TODAY'S TOP TRADERS
                </p>
                <p style={{ marginTop: 0, marginBottom: 10 }}>
                  See who is leading today's competitions
                </p>
              </div>
              <DataGrid
                id="tour_topTradersTable"
                className={"dx-card wide-card px-competition-tables"}
                dataSource={currentRankings?.gems}
                showBorders={true}
                keyExpr="user_id"
                focusedRowEnabled={true}
                columnAutoWidth={true}
                columnHidingEnabled={true}
                allowEditing={true}
                calculateCell
              >
                <Scrolling rowRenderingMode="virtual"></Scrolling>
                <Paging defaultPageSize={ALLOWED_PAGE_SIZES_SMALL[0]} />
                <Pager
                  visible={true}
                  allowedPageSizes={ALLOWED_PAGE_SIZES_SMALL}
                  displayMode={"full"}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <Column
                  dataField={"display_name"}
                  caption={"TRADER"}
                  alignment="left"
                  hidingPriority={2}
                />
                <Column
                  dataField={"quantity"}
                  caption={"GEMS"}
                  cellRender={(e) => {
                    const { quantity } = e.data;
                    return <span>{parseFloat(quantity).toFixed(0)}</span>;
                  }}
                  alignment="left"
                  hidingPriority={2}
                />
              </DataGrid>
            </div>
          </Item>
          <Item>
            <Location row={2} col={1} screen="lg"></Location>
            <Location row={4} col={0} screen="sm"></Location>
            <div className={"content-block dx-card px-item"}>
              <div
                style={{
                  margin: "10px 0 20px 0",
                  position: "relative",
                  display: "inline-grid",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <p
                  className="px-title margin-bottom-50"
                  style={{ fontSize: 20, fontWeight: "bold", marginBottom: 0 }}
                >
                  IMERS RICH LIST
                </p>
                <p style={{ marginTop: 0, marginBottom: 10 }}>
                  Traders with the largest IMERS balance
                </p>
              </div>
              <DataGrid
                id="tour_imersRichListTable"
                className={"dx-card wide-card px-competition-tables"}
                dataSource={currentRankings?.imers}
                showBorders={true}
                keyExpr="user_id"
                focusedRowEnabled={true}
                columnAutoWidth={true}
                columnHidingEnabled={true}
                allowEditing={true}
                calculateCell
              >
                <Scrolling rowRenderingMode="virtual"></Scrolling>
                <Paging defaultPageSize={ALLOWED_PAGE_SIZES_SMALL[0]} />
                <Pager
                  visible={true}
                  allowedPageSizes={ALLOWED_PAGE_SIZES_SMALL}
                  displayMode={"full"}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <Column
                  dataField={"display_name"}
                  caption={"TRADER"}
                  alignment="left"
                  hidingPriority={2}
                />
                <Column
                  dataField={"quantity"}
                  caption={"IMERS"}
                  alignment="left"
                  cellRender={(e) => {
                    const { quantity } = e.data;
                    return <span>{parseFloat(quantity).toFixed(0)}</span>;
                  }}
                  hidingPriority={2}
                />
              </DataGrid>
            </div>
          </Item>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};
export default Competition;
