import axios from "axios";
import {
  SET_USER_DATA,
  SET_QR_INFO,
  REMOVE_USER_DATA,
  REGISTER_EMAIL_PROCESS,
  SAVE_TEMP_TOKEN,
  BEFORE_PROFILE_FORM_EDITED,
  RESET_PASSWORD_REQUEST,
  SIDE_BAR_MODE_CHANGE,
  ACCOUNT_MODE_CHANGE,
} from "./constant";
import { api } from "../../service";
import { remove, set } from "../../utils/storage";
import { MAIN_TOKEN_KEY } from "../../constants/app";

/**
 * Connect with token to get user data.
 *
 * @returns JSON data
 */
export const connect = () => async (dispatch) => {
  const { data } = await api.get(`/users/by-token`);

  if (data) {
    dispatch({ type: SET_USER_DATA, payload: data });
  }
  return data;
};

/**
 * Save temp token to use if when login with 2FA.
 *
 * @param {string} pxTempToken
 * @returns
 */
export const saveTempToken = (pxTempToken) => async (dispatch) => {
  dispatch({ type: SAVE_TEMP_TOKEN, payload: pxTempToken });
};

/**
 * Register standard and vr accounts.
 *
 * @param {object} pxData
 * @returns
 */
export const registerAccount = (pxData) => async (dispatch) => {
  try {
    await api.post(`/auth/register`, pxData);

    // dispatch({ type: REGISTER_EMAIL_PROCESS, payload: true });
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Register user email.
 *
 * @param {string} pxEmail
 * @returns
 */
export const registerEmail = (pxData) => async (dispatch) => {
  try {
    await api.post(`/auth/invites`, pxData);

    dispatch({ type: REGISTER_EMAIL_PROCESS, payload: true });
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : { message: error.message };
    throw new Error(message);
  }
};

export const clearRegisterStatus = () => async (dispatch) => {
  dispatch({ type: REGISTER_EMAIL_PROCESS, payload: false });
};
export const clearRequestStatus = () => async (dispatch) => {
  dispatch({ type: RESET_PASSWORD_REQUEST, payload: false });
};
/**
 * Login with email and password.
 *
 * @param {string} pxEmail
 * @param {string} pxPassword
 * @returns
 */
export const login = (pxEmail, pxPassword) => async (dispatch) => {
  // eslint-disable-next-line no-unused-vars
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_KEY}/auth/login`,
      {
        headers: {
          "X-RMaaS-Email-Address": pxEmail,
          "X-RMaaS-Password": pxPassword,
        },
      }
    );
    return data;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Generate secret key and otpauth_uri.
 *
 * @returns JSON data
 */
export const generate2FA = () => async (dispatch) => {
  const { data } = await api.get(`/auth/2FA/generate`);

  return data;
};

/**
 * Generate QrCode Image as svg or png.
 *
 * @param {string} pxQrCodeUri
 * @returns
 */
export const generateQR = (pxQrCodeUri) => async (dispatch) => {
  const { data } = await api.post(`/auth/2FA/qr`, {
    otpauth_uri: pxQrCodeUri,
  });

  if (data) {
    dispatch({ type: SET_QR_INFO, payload: data });
  }

  return data;
};

/**
 * Verify the code to enable 2FA authentication.
 *
 * @param {*} pxSecret
 * @param {*} pxCode
 * @returns
 */
export const verify2FA = (pxSecret, pxCode) => async (dispatch) => {
  try {
    const { data } = await api.post(`/auth/2FA/verify`, {
      secret: pxSecret,
      code: pxCode,
    });

    return data;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Validate the code to setup 2FA.
 *
 * @param {number} pxCode
 * @param {*} pxToken
 * @returns
 */
export const validateCode = (pxCode, pxToken) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_KEY}/auth/2FA/validate`,
      {
        code: pxCode,
      },
      {
        headers: {
          "X-RMaaS-Token": pxToken,
        },
      }
    );

    if (data) {
      // Set token
      set(MAIN_TOKEN_KEY, data.token);
    }

    return data;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : { message: error.message };

    throw new Error(message);
  }
};

/**
 * Save secret to user data to enable 2FA.
 *
 * @param {string} pxSecret
 * @returns
 */
export const saveSecretToUserData = (pxSecret) => async (dispatch) => {
  const { data } = await api.post(`/auth/2FA`, {
    secret: pxSecret,
  });

  return data;
};

/**
 * Change user password.
 *
 * @param {string} pxOldPassword
 * @param {string} pxNewPassword
 * @param {number} pxCode
 * @returns
 */
export const changeUserPassword =
  (pxOldPassword, pxNewPassword, pxCode) => async (dispatch) => {
    try {
      const { data } = await api.post(`/auth/password`, {
        old_password: pxOldPassword,
        new_password: pxNewPassword,
        code: pxCode,
      });

      return data;
    } catch (error) {
      const message = error.response
        ? error.response.data.message
        : { message: error.message };

      throw new Error(message);
    }
  };

/**
 * Clear their own 2FA.
 *
 * @param {number} pxCode
 * @returns
 */
export const clear2FA = (pxCode) => async (dispatch) => {
  try {
    const { data } = await api.get(`/auth/2FA/clear`, {
      headers: {
        "X-RMaaS-2FA-Code": pxCode,
      },
    });

    return data;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : { message: error.message };

    throw new Error(message);
  }
};

/********* Forgot Password endpoints **********/

/**
 * Email to the login's contact email address with a link to http://{base_confirm_url}/reset-password/<token>.
 * @param {string} pxEmail
 * @returns
 */
export const passwordResetRequest = (pxEmail) => async (dispatch) => {
  try {
    const { data } = await api.post(`/auth/password/reset-request`, {
      login_id: pxEmail,
    });

    dispatch({ type: RESET_PASSWORD_REQUEST, payload: true });

    return data;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : { message: error.message };

    throw new Error(message);
  }
};

/**
 * Password Reset (password reset tokens)
 *
 * @param {string} pxPassword
 * @returns
 */
export const passwordResetChange =
  (pxPassword, pxTempToken) => async (dispatch) => {
    try {
      const { data } = await api.post(
        `/auth/password`,
        {
          password: pxPassword,
        },
        {
          headers: {
            "X-RMaaS-Token": pxTempToken,
          },
        }
      );

      return data;
    } catch (error) {
      const message = error.response
        ? error.response.data.message
        : { message: error.message };

      throw new Error(message);
    }
  };

/**
 * Connect device with pairing code.
 *
 * @param {Object} pxData
 */
export const connectDeviceWithPairCode = (pxData) => async (dispatch) => {
  try {
    const { data } = await api.post("/auth/pair", pxData);

    return data;
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : { message: error.message };

    throw new Error(message);
  }
};

/**
 * Logout from the dashboard and redirect to the login page.
 *
 * @param {Object} history
 * @param {Object} location
 * @returns
 */
export const logout = (history, location) => async (dispatch) => {
  try {
    await api.get(`/auth/logout`);

    // remove token
    remove(MAIN_TOKEN_KEY);

    // remove user data
    dispatch({ type: REMOVE_USER_DATA });

    if (history && location) history.push("/login");
  } catch (error) {
    const message = error.response
      ? error.response.data.message
      : { message: error.message };

    throw new Error(message);
  }
};

/**
 * Dispatch action to get a status of user in profile form.
 *
 * @param {boolean} pxIsEdited
 * @returns
 */
export const ProfileFormEditedBefore = (pxIsEdited) => async (dispatch) => {
  dispatch({ type: BEFORE_PROFILE_FORM_EDITED, payload: pxIsEdited });
};

export const handleSideBarByMode = (pxMode) => async (dispatch) => {
  dispatch({ type: SIDE_BAR_MODE_CHANGE, payload: pxMode });
};

export const handleAccountMode = (pxAccMode) => async (dispatch) => {
  dispatch({ type: ACCOUNT_MODE_CHANGE, payload: pxAccMode });
};
