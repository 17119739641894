import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Form, {
  Item,
  GroupItem,
  ButtonItem,
  Label,
  ButtonOptions,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import Error from "../Error";
import Joyride from "react-joyride";
import {
  accountDefaultOption,
  apiKeyDefaultOption,
  apiSecretDefaultOption,
  exchangeDefaultOption,
} from "../../constants/app";

const AddAccountInformation = ({
  dispatchAddSelfServiceUser,
  exchanges,
  onboardingStatusInfo = null,
}) => {
  const history = useHistory();
  const formData = useRef({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [enableOnboarding, setEnableOnboarding] = useState(false);

  useEffect(() => {
    if (onboardingStatusInfo) setEnableOnboarding(onboardingStatusInfo.visible);
  }, [onboardingStatusInfo]);

  const exchangeEditorOption = {
    ...exchangeDefaultOption,
    items: exchanges,
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      await dispatchAddSelfServiceUser(formData.current);
      // history.push("/accounts");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  const goToList = () => {
    history.push("/accounts");
  };

  return (
    <form onSubmit={handleSubmit}>
      {!!error && <Error>{error}</Error>}
      <Joyride
        continuous={true}
        run={enableOnboarding}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={false}
        steps={[
          {
            title: <p style={{ textAlign: "left" }}>SELECT EXCHANGE</p>,
            content: (
              <p style={{ textAlign: "left" }}>
                CyberTools allows multiple accounts across various exchanges to
                be connected via API permissions. Continue to review this list
                as more exchanges are added daily.
              </p>
            ),
            floaterProps: {
              disableAnimation: true,
            },
            spotlightPadding: 10,
            target: ".px-exchange-dropdown .dx-field-item-content",
            disableBeacon: true,
            placement: "right",
          },
          {
            title: <p style={{ textAlign: "left" }}>ADD API KEY & SECRET</p>,
            content: (
              <p style={{ textAlign: "left" }}>
                Connect your exchange to the Cybertools management services with
                a valid API key & secret.{" "}
                <a href="https://cybertools.club/risk-management/rmaas-tutorial-library/" target={"_blank"} rel="noreferrer">
                  ‘Setup Tutorial Guide’
                </a>
              </p>
            ),
            floaterProps: {
              disableAnimation: true,
            },
            spotlightPadding: 10,
            target: ".px-api-key-input .dx-field-item-content",
            disableBeacon: true,
            placement: "right",
          },
        ]}
      />
      <Form
        formData={formData.current}
        showColonAfterLabel={false}
        colCount={2}
        disabled={loading}
      >
        <GroupItem>
          <GroupItem colCount={2}>
            <Item
              dataField="account_reference"
              editorType={"dxTextBox"}
              editorOptions={accountDefaultOption}
              isRequired={true}
            >
              <Label visible={true} text="ACCOUNT REFERENCE" />
            </Item>
          </GroupItem>
          <Item
            dataField="exchange_account_id"
            editorType={"dxSelectBox"}
            cssClass={"px-exchange-dropdown"}
            editorOptions={exchangeEditorOption}
            isRequired={true}
          >
            <Label visible={true} text="EXCHANGE" />
          </Item>
          <Item
            dataField="api_key"
            editorType={"dxTextBox"}
            cssClass={"px-api-key-input"}
            editorOptions={apiKeyDefaultOption}
            isRequired={true}
          >
            <Label visible={true} text="API KEY" />
          </Item>
          <Item
            dataField="api_secret"
            editorType={"dxTextBox"}
            editorOptions={apiSecretDefaultOption}
            isRequired={true}
          >
            <Label visible={true} text="API SECRET" />
          </Item>
          <GroupItem colCount={2}>
            <ButtonItem horizontalAlignment="left">
              <ButtonOptions
                width="100%"
                text="Add"
                type="default"
                useSubmitBehavior={true}
              >
                <span className="dx-button-text">
                  {loading ? (
                    <LoadIndicator
                      width={"24px"}
                      height={"24px"}
                      visible={true}
                    />
                  ) : (
                    "Create"
                  )}
                </span>
              </ButtonOptions>
            </ButtonItem>
            <ButtonItem horizontalAlignment="right">
              <ButtonOptions
                width="100%"
                type="normal"
                text="Cancel"
                useSubmitBehavior={false}
                onClick={goToList}
              />
            </ButtonItem>
          </GroupItem>
        </GroupItem>
      </Form>
    </form>
  );
};
export default AddAccountInformation;
