import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "devextreme-react/button";
// import { SelectBox } from "devextreme-react/select-box";
import {
  // IconButton,
  Menu,
  MenuItem,
  ListItemText,
  Button as MUIButton,
  // ListItemSecondaryAction,
  MenuList,
} from "@material-ui/core";
import { BiChevronDown } from "react-icons/bi";

import Toolbar, { Item } from "devextreme-react/toolbar";
import { Template } from "devextreme-react/core/template";
import UserPanel from "../user-panel/UserPanel";
import {
  setAccountInformation,
  setSelectedAccount,
} from "../../redux/accounts/action";
import { getAccountStatuses } from "../../redux/references/action";
import "./Header.scss";
import { handleAccountMode } from "../../redux/auth/action";

const Header = ({ menuToggleEnabled, title, showAccountBar, toggleMenu }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const accounts = useSelector((state) => state.accounts.list);
  const accMode = useSelector((state) => state.auth.accMode);
  const sitemode = useSelector((state) => state.auth.mode);
  const currentTheme = useSelector(
    (state) => state.tradingDashboard.currentTheme
  );

  let account = useSelector((state) => state.accounts.account);

  const [filteredAccounts, setFilteredAccounts] = useState(accounts);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    dispatch(getAccountStatuses());
    // When the user dive into there to setup a new account,
    // Initialize selected account as null to avoid running testing api bar in Setup Account
    return () => {
      dispatch(setSelectedAccount(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToAddAccount = () => {
    dispatch(setAccountInformation(null));
    history.push("/accounts/add");
  };

  useEffect(() => {
    if (!accounts) return;
    filterAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accMode, accounts]);

  useEffect(() => {
    if (!sitemode) return;
    if (sitemode === "imers") {
      dispatch(handleAccountMode("practice"));
    } else {
      dispatch(handleAccountMode("live"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sitemode]);

  const filterAccounts = () => {
    const filteredData = accounts.filter(
      (item) => item?.account_mode?.toLowerCase() === accMode?.toLowerCase()
    );

    setFilteredAccounts(filteredData);
    dispatch(setSelectedAccount(filteredData[0]));
  };

  const handleValueChanged = (e) => {
    const selAccount = accounts.filter(
      (item) => item.client_account_id === e
    )[0];
    dispatch(setSelectedAccount(selAccount));
  };

  // const handleMode = (pxMode) => {
  //   dispatch(handleAccountMode(pxMode));
  // };

  return (
    <header
      className={`header-component ${
        currentTheme === "Dark"
          ? "dark"
          : currentTheme === "Glass"
          ? "glass"
          : "light"
      }-theme-bg`}
    >
      <Toolbar className={"header-toolbar"}>
        <Item
          visible={menuToggleEnabled}
          location={"before"}
          widget={"dxButton"}
          cssClass={"menu-button"}
        >
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
        <Item
          location={"before"}
          cssClass={"padding-left-20 px-account-tool-container"}
          visible={showAccountBar}
        >
          <div className="px-account-tool" style={{ marginRight: 30 }}>
            {filteredAccounts && filteredAccounts.length !== 0 && (
              <>
                <MUIButton
                  className="competition-menu"
                  variant="outlined"
                  endIcon={<BiChevronDown fontSize={10} color="#777777" />}
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                  }}
                >
                  {!account
                    ? filteredAccounts
                      ? filteredAccounts[0].account_reference +
                        ": " +
                        filteredAccounts[0].exchange_account_type
                      : null
                    : account.account_reference +
                      ": " +
                      account.exchange_account_type}
                </MUIButton>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  PaperProps={{
                    style: {
                      maxHeight: "calc(100vh - 400px)",
                      minWidth: 180,
                      backgroundColor:
                        currentTheme === "Glass" || currentTheme === "Dark"
                          ? "rgba(43, 29, 26, 0.9)"
                          : "#ffffff",
                      color:
                        currentTheme === "Glass" || currentTheme === "Dark"
                          ? "#ffffff"
                          : "#222222",
                    },
                  }}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <MenuList dense disablePadding disableListWrap>
                    {filteredAccounts.map((data, index) => (
                      <MenuItem
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                          paddingRight: 2,
                          paddingLeft: 4,
                        }}
                        key={index}
                        onClick={() => {
                          setAnchorEl(null);
                          handleValueChanged(data.client_account_id);
                        }}
                      >
                        <ListItemText
                          primary={
                            <p
                              style={{
                                lineHeight: 0,
                                fontSize: 11,
                                fontWeight: "bold",
                              }}
                            >
                              {data.account_reference +
                                ": " +
                                data.exchange_account_type}
                            </p>
                          }
                        />
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
                {/* <SelectBox
                  valueExpr="client_account_id"
                  itemTemplate={(itemData, itemIndex, itemElement) => {
                    const { account_status } = itemData;
                    let bgColor = "";

                    switch (account_status.toLowerCase()) {
                      case "suspended":
                        bgColor = "#f05b41";
                        break;
                      case "active":
                        bgColor = "green";
                        break;

                      default:
                        bgColor = "#1a598f";
                        break;
                    }
                    return window.$("<div />").append(
                      window
                        .$("<span />")
                        .text(
                          `${itemData.account_reference}: ${itemData.exchange_account_type}`
                        ),
                      window
                        .$("<button />")
                        .attr("disabled", "true")
                        .css("background-color", `${bgColor}`)
                        .css("color", "white")
                        .css("position", "absolute")
                        .css("right", "5px")
                        .text(`${itemData.account_status.charAt(0)}`)
                      // itemData.default_account
                      //     ? window
                      //       .$("<button />")
                      //       .attr("disabled", "true")
                      //       .css("background-color", "#1a598f")
                      //       .css("color", "white")
                      //       .css("position", "absolute")
                      //       .css("right", "5px")
                      //       .text("default")
                    );
                  }}
                  displayExpr={(data) => {
                    if (data) {
                      return (
                        data.account_reference +
                        ": " +
                        data.exchange_account_type
                      );
                    }
                  }}
                  items={filteredAccounts}
                  defaultValue={
                    filteredAccounts
                      ? filteredAccounts[0].client_account_id
                      : null
                  }
                  value={
                    !account
                      ? filteredAccounts
                        ? filteredAccounts[0].client_account_id
                        : null
                      : account.client_account_id
                  }
                  placeholder="Select account"
                  onValueChanged={handleValueChanged}
                  width={300}
                /> */}
              </>
            )}
          </div>
        </Item>
        <Item location={"center"} visible={true}>
          <iframe
            id="iMarketingFrame"
            title="imarketing-frame"
            src={`${process.env.REACT_APP_MARKETING_HTML_URL}`}
            frameBorder="0"
            scrolling="no"
            allowtransparency="true"
            width={728}
            height={90}
          ></iframe>
        </Item>
        {/* <Item
          location={"after"}
          locateInMenu={"auto"}
          menuItemTemplate={"userPanelTemplate"}
        >
          <div className="px-operation-mode">
            {sitemode && sitemode === "cybertools" && (
              <>
                <span
                  className={`${
                    accMode && accMode === "live" ? "active-mode" : "mode"
                  }`}
                  onClick={() => handleMode("live")}
                >
                  LIVE
                </span>
              </>
            )}
            {sitemode && sitemode === "imers" && (
              <>
                <span
                  className={`${
                    accMode && accMode === "practice" ? "active-mode" : "mode"
                  }`}
                  onClick={() => handleMode("practice")}
                >
                  PRACTICE
                </span>
                <div className="divider"></div>
                <span
                  className={`${
                    accMode && accMode === "competition"
                      ? "active-mode"
                      : "mode"
                  }`}
                  onClick={() => handleMode("competition")}
                >
                  COMPETITION
                </span>
              </>
            )}
          </div>
        </Item> */}
        {sitemode !== "imers" && (
          <Item
            location={"after"}
            locateInMenu={"auto"}
            menuItemTemplate={"userPanelTemplate"}
          >
            <Button
              type="default"
              text="Add Account"
              className="px-plus-btn"
              onClick={() => goToAddAccount()}
              visible={showAccountBar}
            />
          </Item>
        )}
        <Item
          location={"after"}
          locateInMenu={"auto"}
          menuItemTemplate={"userPanelTemplate"}
        >
          <Button
            className={"user-button authorization"}
            height={"100%"}
            stylingMode={"text"}
          >
            <UserPanel pxMenuMode={"context"} />
          </Button>
        </Item>
        <Template name={"userPanelTemplate"}>
          <UserPanel pxMenuMode={"list"} />
        </Template>
      </Toolbar>
    </header>
  );
};

export default Header;
