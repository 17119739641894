import React from "react";
import MainComponent from "./MainComponent";

const Community = () => {
  return (
    <React.Fragment>
      <div className={"content-block dx-card responsive-paddings"}>
        <h2 className={"content-block"} style={{ marginLeft: 0, marginBottom: 30}}>COMMUNITY</h2>
        <div className="content dx-fieldset">
          <div className="dx-field">
            <div className={"dx-field-value width-100"}>
              <MainComponent />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Community;
