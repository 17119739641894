export const SET_USER_DATA = "SET_USER_DATA";
export const SET_INITIAL_USER = "SET_INITIAL_USER";
export const SET_GENERATED_2FA_DATA = "SET_GENERATED_2FA_DATA";
export const SET_QR_INFO = "SET_QR_INFO";
export const REMOVE_USER_DATA = "REMOVE_USER_DATA";
export const REGISTER_EMAIL_PROCESS = "REGISTER_EMAIL_PROCESS";
export const SAVE_TEMP_TOKEN = "SAVE_TEMP_TOKEN";
export const BEFORE_PROFILE_FORM_EDITED = "BEFORE_PROFILE_FORM_EDITED";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const SIDE_BAR_MODE_CHANGE = "SIDE_BAR_MODE_CHANGE";
export const ACCOUNT_MODE_CHANGE = "ACCOUNT_MODE_CHANGE";
