import { SET_ASSET_ALLOCATION, REMOVE_USER_DATA } from "./constant";

const DEFAULT_STATE = {
  assetsInfo: null,
};

const manageAssetReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_ASSET_ALLOCATION:
      return { ...state, assetsInfo: action.payload };
    // After call logout action.
    case REMOVE_USER_DATA:
      return DEFAULT_STATE;
    default:
      return state;
  }
};

export default manageAssetReducer;
