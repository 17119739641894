import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form, {
  Item,
  ButtonItem,
  Label,
  ButtonOptions,
  RequiredRule,
  PatternRule,
  CustomRule,
} from "devextreme-react/form";
import "devextreme-react/text-area";
import LoadIndicator from "devextreme-react/load-indicator";
import { useToast } from "../../contexts/toast";
import { DEFAULT_TOAST_TIME } from "../../constants";
import Error from "../Error";
import { changeReqPayloadFormat, joinWithoutDupes } from "../../utils/custom-functions";
import { getAssetAllocationByAccountID, updateAssetAllocationByAccountID } from "../../redux/manage-asset/action";

const ReduceTargetAmountForm = ({ allocations, curManagementTypeID }) => {
  const dispatch = useDispatch();
  const formData = useRef({});
  const formRef = useRef();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [uniqueAmount, setUniqueAmount] = useState();

  
  const account = useSelector((state) => state.accounts.info);
  const accountID = account ? account.client_account_id : null;

  const amountFieldDefaultOptions = {
    stylingMode: "filled",
    placeholder: "",
    mode: "text",
    inputAttr: {
      autocomplete: "new-address",
    },
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      // Dispatch method.
      reduceTargetQuantity(formData.current);
      setLoading(false);
      setUniqueAmount(0);
      // Initialize the devextreme form.
      formRef.current.instance.resetValues();
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  const reduceTargetQuantity = async (values) => {
    /**
     * When the user reduces the target allocation, they enter the quantity of the asset to reduce.
     * This quantity must be less than or equal to the Target Allocation for that asset.
     * The Target Allocation is reduced by the specified amount.
     * Then the above calculations are recalculated (Allocated Balance, and Free Balance) Current Balance won’t change
     */
    const { instrument_id, amount } = values;
    var selectedAssetName = "";

    const clonedAllocations = JSON.parse(JSON.stringify(allocations));

    const newAllocations = clonedAllocations.map((item) => {
      if (item.instrument_id === instrument_id) {
        selectedAssetName = item.exchange_symbol;
        if (amount > item.target_quantity) item.target_quantity = 0;
        else item.target_quantity = item.target_quantity - amount;
      }
      item.target_quantity = Math.round(item.target_quantity * 100) / 100;
      return item;
    });

    // Clone the original allocations.
    const originalAllocations = JSON.parse(
      JSON.stringify(allocations)
    );
    // Merge two arrays
    const mixedAllocations = joinWithoutDupes(
      originalAllocations,
      newAllocations
    );

    const reqPayload = {
      management_type_id: curManagementTypeID,
      allocations: changeReqPayloadFormat(mixedAllocations),
    };

    await dispatch(
      updateAssetAllocationByAccountID(accountID, reqPayload)
    ).then((res) => {
      if (res) {
        showToast(
          `The quantity of ${selectedAssetName} has been updated`,
          "success",
          DEFAULT_TOAST_TIME
        );
      }
    });

    await dispatch(getAssetAllocationByAccountID(accountID));
  };

  const validationReduceTargetQuantity = (value) => {
    // uniqueAmount is original value of target quantity of specific asset.
    // value is the inputed one.
    value = parseFloat(value);

    if (value < 0) return false;
    if (value > uniqueAmount) return false;
    if (
      uniqueAmount === parseInt(value)
      // || Math.round(uniqueAmount * 100) / 100 === parseInt(value)
    )
      return true;
    // if (Math.round(uniqueAmount * 100) / 100 > parseInt(value)) return true;
    if (uniqueAmount > parseInt(value)) return true;
  };

  return (
    <form onSubmit={handleSubmit}>
      {!!error && <Error>{error}</Error>}
      <Form
        ref={formRef}
        formData={formData.current}
        showColonAfterLabel={false}
        disabled={loading}
        width={"100%"}
      >
        <Item
          dataField="instrument_id"
          editorType={"dxSelectBox"}
          cacheRawData={false}
          loadMode={"raw"}
          editorOptions={{
            items: allocations,
            searchEnabled: true,
            placeholder: "Select the asset",
            searchExpr: ["exchange_symbol"],
            valueExpr: "instrument_id",
            displayExpr: "exchange_symbol",
            inputAttr: {
              autocomplete: "new-address",
            },
            cacheRawData: false,
            loadMode: "raw",
            onValueChanged: (e) => {
              if (e.value) {
                const data = allocations.filter(
                  (item) => item.instrument_id === e.value
                );

                if (
                  data &&
                  data.length !== 0
                  // && Math.round(data[0].target_quantity * 100) / 100 > 0
                ) {
                  setUniqueAmount(data[0].target_quantity);

                  // Auto focus the amount-input.
                  const field = "amount";
                  const editor = formRef.current.instance.getEditor(field);
                  editor.focus();
                } else {
                  setUniqueAmount(0);
                  showToast(
                    "Current target quantity is 0",
                    "error",
                    DEFAULT_TOAST_TIME
                  );
                }
              }
            },
          }}
        >
          <RequiredRule message="Select the asset" />
          <Label visible={true} text={"ASSET"} />
        </Item>
        {uniqueAmount > 0 && (
          <Item
            dataField="amount"
            editorTyp={"dxTextBox"}
            editorOptions={amountFieldDefaultOptions}
            isRequired={true}
          >
            <Label visible={true} text={"AMOUNT TO REDUCE"} />
            <RequiredRule message="Amount is required" />
            <PatternRule
              // pattern={/^\+?\d+(\.\d+)?$/}
              pattern={/^[+-]?((\.\d+)|(\d+(\.\d+)?))$/}
              message={"This is not the number."}
            />
            <CustomRule
              type="custom"
              message={`The amount should be positive and less than the target quantity`}
              validationCallback={({ value }) =>
                validationReduceTargetQuantity(value)
              }
            />
            {/* // <RangeRule
            //   min={0}
            //   message={`The amount should be positive and less than the ${validationMsg}`}
            //   max={uniqueAmount}
            // /> */}
          </Item>
        )}
        <ButtonItem horizontalAlignment="left">
          <ButtonOptions
            width="100%"
            text="Add"
            type="default"
            useSubmitBehavior={true}
            disabled={uniqueAmount ? false : true}
          >
            <span className="dx-button-text">
              {loading ? (
                <LoadIndicator width={"24px"} height={"24px"} visible={true} />
              ) : (
                "REDUCE TARGET QUANTITY"
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
};

export default React.memo(ReduceTargetAmountForm);
