import React from "react";

export const TRADING_DASHBOARD_STEPS = [
  {
    content: (
      <div className="joyride__content">
        Click a JOIN button to participate in an available competition.
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    hideFooter: true,
    placement: "right",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: "#tour_joinButton",
    title: "Join A Competition",
  },
  {
    content: (
      <div className="joyride__content">
        Click on an available market price on the IMERS exchange to create a
        trade.
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    hideFooter: true,
    placement: "top-start",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: "#tour_selectMarket",
    title: "Make an order",
  },
  {
    content: (
      <div className="joyride__content">
        Here you can choose the quantity of your trade.
        <br />
        (eg. 1.5)
        <br />
        <span style={{ marginTop: "0.5rem" }}>Click ENTER key to complete</span>
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    hideFooter: true,
    placement: "top",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: "#tour_orderQuantityBox",
    title: "Enter quantity",
  },
  {
    content: (
      <div className="joyride__content">
        After entering the trade quantity choose the BUY and SELL button to
        place the trade.
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    hideFooter: true,
    placement: "top",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: "#tour_orderBuyButton",
    title: "Click buy",
  },
  {
    content: (
      <div className="joyride__content">
        Order was not successfull as we are still waiting for other traders to
        join the competition.
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    hideFooter: true,
    placement: "top",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: "#tour_competitionPanel",
    title: "Awaiting players to join",
  },
  {
    content: (
      <div className="joyride__content">
        To correctly place a trade after the competition has started click on a
        market price on the IMERS exchange row.
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    hideFooter: true,
    placement: "top-start",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: "#tour_selectMarket",
    title: "Make an order",
  },
  {
    content: (
      <div className="joyride__content">
        Enter the quantity of your choice to place a trade.
        <br />
        (eg. 9)
        <br />
        <span style={{ marginTop: "0.5ren" }}>click ENTER key to complete</span>
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    hideFooter: true,
    placement: "top-start",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: "#tour_orderQuantityBox",
    title: "Enter quantity",
  },
  {
    content: (
      <div className="joyride__content">
        Now click the BUY or SELL button to place a trade.
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    hideFooter: true,
    placement: "top",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: "#tour_orderBuyButton",
    title: "Buy button",
  },
  {
    content: (
      <div className="joyride__content">
        Continue to place trades throughout the competition to drive your total
        profit. The competition ends when the time remaining hits 00:00.
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    hideFooter: true,
    placement: "top",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: "#tour_competitionPanel",
    title: "Time remaining",
  },
  {
    content: (
      <div className="joyride__content">
        The winner's board displays the top 3 traders by profit and your
        position in the competition
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: false,
    hideBackButton: true,
    hideFooter: true,
    showSkipButton: true,
    placement: "right-end",
    spotlightClicks: false,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: "#tour_competitionEndContent",
    title: "Competition Finished",
  },
];

export const WEALTH_NAV_STEPS = [
  {
    content: (
      <div className="joyride__content">
        You will now be taken to the WEALTH screen to explore your earnings.
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    hideBackButton: true,
    hideFooter: true,
    showSkipButton: false,
    placement: "right",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: "#tour_sideMenuWealth",
    title: "Wealth Screen",
  },
];

export const WEALTH_PAGE_STEPS = [
  {
    content: (
      <div className="joyride__content">
        On our Wealth page the user can track their total GEMS and IMERS
        earnings.
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    hideFooter: false,
    placement: "top",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: "#tour_wealthPageSwitch",
    title: "Switch between GEMS & IMERS",
  },
  {
    content: (
      <div className="joyride__content">
        Here you will see every transaction for GEMS or IMERS
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: false,
    hideCloseButton: true,
    hideFooter: false,
    placement: "right",
    spotlightClicks: false,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: "#tour_earningsTable",
    title: "Earnings Table",
  },
  {
    content: (
      <div className="joyride__content">
        Here you will see the top traders of today's competition. The top
        positon is the trader who has generated the highest volume of GEMS.
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    hideFooter: false,
    placement: "right",
    spotlightClicks: false,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: "#tour_topTradersTable",
    title: "Today's top trader table",
  },
  {
    content: (
      <div className="joyride__content">
        Here you will see the richest traders. The top position in the trader
        that has received the largest volume of IMERS tokens.
      </div>
    ),
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    hideFooter: false,
    placement: "left",
    spotlightClicks: false,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: "#tour_imersRichListTable",
    title: "IMERS Rich list table",
  },
];
