import React from "react";
import PieChart, { Series, Size } from "devextreme-react/pie-chart";
import { Label } from "devextreme-react/chart";
import CenterTemplate from "./CenterTemplate";

const RatingDonutChart = ({cellData, checkRiskReport}) => {
  const { index, asset, risk_number, instrument_id } = cellData.data;

  const sourceArray = [
    {
      id: -1,
      asset,
      value: 100 - risk_number,
    },
    {
      id: index,
      asset,
      value: risk_number,
    },
  ];

  const customizePoint = (point) => {
    const { data } = point;

    if (data.id !== -1) {
      if (data.value > 0)
        return {
          color: "green",
        };
    }
    if (data.id === -1) {
      if (data.value > 0) 
        return {
          color: "red",
        };
    }
  };

  return (
    <div className="px-risk-report-donut" onClick={() => checkRiskReport(instrument_id, asset)} style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <PieChart
        id="pie"
        key="id"
        dataSource={sourceArray}
        resolveLabelOverlapping="shift"
        sizeGroup="piesGroup"
        innerRadius={0.65}
        centerRender={CenterTemplate}
        type="doughnut"
        customizePoint={customizePoint}
        style={{ cursor: "pointer" }}
      >
        <Size width={100} height={30} />
        <Series
          argumentField="id"
          valueField="value"
          border={{
            color: "#1a598f",
            dashStyle: "solid",
            visible: true,
            width: "1px",
          }}
          hoverMode="none"
        >
          <Label visible={false}></Label>
        </Series>
      </PieChart>
    </div>
  );
};

export default RatingDonutChart;
