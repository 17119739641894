import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import DataGrid, {
  Column,
  Scrolling,
  Pager,
  Paging,
} from "devextreme-react/data-grid";
import "devextreme/data/odata/store";
import { saveSelectedRiskReportInfo } from "../../redux/accounts/action";
import { ALLOWED_PAGE_SIZES_BIG } from "../../constants";
import RatingDonutChart from "./RatingDonutChart";

const AssetList = ({ holdings, baseCurrency, comeFrom = null }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = holdings?.map((item, index) => {
    return { ...item, index };
  });

  const checkRiskReport = async (instrument_id, asset) => {
    await dispatch(saveSelectedRiskReportInfo({ instrument_id, asset}));
    history.push("/riskreport");
  };

  return (
    <React.Fragment>
      <DataGrid
        dataSource={data}
        showBorders={false}
        keyExpr="asset"
        focusedRowEnabled={false}
        defaultFocusedRowIndex={false}
        columnAutoWidth={true}
        loadPanel={null}
      >
        <Scrolling rowRenderingMode="virtual"></Scrolling>
        <Paging defaultPageSize={ALLOWED_PAGE_SIZES_BIG[0]} />
        {data && data.length > 10 && (
          <Pager
            visible={true}
            allowedPageSizes={ALLOWED_PAGE_SIZES_BIG}
            displayMode={"full"}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
        )}
        <Column
          cssClass="px-column-name"
          alignment="center"
          dataField={"asset"}
          caption={"ASSET"}
          hidingPriority={2}
        />
        <Column
          cssClass="px-column-name"
          alignment="right"
          dataField={"holding"}
          caption={"QUANTITY"}
          hidingPriority={6}
        />
        <Column
          cssClass="px-column-name"
          alignment="right"
          showBorders={false}
          dataField={"price"}
          caption={`PRICE(${baseCurrency})`}
          hidingPriority={2}
        />
        <Column
          cssClass="px-column-name"
          alignment="right"
          dataField={"holding_in_base"}
          caption={`VALUE(${baseCurrency})`}
          cellRender={(e) => {
            const { holding_in_base } = e.data;
            return holding_in_base.toFixed(2);
          }}
          hidingPriority={2}
        />
        {!comeFrom && <Column
          cssClass="px-column-name"
          alignment="center"
          dataField={"risk_number"}
          dataType={"number"}
          caption={"RISK RATING"}
          hidingPriority={2}
          allowSorting={false}
          cellRender={(e) => {
            const { risk_report_available } = e.data;
            if (risk_report_available) {
              return (
                <RatingDonutChart
                  cellData={e}
                  checkRiskReport={checkRiskReport}
                />
                // <div className="gradient-box">
                //   <div className="gradient-background-image"></div>
                // </div>
              );
            }
          }}
        />}
      </DataGrid>
    </React.Fragment>
  );
};

export default AssetList;
