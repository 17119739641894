import React, { useState, useCallback, useRef, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button from "devextreme-react/button";
import Drawer from "devextreme-react/drawer";
import ScrollView from "devextreme-react/scroll-view";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { Template } from "devextreme-react/core/template";
import { Header, SideNavigationMenu, Footer } from "../../containers";
import { useScreenSize } from "../../utils/media-query";
import { useMenuPatch } from "../../utils/patches";
import "./SideNavInnerToolbar.scss";
import {
  PANXORA_TOP_LEFT_IMERS_LOGO,
  PANXORA_TOP_LEFT_CYBERTOOLS_LOGO,
} from "../../constants";
import { handleSideBarByMode } from "../../redux/auth/action";
import { useDispatch, useSelector } from "react-redux";
import { enableInstructionVideo } from "../../redux/dashboard/action";
import { useSocialContext } from "../../contexts/socialDashboard";
import FriendsDialog from "../../components/social-dashboard/FriendsDialog";

const SideNavInnerToolbar = ({ title, children }) => {
  const dispatch = useDispatch();
  const scrollViewRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const { openSocialDialog, setOpenSocialDialog, socialData } =
    useSocialContext();
  const { isXSmall, isLarge } = useScreenSize();
  const [patchCssClass, onMenuReady] = useMenuPatch();
  const [showAccountBar, setShowAccountBar] = useState(false);
  const [menuStatus, setMenuStatus] = useState(
    isLarge ? MenuStatus.Opened : MenuStatus.Closed
  );

  const sideBarMode = useSelector((state) => state.auth.mode);

  const PANXORA_TOP_LEFT_LOGO =
    !sideBarMode || sideBarMode === "imers"
      ? PANXORA_TOP_LEFT_IMERS_LOGO
      : PANXORA_TOP_LEFT_CYBERTOOLS_LOGO;
  useEffect(() => {
    let visible = true;
    if (
      !(
        location.pathname === "/account-dashboard" ||
        location.pathname === "/trading-dashboard"
      )
    )
      visible = false;
    setShowAccountBar(visible);
  }, [location.pathname]);

  const toggleMenu = useCallback(({ event }) => {
    setMenuStatus((prevMenuStatus) =>
      prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.Opened
        : MenuStatus.Closed
    );
    event.stopPropagation();
  }, []);

  const temporaryOpenMenu = useCallback(() => {
    setMenuStatus((prevMenuStatus) =>
      prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.TemporaryOpened
        : prevMenuStatus
    );
  }, []);

  const onOutsideClick = useCallback(() => {
    setMenuStatus((prevMenuStatus) =>
      prevMenuStatus !== MenuStatus.Closed && !isLarge
        ? MenuStatus.Closed
        : prevMenuStatus
    );
  }, [isLarge]);

  const onNavigationChanged = useCallback(
    ({ itemData: { path, mode }, event, node }) => {
      if (mode && !path) {
        event.preventDefault();
        if (mode && mode !== "instruction_play") {
          dispatch(handleSideBarByMode(mode));
        } else if (mode === "instruction_play") {
          dispatch(
            enableInstructionVideo({
              enableVideo: true,
              id: node?.itemData?.id,
            })
          );
        }
      }
      if (menuStatus === MenuStatus.Closed || !path || node.selected) {
        event.preventDefault();
        return;
      }

      history.push(path);
      scrollViewRef.current.instance.scrollTo(0);

      if (!isLarge || menuStatus === MenuStatus.TemporaryOpened) {
        setMenuStatus(MenuStatus.Closed);
        event.stopPropagation();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, menuStatus, isLarge]
  );

  return (
    <div className={"side-nav-inner-toolbar px-sidebar"}>
      <Drawer
        className={["drawer", patchCssClass].join(" ")}
        position={"before"}
        closeOnOutsideClick={onOutsideClick}
        openedStateMode={
          !isLarge || location.pathname.includes("/trading_chart")
            ? "overlap"
            : "shrink"
        }
        revealMode={isXSmall ? "slide" : "expand"}
        minSize={
          isXSmall || location.pathname.includes("/trading_chart") ? 0 : 60
        }
        maxSize={250}
        shading={isLarge ? false : true}
        opened={
          menuStatus === MenuStatus.Closed ||
          location.pathname.includes("/trading_chart")
            ? false
            : true
        }
        template={"menu"}
      >
        <div className={"container"}>
          <Header
            menuToggleEnabled={isXSmall}
            toggleMenu={toggleMenu}
            showAccountBar={showAccountBar}
          />

          <ScrollView
            ref={scrollViewRef}
            id="scroll-content"
            className={"layout-body with-footer"}
          >
            <div className={"content"}>
              {React.Children.map(children, (item) => {
                return item.type !== Footer && item;
              })}
            </div>
            {!location.pathname.includes("/trading_chart") && (
              <div className={"content-block"}>
                {React.Children.map(children, (item) => {
                  return item.type === Footer && item;
                })}
              </div>
            )}
          </ScrollView>
        </div>
        <Template name={"menu"}>
          <SideNavigationMenu
            compactMode={menuStatus === MenuStatus.Closed}
            selectedItemChanged={onNavigationChanged}
            openMenu={temporaryOpenMenu}
            onMenuReady={onMenuReady}
          >
            <Toolbar id={"navigation-header"}>
              {!isXSmall && (
                <Item location={"before"} cssClass={"menu-button"}>
                  <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
                </Item>
              )}
              <Item location={"before"} cssClass={"header-title"}>
                <div
                  className="px-sidebar-inner-logo"
                  style={{
                    background: `url(${
                      process.env.REACT_APP_IMAGE_URL + PANXORA_TOP_LEFT_LOGO
                    }) no-repeat`,
                    width: 150,
                    height: 40,
                  }}
                />
              </Item>
            </Toolbar>
          </SideNavigationMenu>
        </Template>
      </Drawer>
      {openSocialDialog.open && (
        <FriendsDialog
          closeDialog={() => setOpenSocialDialog({ open: false, tab: 0 })}
          socialData={socialData}
          tab={openSocialDialog.tab}
          s
        />
      )}
    </div>
  );
};

const MenuStatus = {
  Closed: 1,
  Opened: 2,
  TemporaryOpened: 3,
};

export default SideNavInnerToolbar;
