import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadPanel } from 'devextreme-react/load-panel';
import AccountInfo from "./AccountInfo";
import Setup2FAForm from "../../../components/profile-settings/Setup2FAForm";
import {
  generate2FA,
  connect,
  logout,
  verify2FA,
  saveSecretToUserData,
} from "../../../redux/auth/action";
import "./Security.scss";

const position = { of: ".dx-viewport" };

const Security = () => {
  const [loading, setLoading] = useState(false);
  const [twoFa, setTwoFa] = useState();

  const user = useSelector((state) => state.auth.info);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user && !user.client_user_is_2fa) {
      async function loadData() {
        setLoading(true);
        const data = await dispatch(generate2FA());

        setTwoFa(data);
        setLoading(false);
      }
      loadData();
    }
  }, [dispatch, user]);

  const handleLogOut = () => {
    dispatch(logout());
  };

  const dispatchSetup2FA = async (code) => {
    /**
     * Verify TOTP code and save secret info to user data to enable 2fa.
     */
    await dispatch(verify2FA(twoFa.secret, code));
    await dispatch(saveSecretToUserData(twoFa.secret));
    await dispatch(connect(true));
  };

  return (
    <React.Fragment>
      <div
        className={"content-block dx-card responsive-paddings"}
        id="employee"
      >
        <h2 className={"content-block"} style={{ marginLeft: 0, marginBottom: 30}}>SETTINGS</h2>
        {loading && (
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={position}
            visible={loading}
          />
        )}
        {user && user.client_user_is_2fa !== 0 && <AccountInfo user={user} />}
        {user && user.client_user_is_2fa === 0 && (
          <Setup2FAForm
            twoFa={twoFa}
            handleLogOut={handleLogOut}
            dispatchSetup2FA={dispatchSetup2FA}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Security;
