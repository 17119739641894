import { SET_CHART_STATE, TOGGLE_THEME } from "./constant";
import { THEME_STATE } from "../../constants";
import { get, set } from "../../utils/storage";

const DEFAULT_STATE = {
  chartState: {
    exchange: "IMERS",
    market: "BTC/USD",
    time: "hour",
    type: "line",
    indicators: [],
    annotations: null,
  },
  currentTheme: "Glass",
};

const tradingDashboard = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_CHART_STATE:
      return { ...state, chartState: action.payload };
    case TOGGLE_THEME:
      set(THEME_STATE, action.payload);
      return { ...state, currentTheme: action.payload };
    default:
      const themeState = JSON.parse(get(THEME_STATE));
      return {
        ...state,
        currentTheme: themeState ?? "Glass",
      };
  }
};

export default tradingDashboard;
