import { SET_COMPANY_LOGO, REMOVE_USER_DATA, ENABLE_INSTRUCTION_VIDEO, SET_RANKINGS_DATA } from "./constant";

const DEFAULT_STATE = {
  companyLogo: null,
  enableVideo: false,
  videoID: null,
  rankingsData: null,
};

const dashboardReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_COMPANY_LOGO:
      return { ...state, companyLogo: action.payload };
    case REMOVE_USER_DATA:
      return DEFAULT_STATE;
    case ENABLE_INSTRUCTION_VIDEO:
      return { ...state, enableVideo: action.payload?.enableVideo, videoID: action.payload?.id };
    case SET_RANKINGS_DATA:
      return { ...state, rankingsData: action.payload };
    default:
      return state;
  }
};

export default dashboardReducer;
