import React, { useEffect, useState } from "react";
import { TextBox } from "devextreme-react/text-box";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  Scrolling,
  Pager,
  Paging,
} from "devextreme-react/data-grid";
import { ALLOWED_PAGE_SIZES_BIG, DEFAULT_TOAST_TIME } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  addTwitterHandler,
  getAffiliateLink,
  getAffiliateSignUps,
  getTwitterHandles,
  removeTwitterHandler,
} from "../../redux/self-service-user/action";
import { useToast } from "../../contexts/toast";

const Affiliates = () => {
  const { client_user_id } = useSelector((state) => state.auth.info);
  const dispatch = useDispatch();
  const { showToast } = useToast();

  const [affiliateLink, setAffiliateLink] = useState();
  const [affiliateSignUps, setAffiliateSignUps] = useState([]);
  const [twitterHandles, setTwitterHandles] = useState([]);
  const [twitterHandlerName, setTwitterHandlerName] = useState();
  const [selectedTwitterHandlerID, setSelectedTwitterHandlerID] = useState();
  const [twitterHandlesErrMsg, setTwitterHandlesErrMsg] = useState();

  useEffect(() => {
    Promise.all([
      dispatch(getAffiliateSignUps(client_user_id)),
      dispatch(getTwitterHandles(client_user_id)),
    ]).then(([affiliateSignupsData, twitterHandlersData]) => {
      const updatedASD = affiliateSignupsData.map((item, index) => {
        item.id = index;
        item.date_registered = item.date_registered.split("T")[0];
        return item;
      });
      setAffiliateSignUps(updatedASD);

      const updatedTHD = twitterHandlersData.map((item, index) => {
        item.id = index;
        return item;
      });
      setTwitterHandles(updatedTHD);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateAffiliateLink = async () => {
    const data = await dispatch(getAffiliateLink(client_user_id));

    setAffiliateLink(data?.affiliate_link);
  };

  const copyAffiliateLink = () => {
    navigator.clipboard.writeText(affiliateLink);
    showToast(`The url copied`, "success", DEFAULT_TOAST_TIME);
  };

  const handleTwitterHandler = (e) => {
    setTwitterHandlerName(e.value);
  };

  const postTwitterHandler = async () => {
    try {
      await dispatch(addTwitterHandler(client_user_id, twitterHandlerName));
      await requestGetTwitterhandles();
    } catch (error) {
      setTwitterHandlesErrMsg(error.message);
    }
  };

  const requestGetTwitterhandles = async () => {
    const data = await dispatch(getTwitterHandles(client_user_id));
    const updated = data.map((item, index) => {
      item.id = index;
      return item;
    });
    setTwitterHandles(updated);
  };

  const deleteTwitterHandler = async () => {
    const data = twitterHandles.filter(
      (item) => item.id === selectedTwitterHandlerID
    );
    await dispatch(
      removeTwitterHandler(client_user_id, data[0]?.twitter_handle)
    );
    await requestGetTwitterhandles();
  };

  return (
    <React.Fragment>
      <div className="px-affiliate-container">
        <div className="px-affiliate-introducer">
          <p className="title">AFFILIATE INTRODUCER</p>
          <span className="description">
            New users registering with this link will improve your competition
            winnings. This link can be shared on your blog, as a tweet or send
            via other social media networks.
          </span>
          <div className="px-request-container">
            <Button
              text="REQUEST LINK"
              type="default"
              width={150}
              style={{ marginBottom: 10 }}
              onClick={generateAffiliateLink}
              disabled={affiliateLink ? true : false}
            />
            <TextBox value={affiliateLink || ""} />
            <Button
              text="COPY LINK"
              type="default"
              width={150}
              style={{ marginTop: 10 }}
              onClick={copyAffiliateLink}
            />
          </div>
          <div className="px-affiliates-sign-ups">
            <DataGrid
              className={"dx-card wide-card px-competitions"}
              dataSource={affiliateSignUps || []}
              showBorders={true}
              keyExpr="id"
              focusedRowEnabled={true}
              defaultFocusedRowIndex={-1}
              columnAutoWidth={true}
              columnHidingEnabled={true}
              allowEditing={true}
              calculateCell
            >
              <Scrolling rowRenderingMode="virtual"></Scrolling>
              <Paging defaultPageSize={ALLOWED_PAGE_SIZES_BIG[0]} />
              <Pager
                visible={true}
                allowedPageSizes={ALLOWED_PAGE_SIZES_BIG}
                displayMode={"full"}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
              />
              <Column
                dataField={"display_name"}
                headerCellRender={() => {
                  return (
                    <div style={{ textAlign: "center" }}>
                      <p className="no-margin">DISPLAY</p>
                      <p className="no-margin">NICKNAME</p>
                    </div>
                  );
                }}
                hidingPriority={2}
              />
              <Column
                dataField={"date_registered"}
                headerCellRender={() => {
                  return (
                    <div style={{ textAlign: "center" }}>
                      <p className="no-margin">DATE</p>
                      <p className="no-margin">REGISTERED</p>
                    </div>
                  );
                }}
                hidingPriority={2}
              />
              <Column
                dataField={"accounts_registered"}
                headerCellRender={() => {
                  return (
                    <div style={{ textAlign: "center" }}>
                      <p className="no-margin">ACCOUNTS</p>
                      <p className="no-margin">REGISTERED</p>
                    </div>
                  );
                }}
                hidingPriority={2}
              />
              <Column
                dataField={"competitions_entered"}
                headerCellRender={() => {
                  return (
                    <div style={{ textAlign: "center" }}>
                      <p className="no-margin">COMPETITIONS</p>
                      <p className="no-margin">ENTERED</p>
                    </div>
                  );
                }}
                hidingPriority={2}
              />
              <Column
                dataField={"wins"}
                headerCellRender={() => {
                  return (
                    <div style={{ textAlign: "center" }}>
                      <p className="no-margin">NUMBER</p>
                      <p className="no-margin">OF WINS</p>
                    </div>
                  );
                }}
                hidingPriority={2}
              />
            </DataGrid>
          </div>
        </div>
        <div className="px-twitter-introducer">
          <p className="title">TWITTER INTRODUCER</p>
          <span className="description">
            Add up to 5 twitter handles. Twitter influencers are paid for each
            retweet they perform based upon their follower count.
          </span>
          <div className="px-buttons-container">
            <p
              className="error"
              style={{
                display: `${twitterHandlesErrMsg} ? "block" : "none"`,
                margin: "5px 0 5px 0",
              }}
            >
              {twitterHandlesErrMsg || ""}
            </p>
            <TextBox
              value={twitterHandlerName || ""}
              onValueChanged={handleTwitterHandler}
              style={{ marginTop: 10, marginBottom: 10 }}
              placeholder="Input twitter handler..."
            />
            <Button text="ADD" type="default" onClick={postTwitterHandler} />
            <Button
              text="REMOVE"
              type="default"
              style={{ marginLeft: 5 }}
              onClick={deleteTwitterHandler}
            />
          </div>
          <div className="px-twitters-list">
            <DataGrid
              className={"dx-card wide-card"}
              dataSource={twitterHandles}
              showBorders={true}
              keyExpr="id"
              focusedRowEnabled={true}
              defaultFocusedRowIndex={-1}
              columnAutoWidth={true}
              columnHidingEnabled={true}
              allowEditing={true}
              calculateCell
              onFocusedRowChanged={(e) => {
                if (e && e.row) {
                  const { id } = e.row.data;
                  setSelectedTwitterHandlerID(id);
                }
              }}
            >
              <Scrolling rowRenderingMode="virtual"></Scrolling>
              <Column
                dataField={"twitter_handle"}
                caption={"TWITTER HANDLE"}
                hidingPriority={2}
                allowSorting={false}
              />
              <Column
                dataField={"confirmed"}
                caption={"CONFIRMED"}
                hidingPriority={2}
                allowSorting={false}
              />
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Affiliates;
