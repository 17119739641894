/* eslint-disable no-undef */
import React, { useRef, useState, useEffect } from "react";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule,
  CompareRule,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import ScrollView from "devextreme-react/scroll-view";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import Error from "../../components/Error";
import {
  defaultOptions,
  fNameDefaultOptions,
  lNameDefaultOptions,
} from "../../constants";
import "./RegisterForm.scss";
import { checkCookie, getCookie, setCookie } from "../../utils/storage";

import { registerAccount } from "../../redux/auth/action";

const colCountByScreen = {
  sm: 2,
  md: 4,
};

const OpenAccountForm = () => {
  const history = useHistory();
  const formData = useRef({});
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isTAndCPopUp, setIsTAndCPopup] = useState(false);
  const [referrelCode, setReferrelCode] = useState("");
  const [passwordMode, setPasswordMode] = useState("password");

  const codeParam = match?.params?.referrelCode;

  useEffect(() => {
    if (!codeParam) {
      if (checkCookie("referrelCode")) {
        setReferrelCode(getCookie("referrelCode"));
      }
    } else {
      setCookie("referrelCode", codeParam);
      setReferrelCode(codeParam);
    }
  }, [codeParam]);

  const fNameEditorOptions = {
    ...fNameDefaultOptions,
    placeholder: "First Name",
  };

  const lNameEditorOptions = {
    ...lNameDefaultOptions,
    placeholder: "Last Name",
  };
  const dNameEditorOptions = {
    ...defaultOptions,
    placeholder: "Display Name",
  };
  const passwordEditorOptions = {
    stylingMode: "filled",
    placeholder: "Password",
    mode: passwordMode,
    buttons: [
      {
        name: "password",
        location: "after",
        options: {
          icon: "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/icons/eye.png",
          type: "default",
          onClick: () => {
            const mode = passwordMode === "text" ? "password" : "text";
            setPasswordMode(mode);
          },
        },
      },
    ],
  };
  const confirmPasswordEditorOptions = {
    stylingMode: "filled",
    placeholder: "Confirm Password",
    mode: "password",
  };
  const referrralCodeEditorOptions = {
    ...defaultOptions,
    placeholder: "Referral Code",
  };
  const passwordComparison = () => {
    return formData.current.password;
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    var currentData = formData.current;
    currentData = {
      email_address: formData.current.email_address,
      gamer_tag: formData.current.gamer_tag,
      first_name: formData.current.first_name,
      last_name: formData.current.last_name,
      password: formData.current.password,
      affiliate_code: formData.current.affiliate_code || referrelCode,
    };

    setLoading(true);
    setErrorMsg("");
    try {
      // Use referrelCode here to add it to the payload.
      await dispatch(registerAccount(currentData));
      setLoading(false);
      setErrorMsg("");
      history.push("/login");
    } catch (error) {
      setLoading(false);
      setErrorMsg(error.message);
    }
  };

  const screenByWidth = (width) => {
    return width < 720 ? 'sm' : 'lg';
  }

  return (
    <React.Fragment>
      <div className={"link"} style={{ display: "flex", justifyContent: "flex-end" }}>
        <Link to={"/login"}>Go to account Login</Link>
      </div>
      <form
        className={"px-account-form"}
        id={"px-open-account-form"}
        style={{ width: `100%`, marginTop: 10 }}
        onSubmit={onSubmit}
      >
        {!!errorMsg && <Error>{errorMsg}</Error>}
        <Form
          formData={formData.current}
          disabled={loading}
          colCountByScreen={colCountByScreen}
          minColWidth={233}
          colCount="auto"
          screenByWidth={screenByWidth}
        >
          <Item
            dataField="first_name"
            editorType={"dxTextBox"}
            editorOptions={fNameEditorOptions}
            isRequired={true}
          >
            <Label visible={false} text="FIRST NAME" />
          </Item>
          <Item
            dataField="last_name"
            editorType={"dxTextBox"}
            editorOptions={lNameEditorOptions}
            isRequired={true}
          >
            <Label visible={false} text="LAST NAME" />
          </Item>
          <Item
            dataField="gamer_tag"
            editorType={"dxTextBox"}
            editorOptions={dNameEditorOptions}
            isRequired={true}
          >
            <Label visible={false} text="DISPLAY NAME" />
          </Item>
          <Item
            dataField={"email_address"}
            editorType={"dxTextBox"}
            editorOptions={emailEditorOptions}
          >
            <RequiredRule message="Email is required" />
            <EmailRule message="Email is invalid" />
            <Label visible={false} />
          </Item>
          <Item
            dataField={"password"}
            editorType={"dxTextBox"}
            cssClass={"px-acc-password"}
            editorOptions={passwordEditorOptions}
          >
            <RequiredRule message="Password is required" />
            <Label visible={false} />
          </Item>
          <Item
            dataField={"confirmPassword"}
            editorType={"dxTextBox"}
            editorOptions={confirmPasswordEditorOptions}
          >
            <RequiredRule message="Confirm Password is required" />
            <CompareRule
              message="Password and Confirm Password do not match"
              comparisonTarget={passwordComparison}
            />
            <Label visible={false} />
          </Item>
          <Item
            dataField="affiliate_code"
            editorType={"dxTextBox"}
            editorOptions={referrralCodeEditorOptions}
            isRequired={false}
            colSpan={2}
          >
            <Label visible={false} text="Referral Code" />
          </Item>
          <ButtonItem colSpan={2}>
            <ButtonOptions
              width={"100%"}
              height={50}
              type={"default"}
              useSubmitBehavior={true}
            >
              <span className="dx-button-text">
                {loading ? (
                  <LoadIndicator width={"24px"} height={"24px"} visible={true} />
                ) : (
                  "SIGN UP"
                )}
              </span>
            </ButtonOptions>
          </ButtonItem>
        </Form>
        {isTAndCPopUp && (
          <Popup
            visible={true}
            className={"px-terms-conditions-popup"}
            width={`${widthInfo.popupWidth}`}
            height={630}
            position={"center"}
            showCloseButton={false}
            closeOnOutsideClick={false}
            title={`Terms And Conditions`}
          >
            <ToolbarItem
              widget="dxButton"
              toolbar="bottom"
              location="after"
              options={{
                text: "Close",
                type: "default",
                onClick: () => {
                  setIsTAndCPopup(false);
                },
              }}
            />
            <ScrollView width="100%" height="100%">
              <article
                style={{ overflowY: "auto", padding: "0 20px 0 20px" }}
                dangerouslySetInnerHTML={{ __html: markDown && markDown.data }}
              ></article>
            </ScrollView>
          </Popup>
        )}
      </form>
    </React.Fragment>
  );
};

const emailEditorOptions = {
  stylingMode: "filled",
  placeholder: "Email",
  mode: "text",
};

export default OpenAccountForm;
