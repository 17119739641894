import { api } from "../../service";
import { SET_ASSET_ALLOCATION } from "./constant";

/**
 * Get account by ID.
 *
 * @param {string} accountID
 * @returns
 */
export const getAssetAllocationByAccountID =
  (accountID) => async (dispatch) => {
    const { data } = await api.get(`/accounts/${accountID}/assets/allocation`);

    if (data && data.allocations && data.allocations.length !== 0) {
      var totalAssetValue = 0;

      data.allocations.map((item) => {
        const { position, price } = item;

        totalAssetValue += position * price;
        item.percent_basket_value = "-";
        item.target_percent_basket_value = "-";
        item.asset_value = Math.round(position * price * 100) / 100;
        return item;
      });

      const updated = data.allocations.map((item) => {
        item.percent_account_value =
          Math.round((item.asset_value / totalAssetValue) * 100 * 100) / 100;

        return item;
      });

      data.allocations = updated;
    }

    dispatch({ type: SET_ASSET_ALLOCATION, payload: data });

    return data;
  };

/**
 * Update the account's asset allocations.
 *
 * @param {string} accountID
 * @param {array} allocations
 * @returns
 */
export const updateAssetAllocationByAccountID =
  (accountID, allocations) => async () => {
    const { data } = await api.put(
      `/accounts/${accountID}/assets/allocation`,
      allocations
    );

    return data;
  };
