import {
  SET_EXCHANGE_ACCOUNT_LIST,
  SET_PHONE_CODES,
  SET_ACCOUNT_STATUSES,
  SET_GENERAL_ASSETS,
  SET_ASSET_MODELS,
  SET_BASKET_TRADING_MODELS,
  SET_RISK_MANAGEMENT_MODELS,
  SET_ASSETS_WITH_PRICE,
  REMOVE_USER_DATA
} from "./constant";

const DEFAULT_STATE = {
  exchanges: null,
  phoneCodes: null,
  accountStatuses: null,
  generalAssets: null,
  assetModels: null,
  basketModels: null,
  riskModels: null,
  priceAssets: null,
};

const referenceReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_EXCHANGE_ACCOUNT_LIST:
      return { ...state, exchanges: action.payload };
    case SET_PHONE_CODES:
      return { ...state, phoneCodes: action.payload };
    case SET_ACCOUNT_STATUSES:
      return { ...state, accountStatuses: action.payload };
    case SET_GENERAL_ASSETS:
      return { ...state, generalAssets: action.payload };
    case SET_ASSET_MODELS:
      return { ...state, assetModels: action.payload };
    case SET_BASKET_TRADING_MODELS:
      return { ...state, basketModels: action.payload };
    case SET_RISK_MANAGEMENT_MODELS:
      return { ...state, riskModels: action.payload };
    case SET_ASSETS_WITH_PRICE:
      return { ...state, priceAssets: action.payload };
    // After call logout action.
    case REMOVE_USER_DATA:
      return DEFAULT_STATE;
    default:
      return state;
  }
};

export default referenceReducer;
