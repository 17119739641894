import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "rgba(0, 0, 0, 0.87)",
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

export default CustomTooltip;
