import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "devextreme-react/text-area";
import { Button, LoadPanel } from "devextreme-react";
import AccountStepperProgress from "../../../components/account-setup/AccountStepperProgress";
import AddAccountInformation from "../../../components/account-setup/AddAccountInformation";
import EditAccountInformation from "../../../components/account-setup/EditAccountInformation";
import Strategy from "../../../components/account-setup/Strategy";
import CompleteSetup from "../../../components/account-setup/CompleteSetup";
import {
  createSelfServiceAccount,
  getAccountInformationByID,
  getApiKeyAndSecret,
  getBasketAssetsWithAssetsThatCouldBeBasket,
  getRiskManagedAssetsWithAssetsThatCouldBeRiskManaged,
  getSelfServiceAccounts,
  setAccountInformation,
  setManagementTypeID,
  setOriginalManagementTypeID,
  setSelectedAccount,
  updateApiKeyAndSecret,
} from "../../../redux/accounts/action";
import { getAssetAllocationByAccountID } from "../../../redux/manage-asset/action";
import {
  getAssetsPriceByExchangeAccountID,
  getExchangeAccounts,
} from "../../../redux/references/action";
import { api } from "../../../service";
import { useContentLoading } from "../../../contexts/contentLoading";
import { useToast } from "../../../contexts/toast";
import {
  CREATE_ACCOUNT_INFORMATION,
  DEFAULT_TOAST_TIME,
} from "../../../constants/app";
import "./Accounts.scss";
import BasicBasketTrading from "../../../components/account-setup/BasicBasketTrading";
import BasicRiskManagement from "../../../components/account-setup/BasicRiskManagement";
import ViewOnlyManagement from "../../../components/account-setup/ViewOnlyManagement";
import { getOnboardingElementStatusByID } from "../../../redux/dashboard/action";

const SetupAccount = () => {
  const history = useHistory();
  const mountedStatusRef = useRef(false);
  const intervalHandlerRef = useRef(undefined);
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const { setLoadingProgress, setLoadingIndicator, setMessage } =
    useContentLoading();

  const account = useSelector((state) => state.accounts.info);
  const exchanges = useSelector((state) => state.reference.exchanges);
  const managementTypeID = useSelector(
    (state) => state.accounts.curManagementTypeID
  );
  const { client_user_id } = useSelector((state) => state.auth.info);
  const expertGUI = account?.expert_gui;

  const [apiInfo, setApiInfo] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [onboardingStatusInfo, setOnboardingStatusInfo] = useState();

  const accountID = account?.client_account_id || null;
  const exchangeAccountID = account ? account.exchange_account_id : null;
  const apiStatusID = account?.api_status_id || null;

  useEffect(() => {
    if (!account) return;

    dispatch(setManagementTypeID(account.management_type_id));
    dispatch(setOriginalManagementTypeID(account.management_type_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    // If the page is mounted.
    mountedStatusRef.current = true;
    Promise.all([
      dispatch(getExchangeAccounts()),
      dispatch(getOnboardingElementStatusByID(client_user_id)),
    ]).then(([, onboardingInfo]) => {
      if (onboardingInfo) {const data = onboardingInfo.filter((item) => item.ui_element_id === 2); setOnboardingStatusInfo(data[0]);}
    });

    // When the page is unmounted such like user leave the page.
    return () => {
      mountedStatusRef.current = false;
      dispatch(setAccountInformation(null));
      dispatch(setManagementTypeID(null));
      dispatch(setOriginalManagementTypeID(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const caseAPIPendingFunc = () => {
    // Pending
    showToast(CREATE_ACCOUNT_INFORMATION, "success", 8000);
    // Go to the next step.
    setCurrentStep(1);

    setLoadingProgress(true, true);

    async function checkAPIStatus() {
      const { data: resData } = await api.get(
        `/accounts/${accountID}/api-status`
      );

      const data = resData ? { ...account, ...resData[0] } : null;

      if (!mountedStatusRef.current) return;

      if (!data || data.api_status_id === 1) {
        setLoadingProgress(true);
        return false;
      }

      // The odering function call is important here.
      setLoadingProgress(false);
      dispatch(setAccountInformation(data));

      if (intervalHandlerRef.current !== undefined)
        clearInterval(intervalHandlerRef.current);

      return true;
    }

    checkAPIStatus().then((result) => {
      if (!mountedStatusRef.current) return;
      if (result) return;

      // Call the api by every 2s.
      intervalHandlerRef.current = setInterval(
        checkAPIStatus,
        DEFAULT_TOAST_TIME
      );
    });
  };

  const caseAPIFailedFunc = () => {
    // Return to the previous step.
    setCurrentStep(0);
    // Failed API Key
    setMessage(
      "The API Key entered has failed setup. Please recheck the API Key and update to try again",
      accountID
    );
    dispatch(getApiKeyAndSecret(accountID)).then((res) => {
      setApiInfo(res);
    });
  };

  const caseAPISucceedFunc = () => {
    // Go to the next step Asset Allocation.
    setCurrentStep(2);
    showToast(
      "The API Key has been tested successfully! You can do the next step to setup an account.",
      "success",
      4000
    );
    setLoadingIndicator(true);
    Promise.all([
      dispatch(getAssetAllocationByAccountID(accountID)),
      dispatch(getAccountInformationByID(accountID)),
      dispatch(getSelfServiceAccounts()),
      dispatch(getBasketAssetsWithAssetsThatCouldBeBasket(accountID)),
      dispatch(getRiskManagedAssetsWithAssetsThatCouldBeRiskManaged(accountID)),
      dispatch(getAssetsPriceByExchangeAccountID(exchangeAccountID)),
      // eslint-disable-next-line no-empty-pattern
    ]).then(([, data, , , , ,]) => {
      dispatch(setSelectedAccount(data[0]));
      setLoadingIndicator(false);
    });
  };

  useEffect(() => {
    switch (apiStatusID) {
      case 1:
        // Pending
        caseAPIPendingFunc();
        break;
      case 2 || 3:
        // Failed
        caseAPIFailedFunc();
        break;
      case 4:
        // Succeed
        caseAPISucceedFunc();
        break;
      default:
        break;
    }

    return () => {
      if (intervalHandlerRef.current !== undefined)
        clearInterval(intervalHandlerRef.current);
      setLoadingIndicator(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    apiStatusID,
    dispatch,
    setLoadingIndicator,
    setLoadingProgress,
    setMessage,
  ]);

  const dispatchAddSelfServiceUser = async (values) => {
    await dispatch(createSelfServiceAccount(values));

    // Goes to the next step.
    setCurrentStep(1);
  };

  const dispatchUpdateSelfServiceAccount = async (values) => {
    const data = {
      api_secret: values.api_secret,
      api_key: values.api_key,
    };
    // dispatch(updateSelfServiceAccount(values, accountID)),
    await dispatch(updateApiKeyAndSecret(data, accountID)).then((res) => {
      setApiInfo(res);
      const update = {
        ...account,
        api_status_id: 1,
        api_status_name: "Pending",
      };

      dispatch(setAccountInformation(update));
    });
  };

  const goBack = () => {
    history.goBack();
  };

  const previousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  return (
    <React.Fragment>
      <h2 className={"content-block"}>
        <Button
          icon="arrowleft"
          className="margin-right-20"
          onClick={goBack}
          style={{ marginRight: 10 }}
        />
        SETUP ACCOUNT
      </h2>
      <div
        className={"content-block dx-card responsive-paddings"}
        id="setup-client-user"
      >
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ of: "#setup-client-user" }}
          visible={loading}
          container="#setup-client-user"
        />
        <AccountStepperProgress
          currentStep={currentStep}
          expertGUI={expertGUI}
        />
        {currentStep === 0 && account && (
          <EditAccountInformation
            account={account}
            apiInfo={apiInfo}
            exchanges={exchanges}
            setLoading={setLoadingIndicator}
            goBack={goBack}
            dispatchUpdateSelfServiceAccount={dispatchUpdateSelfServiceAccount}
          />
        )}
        {currentStep === 0 && !account && (
          <AddAccountInformation
            exchanges={exchanges}
            onboardingStatusInfo={onboardingStatusInfo}
            dispatchAddSelfServiceUser={dispatchAddSelfServiceUser}
          />
        )}
        {currentStep === 2 && <Strategy onboardingStatusInfo={onboardingStatusInfo} nextStep={nextStep} isSetup={true} />}
        {currentStep === 3 && managementTypeID === 3 && (
          <ViewOnlyManagement
            isSetup={true}
            onboardingStatusInfo={onboardingStatusInfo}
            expertGUI={expertGUI}
            accountID={accountID}
            previousStep={previousStep}
            setSelectedIndex={setSelectedAccount}
            managementTypeID={managementTypeID}
          />
        )}
        {currentStep === 3 && managementTypeID === 1 && (
          <BasicBasketTrading
            isSetup={true}
            onboardingStatusInfo={onboardingStatusInfo}
            expertGUI={expertGUI}
            accountID={accountID}
            previousStep={previousStep}
            managementTypeID={managementTypeID}
          />
        )}
        {currentStep === 3 && managementTypeID === 2 && (
          <BasicRiskManagement
            isSetup={true}
            onboardingStatusInfo={onboardingStatusInfo}
            expertGUI={expertGUI}
            accountID={accountID}
            previousStep={previousStep}
            managementTypeID={managementTypeID}
          />
        )}
        {currentStep === 4 && expertGUI && (
          <CompleteSetup onboardingStatusInfo={onboardingStatusInfo} previousStep={previousStep} setLoading={setLoading} />
        )}
      </div>
    </React.Fragment>
  );
};

export default SetupAccount;
