import React, { useRef, useState } from "react";
import LoadIndicator from "devextreme-react/load-indicator";
import { Popup } from "devextreme-react/popup";
import Form, {
  Item,
  GroupItem,
  ButtonItem,
  Label,
  ButtonOptions,
} from "devextreme-react/form";
import Error from "../../components/Error";
import { apiKeyDefaultOption, apiSecretDefaultOption } from "../../constants";

const UpdateAPIKeyAndSecretPopup = ({
  dispatchUpdateSelfServiceAccount,
  handleUpdateAPIKeySecretPopup,
}) => {
  const formData = useRef({});
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const values = formData.current;

      await dispatchUpdateSelfServiceAccount(values);
      setLoading(false);
      handleUpdateAPIKeySecretPopup();
    } catch (error) {
      setErrorMsg(error.message);
      setLoading(false);
    }
  };

  const renderContent = () => {
    return (
      <div>
        <form className="px-form" onSubmit={handleSubmit}>
          {!!errorMsg && <Error>{errorMsg}</Error>}
          <Form
            formData={formData.current}
            showColonAfterLabel={false}
            className={"expanded"}
          >
            <GroupItem colCount={2}>
              <Item
                dataField="api_key"
                editorOptions={apiKeyDefaultOption}
                isRequired={true}
              >
                <Label visible={true} text="API KEY" />
              </Item>
              <Item
                dataField="api_secret"
                editorOptions={apiSecretDefaultOption}
                isRequired={true}
              >
                <Label visible={true} text="API SECRET" />
              </Item>
            </GroupItem>
            <GroupItem colCount={2}>
              <ButtonItem horizontalAlignment="left">
                <ButtonOptions
                  width="100%"
                  text="Add"
                  type="default"
                  useSubmitBehavior={true}
                >
                  <span className="dx-button-text">
                    {loading ? (
                      <LoadIndicator
                        width={"24px"}
                        height={"24px"}
                        visible={true}
                      />
                    ) : (
                      "UPDATE"
                    )}
                  </span>
                </ButtonOptions>
              </ButtonItem>
              <ButtonItem horizontalAlignment="right">
                <ButtonOptions
                  width="100%"
                  type="normal"
                  text="Cancel"
                  useSubmitBehavior={false}
                  onClick={handleUpdateAPIKeySecretPopup}
                />
              </ButtonItem>
            </GroupItem>
          </Form>
        </form>
      </div>
    );
  };

  return (
    <Popup
      visible={true}
      width={700}
      height={!!errorMsg ? 320 : 280}
      position={"center"}
      showCloseButton={false}
      closeOnOutsideClick={false}
      contentRender={renderContent}
      title={`REPLACE API KEY & SECRET`}
    />
  );
};

export default UpdateAPIKeyAndSecretPopup;
