import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ToastProvider } from "./contexts/toast";
import { AppDataProvider } from "./contexts/app";
import { NavigationProvider } from "./contexts/navigation";
import SocialDashboardProvider from "./contexts/socialDashboard";
import { ContentLoadingProvider } from "./contexts/contentLoading";
import "./polyfills";
import "./index.css";

ReactDOM.render(
  <BrowserRouter
    getUserConfirmation={(message, callback) => {
      // Asking confirmation If user tries to leave specific page.
      const allowTransition = window.confirm(message);
      callback(allowTransition);
    }}
  >
    <Provider store={store}>
      <AppDataProvider>
        <ToastProvider>
          <NavigationProvider>
            <ContentLoadingProvider>
              <SocialDashboardProvider>
                <App />
              </SocialDashboardProvider>
            </ContentLoadingProvider>
          </NavigationProvider>
        </ToastProvider>
      </AppDataProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
