import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {
  ADVANCED_SETUP_MODE_STEPPER,
  BASIC_SETUP_MODE_STEPPER,
} from "../../constants";
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

// function getStepContent(stepIndex) {
//   switch (stepIndex) {
//     case 0:
//       return 'Testing api key...';
//     case 1:
//       return 'Change allocation.';
//     case 2:
//       return 'Completing account!';
//     default:
//       return 'Unknown stepIndex';
//   }
// }

export default function HorizontalLabelPositionBelowStepper({
  currentStep,
  expertGUI,
}) {
  const classes = useStyles();
  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };
  const getSteps = () => {
    if (expertGUI) return ADVANCED_SETUP_MODE_STEPPER;
    return BASIC_SETUP_MODE_STEPPER;
  };

  return (
    <div className={classes.root}>
      <MobileStepper activeStep={currentStep} alternativeLabel>
        {getSteps().map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </MobileStepper>
      {/* <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
            <div>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </div>
          </div>
        )}
      </div> */}
    </div>
  );
}
