import React, { useRef, useState } from "react";
import { Button } from "devextreme-react/button";
import LoadIndicator from "devextreme-react/load-indicator";
import Form, {
  ButtonItem,
  ButtonOptions,
  NumericRule,
  GroupItem,
  Item,
  Label,
  RequiredRule,
} from "devextreme-react/form";
import { useToast } from "../../contexts/toast";
import { CONFIRM_CODE_SUCCESS_MSG } from "../../constants/app";

const Account2FAForm = (props) => {
  const { user, dispatchChange2FA } = props;
  const formData = useRef({});
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const handle2FAForm = () => {
    setShowForm(!showForm);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { code } = formData.current;

    setLoading(true);
    try {
      await dispatchChange2FA(code);
      setLoading(false);
      showToast(CONFIRM_CODE_SUCCESS_MSG, "success");
    } catch (error) {
      setLoading(false);
      showToast(error.message, "error", 4000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <React.Fragment>
      {user && user.client_user_is_2fa && (
        <React.Fragment>
          <div className={"content-2fa"}>
            <p>Two-step verification is on.</p>
            <Button
              className="disable-btn"
              disabled={showForm}
              type="default"
              onClick={handle2FAForm}
            >
              CHANGE 2FA
            </Button>
          </div>
        </React.Fragment>
      )}
      {showForm && (
        <form id={"change-2fa-form"} onSubmit={onSubmit}>
          <Form
            formData={formData.current}
            disabled={loading}
            readOnly={false}
            showColonAfterLabel={true}
            validationGroup="currentData"
          >
            <GroupItem colCount={3}>
              <GroupItem>
                <Item
                  dataField={"code"}
                  editorType={"dxTextBox"}
                  editorOptions={codeEditorOptions}
                >
                  <RequiredRule message="The code is required" />
                  <NumericRule message="The code should be number." />
                  <Label visible={true} text="2FA CODE" />
                </Item>
                <GroupItem colCount={2}>
                  <ButtonItem>
                    <ButtonOptions
                      width={"100%"}
                      type={"default"}
                      useSubmitBehavior={true}
                    >
                      <span className="dx-button-text">
                        {loading ? (
                          <LoadIndicator
                            width={"24px"}
                            height={"24px"}
                            visible={true}
                          />
                        ) : (
                          "CONFIRM"
                        )}
                      </span>
                    </ButtonOptions>
                  </ButtonItem>
                  <ButtonItem horizontalAlignment="right">
                    <ButtonOptions
                      width="100%"
                      type="normal"
                      text="Cancel"
                      useSubmitBehavior={false}
                      onClick={handle2FAForm}
                    />
                  </ButtonItem>
                </GroupItem>
              </GroupItem>
            </GroupItem>
          </Form>
        </form>
      )}
    </React.Fragment>
  );
};

const codeEditorOptions = {
  stylingMode: "filled",
  placeholder: "Type your authentication code",
  mode: "text",
};

export default Account2FAForm;
