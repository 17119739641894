import { api } from "../../service";
import {
  SET_COMPANY_LOGO,
  ENABLE_INSTRUCTION_VIDEO,
  SET_RANKINGS_DATA,
} from "./constant";

/**
 * Get the company logo.
 *
 * @param {string} legalEntityID
 * @returns
 */
export const getCompanyLogo = (legalEntityID) => async (dispatch) => {
  const { data } = await api.get(
    `/reference/legal-entities/${legalEntityID}/logo`
  );

  if (data) dispatch({ type: SET_COMPANY_LOGO, payload: data });

  return data;
};

/**
 * Get the account values.
 *
 * @param {String} clientAccountID
 * @returns
 */
export const getAccountValues = (clientAccountID) => async (dispatch) => {
  try {
    const { data } = await api.get(`/accounts/${clientAccountID}/single-stats`);

    if (data) {
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * Get the balance history.
 *
 * @returns JSON data
 */
export const getBalanceHistory = (clientAccountID) => async (dispatch) => {
  const { data } = await api.get(
    `/accounts/${clientAccountID}/balance-history`
  );

  return data;
};

/**
 * Get the balance history.
 *
 * @returns JSON data
 */
export const getHolidings = (clientAccountID) => async (dispatch) => {
  const { data } = await api.get(`/accounts/${clientAccountID}/holdings`);

  return data;
};

/**
 * Switch the account mode.
 *
 * @param {String} clientAccountID
 * @returns
 */
export const switchAccountMode =
  (clientAccountID, accountFlag) => async (dispatch) => {
    const { data } = await api.put(`/accounts/${clientAccountID}/expert-gui`, {
      expert_gui: accountFlag,
    });

    return data;
  };

/**
 * Get the status of spreedsheet's request.
 *
 * @param {String} clientAccountID
 * @returns
 */
export const postRequestToExportAccountHistory =
  (clientAccountID) => async (dispatch) => {
    const { data } = await api.post(
      `/accounts/${clientAccountID}/trade-list-export`
    );

    return data;
  };

/**
 * Get the spreedsheet file.
 *
 * @param {String} clientAccountID
 * @returns
 */
export const getRequestStatusOfAccountHistoryFile =
  (clientAccountID) => async () => {
    const { data } = await api.get(
      `/accounts/${clientAccountID}/trade-list-export`
    );

    return data;
  };

export const getPerformanceGraphDropDownContents =
  (clientAccountID) => async () => {
    const { data } = await api.get(`/accounts/${clientAccountID}/charts`);

    return data;
  };

export const getPerformanceGraphByChartID =
  (clientAccountID, chartID) => async () => {
    const { data } = await api.get(
      `/accounts/${clientAccountID}/charts/${chartID}`
    );

    return data;
  };

export const getOnboardingElementStatusByID = (clientUserID) => async () => {
  const { data } = await api.get(`/users/${clientUserID}/ui-elements`);

  return data;
};

export const updateOnboardingElementStatusByID =
  (clientUserID, elementID, flag) => async () => {
    await api.put(`/users/${clientUserID}/ui-elements/${elementID}`, {
      visible: flag,
    });
  };

export const updateOnboardingElement = (clientUserID, flag) => async () => {
  await api.put(`/users/${clientUserID}/ui-elements`, { visible: flag });
};

// Settings - Notifications
export const getHintsTipsStatus = (clientUserID) => async () => {
  const { data } = await api.get(
    `/users/${clientUserID}/ui-elements/hints-tips`
  );

  return data;
};

export const updateHintsTipsStatus = (clientUserID, flag) => async () => {
  await api.put(`/users/${clientUserID}/ui-elements/hints-tips`, {
    hints_tips_visibility: flag,
  });
};

export const getEmailCommunicationStatus = (clientUserID) => async () => {
  const { data } = await api.get(`/users/${clientUserID}/receive-emails`);

  return data;
};

export const updateEmailCommunicationStatus =
  (clientUserID, flag) => async () => {
    await api.put(`/users/${clientUserID}/receive-emails`, {
      receive_emails: flag,
    });
  };

export const getCompetitions = () => async () => {
  const { data } = await api.get(`/competitions`);

  return data;
};

export const getRankingsByCompetitionID = (competitionID) => async () => {
  const { data } = await api.get(`/competitions/${competitionID}`);

  return data;
};

export const enableInstructionVideo = (data) => async (dispatch) => {
  dispatch({ type: ENABLE_INSTRUCTION_VIDEO, payload: data });
};

export const setRankingsData = (data) => async (dispatch) => {
  dispatch({ type: SET_RANKINGS_DATA, payload: data });
};
